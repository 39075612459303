import { CurrencyModel } from "../../../models/CurrencyModel";
import { Result } from "../../../models/Result";
import { httpService } from "../../HttpService";

class CurrenciesApiService {
    path: string = "currency";

    getAll = async (): Promise<Result<CurrencyModel[]>> => await httpService.get<CurrencyModel[]>(`/${this.path}`);

    getActive = async (): Promise<Result<CurrencyModel[]>> => await httpService.get<CurrencyModel[]>(`/${this.path}/active`);

    getMerchantActiveCurrencies = async (merchantId: number): Promise<Result<CurrencyModel[]>> => await httpService.get<CurrencyModel[]>(`/${this.path}/${merchantId}/active`);
}

const currenciesApiService = new CurrenciesApiService();

export { currenciesApiService };
