import React, { Component, Fragment } from "react";
import { Container } from "reactstrap";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { classNames } from "primereact/utils";
import { Toast } from "primereact/toast";
import { User } from "../../models/User";
import UserCommentsComponent from "./UserCommentsComponent";
import UserDevicesComponent from "./UserDevicesComponent";
import Loader from "../../components/Loader";

interface MiscComponentState {
    isLoading: boolean;
}

interface MiscComponentProps {
    userId: number
}

export default class MiscComponent extends Component<MiscComponentProps, MiscComponentState> {
    toast: Toast | null = null;

    constructor(props: any) {
        super(props);

        this.state = {
            isLoading: false,
        };
    }

    onResetPassword = () => {
        this.toast?.show({ severity: "success", summary: "Action Completed", detail: "Reset Email Sent Successfully." });
    };

    onUserStatusChanged = (user: User) => {
        this.toast?.show({ severity: "success", summary: "Action Completed", detail: "User Status Changed Successfully." });
    };

    renderStatusBadge(success: boolean, title: string) {
        return <div className={classNames("badge", "badge-" + (success ? "success" : "danger"))}>{title}</div>;
    }

    render() {
        if (this.state.isLoading) {
            return (
                <>
                    <Toast ref={(el) => (this.toast = el)} />
                    <Loader />
                </>
            );
        }

        return (
            <Fragment>
                <TransitionGroup appear={true} exit={false} enter={false}>
                    <CSSTransition classNames="TabsAnimation" timeout={1500}>
                        <Fragment>
                            <Container fluid>
                                <Toast ref={(el) => (this.toast = el)} position={"bottom-center"} baseZIndex={99999}></Toast>
                                <UserDevicesComponent userId={this.props.userId} />
                                <UserCommentsComponent />
                            </Container>
                        </Fragment>
                    </CSSTransition>
                </TransitionGroup>
            </Fragment>
        );
    }
}
