import React, { Fragment } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { LookupItem } from "../../models/Risk";
import { riskApiService } from "../../services/RiskService";
import { Toast } from "primereact/toast";
import ExtendedDataTable from "../../components/ExtendedDataTable";
import { SearchRequestBaseModel, SortOrderType } from "../../models/SearchRequestModel";
import { Column } from "primereact/column";
import { ConvertToISortModel, numberFilterMatchModeOptions, stringFilterMatchModeOptions } from "../Paginator";
import { Card, CardBody, CardHeader, Col, Container, Dropdown, FormGroup, Label, Row } from "reactstrap";
import ExtendedDropdown from "../../components/ExtendedDropdown";
import { SelectItem } from "../../models/SelectItem";

interface LookupItemsProps {
    onHide: () => void; 
}

interface LookupItemsState {
    lookupItems: LookupItem[],
    selectedLookupItem: LookupItem | null,
    editedRiskScore: string;
    inEditMode: boolean,
    visibleEditDialog: boolean,
    visibleConfirmDialog: boolean,
    isLoading: boolean,
    selectedRiskGroup: string,
    totalRecords: number
}

class LookupItemsForm extends React.Component<LookupItemsProps, LookupItemsState> {
    toast: Toast | null = null;
    validationErrors: Map<string, boolean> = new Map<string, boolean>();
    private dataTable: ExtendedDataTable | null = null;

    riskGroups: SelectItem[] = [
        {label: "Main Activity", value: "MainActivity"},
        {label: "Document Authenticity", value: "DocumentAuthenticity"},
        {label: "Account Purpose", value: "AccountPurpose"},
        {label: "Financing Method", value: "FinancingMethod"},
        {label: "Source Of Funds", value: "SourceOfFunds"},
        {label: "Countries", value: "CountriesRisk"}
    ];

    constructor(props: LookupItemsProps | Readonly<LookupItemsProps>) {
        super(props);
        this.state = {
            lookupItems: [],
            selectedLookupItem: null,
            inEditMode: true,
            editedRiskScore : "",
            visibleEditDialog: false,
            visibleConfirmDialog: false,
            isLoading: false,
            selectedRiskGroup: "MainActivity",
            totalRecords: 0,
        };
    }

    // async componentDidMount() {
    //     this.setState({ isLoading: true });
    //     this.getData(this.state.selectedRiskGroup, {
    //         pageNumber: this.dataTable?.state.page! ?? 1,
    //         pageSize: this.dataTable?.state.pageRowCount! ?? 10,
    //         filters: [],
    //         sortings: ConvertToISortModel(this.dataTable?.state.multiSortMeta) //[]
    //     });
    //     this.setState({ isLoading: false });
    // }

    loadData = async (searchRequest: SearchRequestBaseModel) => {
        this.setState({ isLoading: true });
        //this.props.filterFunction && this.props.filterFunction(searchRequest);

        const lookupResult = await riskApiService.getRiskGroupAllPaged(this.state.selectedRiskGroup, searchRequest);
        this.setState({ isLoading: false });

        if (!lookupResult.success) {
            this.toast?.show({ severity: "error", summary: "Error", detail: lookupResult.message, life: 3000 });
            //this.dataTable!.reset();
            return;
        }
        this.setState({ lookupItems: lookupResult.data, totalRecords: lookupResult.totalCount });
    };

    async getData(riskGroup: string, searchRequest: SearchRequestBaseModel) {
        const lookupResult = await riskApiService.getRiskGroupAllPaged(riskGroup, searchRequest);
        if (!lookupResult.success) {
            this.toast?.show({ severity: "error", summary: "Error", detail: lookupResult.message, life: 3000 });
            this.dataTable!.reset();
            return;
        }
        this.setState({ lookupItems: lookupResult.data, totalRecords: lookupResult.totalCount });
    }

    onFieldUpdate = (fieldName: string, value: any) => {
        this.setState({ editedRiskScore: value });

    };

    handleSubmit = async () => {
        let lookupResult;
        const selectedItem = this.state.selectedLookupItem as any;
        selectedItem.riskScore = this.state.editedRiskScore;


        if (this.state.inEditMode && this.state.selectedLookupItem != null) 
            lookupResult = await riskApiService.updateRiskGroupValue(this.state.selectedRiskGroup, selectedItem!);
        else 
            lookupResult = await riskApiService.createRiskGroupValue(this.state.selectedRiskGroup, selectedItem!);

        if (!lookupResult.success) {
            this.toast?.show({ severity: "error", summary: "Error", detail: lookupResult.message, life: 3000 });
            return;
        }        
        this.getData(this.state.selectedRiskGroup, {
            pageNumber: this.dataTable?.state.page!,
            pageSize: this.dataTable?.state.pageRowCount!,
            filters: [],
            sortings: ConvertToISortModel(this.dataTable?.state.multiSortMeta)
        });
        this.setState({ visibleEditDialog: false});
    };

    
    handleAdd = () => {
        const newLookupItem: LookupItem = {name: "", code: "", riskScore: 0.0} as LookupItem;
        this.setState({ visibleEditDialog: true, inEditMode:false, selectedLookupItem: newLookupItem });
    };    

    handleEdit = async (riskGroup: string, lookupItem: LookupItem) => {
        this.setState({ editedRiskScore: "" });
        this.setState({ visibleEditDialog: true, inEditMode:true, selectedLookupItem: lookupItem });
    };

    handleDelete = async () => {
        const lookupResult = await riskApiService.deleteRiskGroupValue(this.state.selectedRiskGroup, this.state.selectedLookupItem!.code);
        if (!lookupResult.success) {
            this.toast?.show({ severity: "error", summary: "Error", detail: lookupResult.message, life: 3000 });
            return;
        }        
        this.setState({ visibleConfirmDialog: false});
        this.getData(this.state.selectedRiskGroup, {
            pageNumber: this.dataTable?.state.page!,
            pageSize: this.dataTable?.state.pageRowCount!,
            filters: [],
            sortings: ConvertToISortModel(this.dataTable?.state.multiSortMeta)
        });
    };

    confirmDelete = async (lookupItem: LookupItem) => {
        this.setState({ visibleConfirmDialog: true, selectedLookupItem: lookupItem });
    };

    renderHeader = () => {
        return (
            <span className="p-input-icon-left" style={{ display: "flex", justifyContent: "space-between" }}>
                <Button type="button" icon="pi pi-filter-slash" label="Clear" className="p-button-outlined" onClick={() => { this.dataTable!.reset(); }} />
                <Button label="Add" onClick={this.handleAdd} style={{ marginRight: "22px" }} />
            </span>
        );
    };

    render() {
        //const { name, code, riskScore, lookupItems, visibleEditDialog } = this.state;
        const dialogHeader = this.state.inEditMode ? "Edit Risk Value" : "Add Risk Value";

        return (
            <Fragment>
                {/* Add Dialog */}
                <Toast ref={(el) => (this.toast = el)} />
                <Dialog header={dialogHeader} visible={this.state.visibleEditDialog} style={{ width: "40vw" }} onHide={() => this.setState({ visibleEditDialog: false })}>
                    <Container fluid>
                        <Card className="mb-3"> 
                            { (this.state.selectedRiskGroup) && <CardHeader className="card-header-tab d-flex justify-content-between" >
                                <span>{this.riskGroups.find((item) => item.value === this.state.selectedRiskGroup)?.label}</span>
                            </CardHeader>}
                            { (this.state.selectedLookupItem) && 
                                <CardBody>
                                	<FormGroup>
                                		<Label for="Code">Code</Label>
                                		<InputText value={this.state.selectedLookupItem!.code} className={"form-control"} id="code" readOnly={this.state.inEditMode} 
                                			onChange={(e) => this.onFieldUpdate("code", e.target.value)} required />
                                	</FormGroup>

                                	<FormGroup>
                                		<Label for="Name">Name</Label>
                                		<InputText value={this.state.selectedLookupItem!.name} className={"form-control"} id="name" readOnly={false} 
                                			onChange={(e) => this.onFieldUpdate("name", e.target.value)} required />
                                	</FormGroup>

                                	<FormGroup>
                                		<Label for="riskScore">Risk Score</Label>
                                		<InputText value={!this.state.editedRiskScore ? this.state.selectedLookupItem!.riskScore : this.state.editedRiskScore} className={"form-control"} id="riskScore" readOnly={false} 
                                			onChange={(e) => this.onFieldUpdate("riskScore", e.target.value)} required />
                                	</FormGroup>
                                </CardBody>}
                        </Card> 
                    </Container>
                    <div className="align-right" style={{ padding: "10px" }}>
                        <Button label="Save" icon="pi pi-check" loading={this.state.isLoading} onClick={this.handleSubmit} />
                    </div>
                </Dialog>


                {/* Risk Configure Dialog */}

                <FormGroup>
                    <Label for="riskGroup" style={{ fontWeight: "bold" }}>Risk group</Label>
                    <ExtendedDropdown
                        style={{ display: "flex" }}
                        value={this.state.selectedRiskGroup}
                        options={this.riskGroups}
                        onChange={(e) => {
                            const selectedRiskGroup = e.value;
                            this.setState({ selectedRiskGroup }, () => {
                                this.getData(selectedRiskGroup, {
                                    pageNumber: this.dataTable?.state.page! ?? 1,
                                    pageSize: this.dataTable?.state.pageRowCount! ?? 10,
                                    filters: [],
                                    sortings: []
                                });
                            });
                        }}
                        filter
                        filterBy="label"
                        required={true}
                        name="Main activity"
                        onValidStatusChanged={(value: boolean) => this.validationErrors.set("riskGroup", value)}
                        disabled={false}
                    />
                </FormGroup>    

                <Card className="mb-3"> 
                    <CardBody>
                        {/* <div className="align-right" style={{ padding: "10px" }}> */}
                        {/* <Button label="Add" onClick={this.handleAdd} /> */}
                        {/* </div> */}

                        {this.state.lookupItems && //this.dataTable &&
                        <ExtendedDataTable
                        	header={this.renderHeader}
                        	value={this.state.lookupItems}
                        	totalRecords={this.state.totalRecords}
                        	onChange={this.loadData}
                        	loading={this.state.isLoading}
                        	ref={(ref) => this.dataTable = ref}
                        	defaultSortOrder={SortOrderType.Ascending}
                        	defaultSortField='code'
                        >
                        	<Column field="code" header="Code" filterMatchModeOptions={stringFilterMatchModeOptions}></Column>
                        	<Column
                        		header="Name"
                        		field="name"
                        		body={(rowData: LookupItem) => <span>{rowData.name}</span>}
                        		filterMatchModeOptions={stringFilterMatchModeOptions}>
                        	</Column>
                        	<Column
                        		header="Risk Score"
                        		field="riskScore"
                        		body={(rowData: LookupItem) => <span>{rowData.riskScore}</span>}
                        		filterMatchModeOptions={numberFilterMatchModeOptions}>
                        	</Column>
                        	<Column
                        		header=""
                        		body={(rowData: LookupItem) =>
                        			<span>
                        				{/* p-button-raised p-button-rounded  p-button-text p-button-icon p-button */}
                        				<Button label="Edit" type="button" className="p-button p-button-success" onClick={() => this.handleEdit(this.state.selectedRiskGroup, rowData)} style={{ marginRight: "5px" }}></Button>
                        				<Button label="Delete" type="button" className="p-button p-button-danger" onClick={() => this.confirmDelete(rowData)}></Button>
                        			</span>
                        		}
                        		headerStyle={{ textAlign: "center" }}
                        		bodyStyle={{ textAlign: "center", overflow: "visible" }}
                        	/>
                        </ExtendedDataTable>}
                    </CardBody>
                </Card>     


                {this.state.visibleConfirmDialog && <Dialog header="Please confirm delete" visible={true} style={{ width: "30vw" }} 
                    modal onHide={() => { this.setState({ visibleConfirmDialog: false }); }}>
                    <Container fluid>
                        <Row>
                            <div>
                                <Label></Label>
                            </div>
                        </Row>
                        <Row>
                            <Col>
                                <div className="align-right" style={{ padding: "10px" }}> 
                                    <Button
                                        label={"Cancel"}
                                        type="button"
                                        className="p-button-success"
                                        onClick={() => { this.setState({ visibleConfirmDialog: false }); }}
                                        style={{ marginRight: "10px" }}
                                    ></Button>
                                    <Button
                                        label={"Delete"}
                                        type="button"
                                        className="p-button-danger"
                                        onClick={() => this.handleDelete() }
                                    ></Button>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Dialog>}

            </Fragment>    
        // </Dialog> 
        );
    }
}

export default LookupItemsForm;
