import React, { Fragment } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { riskApiService } from "../../services/RiskService";
import { Toast } from "primereact/toast";
import ExtendedDataTable from "../../components/ExtendedDataTable";
import { SearchRequestBaseModel, SortOrderType } from "../../models/SearchRequestModel";
import { Column } from "primereact/column";
import { ConvertToISortModel, numberFilterMatchModeOptions, stringFilterMatchModeOptions } from "../Paginator";
import { Card, CardBody, Col, Container, Dropdown, FormGroup, Label, Row, CardHeader } from "reactstrap";
import { IndividualRiskMatrix } from "../../models/IndividualRiskMatrix";

interface IndividualRiskMatrixProps {
    onHide: () => void; 
}

interface IndividualRiskMatrixState {
    riskParams: IndividualRiskMatrix[];
    selectedRiskParam: IndividualRiskMatrix | null;
    editedParamWeight: string;
    inEditMode: boolean;
    visibleEditDialog: boolean;
    visibleConfirmDialog: boolean;
    isLoading: boolean;
    totalRecords: number;
}

class IndividualRiskMatrixForm extends React.Component<IndividualRiskMatrixProps, IndividualRiskMatrixState> {
    toast: Toast | null = null;
    validationErrors: Map<string, boolean> = new Map<string, boolean>();
    private dataTable: ExtendedDataTable | null = null;

    constructor(props: IndividualRiskMatrixProps | Readonly<IndividualRiskMatrixProps>) {
        super(props);
        this.state = {
            riskParams: [],
            selectedRiskParam: null,
            editedParamWeight: "", 
            inEditMode: true,
            visibleEditDialog: false,
            visibleConfirmDialog: false,
            isLoading: false,
            totalRecords: 0,
        };
    }

    // async componentDidMount() {
    //     this.setState({ isLoading: true });
    //     this.getData({
    //         pageNumber: this.dataTable?.state.page!,
    //         pageSize: this.dataTable?.state.pageRowCount!,
    //         filters: [],
    //         sortings: []
    //     });
    //     this.setState({ isLoading: false });
    // }

    loadData = async (searchRequest: SearchRequestBaseModel) => {
        this.setState({ isLoading: true });
        const lookupResult = await riskApiService.getIndividualRiskMatrixPaged(searchRequest);
        this.setState({ isLoading: false });
        if (!lookupResult.success) {
            this.toast?.show({ severity: "error", summary: "Error", detail: lookupResult.message, life: 3000 });
            //this.dataTable!.reset();
            return;
        }
        this.setState({ riskParams: lookupResult.data, totalRecords: lookupResult.totalCount });
    };

    onFieldUpdate = (fieldName: string, value: any) => {
        this.setState({ editedParamWeight: value });

    };

    handleSubmit = async () => {
        let lookupResult;
        const selectedItem = this.state.selectedRiskParam as any;
        selectedItem.paramWeight = this.state.editedParamWeight;
        if (this.state.selectedRiskParam != null) {
            lookupResult = await riskApiService.updateIndividualRiskMatrixParam(selectedItem!);

            if (!lookupResult.success) {
                this.toast?.show({ severity: "error", summary: "Error", detail: lookupResult.message, life: 3000 });
                return;
            }
        }        
        this.loadData({
            pageNumber: this.dataTable?.state.page!,
            pageSize: this.dataTable?.state.pageRowCount!,
            filters: [],
            sortings: ConvertToISortModel(this.dataTable?.state.multiSortMeta)
        });

        this.setState({ visibleEditDialog: false});
    };

    handleEdit = async (riskParam: IndividualRiskMatrix) => {
        this.setState({ editedParamWeight: "" });
        this.setState({ visibleEditDialog: true, inEditMode:true, selectedRiskParam: riskParam });
    };

    renderHeader = () => {
        return (
            <span className="p-input-icon-left" style={{ display: "flex", justifyContent: "space-between" }}>
                <Button type="button" icon="pi pi-filter-slash" label="Clear" className="p-button-outlined" onClick={() => { this.dataTable!.reset(); }} />
            </span>
        );
    };

    render() {
        const dialogHeader = this.state.inEditMode ? "Edit Risk Value" : "Add Risk Value";

        return (
            <Fragment>
                {/* Add Dialog */}
                <Toast ref={(el) => (this.toast = el)} />
                <Dialog header={dialogHeader} visible={this.state.visibleEditDialog} style={{ width: "40vw" }} onHide={() => this.setState({ visibleEditDialog: false })}>
                    <Container fluid>
                        <Card className="mb-3"> 
                            { (this.state.selectedRiskParam) && 
                                <CardBody>
                                	<FormGroup>
                                		<Label for="name">Param Name</Label>
                                		<InputText value={this.state.selectedRiskParam!.riskParamName} className={"form-control"} id="name" readOnly={true} required />
                                	</FormGroup>

                                	<FormGroup>
                                		<Label for="paramWeight">Param Weight</Label>
                                		{console.log(this.state.selectedRiskParam!.paramWeight)}
                                		<InputText value={!this.state.editedParamWeight ? this.state.selectedRiskParam.paramWeight : this.state.editedParamWeight} className={"form-control"} id="paramWeight" readOnly={false} 
                                			onChange={(e) => this.onFieldUpdate("paramWeight", e.target.value)} required />
                                	</FormGroup>
                                </CardBody>}
                        </Card> 
                    </Container>
                    <div className="align-right" style={{ padding: "10px" }}>
                        <Button label="Save" icon="pi pi-check" loading={this.state.isLoading} onClick={this.handleSubmit} />
                    </div>
                </Dialog>

                {/* Risk Configure Dialog */}
                <Card className="mb-3" CardHeader="Risk Matrix"> 
                    <CardHeader className="card-header-tab d-flex justify-content-between" >
                        <span>Individual Risk Matrix</span>
                    </CardHeader>

                    <CardBody>
                        <ExtendedDataTable
                            header={this.renderHeader}
                            value={this.state.riskParams}
                            totalRecords={this.state.totalRecords}
                            onChange={this.loadData}
                            loading={this.state.isLoading}
                            ref={(ref) => this.dataTable = ref}
                            defaultSortOrder={SortOrderType.Ascending}
                            defaultSortField='riskParamName'
                        >
                            {/* <Column field="code" header="Code" filterMatchModeOptions={stringFilterMatchModeOptions}></Column> */}
                            <Column
                                header="Param Name"
                                field="riskParamName"
                                body={(rowData: IndividualRiskMatrix) => <span>{rowData.riskParamName}</span>}
                                filterMatchModeOptions={stringFilterMatchModeOptions}>
                            </Column>
                            <Column
                                header="Param Weight"
                                field="paramWeight"
                                body={(rowData: IndividualRiskMatrix) => <span>{rowData.paramWeight}</span>}
                                // body={(rowData: IndividualRiskMatrix) => 
                                //         // <span>{rowData.paramWeight}</span>
                                //         <InputText value={rowData.paramWeight} 
                                //         className={"form-control"} id="paramWeight" 
                                //         //onChange={(e) => this.onFieldUpdate("lastName", e.target.value)} 
                                //         readOnly={false} />
                                //     }
                                filterMatchModeOptions={numberFilterMatchModeOptions}>
                            </Column>
                            <Column
                                header=""
                                body={(rowData: IndividualRiskMatrix) =>
                                    <span>
                                        {/* p-button-raised p-button-rounded  p-button-text p-button-icon p-button */}
                                        <Button label="Edit" type="button" className="p-button p-button-success" onClick={() => this.handleEdit(rowData)} style={{ marginRight: "5px" }}></Button>
                                        {/* <Button label="Delete" type="button" className="p-button p-button-danger" onClick={() => this.confirmDelete(rowData)}></Button> */}
                                    </span>
                                }
                                headerStyle={{ textAlign: "center" }}
                                bodyStyle={{ textAlign: "center", overflow: "visible" }}
                            />
                        </ExtendedDataTable>
                    </CardBody>
                </Card>     
            </Fragment>    
        // </Dialog> 
        );
    }
}

export default IndividualRiskMatrixForm;
