import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import React, { Component, Fragment } from "react";
import { Col, Container, FormGroup, Label, Row } from "reactstrap";
import {DropdownChangeParams } from "primereact/dropdown";
import { Button } from "primereact/button";
import { SelectItem } from "../../models/SelectItem";
import "../Merchants/tariffDetails.scss";
import { CSSTransitionComponent } from "../../components/CSSTransition";
import { Toast } from "primereact/toast";
import ExtendedDropdown from "../../components/ExtendedDropdown";
import Loader from "../../components/Loader";
import { UserAddress } from "../../models/User";

interface AddUserAddressProps {
    onHide: () => void;
    address: UserAddress ;
    countries: SelectItem[];
    onAddressUpdate: (address: UserAddress) => void;
}

interface AddUserAddressState {
    address: UserAddress;
    isLoading: boolean;
}

export default class AddUserAddress extends Component<AddUserAddressProps, AddUserAddressState> {
    toast: Toast | null = null;
    validationErrors: Map<string, boolean> = new Map<string, boolean>();
    constructor(props: AddUserAddressProps | Readonly<AddUserAddressProps>) {
        super(props);

        this.state = {
            address: this.props.address,
            isLoading: false,
        };
    }

    updateValue = (fieldName: string, value: string) => {
        const userAddressDetails : any = this.state.address;
        userAddressDetails[fieldName] = value;
        this.setState({ address: userAddressDetails });
    };

    onSave = () => {
        this.props.onAddressUpdate(this.state.address);
    };

    onCountryChange = (e: DropdownChangeParams) => {
        const addressDetails = this.state.address;
        addressDetails!.countryId = (e as DropdownChangeParams).value;

        this.setState({ address: addressDetails });
    };

    selectedCountryTemplate(option: SelectItem, props: { placeholder: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal; }) {
        if (option) {
            return (
                <div className="country-item country-item-value">
                    <img
                        style={{ float: "left", marginRight: "5px" }}
                        alt={option.label}
                        src="showcase/demo/images/flag_placeholder.png"
                        onError={(e) => ((e.target as any).src = "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")}
                        className={`flag flag-${option.value.toLowerCase()}`}
                    />
                    <div>{option.label}</div>
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    }

    countryOptionTemplate(option: SelectItem) {
        return (
            <div className="country-item">
                <img
                    alt={option.label}
                    src="showcase/demo/images/flag_placeholder.png"
                    onError={(e) => ((e.target as any).src = "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")}
                    className={`flag flag-${option.value.toLowerCase()}`}
                />
                <div>{option.label}</div>
            </div>
        );
    }

    render() {
        if (this.state.address == null) {
            return (
                <>
                    <Toast ref={(el) => (this.toast = el)} />
                    <Loader />;
                </>
            );
        }
        return (
            <Dialog header="Address" visible={true} style={{ width: "60vw" }} onHide={this.props.onHide}>
                <CSSTransitionComponent>
                    <Fragment>
                        <Toast ref={(el) => (this.toast = el)} />
                        <Container fluid>
                            <Row className="d-flex justify-content-center">
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>Address Line</Label>
                                        <InputText
                                            value={this.state.address?.addressLine}
                                            className={"form-control"}
                                            type="text"
                                            onChange={(e) => this.updateValue("addressLine", e.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>Country</Label>
                                        <ExtendedDropdown
                                            style={{ width: "100%" }}
                                            value={this.state.address?.countryId}
                                            options={this.props.countries}
                                            onChange={this.onCountryChange}
                                            filter
                                            filterBy="label"
                                            placeholder="Select a Country"
                                            required={true}
                                            name="country"
                                            onValidStatusChanged={(value: boolean) => (this.validationErrors.set("countryId" , value))}
                                            valueTemplate={this.selectedCountryTemplate}
                                            itemTemplate={this.countryOptionTemplate}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className="d-flex justify-content-center">
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>City</Label>
                                        <InputText
                                            value={this.state.address?.city}
                                            className={"form-control"}
                                            type="text"
                                            onChange={(e) => this.updateValue("city", e.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>State</Label>
                                        <InputText
                                            value={this.state.address?.stateProvinceCounty}
                                            className={"form-control"}
                                            type="text"
                                            onChange={(e) => this.updateValue("stateProvinceCounty", e.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className="d-flex justify-content-center">
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>Zip Code</Label>
                                        <InputText
                                            value={this.state.address?.zipCode}
                                            className={"form-control"}
                                            type="text"
                                            onChange={(e) => this.updateValue("zipCode", e.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                      
                                    </FormGroup>
                                </Col>
                            </Row>
                            <div className="align-right" style={{ padding: "10px" }}>
                                <Button label="Update" icon="pi pi-check" loading={this.state.isLoading} onClick={this.onSave} />
                            </div>
                        </Container>
                    </Fragment>
                </CSSTransitionComponent>
            </Dialog>
        );
    }
}
