import { DataTable, DataTableHeaderTemplateType, DataTableMultiSortMetaType, DataTablePFSEvent, DataTableSortMeta } from "primereact/datatable";
import React, { ReactElement } from "react";
import { SearchRequestBaseModel, SortOrderType } from "../models/SearchRequestModel";
import { paginatorTemplate, OnPageChange, IPagedState, ConvertToISortModel } from "../Pages/Paginator";
import { Column } from "primereact/column";

interface ExtendedDataGridProps {
    header?: DataTableHeaderTemplateType;
    value?: any[];
    loading: boolean;
    onChange: (searchRequest: SearchRequestBaseModel) => Promise<any>;
    onSorting?: (searchRequest: SearchRequestBaseModel) => Promise<any>;
    totalRecords: number;
    defaultSortField: string | null;
    defaultSortOrder: SortOrderType | null;
}

interface ExtendedDataGridState extends IPagedState {
}

export default class ExtendedDataTable extends React.Component<ExtendedDataGridProps, ExtendedDataGridState>  {
    
    private dataTable: DataTable | null = null;

    constructor(props: ExtendedDataGridProps) {
        super(props);

        this.state = {
            multiSortMeta: this.props.defaultSortField ? [{ order: this.props.defaultSortOrder, field: this.props.defaultSortField } as DataTableSortMeta] : [],
            first: 0,
            page: 1,
            filters: null!,
            pageRowCount: 10
        };
    }

    reset = () => {
        this.dataTable?.reset();
        this.setState({first:0, filters: null!, multiSortMeta: this.props.defaultSortField ? [{ order: this.props.defaultSortOrder, field: this.props.defaultSortField } as DataTableSortMeta] : [], page: 1, pageRowCount: 10 });
        this.props.onChange({ filters: [], sortings: ConvertToISortModel(this.state.multiSortMeta), pageNumber: 1, pageSize: 10 });
    };

    componentDidMount(): void {
        this.props.onChange({ filters: [], sortings: ConvertToISortModel(this.state.multiSortMeta), pageNumber: 1, pageSize: 10 });
    }
    
    onFilterApply = (event: DataTablePFSEvent) => {
        event.first = 0
        OnPageChange(this, event, this.props.onChange)
    }

    render(): React.ReactNode {
        return <>
            <DataTable
                ref={(reference) => this.dataTable = reference}
                header={this.props.header}
                value={this.props.value}
                stripedRows={true}
                paginator
                paginatorTemplate={paginatorTemplate}
                first={this.state.first}
                rows={this.state.pageRowCount}
                sortMode="multiple"
                removableSort
                lazy
                filters={this.state.filters}
                totalRecords={this.props.totalRecords}
                onPage={(event) => OnPageChange(this, event, this.props.onChange)}
                onFilter={(event) => this.onFilterApply(event)}
                onSort={(event) => this.props.onSorting ? OnPageChange(this, event, this.props.onSorting) : OnPageChange(this, event, this.props.onChange)}
                multiSortMeta={this.state.multiSortMeta}
                loading={this.props.loading}
                paginatorClassName="p-jc-end"
            >
                {(this.props.children as ReactElement[]).map((element, i) => {
                    return <Column
                        {...element.props}
                        key={i}
                        sortable={element.props && element.props.filterMatchModeOptions && element.props.filterMatchModeOptions != null}
                        filter={element.props && element.props.filterMatchModeOptions && element.props.filterMatchModeOptions != null}
                        showFilterOperator={false}
                        showAddButton={false}></Column>;
                })}
            </DataTable>
        </>;
    }
}