import { Dropdown, DropdownChangeParams } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import React, { Component } from "react";
import { Col, Label, Row } from "reactstrap";
import { SelectItem } from "../models/SelectItem";

interface InlineEditableInputProps {
    title: string;
    value: string;
    inputType: InputType;
    readOnly?: boolean | undefined;
    options?: SelectItem<number>[] | SelectItem<string>[];
    onSubmit: (value: string) => void;
    className?:string;
}

interface InlineEditableInputState {
    editMode: boolean;
    updatedValue: string;
}

export enum InputType {
    Text,
    DropDown,
}

export default class InlineEditableInput extends Component<InlineEditableInputProps, InlineEditableInputState> {
    constructor(props: InlineEditableInputProps | Readonly<InlineEditableInputProps>) {
        super(props);

        this.state = {
            editMode: false,
            updatedValue: this.props.value,
        };
    }

    onSubmit = () : void => {
        this.setState({ editMode: false });
        this.props.onSubmit(this.state.updatedValue);
    };

    onCancel = (e: { preventDefault: () => void; }) : void => {
        e.preventDefault();
        this.setState({ editMode: false });
    };

    onChange = (e: { preventDefault: () => void; }) : void => {
        e.preventDefault();
        this.setState({ editMode: true, updatedValue: this.props.value });
    };

    renderActions = () => {
        if (this.state.editMode) {
            return (
                <>
                    <a href="#" onClick={this.onSubmit}>
                        Submit
                    </a>
                    <a href="#" onClick={this.onCancel} style={{ paddingLeft: "10px" }}>
                        Cancel
                    </a>
                </>
            );
        }

        if(this.props.readOnly) {
            return <span></span>;
        }

        return (
            <a href="#" onClick={this.onChange}>
                Change
            </a>
        );
    };

    renderValue = () => {
        if (this.state.editMode) {
            if(this.props.inputType === InputType.Text) {
                return (
                    <InputText
                        value={this.state.updatedValue}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            this.setState({ updatedValue: event.target.value });
                        }}
                        className={"form-control"}
                    />
                );
            }
            return (
                <Dropdown
                    style={{ width: "100%" }}
                    value={this.state.updatedValue}
                    options={this.props.options}
                    readOnly={this.props.readOnly}
                    optionLabel="label"
                    optionValue="value"
                    placeholder="Select Data"
                    onChange={(event: DropdownChangeParams) => {
                        this.setState({ updatedValue: event.target.value });
                    }}
                    itemTemplate={(option: SelectItem) => {
                        return <span>{option.label}</span>;
                    }}
                />
            );
        }

        return <Label>{this.props.value}</Label>;
    };

    render() {
        return (
            <Row form>
                <Col>
                    <Label>
                        <b>{this.props.title}</b>
                    </Label>
                </Col>
                <Col md={3} style={{ padding: "0 10px" }} className={this.props.className}>
                    {this.renderValue()}
                </Col>
                <Col md={3} style={{ padding: "0 20px" }} className={this.props.className}>
                    {this.renderActions()}
                </Col>
            </Row>
        );
    }
}
