
import { KycSubmission, KycSubmissionDocument, AddKycDocument, KycSubmissionComment } from "../../../models/KycSubmission";
import { PagedResult, Result } from "../../../models/Result";
import { SearchRequestBaseModel } from "../../../models/SearchRequestModel";
import { SelectItem } from "../../../models/SelectItem";
import { httpService } from "../../HttpService";
import { ICrudApiService } from "../../ICrudApiService";

class KycSubmissionApiService implements ICrudApiService<KycSubmission, AddKycDocument> {
    path: string = "KycSubmission";

    getAll = async (individualId: number, searchRequest: SearchRequestBaseModel): Promise<PagedResult<KycSubmission[]>> => await httpService.getPage<KycSubmission[]>(`${this.path}/${individualId}/search`, searchRequest);

    getAllPendingReview = async (searchRequest: SearchRequestBaseModel): Promise<PagedResult<KycSubmission[]>> => await httpService.getPage<KycSubmission[]>(`${this.path}/search-pending-review`, searchRequest);

    getAllUnderReview = async (searchRequest: SearchRequestBaseModel): Promise<PagedResult<KycSubmission[]>> => await httpService.getPage<KycSubmission[]>(`${this.path}/search-under-review`, searchRequest);

    getAllAwaitingApproval = async (searchRequest: SearchRequestBaseModel): Promise<PagedResult<KycSubmission[]>> => await httpService.getPage<KycSubmission[]>(`${this.path}/search-awaiting-approval`, searchRequest);
    
    getAllCompleted = async (searchRequest: SearchRequestBaseModel): Promise<PagedResult<KycSubmission[]>> => await httpService.getPage<KycSubmission[]>(`${this.path}/search-completed`, searchRequest);

    get = async (userId: number): Promise<Result<KycSubmission>> => { throw new Error("Method not implemented."); };

    getDocuments = async (submissionId: number): Promise<Result<KycSubmissionDocument[]>> => await httpService.get<KycSubmissionDocument[]>(`${this.path}/${submissionId}/documents`);
    //getDocuments = async (submissionId: number): Promise<Result<DocumentFile[]>> => await httpService.get<DocumentFile[]>(`${this.path}/${submissionId}/documents`);

    getComments = async (submissionId: number): Promise<Result<KycSubmissionComment[]>> => await httpService.get<KycSubmissionComment[]>(`${this.path}/${submissionId}/comments`);

    create = async (): Promise<Result<KycSubmission>> => { throw new Error("Method not implemented."); };

    update = async (): Promise<Result<KycSubmission>> => { throw new Error("Method not implemented."); };

    delete = async (entityId: number): Promise<Result<void>> => { throw new Error("Method not implemented."); };

    getKycStatusTypes = async (): Promise<Result<SelectItem<number>[]>> => await httpService.get<SelectItem<number>[]>(`/${this.path}/kycStatusTypes`);

    getKycLevels = async (): Promise<Result<SelectItem<number>[]>> => await httpService.get<SelectItem<number>[]>(`/${this.path}/kyc-levels`);

    resetKycStatus = async (individualId: number): Promise<Result<void>> => await httpService.put<void>(`/${this.path}/${individualId}/reset-kyc-status`, {});

    transitionToPendingReview = async (submissionId: number): Promise<Result<void>> => await httpService.put<void>(`/${this.path}/${submissionId}/to-pending-review`, {});

    transitionToUnderReview = async (submissionId: number): Promise<Result<void>> => await httpService.put<void>(`/${this.path}/${submissionId}/to-under-review`, {});
    
    transitionToApproved = async (submissionId: number): Promise<Result<void>> => await httpService.put<void>(`/${this.path}/${submissionId}/to-approved`, {});

    kycSubmit = async (submissionId: number): Promise<Result<void>> => await httpService.put<void>(`/${this.path}/${submissionId}/submit`, {});

    kycEscalate = async (submissionId: number): Promise<Result<void>> => await httpService.put<void>(`/${this.path}/${submissionId}/escalate`, {});

    transitionToRejected = async (submissionId: number, reason: string): Promise<Result<void>> => await httpService.put<void>(`/${this.path}/${submissionId}/to-rejected`, {submissionId , reason });

    putkycDocument = async (document: AddKycDocument): Promise<Result<KycSubmission>> => await httpService.put<KycSubmission>(`/${this.path}`, document);

    putkycComment = async (comment: KycSubmissionComment): Promise<Result<KycSubmissionComment>> => await httpService.put<KycSubmissionComment>(`/${this.path}/comment`, comment);

}

const kycSubmissionApiService = new KycSubmissionApiService();

export { kycSubmissionApiService };
