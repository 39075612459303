import { Result } from "../../../models/Result";
import { httpService } from "../../HttpService";
import { RoleModel } from "../../../models/Role";

class RolesApiService {
    path: string = "Role";
    baseApiUrl: string = `${process.env.REACT_APP_AUTH_API_BASE_URL}/api/v1` ?? "";

    getAll = async (): Promise<Result<RoleModel[]>> => await httpService.get<RoleModel[]>(`/${this.path}`, { baseURL: this.baseApiUrl });

    getAllByMerchant = async (merchantId: number): Promise<Result<RoleModel[]>> => await httpService.get<RoleModel[]>(`/${this.path}/byMerchant/${merchantId}`, { baseURL: this.baseApiUrl });
}

const rolesApiService = new RolesApiService();

export { rolesApiService };
