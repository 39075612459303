import React, { Fragment } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { riskApiService } from "../../services/RiskService";
import { Toast } from "primereact/toast";
import ExtendedDataTable from "../../components/ExtendedDataTable";
import { SearchRequestBaseModel, SortOrderType } from "../../models/SearchRequestModel";
import { Column } from "primereact/column";
import { ConvertToISortModel, numberFilterMatchModeOptions, stringFilterMatchModeOptions } from "../Paginator";
import { Card, CardBody, Col, Container, FormGroup, Label, Row, CardHeader } from "reactstrap";
import { RiskExpressions, RiskParameters, VisibleRiskParameters } from "../../models/RiskExpressions";
import { InputTextarea } from "primereact/inputtextarea";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Checkbox } from "primereact/checkbox";

interface RiskExpressionsProps {
    onHide: () => void; 
}

interface RiskExpressionsState {
    riskExpressions: RiskExpressions[],
    riskParams: RiskParameters,
    visibleRiskParams: VisibleRiskParameters,
    expectedResult: string,
    selectedRiskExpression: RiskExpressions | null,

    visibleResult: boolean,
    expressionSuccess: number,
    expressionResult: any | null,

    inEditMode: boolean,
    visibleEditDialog: boolean,
    visibleConfirmDialog: boolean,
    isLoading: boolean,
    totalRecords: number
}

class RiskExpressionsForm extends React.Component<RiskExpressionsProps, RiskExpressionsState> {
    toast: Toast | null = null;
    validationErrors: Map<string, boolean> = new Map<string, boolean>();
    private dataTable: ExtendedDataTable | null = null;

    constructor(props: RiskExpressionsProps | Readonly<RiskExpressionsProps>) {
        super(props);
        this.state = {
            riskExpressions: [],
            riskParams: {
                totalRiskScore: 10,
                countOfIndRiskFactor10: 1,
                countOfIndRiskFactor9: 1,
                maxIndividualFactor: 10,
                externalKYC: false,
            },
            visibleRiskParams: {
                visibleTotalRiskScore: false,
                visibleCountOfIndRiskFactor10: false,
                visibleCcountOfIndRiskFactor9: false,
                visibleMaxIndividualFactor: false,
                visibleExternalKYC: false
            },
            expectedResult: "",
            selectedRiskExpression: null,
            visibleResult: false,
            expressionResult: null,
            expressionSuccess: 0,
            inEditMode: true,
            visibleEditDialog: false,
            visibleConfirmDialog: false,
            isLoading: false,
            totalRecords: 0,
        };
    }

    loadData = async (searchRequest: SearchRequestBaseModel) => {
        this.setState({ isLoading: true });
        const result = await riskApiService.getRiskExpressions();
        this.setState({ isLoading: false });
        if (!result.success) {
            this.toast?.show({ severity: "error", summary: "Error", detail: result.message, life: 3000 });
            // this.dataTable!.reset();
            return;
        }
        this.setState({ riskExpressions: result.data, totalRecords: result.data.length });
    };

    onFieldUpdate = (fieldName: string, value: any) => {
        const selectedItem = this.state.selectedRiskExpression as any;
        selectedItem[fieldName] = value;
        this.setState({ selectedRiskExpression: selectedItem });
    };

    onParamUpdate = (fieldName: string, value: any) => {
        const riskParams = this.state.riskParams as any;
        riskParams[fieldName] = value;
        this.setState({ riskParams: riskParams });
    };

    handleSubmit = async () => {
        let lookupResult;
        if (this.state.selectedRiskExpression != null) {
            lookupResult = await riskApiService.updateRiskExpression(this.state.selectedRiskExpression!);

            if (!lookupResult.success) {
                this.toast?.show({ severity: "error", summary: "Error", detail: lookupResult.message, life: 3000 });
                return;
            }
        }        
        this.loadData({
            pageNumber: this.dataTable?.state.page!,
            pageSize: this.dataTable?.state.pageRowCount!,
            filters: [],
            sortings: ConvertToISortModel(this.dataTable?.state.multiSortMeta)
        });

        this.setState({ visibleEditDialog: false});
    };

    handleEdit = async (riskExpression: RiskExpressions) => {
        //Later read those from Risk Service
        let visibleExpressionParams: VisibleRiskParameters;
        visibleExpressionParams =  {
            visibleTotalRiskScore: false,
            visibleCountOfIndRiskFactor10: false,
            visibleCcountOfIndRiskFactor9: false,
            visibleMaxIndividualFactor: false,
            visibleExternalKYC: false
        };
        let expressionResult: string = "";
        switch (riskExpression.name) {
        case "riskGrade": 
        {
            visibleExpressionParams =  {
                visibleTotalRiskScore: true,
                visibleCountOfIndRiskFactor10: true,
                visibleCcountOfIndRiskFactor9: true,
                visibleMaxIndividualFactor: false,
                visibleExternalKYC: false
            };
            expressionResult = "\"Low\", \"Average\", \"High\", \"Critical\"";
            break;
        }
        case "ExpressFlow":
            visibleExpressionParams =  {
                visibleTotalRiskScore: true,
                visibleCountOfIndRiskFactor10: false,
                visibleCcountOfIndRiskFactor9: false,
                visibleMaxIndividualFactor: true,
                visibleExternalKYC: true
            };
            expressionResult = "\"Approve\", \"Review\", \"Reject\"";
            break;
        case "SimplifiedFlow":
            visibleExpressionParams =  {
                visibleTotalRiskScore: true,
                visibleCountOfIndRiskFactor10: false,
                visibleCcountOfIndRiskFactor9: false,
                visibleMaxIndividualFactor: true,
                visibleExternalKYC: true
            };
            expressionResult = "\"Approve\", \"Review\", \"Reject\"";
            break;
        default:
            visibleExpressionParams =  {
                visibleTotalRiskScore: false,
                visibleCountOfIndRiskFactor10: false,
                visibleCcountOfIndRiskFactor9: false,
                visibleMaxIndividualFactor: false,
                visibleExternalKYC: false
            };
        }
        this.setState({ visibleEditDialog: true, inEditMode:true, selectedRiskExpression: riskExpression, visibleResult: false, expressionResult: null, visibleRiskParams: visibleExpressionParams, expectedResult: expressionResult });
    };

    handleTest = async () => {
        this.setState({ visibleResult: false, expressionResult: null, expressionSuccess: 0 });
        let result;
        if (this.state.selectedRiskExpression != null) {
            const testExpression = {riskParams: this.state.riskParams, 
                expression: this.state.selectedRiskExpression};
            result = await riskApiService.testRiskExpression(testExpression);

            if (!result.success || !result.data.isSuccess) {
                this.toast?.show({ severity: "error", summary: "Error", detail: result.message, life: 3000 });
                this.setState({ visibleResult: true, expressionSuccess: -1, expressionResult: result.data.isSuccess ? result.data.data : result.message });
                return;
            }
            this.setState({ visibleResult: true, expressionSuccess: 1, expressionResult: result.data.isSuccess ? result.data.data : result.message });
        }        
        this.loadData({
            pageNumber: this.dataTable?.state.page!,
            pageSize: this.dataTable?.state.pageRowCount!,
            filters: [],
            sortings: ConvertToISortModel(this.dataTable?.state.multiSortMeta)
        });
    };

    renderHeader = () => {
        return (
            <span className="p-input-icon-left" style={{ display: "flex", justifyContent: "space-between" }}>
                <Button type="button" icon="pi pi-filter-slash" label="Clear" className="p-button-outlined" onClick={() => { this.dataTable!.reset(); }} />
            </span>
        );
    };

    render() {
        const dialogHeader = this.state.inEditMode ? "Edit Expression" : "Add Expression";

        return (
            <Fragment>
                {/* Add Dialog */}
                <Toast ref={(el) => (this.toast = el)} />
                <Dialog header={dialogHeader} visible={this.state.visibleEditDialog} style={{ width: "40vw" }} onHide={() => this.setState({ visibleEditDialog: false })}>
                    <Container fluid>
                        <Card className="mb-3"> 
                            { (this.state.selectedRiskExpression) && 
                                <CardBody>
                                	<Row className="d-flex justify-content-center">
                                		<Col md={6}>
                                			<FormGroup>
                                				<Label for="id">Id</Label>
                                				<InputText value={this.state.selectedRiskExpression!.id} className="form-control" id="id" readOnly={true} required />
                                			</FormGroup>
                                		</Col>
                                		<Col md={6}>
                                			<FormGroup>
                                				<Label for="group">Group</Label>
                                				<InputText value={this.state.selectedRiskExpression!.group} className="form-control" id="group" readOnly={true} onChange={(e) => this.onFieldUpdate("group", e.target.value)} required />
                                			</FormGroup>
                                		</Col>
                                	</Row>

                                	<FormGroup>
                                		<Label for="name">Name</Label>
                                		<InputText value={this.state.selectedRiskExpression!.name} className={"form-control"} id="name" readOnly={true} required />
                                	</FormGroup>

                                	<Accordion>
                                		<AccordionTab
                                			header={
                                				<div className="flex align-items-center">
                                					<i className="pi pi-book mr-2"></i>
                                					<span className="vertical-align-middle">Expression Parameters</span>
                                				</div>
                                			}
                                		>                                            
                                			<p className="m-0">
                                				{this.state.visibleRiskParams.visibleTotalRiskScore && (<FormGroup>
                                					<Label for="totalRiskScore">Total Risk Score (totalRiskScore)</Label>
                                					<InputText value={this.state.riskParams!.totalRiskScore} className="form-control" id="totalRiskScore" readOnly={false} onChange={(e) => this.onParamUpdate("totalRiskScore", e.target.value)} required />
                                				</FormGroup>)}

                                				{this.state.visibleRiskParams.visibleCountOfIndRiskFactor10 && (<FormGroup>
                                					<Label for="countOfIndRiskFactor10">Count of elements with Risk factor 10 (countOfIndRiskFactor10)</Label>
                                					<InputText value={this.state.riskParams!.countOfIndRiskFactor10} className="form-control" id="countOfIndRiskFactor10" readOnly={false} onChange={(e) => this.onParamUpdate("countOfIndRiskFactor10", e.target.value)} required />
                                				</FormGroup>)}

                                				{this.state.visibleRiskParams.visibleCcountOfIndRiskFactor9 && (<FormGroup>
                                					<Label for="countOfIndRiskFactor9">Count of elements with Risk factor 9 (countOfIndRiskFactor9)</Label>
                                					<InputText value={this.state.riskParams!.countOfIndRiskFactor9} className="form-control" id="countOfIndRiskFactor9" readOnly={false} onChange={(e) => this.onParamUpdate("countOfIndRiskFactor9", e.target.value)} required />
                                				</FormGroup>)}

                                				{this.state.visibleRiskParams.visibleMaxIndividualFactor && (<FormGroup>
                                					<Label for="maxIndividualFactor">Max Individual Risk Factor (maxIndividualFactor)</Label>
                                					<InputText value={this.state.riskParams!.maxIndividualFactor} className="form-control" id="maxIndividualFactor" readOnly={false} onChange={(e) => this.onParamUpdate("maxIndividualFactor", e.target.value)} required />
                                				</FormGroup>)}

                                				{this.state.visibleRiskParams.visibleExternalKYC && (<FormGroup>
                                					<Label for="externalKYC">External KYC (externalKYC)</Label> <br />
                                					<Checkbox type="checkbox" checked={this.state.riskParams!.externalKYC} onChange={(e) => this.onParamUpdate("externalKYC", e.target.checked)} disabled={false}/>
                                				</FormGroup>)}

                                				<Label for="result">Expected Result: <strong>{this.state.expectedResult}</strong></Label>
                                			</p>
                                		</AccordionTab>
                                	</Accordion>

                                	<FormGroup>
                                		<Label for="expression">Expression</Label>
                                		<InputTextarea  value={this.state.selectedRiskExpression!.expression} className={"form-control"} id="expression" readOnly={false} 
                                			onChange={(e) => this.onFieldUpdate("expression", e.target.value)} rows={3} required />
                                	</FormGroup>

                                	{ (this.state.visibleResult) && <FormGroup>
                                		<Label for="expressionResult" icon="pi pi-play" style={{ marginRight: "10px" }}>Result</Label>
                                		{
                                			(() => {
                                				switch (this.state.expressionSuccess) {
                                				case -1:
                                					return <i className="pi pi-ban" style={{ color: "red", fontSize: "1rem" }}></i>;
                                				case 0:
                                					return <i className="pi pi-spin pi-cog" style={{ fontSize: "1rem" }}></i>;
                                				case 1:
                                					return <i className="pi pi-check" style={{ color: "green", fontSize: "1rem" }}></i>;
                                				default:
                                					return null;
                                				}
                                			})()
                                		}
                                		<InputText value={this.state.expressionResult} className={"form-control"} id="expressionResult" readOnly={true} required />
                                	</FormGroup>}
                                </CardBody>}
                        </Card> 
                    </Container>
                    <div className="align-right" style={{ padding: "10px" }}>
                        <Button label="Run" icon="pi pi-play" loading={this.state.isLoading} onClick={this.handleTest} style={{ marginRight: "10px" }} />
                        <Button label="Save" icon="pi pi-check" className="p-button p-button-success" loading={this.state.isLoading} onClick={this.handleSubmit} />
                    </div>
                </Dialog>

                {/* Risk Configure Dialog */}
                <Card className="mb-3" CardHeader="Risk Matrix"> 
                    <CardHeader className="card-header-tab d-flex justify-content-between" >
                        <span>Risk Expressions</span>
                    </CardHeader>

                    <CardBody>
                        <ExtendedDataTable
                            header={this.renderHeader}
                            value={this.state.riskExpressions}
                            totalRecords={this.state.totalRecords}
                            onChange={this.loadData}
                            loading={this.state.isLoading}
                            ref={(ref) => this.dataTable = ref}
                            defaultSortOrder={SortOrderType.Ascending}
                            defaultSortField='riskParamName'
                        >
                            <Column
                                header="Id"
                                field="id"
                                body={(rowData: RiskExpressions) => <span>{rowData.id}</span>}
                                filterMatchModeOptions={numberFilterMatchModeOptions}>
                            </Column>
                            <Column
                                header="Name"
                                field="name"
                                body={(rowData: RiskExpressions) => <span>{rowData.name}</span>}
                                filterMatchModeOptions={stringFilterMatchModeOptions}>
                            </Column>
                            <Column
                                header="Group"
                                field="group"
                                body={(rowData: RiskExpressions) => <span>{rowData.group}</span>}
                                filterMatchModeOptions={stringFilterMatchModeOptions}>
                            </Column>
                            <Column
                                header="Expression"
                                field="expression"
                                body={(rowData: RiskExpressions) => <span>{rowData.expression}</span>}
                                filterMatchModeOptions={stringFilterMatchModeOptions}>
                            </Column>
                            <Column
                                header=""
                                body={(rowData: RiskExpressions) =>
                                    <span>
                                        <Button label="Edit" type="button" className="p-button p-button-success" onClick={() => this.handleEdit(rowData)} style={{ marginRight: "5px" }}></Button>
                                    </span>
                                }
                                headerStyle={{ textAlign: "center" }}
                                bodyStyle={{ textAlign: "center", overflow: "visible" }}
                            />
                        </ExtendedDataTable>
                    </CardBody>
                </Card>     
            </Fragment>    
        );
    }
}

export default RiskExpressionsForm;
