import React, { Component } from "react";

import { OperatorDataModel } from "../../models/User";

import { Toast } from "primereact/toast";

import "./userprofile.scss";
import Loader from "../../components/Loader";
import { Merchant } from "../../models/MerchantModel";

import _ from "lodash";
import { operatorsApiService } from "../../services/api/users/OperatorApiService";
import { rolesApiService } from "../../services/api/users/RolesApiService";
import { RoleModel } from "../../models/Role";
import { merchantApiService } from "../../services/api/merchants/MerchantApiService";
import ProfileShared from "./ProfileShared";
import history from "../../history";

interface CreateOperatorState {
    operator: OperatorDataModel | null;
    roles: RoleModel[];
    merchants: Merchant[];
    isLoading: boolean;
}

export default class CreateOperatorComponent extends Component<{}, CreateOperatorState> {
    toast: Toast | null = null;

    constructor(props: {}) {
        super(props);

        this.state = {
            operator: null,
            roles: [],
            merchants: [],
            isLoading: true,
        };
    }

    async componentDidMount() {
        const merchantsResult = await merchantApiService.getAll({
            pageNumber: 1,
            pageSize: 100,
            filters: [],
            sortings: [],
        });

        if (!merchantsResult.success) {
            this.setState({ isLoading: false });
            this.toast?.show({ severity: "error", summary: "Error", detail: merchantsResult.message, life: 3000 });
            return;
        }

        this.setState({ isLoading: false, operator: { photoStorageId: null } as any, merchants: merchantsResult.data });
    }

    onSubmitButtonClick = async () => {
        this.setState({ isLoading: true });

        const newData = _.cloneDeep(this.state.operator);

        const result = await operatorsApiService.create(newData!);

        this.setState({ isLoading: false });

        if (!result.success) {
            this.toast?.show({ severity: "error", summary: "Error", detail: result.message, life: 3000 });
            return;
        }
        this.toast?.show({ severity: "success", summary: "Success", detail: "Operation completed successfully.", life: 3000 });
        this.onOperatorCreated(result.data.id);
    };

    onOperatorCreated = (profileId: number) => {
        history.push(`/profile/${profileId}`);
    };

    onMerchantChange = async (merchantId: number | undefined) => {
        const operator = this.state.operator;

        if (operator) {
            operator.userRoles = [];
        }

        this.setState({ roles: [], operator: operator });

        if (!merchantId) {
            return;
        }

        const roleResult = await rolesApiService.getAllByMerchant(merchantId);

        if (roleResult.success) {
            this.setState({ roles: roleResult.data });
            return;
        }

        this.toast?.show({ severity: "error", summary: "Error", detail: roleResult.message, life: 3000 });
    };

    render() {
        if (this.state.isLoading || this.state.operator === null) {
            return (
                <>
                    <Toast ref={(el) => (this.toast = el)} />
                    <Loader />
                </>
            );
        }

        return (
            <>
                <Toast ref={(el) => (this.toast = el)} position={"bottom-center"} baseZIndex={99999} />
                <ProfileShared
                    allowResetPhoneAndMobile={false}
                    isEditMode={false}
                    showMerchant={true}
                    showRoles={true}
                    showPassword={true}
                    merchants={this.state.merchants}
                    roles={this.state.roles}
                    user={this.state.operator}
                    onUpdate={(user) => this.setState({ operator: user })}
                    onSubmit={this.onSubmitButtonClick}
                    onMerchantChange={this.onMerchantChange}
                />
                ;
            </>
        );
    }
}
