import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import React, { Component, Fragment } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Card, CardBody, CardHeader, Col, Container, Label, FormGroup, Row } from "reactstrap";
import PageTitle from "../../Layout/AppMain/PageTitle";
import { Merchant, MerchantStatus } from "../../models/MerchantModel";
import MerchantTariffs from "./MerchantTariffs";
import MerchantGroups from "./MerchantGroups";
import MerchantContact from "./MerchantContact";
import MerchantPaymentMethods from "./MerchantPaymentMethods";
import MerchantUsers from "./MerchantUsers";
import { Dropdown } from "primereact/dropdown";
import "./flags.css";
import "./merchantdetails.scss";
import { SelectItem } from "../../models/SelectItem";
import { Button } from "primereact/button";
import history from "../../history";
import { confirmDialog } from "primereact/confirmdialog";
import MerchantDocuments from "./MerchantDocuments";
import { Toast } from "primereact/toast";
import { Result } from "../../models/Result";
import _ from "lodash";
import { merchantApiService } from "../../services/api/merchants/MerchantApiService";
import { httpService } from "../../services/HttpService";
import ExtendedInputText from "../../components/ExtendedInputText";
import StringUtils from "../../utils/stringUtils";
import MerchantAddress from "./MerchantAddress";
import { TabView, TabPanel } from "primereact/tabview";
import { utils } from "../../utils/utils";
import { fileService } from "../../services/FileService";
import { ProfileImage } from "../../components/Image/ProfileImage";
import { canDeleteMerchant, isMerchantProfileUpdateAllowed, isMerchantsClientsCreateAllowed } from "../../authGuard";
import MerchantIntegration from "./MerchantIntegration";
import { clientApiService } from "../../services/api/clients/ClientApiService";
import { CurrencyModel } from "../../models/CurrencyModel";
import { MultiSelect } from "primereact/multiselect";
import { currenciesApiService } from "../../services/api/merchants/CurrenciesApiService";
import MerchantWalletsComponent, { WalletType } from "./Wallets";
import MerchantDefaultPaymentMethod from "./MerchantDefaultPaymentMethod";
import ReactLoader from "react-loaders";
import MerchantTransactions from "./MerchantTransactions";
import { storageService } from "../../services/StorageService";
import { currencies } from "./CreateMerchant";
import ScaSectionsComponent from "./SacSection";
import { faUserGroup } from "@fortawesome/free-solid-svg-icons";
import { IndividualStatus } from "../../models/User";
import MerchantClientComponent from "./MerchantClient";

interface MerchantDetailsState {
    isLoading: boolean;
    merchant?: Merchant | null;
    merchantPass?: Merchant | null;
    merchantId?: number;
    merchantStatusEdit: boolean;
    selectedCountry?: SelectItem | null;
    activeTabIndex: number;
    countries: SelectItem[];
    currencies: CurrencyModel[];
    merchantClients: SelectItem[];
}

export default class MerchantDetailsComponent extends Component<Record<string, never>, MerchantDetailsState> {
    toast: Toast | null = null;

    validationErrors: Map<string, boolean> = new Map<string, boolean>();

    // TODO. Fix this
    merchantStatus: SelectItem[] = Object.keys(MerchantStatus)
        .map((key) => MerchantStatus[key as any])
        .filter((f) => typeof f == "string")
        .map((val) => ({ label: val, key: val } as any));

    constructor(props: Record<string, never>) {
        super(props);

        this.state = {
            merchantId: utils.getIdentifierFromPath(),
            merchant: null,
            activeTabIndex: 0,
            isLoading: true,
            merchantStatusEdit: false,
            countries: [],
            currencies: [],
            merchantClients: [],
        };
    }

    async componentDidMount() {
        const countriesResult = await utils.getCountriesResult();
        if (!countriesResult.success) {
            this.toast?.show({ severity: "error", summary: "Error", detail: countriesResult.message, life: 3000 });
            this.setState({ isLoading: false });
            return;
        }

        const countries = utils.getCountries();

        const clientsResult = await clientApiService.getClients();
        if (!clientsResult.success) {
            this.setState({ isLoading: false });
            this.toast?.show({ severity: "error", summary: "Error", detail: clientsResult.message, life: 3000 });
            return;
        }

        const currenciesResult = await currenciesApiService.getActive();

        if (!currenciesResult.success) {
            this.setState({ isLoading: false });
            this.toast?.show({ severity: "error", summary: "Error", detail: currenciesResult.message, life: 3000 });
            return;
        }

        const currencies = currenciesResult.data.map((m: CurrencyModel) => ({ name: m.charCode, id: m.id } as CurrencyModel));

        const merchantResult =
            this.state.merchantId == 0
                ? ({ success: true, data: new Merchant() } as Result<Merchant>)
                : await merchantApiService.get(this.state.merchantId!);
        if (!merchantResult.success) {
            this.setState({ isLoading: false });
            this.toast?.show({ severity: "error", summary: "Error", detail: merchantResult.message, life: 3000 });
            return;
        }
        storageService.setSelectedMerchantId(merchantResult.data.id.toString());
        this.setState({
            merchant: merchantResult.data,
            merchantClients: clientsResult.data,
            currencies: currencies,
            countries: countries,
            merchantPass: merchantResult.data,
            isLoading: false,
        });
        this.loadImage();
    }

    loadImage = async () => {
        if (this.state.merchant?.logo == null || this.state.merchant?.logo.length == 0) {
            return;
        }

        const logoResult = await fileService.GetFile(this.state.merchant?.logo);

        if (!logoResult.success) {
            this.toast?.show({
                severity: "error",
                summary: "Error",
                detail: "Could not load Merchant Logo. Please contact your administrator",
                life: 3000,
            });
            return;
        }

        const merchant = this.state.merchant;
        merchant.logo = logoResult.data;

        this.setState({
            merchant: merchant,
        });
    };

    updateValue = (fieldName: string, value: string) => {
        const merchantDetails = this.state.merchant as any;

        merchantDetails[fieldName] = value;

        this.setState({ merchant: merchantDetails });
    };

    onUpdateCurrencyMultiSelect = (ids: number[]) => {
        if (this.state.merchant != null) {
            const merchant = this.state.merchant;
            if (ids.includes(currencies.AAA)) {
                // Select all currency IDs

                merchant.merchantCurrencies = this.state.currencies
                    .filter((currency) => parseInt(currency.id) !== currencies.AAA)
                    .map((currency) => parseInt(currency.id));
            } else {
                merchant.merchantCurrencies = ids;
            }
            this.setState({ merchant: merchant });
        }
    };

    onImageSelected = async (image: File) => {
        const merchantDetails = this.state.merchant;
        merchantDetails!.logo = (await utils.convertFileToBase64(image)) as string;
        this.setState({ merchant: merchantDetails });
    };

    fileInput = (input: boolean) => {
        if (!input) {
            this.toast?.show({ severity: "error", summary: "File Name", detail: "File Name Exceeds 128 Characters.", life: 3000 });
            return;
        }
    };

    onSubmitButtonClick = async () => {
        this.setState({ isLoading: true });

        const result =
            this.state.merchant?.id == 0
                ? await merchantApiService.create(this.state.merchant)
                : await merchantApiService.update(this.state.merchant!);

        this.setState({ isLoading: false });

        if (result.success) {
            history.push("/merchants");
        } else {
            this.toast?.show({ severity: "error", summary: "Error", detail: result.message, life: 3000 });
        }
    };

    renderStatusChangeButton = () => {
        if (!canDeleteMerchant()) {
            return <span></span>;
        }

        return (
            <button type="button" className="p-row-editor-init p-link" tabIndex={0} onClick={this.onStatusChangeButtonClick}>
                <span
                    className={classNames(
                        "p-row-editor-init-icon",
                        "pi pi-fw",
                        this.state.merchant?.status == MerchantStatus.Active ? "pi-times-circle" : "pi-check-circle"
                    )}
                ></span>
            </button>
        );
    };

    onStatusChangeButtonClick = () => {
        if (this.state.merchant?.status == MerchantStatus.Active) {
            confirmDialog({
                message: "This action will deactivate all users related to this Merchant. Are you sure you want to proceed?",
                header: "Deactivate Merchant",
                icon: "pi pi-info-circle",
                acceptClassName: "p-button-danger",
                accept: () => this.deactivateMerchant(),
                reject: () => {},
            });
        } else {
            confirmDialog({
                message: "Are you sure you want to proceed?",
                header: "Activate Merchant",
                icon: "pi pi-exclamation-triangle",
                accept: () => this.activateMerchant(),
                reject: () => {},
            });
        }
    };

    setMerchantStatus = (status: MerchantStatus) => {
        const merchant = this.state.merchant;
        merchant!.status = status;
        this.setState({ merchant: merchant });
    };

    deactivateMerchant = async () => {
        if (this.state.merchant == null) {
            return;
        }

        await merchantApiService.deActivate(this.state.merchant.id);
        this.setMerchantStatus(MerchantStatus.InActive);
    };

    activateMerchant = async () => {
        if (this.state.merchant == null) {
            return;
        }

        await merchantApiService.activate(this.state.merchant.id);
        this.setMerchantStatus(MerchantStatus.Active);
    };

    renderBadge(success: boolean, title: string) {
        return <div className={classNames("badge", "badge-" + (success ? "success" : "danger"))}>{title}</div>;
    }

    defaultImage = () => {
        return "/dummy.jpg";
    };

    renderMerchantBody = () => {
        if (this.state.isLoading || !this.state.merchant) {
            return (
                <Col md={12} lg={12}>
                    <Card style={{ minHeight: "500px" }} className="align-center">
                        <ReactLoader type="ball-pulse" active />
                    </Card>
                </Col>
            );
        }

        return (
            <Col md={12} lg={12}>
                <Card className="mb-3">
                    <CardHeader className="card-header-tab">
                        <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                            <i className="header-icon lnr-laptop-phone mr-3 text-muted opacity-6"> </i>
                            Merchant Details
                        </div>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col md="3" lg="3" sm="12" style={{ padding: "2em" }}>
                                <Row style={{ cursor: "pointer" }}>
                                    <ProfileImage
                                        imageStyle={{ width: "100%" }}
                                        className="rounded-circle"
                                        src={
                                            this.state.merchant?.logo == null || this.state.merchant?.logo.length === 0
                                                ? this.defaultImage()
                                                : utils.appendBase64Data(this.state.merchant!.logo)
                                        }
                                        alt="merchant logo"
                                        onImageSelected={this.onImageSelected}
                                        fileInput={this.fileInput}
                                    />
                                </Row>
                                <Row style={{ marginTop: "50px" }}>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>
                                                <b>Status</b>
                                            </Label>
                                        </FormGroup>
                                    </Col>
                                    <Col md={3} style={{ padding: "0 10px" }}>
                                        {this.state.merchantStatusEdit ? (
                                            <Dropdown
                                                style={{ width: "100%" }}
                                                value={MerchantStatus[this.state.merchant?.status ?? 0]}
                                                options={this.merchantStatus}
                                                optionLabel="label"
                                                optionValue="value"
                                                onChange={(e) => this.updateValue("status", e.value)}
                                                itemTemplate={(option) => {
                                                    return <span>{option.label}</span>;
                                                }}
                                            />
                                        ) : (
                                            <div
                                                className={classNames(
                                                    "badge",
                                                    this.state.merchant?.status == MerchantStatus.Active ? "badge-success" : "badge-danger"
                                                )}
                                            >
                                                {MerchantStatus[this.state.merchant?.status ?? 0]}
                                            </div>
                                        )}
                                    </Col>
                                    <Col md={3}>{this.renderStatusChangeButton()}</Col>
                                </Row>
                            </Col>
                            <Col md={9} lg={9} sm="12">
                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>Name</Label>
                                            <ExtendedInputText
                                                name="Name"
                                                value={this.state.merchant?.name}
                                                required={true}
                                                readOnly={!isMerchantProfileUpdateAllowed()}
                                                onChange={(value) => this.updateValue("name", value)}
                                                onValidStatusChanged={(value: boolean) => this.validationErrors.set("name", value)}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>Email Address</Label>
                                            <ExtendedInputText
                                                type="email"
                                                value={this.state.merchant?.email}
                                                onChange={(value) => this.updateValue("email", value)}
                                                validation={() => StringUtils.isValidEmail(this.state.merchant?.email ?? "")}
                                                readOnly={!isMerchantProfileUpdateAllowed()}
                                                name="Email"
                                                required={true}
                                                onValidStatusChanged={(value: boolean) => this.validationErrors.set("email", value)}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>Website Url</Label>
                                            <InputText
                                                value={this.state.merchant?.websiteUrl}
                                                readOnly={!isMerchantProfileUpdateAllowed()}
                                                className={"form-control"}
                                                onChange={(e) => this.updateValue("websiteUrl", e.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>Phone</Label>
                                            <InputText
                                                value={this.state.merchant?.phone}
                                                readOnly={!isMerchantProfileUpdateAllowed()}
                                                className={"form-control"}
                                                type="text"
                                                onChange={(e) => this.updateValue("phone", e.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>VAT Number</Label>
                                            <InputText
                                                value={this.state.merchant?.vatNumber}
                                                readOnly={!isMerchantProfileUpdateAllowed()}
                                                className={"form-control"}
                                                type="text"
                                                onChange={(e) => this.updateValue("vatNumber", e.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <Label>Currency</Label>
                                        <FormGroup>
                                            <MultiSelect
                                                value={this.state.merchant?.merchantCurrencies}
                                                disabled={!isMerchantProfileUpdateAllowed()}
                                                display="chip"
                                                options={this.state.currencies}
                                                onChange={(e) => this.onUpdateCurrencyMultiSelect(e.value)}
                                                optionLabel="name"
                                                optionValue="id"
                                                placeholder="Select Currency"
                                                filter
                                                className="multiselect-custom"
                                                style={{ width: "100%" }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>Flexcube Branch</Label>
                                            <InputText
                                                value={this.state.merchant?.flexCubeBranch}
                                                readOnly={!isMerchantProfileUpdateAllowed()}
                                                className={"form-control"}
                                                type="text"
                                                disabled={true}
                                                onChange={(e) => this.updateValue("flexCubeBranch", e.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <Label>Merchant Client</Label>
                                        <FormGroup>
                                            <MultiSelect
                                                value={this.state.merchant?.clientIds}
                                                disabled={true}
                                                options={this.state.merchantClients}
                                                display="chip"
                                                optionLabel="label"
                                                optionValue="value"
                                                placeholder={"Select Merchant Client"}
                                                itemTemplate={(option) => {
                                                    return <span>{option.label}</span>;
                                                }}
                                                style={{ width: "100%" }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>Minimum Transaction Limit</Label>
                                            <InputText
                                                value={this.state.merchant?.minimumTransactionAmount}
                                                readOnly={!isMerchantProfileUpdateAllowed()}
                                                className={"form-control"}
                                                type="number"
                                                min="0.01"
                                                step="0.01"
                                                onChange={(e) =>
                                                    this.updateValue("minimumTransactionAmount", e.target.validity.valid ? e.target.value : "0.01")
                                                }
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className="align-right">
                                    {isMerchantProfileUpdateAllowed() && (
                                        <div style={{ padding: "15px" }}>
                                            <Button
                                                type="button"
                                                disabled={Object.values(this.validationErrors).filter((f) => f == false).length > 0}
                                                label="Submit"
                                                icon="pi pi-check"
                                                className="p-button"
                                                onClick={this.onSubmitButtonClick}
                                            ></Button>
                                        </div>
                                    )}
                                </Row>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        );
    };

    render() {
        return (
            <Fragment>
                <TransitionGroup component="div" appear={true} exit={false} enter={false}>
                    <CSSTransition classNames="TabsAnimation" timeout={1500}>
                        <PageTitle heading="Merchant Details" icon={faUserGroup} />
                    </CSSTransition>
                    <CSSTransition classNames="TabsAnimation" timeout={1500}>
                        <Fragment>
                            <Toast ref={(el) => (this.toast = el)} position={"bottom-center"} baseZIndex={99999}></Toast>
                            <Container fluid>
                                <div>
                                    <Row>{this.renderMerchantBody()}</Row>

                                    <Row>
                                        <Col md={12} lg={12}>
                                            <MerchantDefaultPaymentMethod merchantId={this.state.merchantId} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <ScaSectionsComponent merchantId={this.state.merchantId} merchant={this.state.merchantPass!} />
                                    </Row>
                                    <Row>{isMerchantsClientsCreateAllowed() && <MerchantClientComponent merchantId={this.state.merchantId} />}</Row>
                                    <Row className="mt-4">
                                        {this.state.merchant && (
                                            <MerchantWalletsComponent
                                                cbsId={this.state.merchantPass?.flexCubeCustomerId}
                                                Id={this.state.merchantId}
                                                walletType={WalletType.Merchant}
                                            />
                                        )}
                                    </Row>

                                    {this.state.merchantId != 0 && (
                                        <Row>
                                            <Col md="12" lg="12">
                                                <Card className="mb-3">
                                                    <CardBody>
                                                        <TabView
                                                            activeIndex={this.state.activeTabIndex}
                                                            onTabChange={(e) => {
                                                                this.setState({ activeTabIndex: e.index });
                                                            }}
                                                        >
                                                            <TabPanel header="Integration">
                                                                <MerchantIntegration merchantId={this.state.merchantId!} />
                                                            </TabPanel>
                                                            <TabPanel header="Contacts">
                                                                <MerchantContact merchantId={this.state.merchantId!} localMode={false} />
                                                            </TabPanel>
                                                            <TabPanel header="Address">
                                                                <MerchantAddress merchantId={this.state.merchantId!} localMode={false} />
                                                            </TabPanel>
                                                            <TabPanel header="Groups">
                                                                <MerchantGroups merchantId={this.state.merchantId!} />
                                                            </TabPanel>
                                                            <TabPanel header="Tariffs">
                                                                <MerchantTariffs merchantId={this.state.merchantId!.toString()} />
                                                            </TabPanel>
                                                            <TabPanel header="Transactions">
                                                                <MerchantTransactions
                                                                    customerId={this.state.merchant?.flexCubeCustomerId}
                                                                    merchantId={this.state.merchantId}
                                                                />
                                                            </TabPanel>
                                                            <TabPanel header="Users">
                                                                <MerchantUsers merchantId={this.state.merchantId!} />
                                                            </TabPanel>
                                                            <TabPanel header="Payment Methods">
                                                                <MerchantPaymentMethods merchantId={this.state.merchantId!} viewOnly={false} />
                                                            </TabPanel>
                                                            <TabPanel header="Documents">
                                                                <MerchantDocuments merchantId={this.state.merchantId!} localMode={false} />
                                                            </TabPanel>
                                                        </TabView>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    )}
                                </div>
                            </Container>
                        </Fragment>
                    </CSSTransition>
                </TransitionGroup>
            </Fragment>
        );
    }
}
