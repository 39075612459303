import { PaymentMethodType, PaymentMethods } from "../../../models/FinancialData";
import { CreateIbanPaymentMethodRequest, CreateCardPaymentMethodRequest, CreateNonIbanPaymentMethodRequest } from "../../../models/MerchantModel";
import { Result } from "../../../models/Result";
import { httpService } from "../../HttpService";

class UserPaymentMethodApiService {
    path: string = "individualPaymentMethod";

    getAll = async (individualId: number): Promise<Result<PaymentMethods[]>> => await httpService.get<PaymentMethods[]>(`${this.path}/${individualId}`);

    create = async (individualId: number, item: CreateIbanPaymentMethodRequest | CreateCardPaymentMethodRequest | CreateNonIbanPaymentMethodRequest): Promise<Result<void>> => await httpService.post<void>(`/${this.path}/${individualId}/${this.getPaymentTypePath(item)}`, item);

    update = async (individualId: number, item: CreateIbanPaymentMethodRequest | CreateCardPaymentMethodRequest | CreateNonIbanPaymentMethodRequest): Promise<Result<void>> => await httpService.put<void>(`/${this.path}/${individualId}/${this.getPaymentTypePath(item)}`, item);

    delete = async (individualId: number, cardOrIbanNumber: string ): Promise<Result<void>> => await httpService.delete<void>(`/${this.path}/${individualId}/${cardOrIbanNumber}`);

    getPaymentTypePath = (item: CreateIbanPaymentMethodRequest | CreateCardPaymentMethodRequest | CreateNonIbanPaymentMethodRequest) => item.paymentType === PaymentMethodType.Bank ? "iban" : item.paymentType === PaymentMethodType.Card ? "card" : "non-iban";
}

const userPaymentMethodApiService = new UserPaymentMethodApiService();

export { userPaymentMethodApiService };
