import { ProductCode } from "../../../models/ProductCode";
import { PagedResult } from "../../../models/Result";
import { SearchRequestBaseModel } from "../../../models/SearchRequestModel";
import { httpService } from "../../HttpService";

class TariffApiService{
    path: string = "Tariff";

    getProductCodes = async (request: SearchRequestBaseModel): Promise<PagedResult<ProductCode[]>> => await httpService.getPage<ProductCode[]>(`/${this.path}/product-codes`, request);
}

const tariffApiService = new TariffApiService();

export default tariffApiService;