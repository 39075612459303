import React, { Component, Fragment } from "react";
import { Card, CardBody, Container } from "reactstrap";

import { TransitionGroup, CSSTransition } from "react-transition-group";

import _ from "lodash";
import moment from "moment";
import { RequestLog } from "../models/Journaling";
import PageTitle from "../Layout/AppMain/PageTitle";
import { Button } from "primereact/button";
import { journalingsApiService } from "../services/api/journalings/JournalingApiService";
import { Toast } from "primereact/toast";
import { SearchRequestBaseModel, SortOrderType } from "../models/SearchRequestModel";
import ExtendedDataTable from "../components/ExtendedDataTable";
import { Column, ColumnFilterElementTemplateOptions } from "primereact/column";
import { dateFilterTemplate, numberFilterMatchModeOptions, stringFilterMatchModeOptions } from "./Paginator";
import { merchantApiService } from "../services/api/merchants/MerchantApiService";
import { Dropdown } from "primereact/dropdown";
import { SelectItem } from "../models/SelectItem";
import { authUsersApiService } from "../services/api/clients/AuthUsersApiService";
import RequestLogDetailsDialog from "./Journaling/RequestLogDetails";
import { faColonSign } from "@fortawesome/free-solid-svg-icons";

interface RequestLogsState {
    requestLogs: RequestLog[];
    isLoading: boolean;
    totalRecords: number;
    viewRequestLogDetails: boolean;
    selectedRequestLog: RequestLog | null;
    merchants: SelectItem[];
    users: SelectItem[];
}

interface RequestLogComponentProps {
    filterFunction?: (searchRequest: SearchRequestBaseModel) => void;
}

export default class RequestLogComponent extends Component<RequestLogComponentProps, RequestLogsState> {
    private toast: Toast | null = null;
    private dataTable: ExtendedDataTable | null = null;

    constructor(props: RequestLogComponentProps | Readonly<RequestLogComponentProps>) {
        super(props);

        this.state = {
            requestLogs: [],
            isLoading: false,
            totalRecords: 0,
            viewRequestLogDetails: false,
            selectedRequestLog: null,
            merchants: [],
            users: [],
        };
    }

    loadJournalingData = async (searchRequest: SearchRequestBaseModel) => {
        this.setState({ isLoading: true });

        this.props.filterFunction && this.props.filterFunction(searchRequest);

        const requestLogsResult = await journalingsApiService.getAllRequestLogs(searchRequest);
        const merchantResult = await merchantApiService.getSelectItem();
        const usersResult = await authUsersApiService.getSelectItem();

        this.setState({ isLoading: false, merchants: merchantResult.data, users: usersResult.data });

        if (!requestLogsResult.success) {
            this.toast?.show({ severity: "error", summary: "Error", detail: requestLogsResult.message, life: 3000 });
        }
        this.setState({ requestLogs: requestLogsResult.data, totalRecords: requestLogsResult.totalCount });
    };

    loadRequestLogs = async (id: number) => {
        this.setState({ isLoading: true });
        const requestLogsResult = await journalingsApiService.getRequestLogsDetail(id);
        if (!requestLogsResult.success) {
            this.toast?.show({ severity: "error", summary: "Error", detail: requestLogsResult.message, life: 3000 });
            this.setState({ isLoading: false });
            return;
        }

        this.setState({ viewRequestLogDetails: true, selectedRequestLog: requestLogsResult.data });
        this.setState({ isLoading: false });
    };

    showJournalingDetails = (rowData: RequestLog) => {
        this.setState({ viewRequestLogDetails: true, selectedRequestLog: rowData });
    };

    hideJournalingDetailsView = () => {
        this.setState({ viewRequestLogDetails: false });
    };

    actionBodyTemplate = (rowData: RequestLog) => {
        return <Button label="Details" type="button" className="p-button-outlined" onClick={() => this.loadRequestLogs(rowData.id)}></Button>;
    };

    renderHeader = () => {
        return (
            <span className="p-input-icon-left" style={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                    type="button"
                    icon="pi pi-filter-slash"
                    label="Clear"
                    className="p-button-outlined"
                    onClick={() => {
                        this.dataTable!.reset();
                    }}
                />
            </span>
        );
    };

    onActionButtonClick = (row: RequestLog) => {};

    merchantFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
        return (
            <Dropdown
                value={options.value}
                options={this.state.merchants}
                onChange={(e) => options.filterCallback(e.value)}
                optionLabel="label"
                optionValue="value"
                filter
                showClear
                placeholder="Select Merchants"
                className="p-column-filter"
            />
        );
    };

    usersFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
        return (
            <Dropdown
                value={options.value}
                options={this.state.users}
                onChange={(e) => options.filterCallback(e.value)}
                optionLabel="label"
                optionValue="value"
                filter
                showClear
                placeholder="Select Users"
                className="p-column-filter"
            />
        );
    };

    render() {
        return (
            <Fragment>
                <TransitionGroup appear={true} exit={false} enter={false}>
                    <CSSTransition classNames="TabsAnimation" timeout={1500}>
                        <Fragment>
                            <Container fluid>
                                <PageTitle heading="Access Logs" icon={faColonSign} />
                                <Card>
                                    <CardBody>
                                        {this.state.viewRequestLogDetails && this.state.selectedRequestLog != null && (
                                            <RequestLogDetailsDialog
                                                isVisible={this.state.viewRequestLogDetails}
                                                onHide={this.hideJournalingDetailsView}
                                                requestLog={this.state.selectedRequestLog}
                                                editable={false}
                                                userEmail={this.state.users.find((a) => a.value == this.state.selectedRequestLog?.userId)?.label}
                                                merchantName={
                                                    this.state.merchants.find((a) => a.value == this.state.selectedRequestLog?.merchantId)?.label
                                                }
                                            />
                                        )}
                                        <ExtendedDataTable
                                            header={this.renderHeader}
                                            value={this.state.requestLogs}
                                            loading={this.state.isLoading}
                                            totalRecords={this.state.totalRecords}
                                            onChange={this.loadJournalingData}
                                            ref={(ref) => (this.dataTable = ref)}
                                            defaultSortOrder={SortOrderType.Descending}
                                            defaultSortField="madeOn"
                                        >
                                            <Column
                                                field="id"
                                                header="Id"
                                                filterField="id"
                                                filterMatchModeOptions={numberFilterMatchModeOptions}
                                            ></Column>
                                            <Column
                                                field="application"
                                                header="Application"
                                                filterField="application"
                                                filterMatchModeOptions={stringFilterMatchModeOptions}
                                            ></Column>
                                            <Column
                                                field="madeOn"
                                                filterField="madeOn"
                                                header="Event Date"
                                                body={(rowData: RequestLog) => (
                                                    <p>{moment.utc(rowData.madeOn).local().format("DD/MM/YYYY hh:mm:ss A")}</p>
                                                )}
                                                dataType="date"
                                                filterElement={dateFilterTemplate}
                                                filterMatchModeOptions={numberFilterMatchModeOptions}
                                            ></Column>
                                            <Column
                                                field="controllerName"
                                                header="Controller Name"
                                                filterField="controllerName"
                                                filterMatchModeOptions={stringFilterMatchModeOptions}
                                            ></Column>
                                            <Column
                                                field="actionName"
                                                header="Action Name"
                                                filterField="actionName"
                                                filterMatchModeOptions={stringFilterMatchModeOptions}
                                            ></Column>
                                            <Column
                                                field="userId"
                                                header="User"
                                                filterMatchMode="equals"
                                                filterField="userId"
                                                filterMatchModeOptions={stringFilterMatchModeOptions}
                                                filterElement={this.usersFilterTemplate}
                                                body={(rowData: RequestLog) => (
                                                    <p>{this.state.users.find((a) => a.value == rowData.userId)?.label}</p>
                                                )}
                                            ></Column>
                                            <Column
                                                field="merchantId"
                                                header="Merchant"
                                                filterMatchMode="equals"
                                                filterField="merchantId"
                                                showFilterMatchModes={false}
                                                filterMatchModeOptions={stringFilterMatchModeOptions}
                                                filterElement={this.merchantFilterTemplate}
                                                body={(rowData: RequestLog) => (
                                                    <p>{this.state.merchants.find((a) => a.value == rowData.merchantId)?.label}</p>
                                                )}
                                            ></Column>
                                            <Column
                                                field="madeFromIPAddress"
                                                header="Ip Address"
                                                filterField="madeFromIPAddress"
                                                filterMatchModeOptions={stringFilterMatchModeOptions}
                                            ></Column>
                                            <Column
                                                header=""
                                                body={this.actionBodyTemplate}
                                                headerStyle={{ width: "8em", textAlign: "center" }}
                                                bodyStyle={{ textAlign: "center", overflow: "visible" }}
                                            />
                                        </ExtendedDataTable>
                                    </CardBody>
                                </Card>
                            </Container>
                        </Fragment>
                    </CSSTransition>
                </TransitionGroup>
            </Fragment>
        );
    }
}
