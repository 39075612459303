import { OperatorDataModel } from "../models/User";
import jwt_decode from "jwt-decode";

class StorageService {

    private tokenKey: string = "token";
    private permissionsKey: string = "permissions";
    private rolesKey: string = "roles";
    private usersKey: string = "user";
    private selectedMerchantKey: string = "mrchId";

    getLoggedInUserMerchantId = () : number => {
        const jwt = this.getAccessToken().split(" ")[1];
        const decoded = jwt_decode(jwt) as any;
        return parseInt(decoded["tenant"]);
    };


    
    getAccessToken = () => {
        return localStorage.getItem(this.tokenKey) ?? "";
    };

    setAccessToken = (value: string) => {
        localStorage.setItem(this.tokenKey, value);
    };

    setSelectedMerchantId = (value: string) => {
        localStorage.setItem(this.selectedMerchantKey, value);
    };

    getSelectedMerchantId = () : string => {
        return JSON.parse(localStorage.getItem(this.selectedMerchantKey) ?? "0");
    };
    getPermissions = () : string[] => {
        return JSON.parse(localStorage.getItem(this.permissionsKey) ?? "[]");
    };

    setPermissions = (value: string[]) => {
        localStorage.setItem(this.permissionsKey, JSON.stringify(value ?? []));
    };

    getRoles = () : string[] => {
        return JSON.parse(localStorage.getItem(this.rolesKey) ?? "[]");
    };

    setRoles = (value: string[]) => {
        localStorage.setItem(this.rolesKey, JSON.stringify(value ?? []));
    };
    
    setUserData = (value: OperatorDataModel) => {
        localStorage.setItem(this.usersKey, JSON.stringify(value));
    };

    getUserData = () : OperatorDataModel => {
        return JSON.parse(localStorage.getItem(this.usersKey)!);
    };

    removeAccessToken = () => {
        localStorage.removeItem(this.tokenKey);
    };

    clear = () => {
        localStorage.clear();
    };
}

const storageService = new StorageService();

export { storageService };