import React, { ChangeEvent } from "react";
import { Container, Row, Col, Label, FormGroup } from "reactstrap";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { SelectItem } from "../../models/SelectItem";
import { Button } from "primereact/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CreateNonIbanPaymentMethodRequest, PaymentMethodStatus } from "../../models/MerchantModel";
import { BankAccountConstant } from "../../models/IncomingBankAccount";

import { utils } from "../../utils/utils";
import { currenciesApiService } from "../../services/api/merchants/CurrenciesApiService";
import { storageService } from "../../services/StorageService";
import { faMoneyCheck } from "@fortawesome/free-solid-svg-icons";

interface AddNonIbanProps {
    OnBack: () => void;
    OnSuccess: (item: CreateNonIbanPaymentMethodRequest) => void;
}

interface AddNonIBANState {
    accountOwnerName: string;
    bankAccountName: string;
    bankName: string;
    bankAccountNumber: string;
    currency: string;
    swift: string;
    bankAddress: string;
    bankStatement: string;
    bankStatementFileName: string;
    currencies: SelectItem[]
}

export class AddNonIBAN extends React.Component<AddNonIbanProps, AddNonIBANState> {
    bankStatementInput: HTMLInputElement | null = null;

    constructor(props: AddNonIbanProps) {
        super(props);

        this.state = {
            bankAccountName: "",
            accountOwnerName: "",
            bankName: "",
            bankAccountNumber: "",
            currency: "",
            swift: "",
            bankAddress: "",
            bankStatement: "",
            bankStatementFileName: "",
            currencies: []
        };
    }

    componentDidMount(): void {
        this.onLoad();
    }

    onLoad = async () => {
        const currenciesResult = await currenciesApiService.getMerchantActiveCurrencies(Number(storageService.getSelectedMerchantId()));

        if(currenciesResult.success) {
            const currencies = currenciesResult.data.map(a => ({ label: a.charCode, value: a.charCode } as SelectItem));
            currencies.unshift({ label: "NONE", value: "" });
            this.setState({ currencies: currencies });
        }
    };

    setBankStatement = async (file: File) => {
        this.setState({ bankStatementFileName: file.name || "" });
        const data = (await utils.convertFileToBase64(file)) as string;
        this.setState({ bankStatement: data });
    };

    onChangeAccountNumber = (e: ChangeEvent<HTMLInputElement>) => {
        if(!isNaN(Number(e.target.value))){
            this.setState({ bankAccountNumber: e.target.value});
        }
    };

    render(): React.ReactNode {
        return (
            <Container fluid>
                <FontAwesomeIcon size="5x" icon={faMoneyCheck as any} className="text-info" style={{ margin: "auto", display: "block" }} />
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <Label>Account Name</Label>
                            <InputText
                                value={this.state.bankAccountName}
                                type="text"
                                placeholder="Account Name"
                                onChange={(event) => {
                                    this.setState({ bankAccountName: event.target.value });
                                }}
                                className={"form-control"}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label>Account Owner Name</Label>
                            <InputText
                                value={this.state.accountOwnerName}
                                type="text"
                                placeholder="Account Owner Name"
                                onChange={(event) => {
                                    this.setState({ accountOwnerName: event.target.value });
                                }}
                                className={"form-control"}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <Label>Bank Name</Label>
                            <InputText
                                value={this.state.bankName}
                                type="text"
                                onChange={(event) => {
                                    this.setState({ bankName: event.target.value });
                                }}
                                className={"form-control"}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label>Currency</Label>
                            <Dropdown
                                style={{ width: "100%" }}
                                value={this.state.currency}
                                options={this.state.currencies}
                                onChange={(e) => {
                                    this.setState({ currency: e.value });
                                }}
                                appendTo={document.body}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <Label>Account Number</Label>
                            <InputText
                                value={this.state.bankAccountNumber}
                                type="text"
                                inputMode="text"
                                pattern="[0-9\s]"
                                onChange={(event) => this.onChangeAccountNumber(event)}
                                maxLength={BankAccountConstant.accountNumbeMaxLength}
                                className={"form-control"}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label>Swift</Label>
                            <InputText
                                value={this.state.swift}
                                type="text"
                                onChange={(event) => {
                                    this.setState({ swift: event.target.value });
                                }}
                                className={"form-control"}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <Label>Bank Address</Label>
                            <InputText
                                value={this.state.bankAddress}
                                type="text"
                                onChange={(event) => {
                                    this.setState({ bankAddress: event.target.value });
                                }}
                                className={"form-control"}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label>Bank Statement</Label>
                            <InputText
                                ref={(el) => (this.bankStatementInput = el)}
                                type="file"
                                onChange={(event) => event.target.files && this.setBankStatement(event.target.files[0])}
                                className={"form-control"}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xl={{ size: 3, offset: 6 }} lg={{ size: 4, offset: 4 }} md={{ size: 6 }} sm={{ size: 12 }}>
                        <Button
                            style={{ width: "100%" }}
                            label="Back"
                            type="button"
                            onClick={() => this.props.OnBack()}
                            className="p-button-outlined"
                        ></Button>
                    </Col>
                    <Col xl={{ size: 3 }} lg={{ size: 4 }} md={{ size: 6 }} sm={{ size: 12 }}>
                        {/* todo. add bank statement */}
                        <Button
                            style={{ width: "100%" }}
                            label="Submit"
                            type="button"
                            onClick={() => {
                                const obj = new CreateNonIbanPaymentMethodRequest();
                                obj.bankAccountName = this.state.bankAccountName;
                                obj.accountHolderName = this.state.accountOwnerName;
                                obj.bankName = this.state.bankName;
                                obj.bankAccountNumber = this.state.bankAccountNumber;
                                obj.currency = this.state.currency;
                                obj.swift = this.state.swift;
                                obj.bankAddress = this.state.bankAddress;
                                obj.bankStatement = this.state.bankStatement;
                                obj.bankStatementFileName = this.state.bankStatementFileName;
                                obj.status = PaymentMethodStatus.Approve;

                                this.props.OnSuccess(obj);
                            }}
                            className="p-button-success"
                        ></Button>
                    </Col>
                </Row>
            </Container>
        );
    }
}
