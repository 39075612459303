import React, { useState } from "react";
import { Carousel } from "primereact/carousel";
import { Button } from "primereact/button";
import "./WebPagerView.css";

export interface PagerProps<T> {
    style?: React.CSSProperties;
    template: (item: T) => React.ReactNode;
    items: T[];
    mode: "carousel" | "pager";
    onPageChange?(e: { page: number }): void;
    autoplay: boolean;
}

const WebPagerView = <T,>(props: PagerProps<T>) => {
    const [currentPage, setCurrentPage] = useState(0);

    if (props.items.length === 0) {
        return <></>;
    }

    const handlePrevPage = () => {
        setCurrentPage((prevPage) => (prevPage > 0 ? prevPage - 1 : props.items.length - 1));
    };

    const handleNextPage = () => {
        setCurrentPage((prevPage) => (prevPage + 1) % props.items.length);
    };

    const CustomPrevIcon = () => (
        <Button icon="pi pi-chevron-left" className="p-button-rounded p-button-text custom-prev-button" onClick={handlePrevPage} />
    );

    const CustomNextIcon = () => (
        <Button icon="pi pi-chevron-right" className="p-button-rounded p-button-text custom-next-button" onClick={handleNextPage} />
    );

    return (
        <div className="WebPagerViewContainer" style={props.style}>
            <Carousel
                showIndicators={false}
                showNavigators={false}
                page={currentPage}
                value={props.items}
                numVisible={1}
                numScroll={1}
                itemTemplate={props.template}
                autoplayInterval={props.mode === "carousel" ? 5000 : undefined}
                onPageChange={props.onPageChange}
                className="custom-carousel"
                circular
            />
            <div className="custom-icon-container">
                <CustomPrevIcon />
                <CustomNextIcon />
            </div>
        </div>
    );
};

export default WebPagerView;
