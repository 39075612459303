import React, { Component, Fragment } from "react";
import { Card, Container } from "reactstrap";

import { TransitionGroup, CSSTransition } from "react-transition-group";

import _ from "lodash";
import { Column } from "primereact/column";
import { classNames } from "primereact/utils";
import { Button } from "primereact/button";

import AddManualKyc from "./AddManualKyc";
import { Toast } from "primereact/toast";
import moment from "moment";
import { DocumentFile, KycSubmission } from "../../models/KycSubmission";
import { kycSubmissionApiService } from "../../services/api/Kyc/KycSubmissionApiService";
import { isUserProfileUpdateAllowed } from "../../authGuard";
import { SearchRequestBaseModel, SortOrderType } from "../../models/SearchRequestModel";
import ExtendedDataTable from "../../components/ExtendedDataTable";
import { numberFilterMatchModeOptions, stringFilterMatchModeOptions } from "../Paginator";

interface KycDataProps {
    individualId: number;
    kycStatusTypes: Map<number, string>;
    kycStatusValue: number | undefined;
    userKycStatusClassesMap: Map<string, string>;
}

interface KycDataState {
    isLoading: boolean;
    kycSubmissions: KycSubmission[];
    isDialogVisible: boolean;
    submissionId: number;
    totalRecords: number
}

export default class UserKycSubmissionsComponent extends Component<KycDataProps, KycDataState> {
    toast: Toast | null = null;
    private dataTable: ExtendedDataTable | null = null;

    constructor(props: any) {
        super(props);

        this.state = {
            isLoading: false,
            kycSubmissions: [],
            isDialogVisible: false,
            submissionId: 0,
            totalRecords: 0
        };
    }


    loadSubmissions = async (searchRequest: SearchRequestBaseModel) => {
        this.setState({ isLoading: true });

        const submissionResult = await kycSubmissionApiService.getAll(this.props.individualId, searchRequest);

        this.setState({ isLoading: false });
        if (!submissionResult.success) {
            this.toast?.show({ severity: "error", summary: "Error", detail: submissionResult.message, life: 3000 });
            return;
        }
        this.setState({ kycSubmissions: submissionResult.data, totalRecords: submissionResult.totalCount });
    };

    renderBadge(success: boolean, title: string) {
        return <div className={classNames("badge", "badge-" + (success ? "success" : "danger"))}>{title}</div>;
    }

    renderKycStatus = (status: number) => {
        const item = this.props.kycStatusTypes.get(status);
        const badge = item != null ? this.props.userKycStatusClassesMap.get(item)! : "danger";
        return <div className={classNames("badge", "badge-" + badge)}>{item}</div>;
    };

    onDialogClose = (success: boolean) => {
        this.setState({ isDialogVisible: false });
        this.loadSubmissions({
            pageNumber: this.dataTable?.state.page!,
            pageSize: this.dataTable?.state.pageRowCount!,
            filters: [],
            sortings: []
        });
    };

    onSubmissionDocumentDialogShow = (submissionId: number): void => {
        this.setState({ isDialogVisible: true, submissionId: submissionId });
    };

    // onSubmitKycDocuments = async (documents: DocumentFile[]) => {
    //     //Update this and implement "KycDocuments Save" in order to really save KYC documents.
    //     //this.setState({ kycDocuments: documents });
    //   };

    // renderAddManualKYCSubmission = (rowData: KycSubmission) => {
    //     if (this.props.kycStatusTypes.get(rowData.status!) === "New") {
    //         return <Button label="Add Manual KYC Submission" className="p-1 m-0" onClick={() => this.onSubmissionDocumentDialogShow(rowData.id)} disabled={!isUserProfileUpdateAllowed()} />
    //     }
    // }

    render() {
        return (
            <Fragment>
                {/* {this.state.isDialogVisible && <div><AddManualKyc onDialogClose={this.onDialogClose} submissionId={this.state.submissionId} submitToOwner={this.onSubmitKycDocuments} renderIn={""} /></div>} */}
                <TransitionGroup appear={true} exit={true} enter={true}>
                    <CSSTransition unmountOnExit classNames="TabsAnimation" timeout={1500}>
                        <Fragment>
                            <Container fluid>
                                <Card>
                                    <Toast ref={(el) => (this.toast = el)} />
                                    <ExtendedDataTable
                                        value={this.state.kycSubmissions}
                                        loading={this.state.isLoading}
                                        totalRecords={this.state.totalRecords}
                                        onChange={this.loadSubmissions}
                                        ref={(ref) => this.dataTable = ref}
                                        defaultSortField='submissionTimeStamp'
                                        defaultSortOrder={SortOrderType.Descending}
                                    >
                                        <Column
                                            field="submissionTimeStamp"
                                            header="Submission TimeStamp"
                                            body={(rowData: KycSubmission) => (
                                                <p>
                                                    {rowData.submissionTimeStamp ? moment.utc(rowData.submissionTimeStamp).local().format("DD/MM/YYYY hh:mm A") : "N/A"}
                                                </p>
                                            )}
                                            filterMatchModeOptions={numberFilterMatchModeOptions}
                                        ></Column>
                                        <Column
                                            header="Reviewer First Name"
                                            field="reviewerFirstName"
                                            body={(rowData: KycSubmission) => (
                                                <p>
                                                    {rowData.reviewerFirstName ?? "N/A"}
                                                </p>
                                            )}
                                            filterMatchModeOptions={stringFilterMatchModeOptions}
                                        ></Column>
                                        <Column
                                            header="Reviewer Last Name"
                                            field="reviewerLastName"
                                            body={(rowData: KycSubmission) => (
                                                <p>
                                                    {rowData.reviewerLastName ?? "N/A"}
                                                </p>
                                            )}
                                            filterMatchModeOptions={stringFilterMatchModeOptions}
                                        ></Column>
                                        <Column
                                            field="reviewStartedTimeStamp"
                                            header="Review Started"
                                            body={(rowData: KycSubmission) => {
                                                <p>
                                                    {rowData.reviewStartedTimeStamp && moment.utc(rowData.reviewStartedTimeStamp).local().format("DD/MM/YYYY hh:mm A")}
                                                </p>;
                                            }}
                                            filterMatchModeOptions={numberFilterMatchModeOptions}
                                        ></Column>
                                        <Column
                                            field="reviewCompletedTimeStamp"
                                            header="Review Closed"
                                            body={(rowData: KycSubmission) => {
                                                <p>
                                                    {rowData.reviewCompletedTimeStamp && moment.utc(rowData.reviewCompletedTimeStamp).local().format("DD/MM/YYYY hh:mm A")}
                                                </p>;
                                            }}
                                            filterMatchModeOptions={numberFilterMatchModeOptions}
                                        ></Column>
                                        <Column field="reason" header="Reason" filterMatchModeOptions={stringFilterMatchModeOptions}></Column>
                                        <Column
                                            field="status"
                                            header="Kyc Status"
                                            body={(rowData: KycSubmission) => this.renderKycStatus(rowData.status)}
                                        ></Column>
                                        {/* <Column
                                            header=""
                                            body={(rowData: KycSubmission) => this.renderAddManualKYCSubmission(rowData)}
                                        ></Column> */}
                                    </ExtendedDataTable>
                                </Card>
                            </Container>
                        </Fragment>
                    </CSSTransition>
                </TransitionGroup>
            </Fragment>
        );
    }
}
