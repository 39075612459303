import React, { Component, Fragment } from "react";
import { Card, CardBody, CardHeader, Container } from "reactstrap";

import { TransitionGroup, CSSTransition } from "react-transition-group";
import _ from "lodash";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { NetworkType, TennCards, PaymentMethods } from "../../models/FinancialData";
import { classNames } from "primereact/utils";
import { Toast } from "primereact/toast";
import { SplitButton } from "primereact/splitbutton";

import visa from "../../assets/images/visa.png";
import mastercard from "../../assets/images/mastercard.png";

import "./financialData.css";
import Loader from "../../components/Loader";
import { PaymentMethodCrudComponent } from "../Banking/PaymentMethodCrudComponent";
import { userPaymentMethodApiService } from "../../services/api/users/UserPaymentMethodApiService";
import { AddUserPaymentMethod } from "./AddUserPaymentMethod";
import { CreateCardPaymentMethodRequest, CreateIbanPaymentMethodRequest, CreateNonIbanPaymentMethodRequest } from "../../models/MerchantModel";

interface FinancialDataProps {
    userId: number;
    cbsOnboarded?: boolean;
}
interface FinancialDataState {
    isLoading: boolean;
    tennCards: TennCards[];
    paymentMethods: PaymentMethods[];
    isAddPaymentMethodVisible: boolean
}

export default class FinancialDataComponent extends Component<FinancialDataProps, FinancialDataState> {
    toast: Toast | null = null;

    constructor(props: any) {
        super(props);

        this.state = {
            isLoading: false,
            tennCards: [],
            paymentMethods: [],
            isAddPaymentMethodVisible: false
        };
    }

    async componentDidMount() {
        if (this.props.cbsOnboarded === true) {
            await this.loadPaymentMethod();
        }
    }


    loadPaymentMethod = async () => {
        this.setState({ isLoading: true });

        const result = await userPaymentMethodApiService.getAll(this.props.userId);
        if (result.success === false) {
            this.setState({ isLoading: false });
            this.toast?.show({ severity: "error", summary: "Error", detail: result.message, life: 3000 });
            return;
        }

        this.setState({ paymentMethods: result.data, isLoading: false });
    };

    renderBadge(success: boolean, title: string) {
        return <div className={classNames("badge", "badge-" + (success ? "success" : "danger"))}>{title}</div>;
    }

    renderCardNetwork(network: NetworkType) {
        return (
            <div>
                <img src={network == NetworkType.Visa ? visa : mastercard} style={{ maxHeight: "100%", maxWidth: "100px" }} alt='' />
            </div>
        );
    }

    onDeactivate = () => {
        this.toast?.show({ severity: "success", summary: "Action Completed", detail: "user Deactivated." });
    };

    onCvvSend = () => {
        this.toast?.show({ severity: "success", summary: "Action Completed", detail: "cvv Sent Successfully." });
    };

    onSendPin = () => {
        this.toast?.show({ severity: "success", summary: "Action Completed", detail: "PIN Sent Successfully." });
    };

    actionBodyTemplate = () => {
        const items = [
            {
                label: "Deactivate",
                icon: "pi pi-refresh",
                command: (e: any) => this.onDeactivate(),
            },
            {
                label: "Send CVV",
                icon: "pi pi-refresh",
                command: (e: any) => this.onCvvSend(),
            },
            {
                label: "Send Pin",
                icon: "pi pi-external-link",
                command: (e: any) => this.onSendPin(),
            },
        ];

        return <SplitButton label="" icon="pi pi-cog" model={items}></SplitButton>;
    };

    addPaymentMethod = () => {
        this.setState({ isAddPaymentMethodVisible: true });
    };

    onHide = (): void => {
        this.setState({ isAddPaymentMethodVisible: false });
        this.loadPaymentMethod();
    };

    deletePaymentMethod = async (rowData: PaymentMethods) => {
        const deleteResult = await userPaymentMethodApiService.delete(this.props.userId, rowData.value);

        if (deleteResult.success) {
            this.setState({ paymentMethods: this.state.paymentMethods.filter((a) => a.value !== rowData.value) });
        } else {
            this.toast?.show({ severity: "error", summary: "Error", detail: deleteResult.message, life: 3000 });
        }
    };

    updatePaymentMethod = async (rowData:  CreateCardPaymentMethodRequest | CreateIbanPaymentMethodRequest | CreateNonIbanPaymentMethodRequest) => {
        const updateResult = await userPaymentMethodApiService.update(this.props.userId, rowData);
        if (updateResult.success) {
            this.toast?.show({ severity: "success", summary: "Action Completed", detail: updateResult.message ?? "Payment method updated." });
        } else {
            this.toast?.show({ severity: "error", summary: "Error", detail: updateResult.message ?? "Operation not allowed.", life: 3000 });
        }
        this.loadPaymentMethod();
    };

    render() {
        return (
            <Fragment>
                <TransitionGroup appear={true} exit={false} enter={false}>
                    <CSSTransition classNames="TabsAnimation" timeout={1500}>
                        <Fragment>
                            <Container fluid>
                                {this.state.isAddPaymentMethodVisible && (
                                    <AddUserPaymentMethod userId={this.props.userId} onHide={this.onHide} />
                                )}
                                <Toast ref={(el) => (this.toast = el)} position={"bottom-center"} baseZIndex={99999}></Toast>
                                <Card>
                                    <CardHeader className="card-header-tab">
                                        <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                                            <i className="header-icon lnr-laptop-phone mr-3 text-muted opacity-6"> </i>
                                            10n Cards
                                        </div>
                                    </CardHeader>
                                    <CardBody>
                                        <DataTable value={this.state.tennCards} emptyMessage="No cards issued">
                                            <Column field="id" header="Card Issuing Id"></Column>
                                            <Column
                                                field="network"
                                                header="Network Type"
                                                body={(rowData: TennCards) => this.renderCardNetwork(rowData.network)}
                                            ></Column>
                                            <Column field="type" header="Card Type"></Column>
                                            <Column field="provider" header="provider"></Column>
                                            <Column
                                                field="issueDate"
                                                header="Card Issuing Date"
                                                body={(rowData: TennCards) => <p>{rowData.issueDate.toDateString()}</p>}
                                            ></Column>
                                            <Column field="pan" header="Pan"></Column>
                                            <Column
                                                field="expiryDate"
                                                header="Card Expiry Date"
                                                body={(rowData: TennCards) => <p>{rowData.expiryDate.toDateString()}</p>}
                                            ></Column>
                                            <Column field="holderName" header="Card Holder Name"></Column>
                                            <Column field="walletId" header="Attached to WalletId"></Column>
                                            <Column field="tariff" header="Tariff"></Column>
                                            <Column
                                                field="active"
                                                header="Is Active"
                                                body={(rowData: TennCards) => this.renderBadge(rowData.active, rowData.active ? "Yes" : "No")}
                                            ></Column>
                                            <Column header="Actions" body={this.actionBodyTemplate} />
                                        </DataTable>
                                    </CardBody>
                                </Card>
                                <PaymentMethodCrudComponent updatePaymentMethod={this.updatePaymentMethod} addPaymentMethod={this.addPaymentMethod} deletePaymentMethod={this.deletePaymentMethod} paymentMethods={this.state.paymentMethods} />
                            </Container>
                        </Fragment>
                    </CSSTransition>
                </TransitionGroup>
            </Fragment>
        );
    }
}
