import React, { Component, Fragment } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Container, Card, CardHeader, CardBody } from "reactstrap";
import PageTitle from "../../Layout/AppMain/PageTitle";
import { Toast } from "primereact/toast";
import PendingKycSubmissions from "./PendingKycSubmissions";
import UnderReviewKycSubmissions from "./UnderReviewKycSubmissions";
import ClosedKycSubmissions from "./ClosedKycSubmissions";
import { TabPanel, TabView } from "primereact/tabview";
import { isKYCApproveAllowed, isKYCAssessAllowed } from "../../authGuard";
import { Button } from "primereact/button";
import RiskSettings from "./RiskSettings";
import { faUserLock } from "@fortawesome/free-solid-svg-icons";

interface KycSubmissionState {
    activeTabIndex: number;
    viewKycSubmissionModal: boolean;
    viewRiskSettingsModal: boolean;
}

export default class KycSubmissionComponent extends Component<Record<string, never>, KycSubmissionState> {
    tabNames: string[] = ["Pending KYC Submissions", "KYC Submissions Under Review", "Closed KYC Submissions"];
    toast: Toast | null = null;

    constructor(props: Record<string, never> | Readonly<Record<string, never>>) {
        super(props);

        this.state = {
            activeTabIndex: 0,
            viewKycSubmissionModal: false,
            viewRiskSettingsModal: false
        };
    }

    toggleRiskSettingsModalView = async () => {
        this.setState({ viewRiskSettingsModal: false });
    };

    render() {
        return (
            <Fragment>
                <TransitionGroup appear={true} exit={false} enter={false}>
                    <CSSTransition classNames="TabsAnimation" timeout={1500}>
                        <Fragment>
                            <Container fluid>
                                <PageTitle heading="KYC" icon={faUserLock} />
                                {this.state.viewRiskSettingsModal && <RiskSettings onHide={this.toggleRiskSettingsModalView} />}
                                {/* {this.state.viewRiskSettingsModal && <LookupItemsForm onHide={this.toggleRiskSettingsModalView} />} */}
                                <Card className="mb-3">
                                    <CardHeader className="card-header-tab d-flex justify-content-between" >
                                        <span>KYC Submissions</span>
                                        {isKYCApproveAllowed() && <Button className="p-button-text p-button-rounded p-button-plain"  
                                            onClick={() => this.setState({ viewRiskSettingsModal: true }) }/>}
                                    </CardHeader>
                                    <CardBody>
                                        <TabView activeIndex={this.state.activeTabIndex}
                                            onTabChange={(e) => {
                                                this.setState({ activeTabIndex: e.index });
                                            }}>
                                            <TabPanel header="Completed KYC Submissions">
                                                <ClosedKycSubmissions />
                                            </TabPanel>
                                            {isKYCAssessAllowed() && 
                                                <TabPanel header="Pending KYC Submissions">
                                                	<PendingKycSubmissions />
                                                </TabPanel>}
                                            {isKYCAssessAllowed() && 
                                                <TabPanel header="KYC Submissions Under Review">
                                                	<UnderReviewKycSubmissions showKycSubmissionsStatus={"UnderReview"}/>
                                                </TabPanel>}
                                            {isKYCApproveAllowed() && 
                                                <TabPanel header="KYC Submissions Awaiting Approval">
                                                	<UnderReviewKycSubmissions showKycSubmissionsStatus={"AwaitingApproval"}/>
                                                </TabPanel>}
                                        </TabView>
                                    </CardBody>
                                </Card>
                            </Container>
                        </Fragment>
                    </CSSTransition>
                </TransitionGroup>
            </Fragment>
        );
    }
}
