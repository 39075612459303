import React, { Component, Fragment } from "react";
import { Card, CardHeader, Col, Container, Row, CardBody } from "reactstrap";
import { CSSTransitionComponent } from "../components/CSSTransition";
import { HoveredWidget } from "../components/HoveredWidget";
import PageTitle from "../Layout/AppMain/PageTitle";
import Users from "./Users/Users";
import "./merchantDashboard.scss";
import MerchantPaymentMethods from "./Merchants/MerchantPaymentMethods";
import { merchantWalletApiService } from "../services/api/merchants/MerchantWalletApiService";
import { Wallets } from "../models/FinancialData";
import ReactLoader from "react-loaders";
import { storageService } from "../services/StorageService";
import { DepositViaPaymentMethodDialog } from "./Banking/DepositViaPaymentMethodDialog";
import { WithdrawViaPaymentMethodDialog } from "./Banking/WithdrawViaPaymentMethodDialog";
import { faBalanceScale, faChartLine, faCircleMinus, faDashboard, faMoneyCheckAlt, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { isMerchantDashboardUpdateAllowed, isMerchantPaymentMethodUpdateAllowed } from "../authGuard";
import AllTransactions from "./Operations/AllTransactions";

import WebPagerView from "./Merchants/WebPagerView";
import { TransferFundsDialogue } from "./Banking/TransferFundsDialogue";
import { ExtendedSelectItem } from "../models/SelectItem";
import { Toast } from "primereact/toast";
import { operatorsApiService } from "../services/api/users/OperatorApiService";
import EventEmitter, { Events } from "../services/eventemitter";
import { OperatorDataModel } from "../models/User";

interface MerchantDashboardState {
    showDepositDialog: boolean,
    showWithdrawDialog: boolean;
    showTransferDialog: boolean;
    showDirectDebitDialog: boolean;
    wallets: Wallets[],
    isLoading: boolean,
    isWalletBalanceLoading: boolean,
    merchantId: number,
    showBalance: boolean,
    sourceWallets: Wallets[];
    sourceAccountsOptions: ExtendedSelectItem[];
    loggedUserData: OperatorDataModel | null;
}

export default class MerchantDashboard extends Component<Record<string, never>, MerchantDashboardState> {
    toast: Toast | null = null;
    constructor(props: Record<string, never> | Readonly<Record<string, never>>) {
        super(props);

        this.state = {
            showDepositDialog: false,
            showDirectDebitDialog: false,
            showTransferDialog: false,
            showWithdrawDialog: false,
            wallets: [],
            isLoading: true,
            isWalletBalanceLoading: true,
            merchantId: storageService.getLoggedInUserMerchantId(),
            showBalance: false,
            sourceWallets: [],
            sourceAccountsOptions: [],
            loggedUserData: null,
        };
        EventEmitter.on(Events.OnUserDataUpdate, this.updateUserData);
    }

    async componentDidMount(): Promise<void> {
        await this.updateUserData();
        await this.onLoad();
    }

    
    updateUserData = async () => {
        const userData = await operatorsApiService.get();
        if (!userData.success) {
            return;
        }
        this.setState({ loggedUserData: userData.data });
    };

    onLoad = async () => {
        this.setState({ isLoading: true });
        const walletsResult = await merchantWalletApiService.getWallets(this.state.merchantId);

        if (!walletsResult.success) {

            this.setState({ isLoading: false, isWalletBalanceLoading: false });
            this.toast?.show({ severity: "error", summary: "Error", detail: walletsResult.message, life: 3000 });
            return;
        }

        const balanceResult = (await Promise.all(walletsResult.data.map(item => merchantWalletApiService.getWalletBalance({ merchantId: this.state.merchantId, accountNumber: item.id }))))
            .filter(a => a.success)
            .map(a => a.data);

        const wallets =walletsResult.data.map(w => {
            w.balance = balanceResult.find(a => a.id == w.id)?.balance ?? 0;
            w.blockedAmount = balanceResult.find(a => a.id == w.id)?.blockedAmount ?? 0;

            return w;
        });

        const sourceAccountOptions = wallets.map((wallet) => ({
            label: wallet.ibanAccountNumber,
            value: wallet.ibanAccountNumber,
            details: { fullname: this.state.loggedUserData?.firstName + " " + this.state.loggedUserData?.lastName, subtitle: this.state.loggedUserData?.email,  icons: "wallet-outline", currency: wallet.currency }
        })) as ExtendedSelectItem[];

        this.setState((prevState) => ({
            ...prevState,
            sourceAccountsOptions: sourceAccountOptions,
            sourceWallets: walletsResult.data,
            wallets: wallets, isWalletBalanceLoading: false, isLoading: false
        }));
        
    };

    renderWallets = () => {
        if (this.state.isLoading) {
            return (
                <Col style={{ maxWidth: "300px" }} lg={2} md={3} xl={3} xxl={2}>
                    <HoveredWidgetWrapper>
                        <span className="align-center"><ReactLoader type="ball-grid-pulse" active /></span>
                    </HoveredWidgetWrapper>
                </Col>
            );
        }

        return this.state.wallets.map((wallet, idx) => (
            <Col key={idx} style={{ maxWidth: "300px" }} lg={3} md={3} xl={3} xxl={2}>
                <HoveredWidgetWrapper>
                    <HoveredWidget textClass="" icon={wallet.currency} description={wallet.ibanAccountNumber} title={`${wallet.balance}`} subHeading={wallet.currency} isLoading={this.state.isWalletBalanceLoading} />
                </HoveredWidgetWrapper>
            </Col>
        ));
    };

    walletTemplate = (wallet: Wallets) => {
        if (this.state.isLoading) {
            return (
                <Col style={{ maxWidth: "300px" }} lg={2} md={3} xl={3} xxl={2}>
                    <HoveredWidgetWrapper>
                        <span className="align-center"><ReactLoader type="ball-grid-pulse" active /></span>
                    </HoveredWidgetWrapper>
                </Col>
            );
        }
        return (
            <Col key={wallet.id} style={{ maxWidth: "300px" }}>
            <HoveredWidgetWrapper>
                <HoveredWidget textClass="info" walletsWidget={true} small={true} arrowIcon={faMoneyCheckAlt} icon={faMoneyCheckAlt } title={`${wallet.balance}`} subHeading={wallet.currency} description={wallet.ibanAccountNumber} />
            </HoveredWidgetWrapper>
        </Col>
        );
    };

    renderWidgets = () => {
        return (
            <Container fluid className="short-widget-numbers">
                <Row>
                    <Col style={{ maxWidth: "300px" }}>
                        <WebPagerView
                            template={this.walletTemplate}
                            items={this.state.sourceWallets ?? []}
                            mode={"carousel"}
                            autoplay={true}
                        />
                    </Col>

                    <Col style={{ maxWidth: '300px' }}>
                            <HoveredWidgetWrapper>
                                <HoveredWidget textClass="warning" small={true} icon={faCircleMinus} title="Transfer" subHeading="_" onClick={this.onTransfer} />
                            </HoveredWidgetWrapper>
                    </Col>
                    <Col style={{ maxWidth: "300px" }}>
                        <HoveredWidgetWrapper>
                            <HoveredWidget textClass="info" small={true} icon={faPlusCircle} title="Deposit" subHeading="_" onClick={this.onDeposit} />
                        </HoveredWidgetWrapper>
                    </Col>
                    <Col style={{ maxWidth: "300px" }}>
                        <HoveredWidgetWrapper>
                            <HoveredWidget textClass="danger" small={true} icon={faCircleMinus} title="Withdraw" subHeading="_" onClick={this.onWithdraw} />
                        </HoveredWidgetWrapper>
                    </Col>
                        
                        {/*
                        <Col style={{ maxWidth: '300px' }}>
                            <HoveredWidgetWrapper>
                                <HoveredWidget textClass="primary" small={true} icon="lnr-location" title="Direct Debit" subHeading="_" onClick={this.onDirectDebit} />
                            </HoveredWidgetWrapper>
                        </Col> */}
                </Row>
            </Container>
        );
    };

    onBalance = () => {
        this.setState({ showBalance: true });
    };

    onDeposit = () => {
        this.setState({ showDepositDialog: true });
    };

    onWithdraw = () => {
        this.setState({ showWithdrawDialog: true });
    };

    onTransfer = () => {
        this.setState({ showTransferDialog: true });
    };

    onDirectDebit = () => {
        this.setState({ showDirectDebitDialog: true });
    };

    render() {
        return (
            <Fragment>
                <Toast ref={(el) => (this.toast = el)} position={"bottom-center"} baseZIndex={99999}></Toast>
                {this.state.showTransferDialog && <TransferFundsDialogue loggedUserData={this.state.loggedUserData} sourceAccountsOptions={this.state.sourceAccountsOptions} sourceWallets={this.state.sourceWallets} merchantId={this.state.merchantId} onHide={() => this.setState({ showTransferDialog: false })} />}
                {this.state.showDepositDialog && <DepositViaPaymentMethodDialog onHide={() => this.setState({ showDepositDialog: false })} onSuccess={() => { }} />}
                {this.state.showWithdrawDialog && <WithdrawViaPaymentMethodDialog loggedUserData={this.state.loggedUserData} sourceAccountsOptions={this.state.sourceAccountsOptions} sourceWallets={this.state.sourceWallets}  merchantId={this.state.merchantId} onHide={() => this.setState({ showWithdrawDialog: false })} />}
                <CSSTransitionComponent>
                    <PageTitle heading="Dashboard" icon={faDashboard} />
                </CSSTransitionComponent>
                {(isMerchantDashboardUpdateAllowed() || isMerchantPaymentMethodUpdateAllowed()) &&
                    <CSSTransitionComponent>
                        {this.renderWidgets()}
                    </CSSTransitionComponent>
                }
                <CSSTransitionComponent>
                    <Card>
                        <CardHeader>
                            Payment Methods
                        </CardHeader>
                        <CardBody>
                            <MerchantPaymentMethods merchantId={this.state.merchantId} viewOnly={false} />
                        </CardBody>
                    </Card>
                </CSSTransitionComponent>
                <CSSTransitionComponent>
                    <Card>
                        <CardHeader>
                            All Transactions
                        </CardHeader>
                        <CardBody>
                            {this.state.loggedUserData && this.state.loggedUserData.flexCubeBranch && <AllTransactions merchantBranch={this.state.loggedUserData.flexCubeBranch} merchantId={this.state.merchantId}/>}
                        </CardBody>
                    </Card>
                </CSSTransitionComponent>
            </Fragment>
        );
    }
}

function HoveredWidgetWrapper({ ...props }) {
    return (
        <Card className="main-card mb-3">
            <div className="grid-menu grid-menu-2col">
                <Row className="no-gutters">
                    <Col style={{ maxWidth: "300px" }}>
                        {props.children}
                    </Col>
                </Row>
            </div>
        </Card>
    );
}
