import { MerchantWalletBalanceRequest, Wallets } from "../../../models/FinancialData";
import { Result } from "../../../models/Result";
import { httpService } from "../../HttpService";

class MerchantWalletApiService {
    path: string = "MerchantWallet";

    getWalletBalance = async (request: MerchantWalletBalanceRequest): Promise<Result<Wallets>> => await httpService.post<Wallets>(`/${this.path}/wallet-balance`, request);

    getOwnWallets = async (): Promise<Result<Wallets[]>> => await httpService.get<Wallets[]>(`/${this.path}/byMerchant`);

    getWallets = async (id: number): Promise<Result<Wallets[]>> => await httpService.get<Wallets[]>(`/${this.path}/${id}/wallets`);
}

const merchantWalletApiService = new MerchantWalletApiService();

export { merchantWalletApiService };