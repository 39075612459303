import React, { Component } from "react";
import { Card, CardBody, CardHeader } from "reactstrap";

import _ from "lodash";
import moment from "moment";
import { Column, ColumnFilterElementTemplateOptions } from "primereact/column";
import { DataTable, DataTableFilterMetaData, DataTablePFSEvent } from "primereact/datatable";

import { AllTransactionRequestModel, PaymentStatus, TransactionReportDetails, TransactionReportResponse, TransactionStatus } from "../../models/Transactions";
import TransactionDetailsDialog from "../Users/TransactionDetails";
import { dateRangeFilterTemplate, paginatorTemplate, statusFilterMatchModeOptions, stringFilterMatchModeOptions } from "../Paginator";

import { FilterMatchMode } from "primereact/api";
import { Toast } from "primereact/toast";
import {
    ActionBodyTemplate,
    DateFilterTemplate,
    InputTextFilerTemplate,
    RenderPaymentStatusBadge,
    RenderStatusBadge,
    StatusFilterTemplate,
    TransactionSystemFilterTemplate,
    TransactionTypeFilterTemplate,
} from "./TransactionFilterTemplates";
import { transactionsReportApiService } from "../../services/api/operations/TransactionsReportApiService";
import { SelectItem } from "../../models/SelectItem";
import { merchantApiService } from "../../services/api/merchants/MerchantApiService";
import { Dropdown } from "primereact/dropdown";
import { SearchRequestBaseModel, SortOrderType } from "../../models/SearchRequestModel";
import { utils } from "../../utils/utils";
import ExtendedDataTable from "../../components/ExtendedDataTable";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { InputText } from "primereact/inputtext";
import { currenciesApiService } from "../../services/api/merchants/CurrenciesApiService";

interface TransactionsProps {
    merchantBranch?: string;
    merchantId?: number;
}


type TransactionsState = {
    isLoading: boolean;
    merchants: SelectItem[];
    transactions: TransactionReportDetails[];
    filters?: {};
    viewTransactionDetails: boolean;
    selectedTransaction: TransactionReportDetails | null;
    first: number;
    rows: number;
    totalRecords: number;
    currencies: SelectItem[];
};


class AllTransactions extends Component<TransactionsProps, TransactionsState> {
    toast: Toast | null = null;
    private dataTable: ExtendedDataTable | null = null;

    constructor(props: TransactionsProps) {
        super(props);

        this.state = {
            isLoading: false,
            viewTransactionDetails: false,
            transactions: [],
            filters: {
                transactionID: { value: null, matchMode: FilterMatchMode.EQUALS },
                type: { value: null, matchMode: FilterMatchMode.EQUALS },
                timestampTo: { value: null, matchMode: FilterMatchMode.EQUALS },
                amount: { value: null, matchMode: FilterMatchMode.EQUALS },
                currency: { value: null, matchMode: FilterMatchMode.EQUALS },
            },
            selectedTransaction: null,
            first: 0,
            rows: 10,
            totalRecords: 0,
            merchants: [],
            currencies: [],
        };
    }

    componentDidMount(): void {
        this.onLoad();
    }

    onLoad = async () => {
        this.loadCurrencies();
        this.loadTransactions();
    };

    loadCurrencies = async () => {
        this.setState({ isLoading: true });
        const currenciesResult = this.props.merchantId ? await currenciesApiService.getMerchantActiveCurrencies(this.props.merchantId) : await currenciesApiService.getAll();

        if (!currenciesResult.success) {
            this.setState({ isLoading: false });
            this.toast?.show({ severity: "error", summary: "Error", detail: currenciesResult.message, life: 3000 });
            return;
        }
        const currenciesList: SelectItem[] = currenciesResult.data.map((currency) => ({
            label: currency.charCode,
            value: currency.charCode
        }));

        this.setState({ currencies: currenciesList, isLoading: false });
    };

    onSorting = async (filters: SearchRequestBaseModel = {} as SearchRequestBaseModel, transactions?: TransactionReportDetails[]) => {
        this.setState({ transactions: await utils.extendedDatatableSorting(filters, transactions ?? this.state.transactions) });
    }


    loadTransactions = async (filters: SearchRequestBaseModel = {} as SearchRequestBaseModel) => {
        this.setState({ isLoading: true });
        const filterRequest: AllTransactionRequestModel = await utils.extractFiltersData(filters);
        filterRequest.dataBatch = filters.pageNumber ?? 1;
        filterRequest.dataBatchCount = filters.pageSize ?? 10;
        this.props.merchantBranch ? filterRequest.merchant = this.props.merchantBranch : null;
        const transactionsResult = await transactionsReportApiService.getAllTransactionsReportResponse(filterRequest);
        if (!transactionsResult.success) {
            this.setState({ isLoading: false, transactions: [] });
            this.toast?.show({ severity: "error", summary: "Error", detail: transactionsResult.message, life: 3000 });
            return;
        }
        await this.onSorting(filters, transactionsResult.data.transactions);
        this.setState({ isLoading: false, totalRecords: transactionsResult.data.transactionCount });
    };

    renderHeader = () => {
        return (
            <span className="p-input-icon-left" style={{ display: "flex", justifyContent: "space-between" }}>
                <Button type="button" icon="pi pi-filter-slash" label="Clear" className="p-button-outlined" onClick={() => { this.dataTable!.reset(); }} />
            </span>
        );
    };

    showTransactionDetails = (rowData: TransactionReportDetails) => {
        this.setState({ viewTransactionDetails: true, selectedTransaction: rowData });
    };

    hideTransactionDetailsView = () => {
        this.setState({ viewTransactionDetails: false });
    };

    // OnPageChange = (event: DataTablePFSEvent) => {
    //     var filter = {} as AllTransactionRequestModel;

    //     Object.keys(event.filters ?? {})
    //         .flatMap(key => {
    //             var data = (event.filters[key] as DataTableFilterMetaData);

    //             return { columnName: key, value: data.value };
    //         })
    //         .filter(f => f.value != null)
    //         .map(f => {
    //             (filter as any)[f.columnName] = f.value;
    //         }); 

    //     // if(filter.timestampTo != null){
    //     //     filter.timestampTo = utils.convertDateToUTC(filter.timestampTo);
    //     // }
    //     if(filter.timestampTo != null){
    //         const updatedEndDate = moment(filter.timestampTo).add(1, 'day').toDate();
    //         filter.timestampTo = moment(updatedEndDate).format("YYYY-MM-DD");
    //     }

    //     this.loadTransactions(filter);
    // } 

    currencyFilterTemplate = (options: ColumnFilterElementTemplateOptions, columnName: string, placeHolder: string) => {
        return (
            <Dropdown
                value={options.value}
                options={this.state.currencies}
                filter
                onChange={(e) => options.filterCallback(e.value, options.index)}
                placeholder={`Select ${placeHolder}`}
                className="p-column-filter"
                showClear
            />
        );
    };



    render(): React.ReactNode {
        return (
            <>
                <Card className="mb-3">
                    {this.state.viewTransactionDetails && this.state.selectedTransaction != null && (
                        <TransactionDetailsDialog
                            isVisible={this.state.viewTransactionDetails}
                            onHide={this.hideTransactionDetailsView}
                            transaction={this.state.selectedTransaction}
                            editable={false}
                        />
                    )}
                    <CardHeader className="card-header-tab" style={{ justifyContent: "space-between" }}>
                        <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                            <i className="header-icon lnr-laptop-phone mr-3 text-muted opacity-6"> </i>
                            All Transactions
                        </div>
                    </CardHeader>
                    <CardBody>
                        <ExtendedDataTable
                            header={this.renderHeader}
                            value={this.state.transactions}
                            loading={this.state.isLoading}
                            totalRecords={this.state.totalRecords}
                            onChange={this.loadTransactions}
                            ref={(ref) => this.dataTable = ref}
                            defaultSortOrder={SortOrderType.Descending}
                            defaultSortField='timeStamp'
                            onSorting={this.onSorting}
                        >
                            <Column
                                field="paymentSystem"
                                header="Payment System"
                                body={(rowData: TransactionReportDetails) => <p>{rowData.paymentSystem}</p>}
                                showFilterMatchModes={false}
                                filterField="paymentSystem"
                                filterMatchModeOptions={stringFilterMatchModeOptions}
                                filterElement={TransactionSystemFilterTemplate}
                            />

                            <Column
                                field="transactionId"
                                header="Transaction ID"
                                body={(rowData: TransactionReportDetails) => <p>{rowData.transactionId}</p>}
                                showFilterMatchModes={false}
                                filterField="transactionId"
                                filterPlaceholder="Search by Transaction ID"
                                filterMatchModeOptions={stringFilterMatchModeOptions}
                            />

                            <Column
                                field="transactionType"
                                header="Type"
                                body={(rowData: TransactionReportDetails) => <p>{rowData.transactionType}</p>}
                                filterMatchModeOptions={stringFilterMatchModeOptions}
                                showFilterMatchModes={false}
                                filterField="trxDirection"
                                filterElement={TransactionTypeFilterTemplate}
                            />

                            <Column
                                field="senderName"
                                header="Sender"
                                body={(rowData: TransactionReportDetails) => <p>{rowData.senderName}</p>}
                            />

                            <Column
                                field="recepientName"
                                header="Recepient"
                                body={(rowData: TransactionReportDetails) => <p>{rowData.receiverName}</p>}
                            />

                            <Column
                                field="timeStamp"
                                header="Transaction Date"
                                body={(rowData: TransactionReportDetails) => <p>{moment.utc(rowData.timeStamp).local().format("DD/MM/YYYY hh:mm:ss")}</p>}
                                dataType="date"
                                filterElement={dateRangeFilterTemplate}
                                filterField="timeStampTo"
                                filterMatchModeOptions={statusFilterMatchModeOptions}
                                showFilterMatchModes={false}

                            />

                            <Column
                                field="amount"
                                header="Amount"
                                showFilterMatchModes={false}
                                filterField="amountTo"
                                filterPlaceholder="Search by Amount"
                                filterMatchModeOptions={stringFilterMatchModeOptions}
                                body={(rowData: TransactionReportDetails) => <p>{rowData.amount}</p>}
                            />

                            <Column
                                field="currency"
                                header="Currency"
                                body={(rowData: TransactionReportDetails) => <p>{rowData.currency}</p>}
                                showFilterMatchModes={false}
                                filterField="currency"
                                filterMatchModeOptions={stringFilterMatchModeOptions}
                                filterElement={(options => this.currencyFilterTemplate(options, "currency", "Currency"))}
                            />

                            <Column
                                field="status"
                                header="Status"
                                body={(rowData: TransactionReportResponse) =>
                                    rowData.wireTransferReturnStatus.includes(TransactionStatus.Accepted) ? RenderStatusBadge(TransactionStatus.Accepted)
                                    :
                                    rowData.wireTransferReturnStatus.includes(TransactionStatus.Rejected) ? RenderStatusBadge(TransactionStatus.Rejected)
                                    :
                                    RenderPaymentStatusBadge(PaymentStatus.Processed)
                                }
                            />

                            <Column
                                header="Details"
                                body={(rowData: TransactionReportDetails) => <ActionBodyTemplate data={rowData} onClickAction={this.showTransactionDetails} />}
                            />
                        </ExtendedDataTable>
                    </CardBody>
                </Card>
            </>
        );
    }
}

export default AllTransactions;
