import React, { Component, CSSProperties } from "react";
import { classNames } from "primereact/utils";

import placeHolder from "../../images/chooseimage.png";
import { utils } from "../../utils/utils";

export interface ImageProps {
    className?: string;
    style?: CSSProperties | undefined;
    imageStyle?: CSSProperties | undefined;
    imageClassName?: string;
    src?: string;
    alt?: string;
    width?: string;
    height?: string;
    onImageSelected: (image: File) => void;
    fileInput:(input: boolean) => void ;
}

export class ProfileImage extends Component<ImageProps, {}> {
    imageInput: HTMLInputElement | null = null;

    onImageSelected = (event: any) => {
        const files = (event.target.files || event.dataTransfer.files) as FileList;
        const image = files[0];
        if (!utils.checkCharacterlength(image.name) && this.imageInput) {
            this.props.fileInput(false);
            this.imageInput.value = "";
        } else {
            this.props.onImageSelected(image);
        }
    };

    render() {
        const containerClassName = classNames("p-image p-component", this.props.className, {
            "p-image-preview-container": true,
        });

        const { alt, width, height } = this.props;

        return (
            <span className={containerClassName} style={this.props.style}>
                <input
                    ref={(el) => (this.imageInput = el)}
                    type="file"
                    style={{ display: "none" }}
                    accept="image/*"
                    onChange={this.onImageSelected}
                />

                <img
                    src={(this.props.src?.length ?? 0) > 0 ? this.props.src : placeHolder}
                    className={this.props.imageClassName}
                    width={width}
                    height={height}
                    style={this.props.imageStyle as any}
                    alt={alt}
                />
                {
                    <div className="p-image-preview-indicator" onClick={() => this.imageInput?.click()}>
                        <i className="p-image-preview-icon pi pi-upload"></i>
                    </div>
                }
            </span>
        );
    }
}