import { Component } from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import { DomHandler } from "primereact/utils";
import PrimeReact, { AppendToType } from "primereact/api";

interface PortalState {
    mounted: boolean
}

interface PortalProps {
    onUnmounted?: () => void
    onMounted?: () => void
    element: React.ReactNode | null
    appendTo: AppendToType | null,
    visible: boolean
}

export class Portal extends Component<PortalProps, PortalState> {

    static defaultProps = {
        visible: false,
    };

    static propTypes = {
        element: PropTypes.any,
        appendTo: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        visible: PropTypes.bool,
        onMounted: PropTypes.func,
        onUnmounted: PropTypes.func
    };

    constructor(props: PortalProps | Readonly<PortalProps>) {
        super(props);

        const mounted = props.visible && DomHandler.hasDOM();

        this.state = {
            mounted
        };
    }

    componentDidMount() {
        if (DomHandler.hasDOM() && !this.state.mounted) {
            this.setState({ mounted: true }, this.props.onMounted);
        }
    }

    componentWillUnmount() {
        this.props.onUnmounted && this.props.onUnmounted();
    }

    render() {
        const element = this.props.element || this.props.children;
        if (element && this.state.mounted) {
            const appendTo = this.props.appendTo || PrimeReact.appendTo || document.body;
            return appendTo === "self" ? element : ReactDOM.createPortal(element, appendTo);
        }

        return null;
    }
}