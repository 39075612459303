import React, { Component, Fragment } from "react";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Container, Row, Col } from "reactstrap";
import { MerchantTariff, MerchantTariffDetail } from "../../models/MerchantModel";
import { Column, ColumnEditorOptions } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { SelectItem } from "../../models/SelectItem";
import { MultiSelect } from "primereact/multiselect";
import "./tariffDetails.scss";

interface TariffDetailsProps {
    onHide: () => void;
    tariff: MerchantTariff;
}

interface TariffDetailsState {
    tariff: MerchantTariff;
    isSaving: boolean;
}

export default class TariffDetails extends Component<TariffDetailsProps, TariffDetailsState> {
    toast: Toast | null = null;

    operations: SelectItem[] = [
        { label: "Load", value: "Load" },
        { label: "Withdraw", value: "Withdraw" },
        { label: "Fees", value: "Fees" },
        { label: "Cards 1", value: "Cards1" },
        { label: "Cards 2", value: "Cards2" },
        { label: "Cards 3", value: "Cards3" },
        { label: "Other", value: "Other" },
    ];

    currencies: SelectItem[] = [
        { label: "EUR", value: "EUR" },
        { label: "USD", value: "USD" },
        { label: "GBP", value: "GBP" },
        { label: "BGN", value: "BGN" },
    ];

    userLevels: SelectItem[] = [
        { label: "Level 1", value: "L1" },
        { label: "Level 2", value: "L2" },
        { label: "Level 3", value: "L3" },
    ];

    groups: SelectItem[] = [
        { label: "Group 1", value: "G1" },
        { label: "Group 2", value: "G2" },
        { label: "Group 3", value: "G3" },
        { label: "Group 4", value: "G4" },
    ];

    constructor(props: any) {
        super(props);

        this.state = {
            tariff: this.props.tariff,
            isSaving: false,
        };
    }

    componentDidUpdate(prevProps: TariffDetailsProps) {
        if (this.props.tariff != prevProps.tariff) {
            this.setState({ tariff: this.props.tariff });
        }
    }

    renderHeader = () => {
        return (
            <div className="table-header">
                <span className="p-input-icon-left align-right">
                    <Button label="Add Operation" className="p-button-outlined" icon="pi pi-plus" onClick={this.addDetail} />
                </span>
            </div>
        );
    };

    addDetail = () => {
        const tariff = this.state.tariff;
        tariff.details.push(new MerchantTariffDetail());
        this.setState({ tariff: tariff });
    };

    onRowEditComplete = (e: { newData: any; index: any; }) => {
        const tariff = this.state.tariff;
        const details = [...tariff.details];
        const { newData, index } = e;

        details[index] = newData;

        tariff.details = details;

        this.setState({ tariff: tariff });
    };

    numberEditor(options: ColumnEditorOptions) {
        return <InputText type="number" className="p-inputtext-sm" value={options.value} onChange={(e) => options.editorCallback && options.editorCallback(e.target.value)} />;
    }

    operationEditor = (options: ColumnEditorOptions, items: SelectItem[], placeHolder: string) => {
        return (
            <Dropdown
                value={options.value}
                options={items}
                optionLabel="label"
                optionValue="value"
                onChange={(e) =>options.editorCallback && options.editorCallback(e.value)}
                placeholder={placeHolder}
                itemTemplate={(option) => {
                    return <span>{option.label}</span>;
                }}
            />
        );
    };

    onSave = () => {
        this.setState({ isSaving: true });
        setTimeout(() => {
            this.setState({ isSaving: false });
            this.props.onHide();
        }, 3000);
    };

    updateTariff = (field: string, value: string) => {
        const tariff : any = this.state.tariff;

        tariff[field] = value;

        this.setState({ tariff: tariff });
    };

    setSelectedGroupNames = (values: any[]) => {
        const tariff = this.state.tariff;

        tariff.groupNames = values;

        this.setState({ tariff: tariff });
    };

    render() {
        if (this.state.tariff == null) {
            return "";
        }

        return (
            <Dialog header="Tariff" visible={true} style={{ width: "99vw" }} onHide={this.props.onHide}>
                <TransitionGroup component="div" appear={true} exit={false} enter={false}>
                    <CSSTransition classNames="TabsAnimation" timeout={1500}>
                        <Fragment>
                            <Container fluid>
                                <Row>
                                    <Col md={4}>
                                        <div className="p-fluid p-grid p-formgrid">
                                            <div className="p-field p-col-4 p-md-4">
                                                <label htmlFor="name">Name</label>
                                                <InputText className="p-inputtext-sm" id="name" value={this.state.tariff.name} onChange={(e) => this.updateTariff("name", e.target.value)} />
                                            </div>
                                            <div className="p-field p-col-4 p-md-4">
                                                <label htmlFor="code">Code</label>
                                                <InputText className="p-inputtext-sm" id="code" value={this.state.tariff.code} onChange={(e) => this.updateTariff("code", e.target.value)} />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className="p-fluid p-grid p-formgrid">
                                            <div className="p-field p-col-4 p-md-4">
                                                <label>Groups</label>
                                                <MultiSelect
                                                    display="chip"
                                                    optionLabel="label"
                                                    value={this.state.tariff.groupNames}
                                                    options={this.groups}
                                                    onChange={(e) => this.setSelectedGroupNames(e.value)}
                                                />
                                            </div>
                                            <div className="p-field p-col-4 p-md-4">
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <h5 />
                                <h5 />
                                <h5 />
                                <DataTable
                                    header={this.renderHeader}
                                    autoLayout={true}
                                    dataKey="id"
                                    value={this.state.tariff.details}
                                    editMode="row"
                                    style={{ fontSize: "13px" }}
                                    onRowEditComplete={this.onRowEditComplete}
                                    responsiveLayout="scroll"
                                >
                                    <Column
                                        field="operation"
                                        header="Operation"
                                        editor={(options) => this.operationEditor(options, this.operations, "Select")}
                                        style={{ width: "100px" }}
                                    ></Column>
                                    <Column
                                        field="currency"
                                        header="Currency"
                                        editor={(options) => this.operationEditor(options, this.currencies, "Select")}
                                        style={{ width: "50px" }}
                                    ></Column>
                                    <Column field="feeAmount" header="Fee Amount" editor={(options) => this.numberEditor(options)}></Column>
                                    <Column field="feePercenzt" header="Fee Percent" editor={(options) => this.numberEditor(options)}></Column>
                                    <Column
                                        field="forexFeeAmount"
                                        header="Forex Fee Amount"
                                        editor={(options) => this.numberEditor(options)}
                                    ></Column>
                                    <Column
                                        field="forexFeePercent"
                                        header="Forex Fee Percent"
                                        editor={(options) => this.numberEditor(options)}
                                    ></Column>
                                    <Column
                                        field="FxRateMarkUp"
                                        header="Fx Rate MarkUp"
                                        editor={(options) => this.numberEditor(options)}
                                    ></Column>
                                    <Column
                                        field="FxRateMarkDown"
                                        header="Fx Rate MarkDown"
                                        editor={(options) => this.numberEditor(options)}
                                    ></Column>
                                    <Column field="fromRange" header="From Range" editor={(options) => this.numberEditor(options)}></Column>
                                    <Column field="toRange" header="To Range" editor={(options) => this.numberEditor(options)}></Column>
                                    <Column field="minAmount" header="Min Amount" editor={(options) => this.numberEditor(options)}></Column>
                                    <Column
                                        field="maxAmountTransactionAndDay"
                                        header="Max Amount (transaction and day)"
                                        editor={(options) => this.numberEditor(options)}
                                    ></Column>
                                    <Column field="maxAmount24H" header="Max Amount (24h)" editor={(options) => this.numberEditor(options)}></Column>
                                    <Column field="maxAmount5D" header="Max Amount (5d)" editor={(options) => this.numberEditor(options)}></Column>
                                    <Column field="minFee" header="Min Fee" editor={(options) => this.numberEditor(options)}></Column>
                                    <Column field="maxFee" header="Max Fee" editor={(options) => this.numberEditor(options)}></Column>
                                    <Column
                                        field="userLevel"
                                        header="User Level"
                                        editor={(options) => this.operationEditor(options, this.userLevels, "Select")}
                                    ></Column>
                                    <Column rowEditor bodyStyle={{ textAlign: "center" }}></Column>
                                </DataTable>
                                <div className="align-right" style={{ padding: "10px" }}>
                                    <Button label="Submit" icon="pi pi-check" loading={this.state.isSaving} onClick={this.onSave} />
                                </div>
                            </Container>
                        </Fragment>
                    </CSSTransition>
                </TransitionGroup>
            </Dialog>
        );
    }
}
