import { Component, Fragment } from "react";
import { IndividualList } from "../../models/IndividualSelectModel";
import { MerchantPaymentMethod } from "../../models/MerchantModel";
import { BankTransferInitiateRequestModel, BankTransferResponseCode, CalculateMoneyTransferChargeRequest, MoneyTransferRequestDetails, PaymentType, PointToPointMoneyTransferRequestModel, TennEntityType, ValidateIbanRequest } from "../../models/MoneyTransferModal";
import { ExtendedSelectItem, SelectItem } from "../../models/SelectItem";
import { Amount_Valid_REGEX, AppConstants, Notes_And_Reason_Length, Withdraw_Max_Amount } from "../../models/AppConstants";
import { utils } from "../../utils/utils";
import * as Yup from "yup";
import { ErrorMessage, Field, Formik, FormikErrors, FormikHelpers, FormikProps, useFormik } from "formik";
import { MerchantWalletBalanceRequest, Wallets } from "../../models/FinancialData";
import { KycDocument } from "../../models/DocumentFile";
import { BankTransferRequestModel, SourceOfFundsUploadRequest } from "../../models/Orchestrator";
import { TabPanel, TabView, TabViewTabChangeParams } from "primereact/tabview";
import { Button } from 'primereact/button';
import { Card, CardBody, Col, FormGroup, Label, Row, Spinner, Input, Modal, ModalHeader, ModalBody, Container, CardTitle } from "reactstrap";
import { Dropdown } from "primereact/dropdown";
import { merchantWalletApiService } from "../../services/api/merchants/MerchantWalletApiService";
import { Dialog } from "primereact/dialog";
import ReactLoader from "react-loaders";
import { InputTextarea } from "primereact/inputtextarea";
import { storageService } from "../../services/StorageService";
import React from "react";

import ConfirmPaymentDialogue from "./TransferFundsConfirmDialogue";
import { userWalletApiService } from "../../services/api/users/UserWalletApiService";
import { userApiService } from "../../services/api/users/UsersApiService";
import { operatorsApiService } from "../../services/api/users/OperatorApiService";
import { OperatorDataModel } from "../../models/User";
import { InputText } from "primereact/inputtext";
import { values } from "lodash";
import { merchantPaymentApiService } from "../../services/api/merchants/MerchantPaymentApiService";
import { fileService } from "../../services/FileService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faUser } from "@fortawesome/free-solid-svg-icons";
import TransferFundsConfirmDialogue from "./TransferFundsConfirmDialogue";
import { Toast } from "primereact/toast";

interface TransferFundsDialogueProps {
    loggedUserData: OperatorDataModel | null;
    sourceAccountsOptions: ExtendedSelectItem[];
    sourceWallets: Wallets[];
    merchantId: number;
    onHide: () => void;
}

interface TransferFundsModal {
    selectsourceAccount: string;
    selectSourceAccountP2Any: string,
    selectbeneficiaryAccount: string;
    selectbeneficiaryIBANAccount: string;
    amount: string;
    paymentnote: string;
    amountP2Any: string;
    paymentnoteP2Any: string;
    beneficiaryName: string;
    beneficiaryIBANAccount: string;
    beneficiaryBICAccount: string;
    selectsourceName: string;
    selectSourceEmail: string;
    selectSourceCurrency: string;
    selectSourceCurrencyP2Any: string;
    sourceBalance: number;
    sourceBalanceP2Any: number;
    beneficiaryNameP2Any: string;
    beneficiarycurrency: string;
    tID: string;
    beneficiarySelectedEmail: string;
    beneficiaryBankName: string;
}

enum FundsTransferTypeEnum {
    PartyToPartyTransfer,
    PartyToAnyIBANTransfer
}

interface TransferFundsDialogueState {
    lastRoute: string;
    SendPaymentType: FundsTransferTypeEnum;
    loading: boolean;
    btnLoader: boolean;
    isSubmitting: boolean;
    Bic: boolean;
    successAlert: boolean;
    SourceOfFundsAreRequired: boolean;
    beneficiaryAccountsOptions: ExtendedSelectItem[];
    beneficiaryIBANOptions: ExtendedSelectItem[];
    beneficiaryList: IndividualList[];
    beneficiaryWallets: Wallets[];
    paymentMethods: MerchantPaymentMethod[];
    SendRequestDetails: MoneyTransferRequestDetails | null;
    sourceOfFundsAreRequired: boolean;
    activeIndex: number;
    selectedBeneficiaryId: number;
    tID: string;
}

const validationSchema = Yup.object().shape({

    selectsourceAccount: Yup.string().required('Please select source account number.'),
    amount: Yup.string()
        .required('Please enter amount.')
        .test('isValidAmount', 'Please enter a valid amount, maximum.', (inputAmount) => {
            const amountValue = Number(inputAmount.replace(',', ''));
            return amountValue <= Withdraw_Max_Amount && Amount_Valid_REGEX.test(inputAmount);
        })
        .test('isSufficientBalance', 'You have insufficient funds in your wallet. Please choose another account.', (inputAmount, { parent }) => {
            const amountValue = Number(inputAmount.replace(',', ''));
            return amountValue <= parent.sourceBalance;
        }),
    paymentnote: Yup.string().required('Please enter payment notes.').max(Notes_And_Reason_Length, "Notes and reasons must not exceed " + Notes_And_Reason_Length + " characters."),

    selectbeneficiaryAccount: Yup.string().required('Please select beneficiary account number.'),
    selectbeneficiaryIBANAccount: Yup.string().required('Please select beneficiary IBAN account number.'),
});


const validationSchemaP2Any = Yup.object().shape({
    selectSourceAccountP2Any: Yup.string().required('Please select source account number.'),
    amountP2Any: Yup.string()
        .required('Please enter amount.')
        .test('isValidAmount', 'Please enter a valid amount, maximum.', (inputAmount) => {
            const amountValue = Number(inputAmount.replace(',', ''));
            return amountValue <= Withdraw_Max_Amount && Amount_Valid_REGEX.test(inputAmount);
        })
        .test('isSufficientBalance', 'You have insufficient funds in your wallet. Please choose another account.', (inputAmount, { parent }) => {
            const amountValue = Number(inputAmount.replace(',', ''));
            return amountValue <= parent.sourceBalanceP2Any;
        }),
    paymentnoteP2Any: Yup.string().required('Please enter payment notes.').max(Notes_And_Reason_Length, "Notes and reasons must not exceed " + Notes_And_Reason_Length + " characters."),
    beneficiaryNameP2Any: Yup.string().required('Please enter beneficiary account name.'),
    beneficiaryIBANAccount: Yup.string()
        .required('Please enter beneficiary IBAN account number.')
        .test('iban-validation', 'Please enter valid IBAN account number.', (value) => {
            if (!value) {
                return false;
            }
            return utils.validateIban(value);
        }),
    beneficiaryBICAccount: Yup.string().required('Please enter beneficiary BIC account number.'),
    beneficiaryBankName: Yup.string().required('Please enter beneficiary Bank name.'),
});


export class TransferFundsDialogue extends Component<TransferFundsDialogueProps, TransferFundsDialogueState> {
    toast: Toast | null = null;
    formikInitialValue: TransferFundsModal;
    constructor(props: TransferFundsDialogueProps) {
        super(props);
        this.state = {
            activeIndex: 0,
            lastRoute: '',
            SendPaymentType: FundsTransferTypeEnum.PartyToPartyTransfer,
            loading: true,
            btnLoader: false,
            isSubmitting: false,
            Bic: false,
            successAlert: false,
            SourceOfFundsAreRequired: false,
            beneficiaryAccountsOptions: [],
            beneficiaryIBANOptions: [],
            beneficiaryList: [],
            beneficiaryWallets: [],
            paymentMethods: [],
            SendRequestDetails: null,
            sourceOfFundsAreRequired: false,
            selectedBeneficiaryId: 0,
            tID: "",
        };
        this.formikInitialValue = {
            selectsourceAccount: "",
            selectSourceAccountP2Any: "",
            selectbeneficiaryAccount: "",
            selectbeneficiaryIBANAccount: "",
            amount: "",
            paymentnote: "",
            amountP2Any: '',
            paymentnoteP2Any: '',
            beneficiaryName: "",
            beneficiaryIBANAccount: "",
            beneficiaryBICAccount: "",
            selectsourceName: "",
            selectSourceEmail: "",
            selectSourceCurrency: "",
            sourceBalance: 0,
            sourceBalanceP2Any: 0,
            beneficiarycurrency: "",
            tID: "",
            beneficiarySelectedEmail: "",
            beneficiaryBankName: "",
            beneficiaryNameP2Any: "",
            selectSourceCurrencyP2Any: ""
        }
    }

    async componentDidMount(): Promise<void> {
        await this.loadData();
    }

    componentWillUnmount(): void {
        this.toast?.clear();
    }

    loadData = async () => {
        this.setState({ loading: true });
        await this.getMerchantBeneficiaries();
        this.setState({ loading: false });
    };

    // getSourceWallets = async () => {

    //     const walletsResult = await merchantWalletApiService.getOwnWallets();
    //     if (!walletsResult.success) {

    //         this.setState({ loading: false });
    //         this.toast?.show({ severity: "error", summary: "Error", detail: walletsResult.message, life: 3000 });
    //         return;
    //     }
    //     const sourceAccountOptions = walletsResult.data.map((wallet) => ({
    //         label: wallet.ibanAccountNumber,
    //         value: wallet.ibanAccountNumber,
    //         details: { icons: "wallet-outline", currency: wallet.currency }
    //     })) as SelectItem[];

    //     this.setState((prevState) => ({
    //         ...prevState,
    //         sourceAccountsOptions: sourceAccountOptions,
    //         sourceWallets: walletsResult.data
    //     }));
    // };

    getMerchantBeneficiaries = async () => {
        const beneficiaryListResult = await userApiService.getActiveIndividual();
        if (!beneficiaryListResult.success) {
            this.setState({ loading: false });
            this.toast?.show({ severity: "error", summary: "Error", detail: beneficiaryListResult.message, life: 3000 });
            return;
        }

        const filteredBeneficiaries = beneficiaryListResult.data.filter((beneficiary) => beneficiary.status === "Active");

        const beneficiaryDropDown = await Promise.all(
            filteredBeneficiaries.map(async (beneficiary) => {
                //const photoResult = beneficiary.photo ? await this.getIndividualBeneficiaryPhoto(beneficiary.photo) : null;
                return {
                    label: beneficiary.email,
                    value: beneficiary.id.toString(),
                    details: {
                        fullname: beneficiary.firstName + " " + beneficiary.lastName,
                        //image: photoResult,
                        showimage: true,
                    }
                } as ExtendedSelectItem;
            })
        );

        this.setState({
            beneficiaryAccountsOptions: beneficiaryDropDown,
            beneficiaryList: beneficiaryListResult.data
        });
    };

    itemTemplate = (option: ExtendedSelectItem) => {
        return (
            <div>
                <div className="text-primary">{option.details?.fullname}</div>
                <small className="text-muted">{option.label}</small>
            </div>
        );
    }

    getWalletBalance = async (accountNumber: string) => {
        const merchantBalanceRequest: MerchantWalletBalanceRequest = {
            merchantId: this.props.merchantId,
            accountNumber: accountNumber
        }
        const balanceResponse = await merchantWalletApiService.getWalletBalance(merchantBalanceRequest);
        if (!balanceResponse.success) {
            this.setState({ loading: false });
            this.toast?.show({ severity: "error", summary: "Error", detail: balanceResponse.message, life: 3000 });
            return;
        }
        return balanceResponse.data;
    };

    setBeneficiaryWalletDetails = async (Wallets: Wallets[], beneficiaryid: string, sourceCurrency: string, selectedSourceIban: string, setFieldValue: FormikHelpers<TransferFundsModal>['setFieldValue'], values: TransferFundsModal) => {
        if (!utils.isNullOrEmptyString(selectedSourceIban)) {
            if (!utils.isNullOrEmptyString(beneficiaryid)) {
                const beneficiaryWalletFilterdWallets = Wallets.filter((x) => x.currency === sourceCurrency);
                if (beneficiaryWalletFilterdWallets.length > 0) {
                    this.setState({
                        beneficiaryIBANOptions: beneficiaryWalletFilterdWallets.map((wallet) => ({
                            label: wallet.ibanAccountNumber,
                            value: wallet.ibanAccountNumber
                        })) as SelectItem[]
                    });
                    setFieldValue("selectbeneficiaryAccount", beneficiaryid);
                    setFieldValue("beneficiarycurrency", beneficiaryWalletFilterdWallets[0].currency ?? "");
                    setFieldValue("selectbeneficiaryIBANAccount", beneficiaryWalletFilterdWallets[0].ibanAccountNumber ?? "");
                } else {
                    this.setState({ beneficiaryIBANOptions: [] });
                    setFieldValue("selectbeneficiaryIBANAccount", "");
                    setFieldValue("beneficiarycurrency", "");
                    this.toast?.show({ severity: "error", summary: "Error", detail: "Beneficiary does not have same currency account", life: 3000 });
                }
            }
        }
    };

    handleSourceAccountChange = async (e: { value: string }, setFieldValue: FormikHelpers<TransferFundsModal>['setFieldValue'], values: TransferFundsModal) => {
        this.setState((prevState) => ({ ...prevState, loading: true }));
        const response = await this.getWalletBalance(this.props.sourceWallets.find((i) => i.ibanAccountNumber === e.value)?.id ?? "");
        if (response == null) {
            this.setState((prevState) => ({ ...prevState, loading: false }));
            return;
        }
        setFieldValue("selectSourceCurrency", response?.currency ?? "");
        setFieldValue("sourceBalance", response?.balance ?? 0);
        await this.setBeneficiaryWalletDetails(this.state.beneficiaryWallets, values.selectbeneficiaryAccount, response?.currency, e.value, setFieldValue, values);
        this.setState((prevState) => ({ ...prevState, loading: false }));
    };

    handleSourceAccountChangeP2Any = async (e: { value: string }, setFieldValue: FormikHelpers<TransferFundsModal>['setFieldValue'], values: TransferFundsModal) => {
        this.setState((prevState) => ({ ...prevState, loading: true }));
        const response = await this.getWalletBalance(this.props.sourceWallets.find((i) => i.ibanAccountNumber === e.value)?.id ?? "");
        if (response == null) {
            this.setState((prevState) => ({ ...prevState, loading: false }));
            return;
        }
        setFieldValue("selectSourceCurrencyP2Any", response?.currency ?? "");
        setFieldValue("sourceBalanceP2Any", response?.balance ?? 0);
        this.setState((prevState) => ({ ...prevState, loading: false }));
    };

    getIndividualBeneficiaryPhoto = async (imageId: string) => {
        const userPhoto = await fileService.GetFile(imageId);
        if (!userPhoto.success) {
            this.toast?.show({ severity: "error", summary: "Error", detail: userPhoto.message, life: 3000 });
            return;
        }
        return utils.appendBase64Data(userPhoto.data);
    };



    onSourceOfFundsUpload = async (documents: KycDocument[]) => {
        console.log("onSourceOfFundsUpload called------------")
        if (documents.length === 0) {
            //  toast.error("Please select documents");
            return;
        }
        // this.setState({ loading: true });

        // var currentUserId = await storageService.getCurrentUserId();
        // var promises = documents
        //     .filter((a) => a.objectURL !== "1")
        //     .map(async (a) => {
        //         // Pending Upload Documents
        //         const result = await paymentApiService.uploadSourceOfFunds({
        //             sourceEntityType: TennEntityType.Individual,
        //             sourceEntityId: currentUserId,
        //             transferId: this.state.SendRequestDetails?.p2AnyIbanTransferId,
        //             fileName: a.fileName,
        //             fileData: a.fileData
        //         } as unknown as SourceOfFundsUploadRequest);
        //         a.objectURL = result.success ? "1" : "";
        //         return result;
        //     });

        // var results = await Promise.all(promises);
        // var failedResults = results.filter((a) => a.success === false);
        // if (failedResults.length > 0) {
        //     this.setState({ loading: false });
        //     // toast.error("UploadingFailed");
        //     return;
        // }

        // var submitResult = await paymentApiService.notifySourceOfFundsUploaded({
        //     sourceEntityType: TennEntityType.Individual,
        //     sourceEntityId: currentUserId,
        //     transferId: this.state.SendRequestDetails?.p2AnyIbanTransferId
        // } as BankTransferRequestModel);

        // if (!submitResult.success) {
        //     this.setState({ loading: false });
        //     //     toast.error(utils.removeBrackets(submitResult.message));
        //     return;
        // }

        // this.setState({ loading: false, sourceOfFundsAreRequired: false });
    };

    onContinue = async (data: TransferFundsModal) => {
        let transactioDetails = {} as MoneyTransferRequestDetails;
        transactioDetails = {
            sourceCustomerEmail: data.selectSourceEmail,
            sourceCustomerName: data.selectsourceName,
            sourceCustomerCurrency: data.selectSourceCurrency,
            beneficiaryCustomerAccount: data.selectbeneficiaryIBANAccount,
            beneficiaryCustomerEmail: data.beneficiarySelectedEmail,
            beneficiaryCustomerCurrency: data.beneficiarycurrency,
            sourceCustomerAccount: this.state.SendPaymentType === FundsTransferTypeEnum.PartyToPartyTransfer ? data.selectsourceAccount : data.selectSourceAccountP2Any,
            transactionAmount: this.state.SendPaymentType === FundsTransferTypeEnum.PartyToPartyTransfer ? data.amount : data.amountP2Any,
            paymentNotes: this.state.SendPaymentType === FundsTransferTypeEnum.PartyToPartyTransfer ? data.paymentnote : data.paymentnoteP2Any,
            sourceCurrentBalance: this.state.SendPaymentType === FundsTransferTypeEnum.PartyToPartyTransfer ? data.sourceBalance : data.sourceBalanceP2Any,
            beneficiaryName: this.state.SendPaymentType === FundsTransferTypeEnum.PartyToPartyTransfer ? data.beneficiaryName : data.beneficiaryNameP2Any,
        };

        if (this.state.SendPaymentType === FundsTransferTypeEnum.PartyToPartyTransfer) {
            const calculateMoneyTransferChargeRequest: CalculateMoneyTransferChargeRequest = {
                customerAccount: transactioDetails.sourceCustomerAccount,
                amount: Number(transactioDetails.transactionAmount),
                id: this.props.loggedUserData?.id ?? 0,
                PaymentType: PaymentType.P2P
            };
            const calculateMoneyTransferChargeReponse = await merchantPaymentApiService.getMoneyTransferCharge(calculateMoneyTransferChargeRequest);

            if (!calculateMoneyTransferChargeReponse.success) {
                this.setState({ btnLoader: false });
                this.toast?.show({ severity: "error", summary: "Error", detail: calculateMoneyTransferChargeReponse.message, life: 3000 });
                return;
            }
            transactioDetails.transactionFee = calculateMoneyTransferChargeReponse.data.transactionFee;

            this.setState({ btnLoader: false, SendRequestDetails: transactioDetails });
            return;
        }

        if (this.state.SendPaymentType === FundsTransferTypeEnum.PartyToAnyIBANTransfer) {

            try {
                var p2AnyIABNObject: BankTransferInitiateRequestModel = {
                    sourceEntityType: TennEntityType.Merchant,
                    sourceEntityId: storageService.getLoggedInUserMerchantId(),
                    sourceCustomerAccount: data.selectSourceAccountP2Any,
                    beneficiaryCustomerIBAN: data.beneficiaryIBANAccount,
                    beneficiaryCustomerBIC: data.beneficiaryBICAccount,
                    beneficiaryCustomerName: data.beneficiaryNameP2Any,
                    amount: Number(data.paymentnoteP2Any),
                    paymentNotes: data.beneficiaryNameP2Any,
                    currency: data.selectSourceCurrency
                };

                // const p2AnyIABResponse1 = await paymentApiService.toAnyIbanMoneyTransferInitiateAsync(p2AnyIABNObject);

                // console.info("After toAnyIbanMoneyTransferInitiateAsync. Result: ", JSON.stringify(p2AnyIABResponse1));

                // if (!p2AnyIABResponse1.success) {
                //     console.info("toAnyIbanMoneyTransferInitiateAsync Error: ", p2AnyIABResponse1.message);
                //     this.setState({ btnLoader: false });
                //     // toast.error(utils.removeBrackets(p2AnyIABResponse1.message));
                //     return;
                // }
                // if (p2AnyIABResponse1.data.responseCode === BankTransferResponseCode.RiskReject) {
                //     console.info("toAnyIbanMoneyTransferInitiateAsync - Risk reject");
                //     this.setState({ btnLoader: false });
                //     // toast.error("RejectedByRisk");
                //     return;
                // }
                // transactioDetails.transactionFee = p2AnyIABResponse1.data.chargeAmount;
                // transactioDetails.p2AnyIbanTransferId = p2AnyIABResponse1.data.transferId;

                transactioDetails.transactionFee = 21;
                transactioDetails.p2AnyIbanTransferId = 1212121;
                this.setState({ SendRequestDetails: transactioDetails, btnLoader: false });
                //    this.setSourceOfFundsAreRequired(p2AnyIABResponse1.data.responseCode === BankTransferResponseCode.SourceOfFundsRequired);
            } catch (error) {
                console.error("Error during API call: ", error);
            }
        }

    }

    onSubmit = async () => {
        console.log("Point to point submitting----")
        try {
            this.setState({ isSubmitting: true });

            if (this.state.SendPaymentType === FundsTransferTypeEnum.PartyToPartyTransfer) {
                var p2pObject: PointToPointMoneyTransferRequestModel = {
                    sourceCustomerAccount: this.state.SendRequestDetails?.sourceCustomerAccount!,
                    beneficiaryCustomerAccount: this.state.SendRequestDetails?.beneficiaryCustomerAccount!,
                    transactionAmount: Number(this.state.SendRequestDetails?.transactionAmount),
                    paymentNotes: this.state.SendRequestDetails?.paymentNotes
                };

                const p2pSendMoneyResponse = await merchantPaymentApiService.toEndUserMoneyTransfer(
                    this.props.loggedUserData?.id ?? 0,
                    Number(this.state.selectedBeneficiaryId),
                    p2pObject
                );

                if (!p2pSendMoneyResponse.success) {
                    this.setState({ isSubmitting: false });
                    this.toast?.show({ severity: "error", summary: "Error", detail: p2pSendMoneyResponse.message, life: 3000 });
                    return;
                }

                this.setState({ SendRequestDetails: null, isSubmitting: false, successAlert: true, tID: p2pSendMoneyResponse.data });
            }

        }
        catch (error) {
            console.log("Exception occure in Point to point submitting----", error)
            this.setState({ isSubmitting: false });
        }
    };

    onSubmitP2Any = async () => {
        console.log("Point to Any submitting----")
        return;
        // Bank Transfer via Orchestrator
        // if (this.state.SendPaymentType === FundsTransferTypeEnum.PartyToAnyIBANTransfer) {
        //     var p2AnyIABNObject: BankTransferRequestModel = {
        //         sourceEntityType: TennEntityType.Individual,
        //         sourceEntityId: await storageService.getCurrentUserId(),
        //         transferId: this.state.SendRequestDetails?.p2AnyIbanTransferId ?? 0
        //     };

        //     const p2AnyIABResponse = await paymentApiService.toAnyIbanMoneyTransferExecuteAsync(p2AnyIABNObject);
        //     console.info("After toAnyIbanMoneyTransferExecuteAsync. Result: ", JSON.stringify(p2AnyIABResponse));

        //     if (!p2AnyIABResponse.success) {
        //         this.setState({ isSubmitting: false });
        //         //   toast.error(utils.removeBrackets(p2AnyIABResponse.message));
        //         return;
        //     }

        //     if (p2AnyIABResponse.data.responseCode === BankTransferResponseCode.RiskReject) {
        //         this.setState({ isSubmitting: false, SendRequestDetails: null });
        //         //  toast.error(t("RejectedByRisk"));
        //         return;
        //     }

        //     if (p2AnyIABResponse.data.responseCode === BankTransferResponseCode.UnderReiew) {
        //         this.setState({ isSubmitting: false, SendRequestDetails: null });
        //         //  toast.error(t("ComplianceReview"));
        //         return;
        //     }

        //     this.setState({ isSubmitting: false, SendRequestDetails: null });
        //     setFieldValue("tID", p2AnyIABResponse.data.referenceNumber ?? "");
        //     this.setState({ successAlert: true });

        //     if (p2AnyIABResponse.data.referenceNumber === "") {
        //         // toast.error(t("ComplianceReview"));
        //     }
        // }

    }

    handleBeneficiaryAccountChange = async (beneficiaryAccount: string, setFieldValue: FormikHelpers<TransferFundsModal>['setFieldValue'], values: TransferFundsModal, beneficiaryId: number) => {
        this.setState({ loading: true })
        try {
            const beneficiaryWalletResult = await userWalletApiService.getWallets(Number(beneficiaryAccount));
            if (!beneficiaryWalletResult.success) {
                this.setState({ beneficiaryIBANOptions: [] });
                this.setState({ loading: false });
                setFieldValue("beneficiarycurrency", "");
                setFieldValue("selectbeneficiaryIBANAccount", "");
                this.toast?.show({ severity: "error", summary: "Error", detail: beneficiaryWalletResult.message, life: 3000 });
                return;
            }
            if (beneficiaryWalletResult.data.length <= 0) {
                this.setState({ beneficiaryIBANOptions: [] });
                this.setState({ loading: false });
                setFieldValue("beneficiarycurrency", "");
                setFieldValue("selectbeneficiaryIBANAccount", "");
                this.toast?.show({ severity: "error", summary: "Error", detail: "Beneficery wallets not found.", life: 3000 });
                return;
            }

            await this.setBeneficiaryWalletDetails(beneficiaryWalletResult.data, beneficiaryAccount, values.selectSourceCurrency, values.selectsourceAccount, setFieldValue, values);
            this.setState({ loading: false, selectedBeneficiaryId: beneficiaryId });
        } catch (error) {
            this.setState({ loading: false });
            this.toast?.show({ severity: "error", summary: "Error", detail: "An error occurred while fetching the beneficiary account details.", life: 3000 });
        }
    };

    handleSubmit = async (values: TransferFundsModal) => {
        this.setState({ btnLoader: true });
        await this.onContinue(values);
        this.setState({ btnLoader: false });
    };

    setValue = async (e: number, mode: FundsTransferTypeEnum) => {
        this.setState({ activeIndex: e, SendPaymentType: mode });
    }

    handleTabChange = async (event: TabViewTabChangeParams, formikError: any, formikTouched: any) => {
        await formikError({});
        await formikTouched({})
        if (event.index == 0) {
            await this.setValue(event.index, FundsTransferTypeEnum.PartyToPartyTransfer)
        }
        if (event.index == 1) {
            await this.setValue(event.index, FundsTransferTypeEnum.PartyToAnyIBANTransfer)
        }
    };

    renderLaoding = () => {
        if (this.state.loading) {
            return (
                <div className="d-flex justify-content-center align-items-center">
                    <div className="m-3">
                        {/* <ProgressSpinner  style={{width: '50px', height: '50px'}}/> */}
                        <ReactLoader type="ball-grid-pulse" active />
                    </div>
                </div>
            )
        }
    }

    renderHeader = () => {
        return <Button icon="pi pi-arrow-left" className="p-button-rounded p-button-outlined p-button-outlined p-dialog-header-icon" aria-label="Cancel" onClick={() => this.setState({ successAlert: false })} />;

    };

    render() {

        return (
            <Dialog header={this.state.successAlert ? this.renderHeader : "Transfer Funds"}
                modal
                blockScroll
                draggable={false}
                resizable={false}
                visible={true}
                style={{ width: "60vw" }}
                onHide={this.props.onHide}
            >


                {!this.state.successAlert &&
                    <Formik
                        validateOnChange={true}
                        validateOnBlur={true}
                        initialValues={this.formikInitialValue}
                        validationSchema={this.state.SendPaymentType == FundsTransferTypeEnum.PartyToPartyTransfer ? validationSchema : validationSchemaP2Any}
                        onSubmit={async (values, { setSubmitting }) => {
                            await this.handleSubmit(values);
                            setSubmitting(false);
                        }}
                    >
                        {({ values, errors, touched, handleSubmit, setFieldValue, setErrors, setTouched }) => (
                            <>
                                <TabView className="p-0" activeIndex={this.state.activeIndex} onTabChange={(e) => this.handleTabChange(e, setErrors, setTouched)}>
                                    <TabPanel header="Wallet Transfer" leftIcon="pi pi-user mr-2"  >
                                        <Card className="p-0 m-0">
                                            <CardBody>
                                                <>
                                                    {this.renderLaoding()}

                                                    {values.selectSourceCurrency &&
                                                        <div className="d-flex justify-content-end align-items-end">
                                                            <p>
                                                                Bal: <span className="text-success">{utils.formatBalance(values.sourceBalance, values.selectSourceCurrency)}</span>
                                                            </p>
                                                        </div>
                                                    }

                                                    <FormGroup>
                                                        <Label>Select source Account</Label>
                                                        <Dropdown
                                                            disabled={this.state.btnLoader}
                                                            style={{ width: "100%" }}
                                                            appendTo={document.body}
                                                            value={values.selectsourceAccount}
                                                            onChange={(e) => {
                                                                const sourceDetails = this.props.sourceAccountsOptions.find(i => i.value == e.value)?.details
                                                                setFieldValue("selectsourceName", sourceDetails?.fullname)
                                                                setFieldValue("selectSourceEmail", sourceDetails?.subtitle)
                                                                setFieldValue("selectsourceAccount", e.value)
                                                                this.handleSourceAccountChange(e, setFieldValue, values)
                                                            }}
                                                            name="selectsourceAccount" options={this.props.sourceAccountsOptions}
                                                            required={true}
                                                            filter
                                                            className={`${errors.selectsourceAccount && touched.selectsourceAccount ? 'p-invalid' : ''}`}
                                                        />
                                                        <ErrorMessage component="small" name="selectsourceAccount" className="p-error" />
                                                    </FormGroup>

                                                    <Row>
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <Label>Select Beneficiary Account</Label>
                                                                <Dropdown
                                                                    disabled={values.selectsourceAccount == "" || this.state.btnLoader}
                                                                    style={{ width: "100%" }}
                                                                    appendTo={document.body}
                                                                    value={values.selectbeneficiaryAccount}
                                                                    onChange={(e) => {
                                                                        const beneficiaryDetails = this.state.beneficiaryList.find((f) => f.id == Number(e.value))
                                                                        this.handleBeneficiaryAccountChange(e.value, setFieldValue, values, beneficiaryDetails?.id ?? 0);
                                                                        setFieldValue("beneficiarySelectedEmail", beneficiaryDetails?.email);
                                                                        setFieldValue("selectbeneficiaryAccount", e.value)
                                                                        setFieldValue("beneficiaryName", beneficiaryDetails?.firstName + " " + beneficiaryDetails?.lastName)
                                                                    }}
                                                                    name="selectbeneficiaryAccount"
                                                                    options={this.state.beneficiaryAccountsOptions}
                                                                    required={true}
                                                                    filter
                                                                    className={`${errors.selectbeneficiaryAccount && touched.selectbeneficiaryAccount ? 'p-invalid' : ''}`}
                                                                    itemTemplate={this.itemTemplate}
                                                                />
                                                                <ErrorMessage component="small" name="selectbeneficiaryAccount" className="p-error" />
                                                            </FormGroup>
                                                        </Col>

                                                        <Col md={6}>

                                                            <FormGroup>
                                                                <Label>Select Beneficiary IBAN Account</Label>
                                                                <Dropdown
                                                                    style={{ width: "100%" }}
                                                                    appendTo={document.body}
                                                                    value={values.selectbeneficiaryIBANAccount}
                                                                    onChange={(e) => setFieldValue("selectbeneficiaryIBANAccount", e.value)}
                                                                    name="selectbeneficiaryIBANAccount"
                                                                    options={this.state.beneficiaryIBANOptions}
                                                                    required={true}
                                                                    filter
                                                                    className={`${errors.selectbeneficiaryIBANAccount && touched.selectbeneficiaryIBANAccount ? 'p-invalid' : ''}`}
                                                                    disabled={values.selectsourceAccount == "" || this.state.btnLoader}
                                                                />
                                                                <ErrorMessage component="small" name="selectbeneficiaryIBANAccount" className="p-error" />
                                                            </FormGroup>
                                                        </Col>

                                                    </Row>


                                                    <FormGroup>
                                                        <Label>Amount</Label>
                                                        <InputText
                                                            style={{ backgroundColor: 'transparent' }}
                                                            required={true}
                                                            value={values.amount}
                                                            onChange={(inputValue) => {
                                                                const formattedAmount = utils.formatAmount(inputValue.target.value);
                                                                setFieldValue("amount", formattedAmount);
                                                            }}
                                                            readOnly={values.selectsourceAccount == "" || this.state.btnLoader}
                                                            name="amount"
                                                            className={`form-control ${errors.amount && touched.amount ? 'p-invalid' : ''}`}
                                                            maxLength={10}
                                                        />
                                                        <ErrorMessage component="small" name="amount" className="p-error" />
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <Label>Payment Notes</Label>
                                                        <InputTextarea
                                                            style={{ width: "100%" }}
                                                            required={true}
                                                            value={values.paymentnote}
                                                            onChange={(value) => setFieldValue("paymentnote", value.target.value)}
                                                            readOnly={values.selectsourceAccount == "" || this.state.btnLoader}
                                                            name="paymentnote"
                                                            className={`${errors.paymentnote && touched.paymentnote ? 'p-invalid' : ''}`}
                                                            maxLength={AppConstants.NotesAndReasonLength}
                                                        />
                                                        <ErrorMessage component="small" name="paymentnote" className="p-error" />
                                                    </FormGroup>

                                                    <div className="d-flex justify-content-end align-items-end">
                                                        <div>
                                                            <Button type="button" className="p-button p-button-primary" iconPos="right" label="Continue" onClick={() => handleSubmit()} loading={this.state.btnLoader} disabled={this.state.btnLoader} />
                                                        </div>
                                                    </div>

                                                </>


                                            </CardBody>
                                        </Card>

                                    </TabPanel>
                                    <TabPanel header="External IBAN Transfer" rightIcon="pi pi-user ml-2">
                                        <Card className="p-0 m-0">
                                            <CardBody>

                                                <>

                                                    {this.renderLaoding()}

                                                    {values.selectSourceCurrencyP2Any &&
                                                        <div className="d-flex justify-content-end align-items-end">
                                                            <p>
                                                                Bal: <span className="text-success">{utils.formatBalance(values.sourceBalanceP2Any, values.selectSourceCurrencyP2Any)}</span>
                                                            </p>
                                                        </div>
                                                    }

                                                    <FormGroup>
                                                        <Label>Select source Account</Label>
                                                        <Dropdown
                                                            style={{ width: "100%" }}
                                                            appendTo={document.body}
                                                            value={values.selectSourceAccountP2Any}
                                                            onChange={(e) => {
                                                                const sourceDetails = this.props.sourceAccountsOptions.find(i => i.value == e.value)?.details
                                                                setFieldValue("selectSourceName", sourceDetails?.fullname)
                                                                setFieldValue("selectSourceAccountP2Any", e.value)
                                                                this.handleSourceAccountChangeP2Any(e, setFieldValue, values)
                                                            }}
                                                            name="selectSourceAccountP2Any" options={this.props.sourceAccountsOptions}
                                                            required={true}
                                                            filter
                                                            className={`${errors.selectSourceAccountP2Any && touched.selectSourceAccountP2Any ? 'p-invalid' : ''}`}
                                                        />
                                                        <ErrorMessage component="small" name="selectSourceAccountP2Any" className="p-error" />
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <Label>Beneficiary Name</Label>
                                                        <InputText
                                                            style={{ backgroundColor: 'transparent' }}
                                                            required={true}
                                                            value={values.beneficiaryNameP2Any}
                                                            onChange={(inputValue) => {
                                                                setFieldValue("beneficiaryNameP2Any", inputValue.target.value);
                                                            }}
                                                            readOnly={this.state.btnLoader}
                                                            name="beneficiaryNameP2Any"
                                                            className={`form-control ${errors.beneficiaryNameP2Any && touched.beneficiaryNameP2Any ? 'p-invalid' : ''}`}
                                                            maxLength={AppConstants.NameLength}
                                                        />
                                                        <ErrorMessage component="small" name="beneficiaryNameP2Any" className="p-error" />
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <Label>Beneficiary IBAN</Label>
                                                        <InputText
                                                            style={{ backgroundColor: 'transparent' }}
                                                            required={true}
                                                            value={values.beneficiaryIBANAccount}
                                                            onChange={(inputValue) => {
                                                                setFieldValue("beneficiaryIBANAccount", inputValue.target.value);
                                                                setFieldValue("beneficiaryBICAccount", '')
                                                                setFieldValue("beneficiaryBankName", '')
                                                            }}
                                                            readOnly={this.state.btnLoader}
                                                            name="beneficiaryIBANAccount"
                                                            className={`form-control ${errors.beneficiaryIBANAccount && touched.beneficiaryIBANAccount ? 'p-invalid' : ''}`}
                                                            maxLength={AppConstants.IbanMaxLength}
                                                            onBlur={async () => {
                                                                //handleBlur("beneficiaryIBANAccount");
                                                                if (values.beneficiaryIBANAccount.length > 14) {
                                                                    this.setState({ loading: true });
                                                                    const obj: ValidateIbanRequest = {
                                                                        iban: values.beneficiaryIBANAccount
                                                                    };
                                                                    var validIban = await userApiService.validateIban(obj);
                                                                    if (!validIban.success) {
                                                                        setFieldValue("beneficiaryBICAccount", '')
                                                                        setFieldValue("beneficiaryBankName", '')
                                                                        this.setState({ loading: false });
                                                                        // toast.error(t(utils.removeBrackets(validIban.message)));
                                                                        return;
                                                                    }
                                                                    setFieldValue("beneficiaryBICAccount", validIban.data.bic.endsWith("XXX") ? validIban.data.bic.slice(0, -3) : validIban.data.bic);
                                                                    setFieldValue("beneficiaryBankName", validIban.data.bank)
                                                                    this.setState({ loading: false });
                                                                }
                                                            }}
                                                        />
                                                        <ErrorMessage component="small" name="beneficiaryIBANAccount" className="p-error" />
                                                    </FormGroup>

                                                    <Row>
                                                        <Col md="6">
                                                            <FormGroup>
                                                                <Label>Beneficiary BIC</Label>
                                                                <InputText
                                                                    style={{ backgroundColor: 'transparent' }}
                                                                    required={true}
                                                                    value={values.beneficiaryBICAccount}
                                                                    onChange={(inputValue) => {
                                                                        setFieldValue("beneficiaryBICAccount", inputValue.target.value);
                                                                    }}
                                                                    readOnly={true}
                                                                    name="beneficiaryBICAccount"
                                                                    className={`form-control ${errors.beneficiaryBICAccount && touched.beneficiaryBICAccount ? 'p-invalid' : ''}`}
                                                                />
                                                                <ErrorMessage component="small" name="beneficiaryBICAccount" className="p-error" />
                                                            </FormGroup>
                                                        </Col>

                                                        <Col md="6">
                                                            <FormGroup>
                                                                <Label>Beneficiary Bank Name</Label>
                                                                <InputText
                                                                    style={{ backgroundColor: 'transparent' }}
                                                                    required={true}
                                                                    value={values.beneficiaryBankName}
                                                                    onChange={(inputValue) => {
                                                                        setFieldValue("beneficiaryBankName", inputValue.target.value);
                                                                    }}
                                                                    readOnly={true}
                                                                    name="beneficiaryBankName"
                                                                    className={`form-control ${errors.beneficiaryBankName && touched.beneficiaryBankName ? 'p-invalid' : ''}`}
                                                                />
                                                                <ErrorMessage component="small" name="beneficiaryBankName" className="p-error" />
                                                            </FormGroup>
                                                        </Col>

                                                    </Row>

                                                    <FormGroup>
                                                        <Label>Amount</Label>
                                                        <InputText
                                                            style={{ backgroundColor: 'transparent' }}
                                                            required={true}
                                                            value={values.amountP2Any}
                                                            onChange={(inputValue) => {
                                                                const formattedAmount = utils.formatAmount(inputValue.target.value);
                                                                setFieldValue("amountP2Any", formattedAmount);
                                                            }}
                                                            readOnly={values.selectSourceAccountP2Any == "" || this.state.btnLoader}
                                                            name="amountP2Any"
                                                            className={`form-control ${errors.amountP2Any && touched.amountP2Any ? 'p-invalid' : ''}`}
                                                            maxLength={10}
                                                        />
                                                        <ErrorMessage component="small" name="amountP2Any" className="p-error" />
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <Label>Payment Notes</Label>
                                                        <InputTextarea
                                                            style={{ width: "100%" }}
                                                            required={true}
                                                            value={values.paymentnoteP2Any}
                                                            onChange={(value) => setFieldValue("paymentnoteP2Any", value.target.value)}
                                                            readOnly={values.selectSourceAccountP2Any == "" || this.state.btnLoader}
                                                            name="paymentnoteP2Any"
                                                            className={`${errors.paymentnoteP2Any && touched.paymentnoteP2Any ? 'p-invalid' : ''}`}
                                                            maxLength={AppConstants.NotesAndReasonLength}
                                                        />
                                                        <ErrorMessage component="small" name="paymentnoteP2Any" className="p-error" />
                                                    </FormGroup>

                                                    <div className="d-flex justify-content-end align-items-end">
                                                        <div>
                                                            <Button iconPos="right" type="button" className="p-button p-button-primary" label="Continue" onClick={() => handleSubmit()} loading={this.state.btnLoader} disabled={this.state.btnLoader} />
                                                        </div>
                                                    </div>
                                                </>

                                            </CardBody>
                                        </Card>
                                    </TabPanel>
                                </TabView>
                            </>

                        )}
                    </Formik>
                }


                {this.state.SendRequestDetails != null && (
                    <TransferFundsConfirmDialogue
                        transactionDetails={this.state.SendRequestDetails}
                        sourceSelectedCurrency={this.state.SendRequestDetails.sourceCustomerAccount}
                        beneficiarySelectedCurrency={this.state.SendRequestDetails.beneficiaryCustomerCurrency!}
                        isSubmitting={this.state.isSubmitting}
                        onSubmit={this.state.SendPaymentType == FundsTransferTypeEnum.PartyToPartyTransfer ? async () => await this.onSubmit() : async () => await this.onSubmitP2Any()}
                        onHide={() => {
                            this.setState({ isSubmitting: false, SendRequestDetails: null });
                        }}
                        p2pTransfer={this.state.SendPaymentType == FundsTransferTypeEnum.PartyToPartyTransfer ? true : false}
                        p2AnyIbanTransfer={this.state.SendPaymentType === FundsTransferTypeEnum.PartyToAnyIBANTransfer}
                    />
                )}

                {this.state.successAlert &&

                    <Container>
                        <Card className="p-0 m-0">
                            <CardBody>
                                <FontAwesomeIcon size="5x" icon={faCheck as any} className="text-success" style={{ margin: "auto", display: "block" }} />
                                <CardTitle className="align-center">
                                    <Row>
                                        <Col>
                                            <Label >TID:</Label>
                                        </Col>
                                        <Col>
                                            <div>{this.state.tID}</div>
                                        </Col>
                                    </Row>
                                </CardTitle>
                                <div className="align-center" >
                                    Success!. Your money is on the way
                                </div>
                            </CardBody>
                        </Card>

                    </Container>

                }
                <Toast ref={(el) => (this.toast = el)} position={"bottom-center"} baseZIndex={99999}></Toast>

            </Dialog>
        )


    }
}
