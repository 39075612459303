import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { Component } from "react";
import { Card, CardHeader, CardBody } from "reactstrap";
import { PaymentMethods } from "../../models/FinancialData";
import { merchantPaymentMethodApiService } from "../../services/api/merchants/MerchantPaymentMethodApiService";
import classNames from "classnames";
import { Toast } from "primereact/toast";

interface MerchantDefaultPaymentMethodProps {
    merchantId: number | undefined;
}

interface MerchantDefaultPaymentMethodState {
    isLoading: boolean;
    merchantDefaultPaymentMethod: PaymentMethods | null;
}

export default class MerchantDefaultPaymentMethod extends Component<MerchantDefaultPaymentMethodProps, MerchantDefaultPaymentMethodState> {

    toast: Toast | null = null;


    constructor(props: MerchantDefaultPaymentMethodProps) {
        super(props);

        this.state = {
            isLoading: true,
            merchantDefaultPaymentMethod: null,
        };
    }
    
    async componentDidMount() {

        const paymentMethodsResult = await merchantPaymentMethodApiService.getAll(this.props.merchantId!);

        if (!paymentMethodsResult.success) {
            this.setState({ isLoading: false });
            this.toast?.show({ severity: "error", summary: "Error", detail: paymentMethodsResult.message, life: 3000 });
            return;
        }

        const merchantDefaultPaymentMethod = paymentMethodsResult.data.length > 0 ? paymentMethodsResult.data[0] : null;

        this.setState({
            isLoading: false,
            merchantDefaultPaymentMethod: merchantDefaultPaymentMethod,
        });
    }
    
    renderBadge(success: boolean, title: string) {
        return <div className={classNames("badge", "badge-" + (success ? "success" : "danger"))}>{title}</div>;
    }
    
    renderMerchantDefaultPaymentMethod = () => {
        return (
            this.state.merchantDefaultPaymentMethod && (
                <Card>
                    <CardHeader className="card-header-tab">
                        <div className="card-header-title font-size-lg text-capitalize font-weight-normal">Default Payment Method</div>
                    </CardHeader>
                    <CardBody>
                        <DataTable value={[this.state.merchantDefaultPaymentMethod]} loading={this.state.isLoading}>
                            <Column field="paymentType" header="Type"></Column>
                            <Column field="value" header="Pan / IBAN"></Column>
                            <Column field="ownerName" header="Owner Names On"></Column>
                            <Column field="lastFundsDeposited" header="Last Funds Deposited"></Column>
                            <Column field="lastFundsWithdrawn" header="Last Funds Withdrawn"></Column>
                            <Column
                                field="has2FAOr3DS"
                                header="2FA / 3DS"
                                body={(rowData: PaymentMethods) => this.renderBadge(rowData.has2FAOr3DS, rowData.has2FAOr3DS ? "Yes" : "No")}
                            ></Column>
                            
                        </DataTable>
                    </CardBody>
                </Card>
            )
        );
    };

    render() {
        return this.renderMerchantDefaultPaymentMethod();
    }
}