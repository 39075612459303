import { PagedResult, Result } from "../../../models/Result";
import { SelectItem } from "../../../models/SelectItem";
import { IndividualStatus, User, UserBalance, UserProfileUpdate } from "../../../models/User";
import { httpService } from "../../HttpService";
import { ICrudApiService } from "../../ICrudApiService";
import { SearchRequestBaseModel } from "../../../models/SearchRequestModel";
import { ValidateIbanRequest, ValidateIbanResponse } from "../../../models/MoneyTransferModal";
import { IndividualList, IndividualSelectModel } from "../../../models/IndividualSelectModel";

class UsersApiService implements ICrudApiService<User,UserProfileUpdate> {
    path: string = "Individual";

    getAll = async (request: SearchRequestBaseModel): Promise<PagedResult<User[]>> => await httpService.getPage<User[]>(`${this.path}/search`, request);

    get = async (userId: number): Promise<Result<User>> => await httpService.get<User>(`/${this.path}/${userId}`);
    
    getFromSubmission = async (submissionId: number): Promise<Result<User>> => await httpService.get<User>(`/${this.path}/from-submission/${submissionId}`);

    getUserBalance = async (userId: number): Promise<Result<UserBalance>> => await httpService.get<UserBalance>(`/${this.path}/${userId}/balance`);

    create = async (user: UserProfileUpdate): Promise<Result<User>> => await httpService.post<User>(`/${this.path}`, user);

    update = async (user: UserProfileUpdate): Promise<Result<User>> => await httpService.put<User>(`/${this.path}`, user);

    updateForCompliance = async (user: UserProfileUpdate): Promise<Result<User>> => await httpService.put<User>(`/${this.path}/for-compliance`, user);

    deactivateUser = async (userId: number): Promise<Result<void>> => await httpService.put<void>(`/${this.path}/${userId}/deactivate`,{});

    resetEmail = async (individualId: number): Promise<Result<void>> => await httpService.put<void>(`/${this.path}/${individualId}/reset-email`, {});

    resetPhone = async (individualId: number): Promise<Result<void>> => await httpService.put<void>(`/${this.path}/${individualId}/reset-phone`, {});

    delete = async (userId: number): Promise<Result<void>> => await httpService.delete<void>(`/${this.path}/${userId}`);

    getAvailableTriggers = async (userId: number): Promise<Result<IndividualStatus[]>> => await httpService.get<IndividualStatus[]>(`/${this.path}/${userId}/triggers`);
   
    getIndividualDocumentsTypes = async (): Promise<Result<SelectItem[]>> => await httpService.get<SelectItem[]>(`/${this.path}/individual-document-types`);

    getSourceOfFunds = async (): Promise<Result<SelectItem[]>> => {
        var sourceOfFunds = await httpService.get<SelectItem[]>(`/${this.path}/source-of-funds`);
        if(sourceOfFunds.data){
            sourceOfFunds.data.sort((a, b) => (a.label > b.label) ? 1 : -1);
        }
        return sourceOfFunds
    };
    
    getStatusTypes = async (): Promise<Result<SelectItem<number>[]>> => await httpService.get<SelectItem<number>[]>(`/${this.path}/statusTypes`);

    updatesKycLevel = async (user: User): Promise<Result<User>> => await httpService.put<User>(`/${this.path}/kyclevel`, user);
    
    blockUser = async (userId: number): Promise<Result<User>> => await httpService.put<User>(`/${this.path}/${userId}/block`,{});

    activateUser = async (userId: number): Promise<Result<User>> => await httpService.put<User>(`/${this.path}/${userId}/activate`,{});

    getMainActivity = async (): Promise<Result<SelectItem[]>> => {
        const mainActivity = await httpService.get<SelectItem[]>(`/${this.path}/main-activity`);
        if(mainActivity.data){
            mainActivity.data.sort((a, b) => (a.label > b.label) ? 1 : -1);
        }
        return mainActivity;
    };

    getFinancingMethod = async (): Promise<Result<SelectItem[]>> => {
        const financingMethod = await httpService.get<SelectItem[]>(`/${this.path}/financing-method`);
        if(financingMethod.data){
            financingMethod.data.sort((a, b) => (a.label > b.label) ? 1 : -1);
        }
        return financingMethod;
    };

    getAccountPurpose = async (): Promise<Result<SelectItem[]>> => {
        const accountPurpose = await httpService.get<SelectItem[]>(`/${this.path}/account-purpose`);
        if(accountPurpose.data){
            accountPurpose.data.sort((a, b) => (a.label > b.label) ? 1 : -1);
        }
        return accountPurpose;
    };

    getActiveIndividual = async (): Promise<Result<IndividualList[]>> => await httpService.get<IndividualList[]>(`/${this.path}/get-active-individuals`);


    validateIban = async (request: ValidateIbanRequest): Promise<Result<ValidateIbanResponse>> =>
		await httpService.post<ValidateIbanResponse>(`/Iban`, request);

}

const userApiService = new UsersApiService();

export { userApiService };
