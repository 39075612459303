import { PagedResult, Result } from "../../../models/Result";
import { OperatorDataModel } from "../../../models/User";
import { httpService } from "../../HttpService";
import { SelectItem } from "../../../models/SelectItem";
import { SearchRequestBaseModel } from "../../../models/SearchRequestModel";
import { RoleModel } from "../../../models/Role";

class OperatorsApiService {
    path: string = "Operator";

    get = async (): Promise<Result<OperatorDataModel>> => await httpService.get<OperatorDataModel>(`/${this.path}/own`,{});

    getRoles = async (): Promise<Result<RoleModel[]>> => await httpService.get<RoleModel[]>(`/${this.path}/own/roles`);

    create = async (item: OperatorDataModel): Promise<Result<OperatorDataModel>> => await httpService.post<OperatorDataModel>(`/${this.path}/`,item);

    getById = async (id: number): Promise<Result<OperatorDataModel>> => await httpService.get<OperatorDataModel>(`/${this.path}/${id}`,{});

    getAll = async (searchRequest: SearchRequestBaseModel): Promise<PagedResult<OperatorDataModel[]>> => await httpService.getPage<OperatorDataModel[]>(`/${this.path}/search`, searchRequest);

    updateOwn = async (item: OperatorDataModel): Promise<Result<OperatorDataModel>> => await httpService.put<OperatorDataModel>(`/${this.path}/own`, item);

    update = async (item: OperatorDataModel): Promise<Result<OperatorDataModel>> => await httpService.put<OperatorDataModel>(`/${this.path}`, item);
    
    deactivateoperator = async (operatorId: number): Promise<Result<void>> => await httpService.put<void>(`/${this.path}/${operatorId}/deactivate`,{});

    activateoperator = async (operatorId: number): Promise<Result<void>> => await httpService.put<void>(`/${this.path}/${operatorId}/activate`,{});

    getStatusTypes = async (): Promise<Result<SelectItem<number>[]>> => await httpService.get<SelectItem<number>[]>(`/${this.path}/statusTypes`);
}

const operatorsApiService = new OperatorsApiService();

export { operatorsApiService };
