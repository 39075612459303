import { CardRequest, EWalletRequest } from "../models/EWalletRequest";
import { CardResponse, EWalletResponse } from "../models/EWalletResponse";
import { Result } from "../models/Result";
import { httpService } from "./HttpService";

class PaymentService {
   
    baseApiUrl: string = process.env.REACT_APP_PAY_API_BASE_URL ?? "";

    GetWalletPayload = async (payload: EWalletRequest) : Promise<Result<EWalletResponse>> => {
        return await httpService.post<EWalletResponse>("/api/v1/Payment/e-wallet", payload, { baseURL: this.baseApiUrl });
    };
    
    GetCardPayload = async (payload: CardRequest) : Promise<Result<CardResponse>> => {
        return await httpService.post<CardResponse>("/api/v1/Payment/card-transfer", payload, { baseURL: this.baseApiUrl });
    };
}

const paymentService = new PaymentService();

export { paymentService };