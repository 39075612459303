import React, { Fragment } from "react";
import cx from "classnames";

import ResizeDetector from "react-resize-detector";

import Router from "./router";

export default class MainComponentWrapper extends React.Component {
    constructor(props : any) {
        super(props);
        this.state = {
            closedSmallerSidebar: false,
        };
    }

    render() {

        return (
            <ResizeDetector
                handleWidth
                render={({ width }) => (
                    <Fragment>
                        <div
                            className={cx(
                                "app-container app-theme-white",
                                { "fixed-header": true },
                                { "fixed-sidebar": true || width! < 1250 },
                                { "fixed-footer": true },
                                { "closed-sidebar": false || width! < 1250 },
                                {
                                    "closed-sidebar-mobile": width! < 1250,
                                },
                                // { "sidebar-mobile-open": enableMobileMenu },
                                { "body-tabs-shadow-btn": true }
                            )}
                        >
                            <Router />
                        </div>
                    </Fragment>
                )}
            />
        );
    }
}