import React, { Component, Fragment } from "react";
import { NavLink } from "react-router-dom";
import { OperationNav } from "../../sidemenu";
import _ from "lodash";
import { areOperationsAllowed, getUserPermittedRoutesForNav } from "../../authGuard";

class Nav extends Component {
    render() {
        return (
            <Fragment>
                <h5 className="app-sidebar__heading">Menu</h5>
                <div className="metismenu vertical-nav-menu">
                    <ul className="metismenu-container">
                        <li className="metismenu-item">
                            <span className="metismenu-link has-active-child">
                                <i className="metismenu-icon pe-7s-rocket"></i>Pages
                                <i className="metismenu-state-icon pe-7s-angle-down caret-left rotate-minus-90"></i>
                            </span>
                            <ul className="metismenu-container visible">
                                {getUserPermittedRoutesForNav().map(item => {
                                    return (
                                        <li className="metismenu-item" key={item.path}>
                                            <NavLink className="metismenu-link" to={item.path}>
                                                <i className="metismenu-icon"></i>{item.label}
                                            </NavLink>
                                        </li>
                                    );
                                })}

                            </ul>
                        </li>
                        {areOperationsAllowed() && <li className="metismenu-item">
                            <span className="metismenu-link has-active-child">
                                <i className="metismenu-icon pe-7s-plane"></i>Operations
                                <i className="metismenu-state-icon pe-7s-angle-down caret-left rotate-minus-90"></i>
                            </span>
                            <ul className="metismenu-container visible">
                                {OperationNav.map(item => {
                                    return (
                                        <li className="metismenu-item" key={item.to}>
                                            <NavLink className="metismenu-link" to={item.to}>
                                                <i className="metismenu-icon"></i>{item.label}
                                            </NavLink>
                                        </li>
                                    );
                                })}

                            </ul>
                        </li>}
                    </ul>
                </div>
            </Fragment>
        );
    }
}

export default Nav;
