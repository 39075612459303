import React, { Component, Fragment } from "react";
import { Container } from "reactstrap";
import { Toast } from "primereact/toast";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Dropdown } from "primereact/dropdown";
import _ from "lodash";
import moment from "moment";
import { Column, ColumnFilterElementTemplateOptions } from "primereact/column";
import { Button } from "primereact/button";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { SearchRequestBaseModel, SortOrderType } from "../../models/SearchRequestModel";
import ExtendedDataTable from "../../components/ExtendedDataTable";
import { dateFilterTemplate, numberFilterMatchModeOptions, stringFilterMatchModeOptions } from "../Paginator";
import { orchestratorApiService } from "../../services/api/Compliance/OrchestratorApiService";
import { BankTransferOutgoing } from "../../models/Orchestrator";
import { PagedResult } from "../../models/Result";
import ReviewPendingBankTransferModal from "./ReviewPendingBankTransferModal";

export enum BankTransfersListType {
    All,
    Pending,
}

interface ListBankTransfersOutgoingProps {
    listType: BankTransfersListType;
}

interface ListBankTransfersOutgoingState {
    isLoading: boolean;
    bankTransfers: BankTransferOutgoing[];
    viewBankTransferModal: boolean;
    selectedTransfer: BankTransferOutgoing | null;
    totalRecords: number;
}

export default class ListBankTransfersOutgoing extends Component<ListBankTransfersOutgoingProps, ListBankTransfersOutgoingState> {
    toast: Toast | null = null;
    private dataTable: ExtendedDataTable | null = null;

    constructor(props: ListBankTransfersOutgoingProps | Readonly<ListBankTransfersOutgoingProps>) {
        super(props);

        this.state = {
            isLoading: false,
            bankTransfers: [],
            viewBankTransferModal: false,
            selectedTransfer: null,
            totalRecords: 0,
        };
    }

    loadData = async (searchRequest: SearchRequestBaseModel) => {
        this.setState({ isLoading: true });
        //add Sorting due to by defult is create date and when modal close its change
        searchRequest.sortings = [
            {
                sortOrder: 2,
                sortBy: "CreateDate",
            },
        ];
        let bankTransfersResult: PagedResult<BankTransferOutgoing[]> | null = null;
        if (this.props.listType === BankTransfersListType.Pending) {
            bankTransfersResult = await orchestratorApiService.getPendingPaged(searchRequest);
        }
        if (this.props.listType === BankTransfersListType.All) {
            bankTransfersResult = await orchestratorApiService.getAllPaged(searchRequest);
        }

        this.setState({ isLoading: false });

        if (bankTransfersResult != null) {
            if (!bankTransfersResult?.success) {
                this.toast?.show({ severity: "error", summary: "Error", detail: bankTransfersResult?.message, life: 3000 });
                return;
            }

            this.setState({ bankTransfers: bankTransfersResult?.data, totalRecords: bankTransfersResult.totalCount });
        }
    };

    startReviewing = async (row: BankTransferOutgoing) => {
        this.setState({ viewBankTransferModal: true, selectedTransfer: row });
    };

    actionBodyTemplate = (row: BankTransferOutgoing) => {
        if (this.props.listType === BankTransfersListType.Pending) {
            return <Button label="Review" type="button" className="p-button p-button-warning" onClick={() => this.startReviewing(row)}></Button>;
        }
    };

    statusFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
        // const statusOptions = Object.keys(BankTransferStatus).map((key) => ({
        //     label: key,
        //     value: BankTransferStatus[key as keyof typeof BankTransferStatus],
        //   }));
        // const statusOptions = [
        //     { label: 'New', value: BankTransferStatus.New },
        //     { label: 'Initiated', value: BankTransferStatus.Initiated },
        //     { label: 'Pending Execution', value: BankTransferStatus.PendingExecution },
        //     { label: 'Rejected', value: BankTransferStatus.Rejected },
        //     { label: 'Canceled', value: BankTransferStatus.Canceled },
        //     { label: 'Executed', value: BankTransferStatus.Executed },
        //     { label: 'Rejected By Borika', value: BankTransferStatus.RejectedByBorika },
        //     { label: 'Returned To Customer', value: BankTransferStatus.ReturnedToCustomer },
        //   ];
        const statusOptions = [
            { label: "New", value: 0 },
            { label: "Initiated", value: 1 },
            { label: "Pending Execution", value: 2 },
            { label: "Rejected", value: 3 },
            { label: "Canceled", value: 4 },
            { label: "Executed", value: 5 },
            { label: "Rejected By Borika", value: 6 },
            { label: "Returned To Customer", value: 7 },
        ];

        return (
            <Dropdown
                value={options.value}
                options={statusOptions}
                onChange={(e) => options.filterCallback(e.value)}
                optionLabel="label"
                optionValue="value"
                filter
                showClear
                placeholder="Select Status"
                className="p-column-filter"
            />
        );
        // return <Dropdown value={options.value} options={this.state.users} onChange={(e) => options.filterCallback(e.value)} optionLabel="label" optionValue="value" filter showClear placeholder="Select Users" className="p-column-filter" />;
    };

    toggleModalView = () => {
        this.setState({ viewBankTransferModal: false });
        this.loadData({
            pageNumber: this.dataTable?.state.page!,
            pageSize: this.dataTable?.state.pageRowCount!,
            filters: [],
            sortings: [],
        });
    };

    render() {
        return (
            <Fragment>
                <TransitionGroup appear={true} exit={false} enter={false}>
                    <CSSTransition classNames="TabsAnimation" timeout={1500}>
                        <Fragment>
                            <Toast ref={(el) => (this.toast = el)} position={"bottom-center"} baseZIndex={99999}></Toast>
                            {this.state.viewBankTransferModal && (
                                <ReviewPendingBankTransferModal onHide={this.toggleModalView} bankTransfer={this.state.selectedTransfer!} />
                            )}
                            <Container fluid>
                                <ConfirmDialog />
                                <ExtendedDataTable
                                    value={this.state.bankTransfers}
                                    totalRecords={this.state.totalRecords}
                                    onChange={this.loadData}
                                    loading={this.state.isLoading}
                                    ref={(ref) => (this.dataTable = ref)}
                                    defaultSortOrder={SortOrderType.Descending}
                                    defaultSortField="CreateDate"
                                >
                                    <Column header="ID" field="id" filterMatchModeOptions={numberFilterMatchModeOptions}></Column>
                                    <Column
                                        field="createDate"
                                        header="Date"
                                        body={(rowData: BankTransferOutgoing) => (
                                            <p>{moment.utc(rowData.createDate).local().format("DD/MM/YYYY hh:mm:ss A")}</p>
                                        )}
                                        filterMatchModeOptions={numberFilterMatchModeOptions}
                                        dataType="date"
                                        filterElement={dateFilterTemplate}
                                    ></Column>
                                    <Column
                                        header="Sender Name"
                                        field="sourceUserCustomerName"
                                        body={(rowData: BankTransferOutgoing) => <span>{rowData.sourceUserCustomerName}</span>}
                                        filterMatchModeOptions={stringFilterMatchModeOptions}
                                    ></Column>
                                    <Column
                                        header="Beneficiary Name"
                                        field="beneficiaryCustomerName"
                                        body={(rowData: BankTransferOutgoing) => <span>{rowData.beneficiaryCustomerName}</span>}
                                        filterMatchModeOptions={stringFilterMatchModeOptions}
                                    ></Column>
                                    <Column header="Amount" field="amount" filterMatchModeOptions={numberFilterMatchModeOptions}></Column>
                                    <Column header="Currency" field="currency" filterMatchModeOptions={stringFilterMatchModeOptions}></Column>

                                    {/* <Column field="status"
                                                header="Status F"
                                                filterMatchMode="equals"
                                                filterField="status"
                                                filterMatchModeOptions={numberFilterMatchModeOptions}
                                                // filterMatchModeOptions={stringFilterMatchModeOptions}
                                                filterElement={this.statusFilterTemplate}
                                                body={(rowData: BankTransferOutgoing) => <span>{BankTransferStatus[rowData.status]}</span>}
                                        ></Column> */}

                                    <Column
                                        hidden={!(this.props.listType === BankTransfersListType.All)}
                                        header="Status"
                                        field="status"
                                        // body={(rowData: BankTransferOutgoing) => <span>{BankTransferStatus[rowData.status]}</span>}
                                        body={(rowData: BankTransferOutgoing) => <span>{rowData.status}</span>}
                                        filterMatchModeOptions={stringFilterMatchModeOptions}
                                    ></Column>

                                    <Column
                                        hidden={!(this.props.listType === BankTransfersListType.All)}
                                        header="Review Status"
                                        field="reviewStatus"
                                        //  body={(rowData: BankTransferOutgoing) => <span>{ReviewStatus[rowData.reviewStatus]}</span>}
                                        body={(rowData: BankTransferOutgoing) => <span>{rowData.reviewStatus}</span>}
                                        filterMatchModeOptions={stringFilterMatchModeOptions}
                                    ></Column>

                                    <Column
                                        hidden={!(this.props.listType === BankTransfersListType.All)}
                                        header="Payment Reference"
                                        field="flexCubePaymentReference"
                                        body={(rowData: BankTransferOutgoing) => <span>{rowData.flexCubePaymentReference}</span>}
                                        filterMatchModeOptions={stringFilterMatchModeOptions}
                                    ></Column>

                                    {/* 
                                         <Column
                                            hidden={ !(this.props.listType === BankTransfersListType.All)}
                                            header="Source of Funds"
                                            field="sourceOfFundsStatus"
                                            body={(rowData: BankTransferOutgoing) => <span>{SourceOfFundsStatus[rowData.sourceOfFundsStatus]}</span>}
                                            filterMatchModeOptions={stringFilterMatchModeOptions}>
                                        </Column>  */}

                                    {/* <Column
                                            hidden={ (this.props.listType === BankTransfersListType.All)}
                                            header="Risk Info"
                                            field="riskRulesInfo"
                                            body={(rowData: BankTransferOutgoing) => <span>{rowData.riskRulesInfo}</span>}
                                            filterMatchModeOptions={stringFilterMatchModeOptions}>
                                        </Column> */}

                                    {/* <Column field="endUserPhone" header="End User Phone" sortable filterMatchModeOptions={stringFilterMatchModeOptions}></Column>
                                        <Column field="endUserEmail" header="End User Email" sortable filterMatchModeOptions={stringFilterMatchModeOptions}></Column>
                                        <Column field="reason" header="Risk reason" filterMatchModeOptions={stringFilterMatchModeOptions}></Column>
                                        <Column
                                            header="Uploaded Files">
                                        </Column> */}

                                    <Column
                                        hidden={this.props.listType === BankTransfersListType.All}
                                        header=""
                                        body={this.actionBodyTemplate}
                                        headerStyle={{ width: "8em", textAlign: "center" }}
                                        bodyStyle={{ textAlign: "center", overflow: "visible" }}
                                    />
                                </ExtendedDataTable>
                            </Container>
                        </Fragment>
                    </CSSTransition>
                </TransitionGroup>
            </Fragment>
        );
    }
}
