import React, { Component, Fragment } from "react";
import { Container, Card, CardBody } from "reactstrap";
import { User } from "../../models/User";
import { Column, ColumnFilterElementTemplateOptions } from "primereact/column";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import history from "../../history";
import "./users.scss";
import PageTitle from "../../Layout/AppMain/PageTitle";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import moment from "moment";
import { dateFilterTemplate, numberFilterMatchModeOptions, statusFilterMatchModeOptions, stringFilterMatchModeOptions } from "../Paginator";
import { userApiService } from "../../services/api/users/UsersApiService";
import { Toast } from "primereact/toast";
import { SearchRequestBaseModel, SortOrderType} from "../../models/SearchRequestModel";
import ExtendedDataTable from "../../components/ExtendedDataTable";
import { Link } from "react-router-dom";
import { TriStateCheckbox } from "primereact/tristatecheckbox";
import { SelectItem } from "../../models/SelectItem";
import { merchantApiService } from "../../services/api/merchants/MerchantApiService";
import { Dropdown } from "primereact/dropdown";
import { utils } from "../../utils/utils";
import { faUserGroup } from "@fortawesome/free-solid-svg-icons";

interface UsersProps {
    merchantId: number | null
    

}

interface UsersState {
    users: User[];
    merchants: SelectItem[]
    isLoading: boolean;
    totalRecords: number;
    selectedStatusFilter: string | null;
}

export default class Users extends Component<UsersProps, UsersState> {
    private toast: Toast | null = null;

    userStatusClassesMap: Map<string, string> = new Map<string, string>([
        ["Active", "success"],
        ["Inactive", "warning"],
        ["Deactivated", "danger"],
        ["New", "success"],
        ["Pending Verification", "warning"],
        ["Verified", "success"],
        ["Blocked", "danger"],
    ]);
    statusTypes: Map<number, string> = new Map<number, string>();
    private dataTable: ExtendedDataTable | null = null;

    constructor(props: UsersProps | Readonly<UsersProps>) {
        super(props);

        this.state = {
            users: [],
            merchants: [],
            isLoading: false,
            totalRecords: 0,
            selectedStatusFilter: null,
        };
    }
    statusFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
        const { selectedStatusFilter } = this.state;
    
        return (
            <Dropdown
                value={selectedStatusFilter}
                options={[
                    { label: "Active", value: "200" },
                    { label: "Closed", value: "350" },
                    { label: "Deactivated", value: "300" },
                    { label: "New", value: "100" },
                    { label: "Pending Verification", value: "150" },
                    { label: "Blocked", value: "400" },
                ]}
                onChange={(e) => {
                    this.setState({ selectedStatusFilter: e.value });
                    options.filterCallback(e.value);
                }}
                placeholder="Select Status"
            />
        );
    };
    
    loadMerchantsAndStatusData = async () => {
        this.setState({ isLoading: true });

        const statusTypes = await userApiService.getStatusTypes();

        if (!statusTypes.success) {
            this.setState({ isLoading: false });
            this.toast?.show({ severity: "error", summary: "Error", detail: statusTypes.message, life: 3000 });
            return;
        }

        statusTypes.data.forEach((item) => {
            this.statusTypes.set(item.value, item.label);
        });

        this.setState({ isLoading: false });

        const merchantResult = await merchantApiService.getSelectItem();

        this.setState({ merchants: merchantResult.success ? merchantResult.data : [] });
    };

    loadUsersData = async (searchRequest: SearchRequestBaseModel) => {
        this.setState({ isLoading: true });
        searchRequest.filters = utils.manageDataTableFilters(searchRequest.filters);

        const usersResult = await userApiService.getAll(searchRequest);
        this.setState({ isLoading: false });

        if (!usersResult.success) {
            this.toast?.show({ severity: "error", summary: "Error", detail: usersResult.message, life: 3000 });
        }

        this.setState({ users: usersResult.data, totalRecords: usersResult.totalCount });

        const users = this.state.users ?? [];
        users.filter(a => a.cbsOnboarded).forEach((user) => {
            userApiService.getUserBalance(user.id).then(item => {
                const stateUsers = this.state.users;
                const index = stateUsers.findIndex(a => a.id == user.id);
                stateUsers[index].walletBalance = item.data?.walletBalance;
                stateUsers[index].walletId = item.data?.walletId;
                stateUsers[index].currency = item.data?.currency;

                this.setState({ users: stateUsers });
            }).catch((_) => {});
        });
    };

    async componentDidMount() {
        this.loadMerchantsAndStatusData();
    }


    renderHeader = () => {
        return (
            <span className="p-input-icon-left" style={{ display: "flex", justifyContent: "space-between" }}>
                <Button type="button" icon="pi pi-filter-slash" label="Clear" className="p-button-outlined" onClick={() => { this.dataTable!.reset(); }} />
            </span>
        );
    };

    onNameClick = (userId: number) => {
        history.push(`/user-profile/${userId}`);
    };

    onActionButtonClick = (user: User) => {
        history.push(`/user-profile/${user.id}`);
    };

    actionBodyTemplate = (rowData: User) => {
        return <Button type="button" icon="pi pi-cog" className="p-button-outlined" onClick={() => this.onActionButtonClick(rowData)}></Button>;
    };

    renderStatusBadge(success: number, title: string) {
        const item = this.statusTypes.get(success);
        const className = this.userStatusClassesMap.get(item ?? "") ?? "danger";
        return <div className={classNames("badge", "badge-" + className)}>{title}</div>;
    }

    getOnboardedClassName = (param: boolean): string => {
        return param === true ? "text-success" : "text-danger";
    };

    verifiedBodyTemplate = (rowData : User) => {
        return <i className={classNames("pi", this.getOnboardedClassName(rowData.cbsOnboarded), {"true-icon pi-check-circle": rowData.cbsOnboarded, "false-icon pi-times-circle": !rowData.cbsOnboarded})}></i>;
    };

    verifiedFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
        return <TriStateCheckbox value={options.value} onChange={(e) => options.filterCallback(e.value)} />;
    };

    merchantFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
        return <Dropdown value={options.value} options={this.state.merchants} onChange={(e) => options.filterCallback(e.value)} optionLabel="label" optionValue="value" filter showClear placeholder="Select Merchants" className="p-column-filter" />;
    };

    render() {
        return (
            <Fragment>
                <Toast ref={(el) => (this.toast = el)} />
                <TransitionGroup appear={true} exit={false} enter={false}>
                    <CSSTransition classNames="TabsAnimation" timeout={1500}>
                        <Fragment>
                            <PageTitle
                                heading="Customers"
                                icon={faUserGroup}
                            />
                            <Container fluid>
                                <Card className="mb-3">
                                    <CardBody>
                                        <ExtendedDataTable
                                            header={this.renderHeader}
                                            value={this.state.users}
                                            loading={this.state.isLoading}
                                            totalRecords={this.state.totalRecords}
                                            onChange={this.loadUsersData}
                                            ref={(ref) => this.dataTable = ref}
                                            defaultSortOrder={SortOrderType.Descending}
                                            defaultSortField='createdTimeStamp'
                                        >
                                            <Column field="id" header="Id" filterMatchModeOptions={numberFilterMatchModeOptions}></Column>
                                            <Column
                                                field="firstName"
                                                header="First Name"
                                                body={(rowData: User) => (
                                                    <Link to={`/user-profile/${rowData.id}`} >{rowData.firstName}</Link>
                                                )}
                                                filterMatchModeOptions={stringFilterMatchModeOptions}>
                                            </Column>
                                            <Column
                                                field="lastName"
                                                header="Last Name"
                                                body={(rowData: User) => (
                                                    <Link to={`/user-profile/${rowData.id}`} >{rowData.lastName}</Link>
                                                )}
                                                filterMatchModeOptions={stringFilterMatchModeOptions}>
                                            </Column>
                                            <Column field="email" header="Email" filterMatchModeOptions={stringFilterMatchModeOptions}></Column>
                                            <Column field="walletId" header="Wallet Id" body={(row : User) => row.cbsOnboarded ? row.walletId : null}></Column>
                                            <Column field="walletBalance" header="Balance" body={(row : User) => row.cbsOnboarded ? row.walletBalance : null}></Column>
                                            <Column field="currency" header="Currency"></Column>
                                            <Column field="merchant" filterMatchMode="equals" header="Merchant" filterField="MerchantId" showFilterMatchModes={false} filterMatchModeOptions={stringFilterMatchModeOptions} filterElement={this.merchantFilterTemplate}></Column>
                                            <Column field="merchantGroup" header="Merchant Group" filterMatchModeOptions={stringFilterMatchModeOptions}></Column>
                                            <Column
                                                field="createdTimeStamp"
                                                header="Created TimeStamp"
                                                body={(rowData: User) => (
                                                    <p>
                                                        {moment(rowData.createdTimeStamp).isAfter(moment.utc("0001-01-01"))
                                                            ? moment(rowData.createdTimeStamp).format("DD/MM/YYYY hh:mm:ss")
                                                            : ""}
                                                    </p>
                                                )}
                                                filterMatchModeOptions={numberFilterMatchModeOptions}
                                                dataType="date"
                                                filterElement={dateFilterTemplate}    
                                            ></Column>
                                            <Column
                                                field="lastLogin"
                                                header="Last Login"
                                                body={(rowData: User) => (
                                                    <p>
                                                        {moment(rowData.lastLogin).isAfter(moment.utc("0001-01-01"))
                                                            ? moment(rowData.lastLogin).format("DD/MM/YYYY hh:mm:ss")
                                                            : ""}
                                                    </p>
                                                )}
                                                filterMatchModeOptions={numberFilterMatchModeOptions}
                                                dataType="date"
                                                filterElement={dateFilterTemplate}    
                                            ></Column>
                                            <Column
                                                field="status"
                                                header="Status"
                                                filterMatchMode="equals"
                                                filterMatchModeOptions={statusFilterMatchModeOptions}
                                                filterElement={this.statusFilterTemplate}
                                                body={(rowData: User) =>
                                                    this.renderStatusBadge(rowData.status, this.statusTypes.get(rowData.status)!)
                                                }
                                            ></Column>
                                            <Column
                                                field="cbsOnboarded"
                                                header="CBS Onboarded"
                                                dataType="boolean"
                                                filterMatchMode="equals"
                                                filterMatchModeOptions={numberFilterMatchModeOptions}
                                                body={this.verifiedBodyTemplate}
                                                filterElement={this.verifiedFilterTemplate}
                                                sortable>
                                            </Column>
                                            <Column
                                                body={this.actionBodyTemplate}
                                                headerStyle={{ width: "8em", textAlign: "center" }}
                                                bodyStyle={{ textAlign: "center", overflow: "visible" }}
                                            />
                                        </ExtendedDataTable>
                                    </CardBody>
                                </Card>
                            </Container>
                        </Fragment>
                    </CSSTransition>
                </TransitionGroup>
            </Fragment>
        );
    }
}
