import React, { Fragment, ReactNode } from "react";
import { DropdownToggle, DropdownMenu, Nav, Button, NavItem, NavLink, UncontrolledButtonDropdown } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { utils } from "../../../utils/utils";
import history from "../../../history";
import EventEmitter, { Events } from "../../../services/eventemitter";
import { operatorsApiService } from "../../../services/api/users/OperatorApiService";
import { fileService } from "../../../services/FileService";
import { authService } from "../../../services/authService";
import { faAngleDown, faUser } from "@fortawesome/free-solid-svg-icons";

interface UserBoxState {
    email: string,
    username: string,
    photo: string | null
}

class UserBox extends React.Component<Record<string, never>, UserBoxState> {
    constructor(props : Record<string, never>) {
        super(props);

        this.state = {
            username: "",
            email: "",
            photo: "",
        };

        EventEmitter.on(Events.OnUserDataUpdate, this.updateUserData);
    }

    async componentDidMount() {
        this.updateUserData();
    }

    updateUserData = async () => {
        const userData = await operatorsApiService.get();

        if(!userData.success) {
            return;
        }

        let photo = null;
        if(userData.data.photoStorageId != null) {
            const photoResult = await fileService.GetFile(userData.data.photoStorageId);

            if (photoResult.success) {
                photo = photoResult.data;
            }    
        }
        
        this.setState({
            username: userData.data.firstName + " " + userData.data.lastName,
            email: userData.data.email,
            photo: photo,
        });
    };

    logOut = () => {
        authService.signoutRedirect();
    };

    getImageSource = (color: string = "#3366ff"): any => {
        if(!this.state?.photo) {
            return <FontAwesomeIcon size="2x" icon={faUser as any} color={color} width={50} height={50} />;
        }
		
        return <img width={42} className="rounded-circle" src={utils.appendBase64Data(this.state.photo!)} style={{ width: "50px", height: "50px" }} alt="" />;
    };

    render() {
        return (
            <Fragment>
                <div className="header-btn-lg pr-0">
                    <div className="widget-content p-0">
                        <div className="widget-content-wrapper">
                            <div className="widget-content-left">
                                <UncontrolledButtonDropdown>
                                    <DropdownToggle color="link" className="p-0">
                                        {this.getImageSource()}
                                        <FontAwesomeIcon className="ml-2 opacity-8" icon={faAngleDown as any} />
                                    </DropdownToggle>
                                    <DropdownMenu end className="rm-pointers dropdown-menu-lg">
                                        <div className="dropdown-menu-header">
                                            <div className="dropdown-menu-header-inner">
                                                <div
                                                    className="menu-header-image"
                                                    style={{
                                                        backgroundColor: "#5897d0",
                                                    }}
                                                />
                                                <div className="menu-header-content text-left">
                                                    <div className="widget-content p-0">
                                                        <div className="widget-content-wrapper">
                                                            <div className="widget-content-left mr-3">
                                                                {this.getImageSource("white")}
                                                            </div>
                                                            <div className="widget-content-left">
                                                                <div className="widget-heading">{this.state.username}</div>
                                                                <div className="widget-heading">{this.state.email}</div>
                                                            </div>
                                                            <div className="widget-content-right mr-2">
                                                                <Button className="btn-pill btn-shadow btn-shine" color="focus" onClick={this.logOut}>
                                                                    Logout
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="scroll-area-xs"
                                            style={{
                                                height: "150px",
                                            }}
                                        >
                                            <Nav vertical>
                                                <NavItem>
                                                    <NavLink href="#" onClick={() => history.push("/profile")}>Profile</NavLink>
                                                    {/* <NavLink href="#">Logs</NavLink> */}
                                                </NavItem>
                                            </Nav>
                                        </div>
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default UserBox;
