import React from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";

export function CSSTransitionComponent({ ...props }) {
    return (
        <TransitionGroup appear={true} exit={false} enter={false}>
            <CSSTransition classNames="TabsAnimation" timeout={1500}>
                {props.children}
            </CSSTransition>
        </TransitionGroup>

    );
}