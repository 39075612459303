import { Log, User, UserManager, UserManagerSettings, WebStorageStateStore } from "oidc-client";
import { httpService } from "./HttpService";
import { storageService } from "./StorageService";

class AuthService {
    private userManagerSettings: UserManagerSettings = {
        authority: process.env.REACT_APP_AUTH_API_BASE_URL,
        client_id: process.env.REACT_APP_CLIENT_ID,
        response_type: "code",
        response_mode: "query",
        post_logout_redirect_uri: `${window.location.origin}/logout`,
        redirect_uri: `${window.location.origin}/auth-result`,
        scope: "IdentityServerApi TokenserverAPI.write TokenserverAPI.read Tenn.CRM offline_access Tenn.FileStorage Tenn.SFTP Tenn.Payment Tenn.RiskService",
        automaticSilentRenew: false,
        silent_redirect_uri: `${window.location.origin}/silentRenew`,
        accessTokenExpiringNotificationTime: 300,
        checkSessionInterval: 1000,
        silentRequestTimeout: 1000,
        stateStore: new WebStorageStateStore({ store: window.sessionStorage })
    };

    // https://medium.com/@franciscopa91/how-to-implement-oidc-authentication-with-react-context-api-and-react-router-205e13f2d49
    private userManager: UserManager;

    constructor() {
        this.userManager = new UserManager(this.userManagerSettings);

        this.userManager.events.addSilentRenewError((error: Error) => {
            console.log("Silent Renew failed with Error", error);
        });

        this.userManager.events.addAccessTokenExpired(() => {
            console.log("Access Token Expired");
            this.signinSilent();
        });

        // Logger
        Log.logger = console;
        Log.level = Log.DEBUG;
    }

    getUser = async () : Promise<User | null> => {
        try {
            return  this.userManager.getUser();            
        } catch (error) {
            return null;
        }
    };

    signinRedirect = async () : Promise<void> => {
        await this.userManager.signinRedirect();
    };

    isAuthenticated = () => {
        const oidcStorage: User = JSON.parse(sessionStorage.getItem(`oidc.user:${process.env.REACT_APP_AUTH_API_BASE_URL}:${process.env.REACT_APP_CLIENT_ID}`) ?? "");

        return (!!oidcStorage && !!oidcStorage.access_token);
    };

    signinRedirectCallback = async () : Promise<User> => {
        return this.userManager.signinRedirectCallback();
    };

    signinSilentCallback = async () : Promise<void> => {
        try {
            const user = await this.userManager.signinSilentCallback();
            const token = `${user?.token_type} ${user?.access_token}`;

            storageService.setAccessToken(token);
            httpService.setAuthToken(token);
        } catch (error) {
            console.log("signinSilentCallback failed with Error", error);
        }
    };

    signoutRedirect = async () : Promise<void> => {
        await this.userManager.clearStaleState();
        return this.userManager.signoutRedirect();
    };

    signoutRedirectCallback = async () : Promise<void> => {
        await this.userManager.signoutRedirectCallback();
    };

    signinSilent = async () => {
        try {
            const user = await this.userManager.signinSilent();
            const token = `${user.token_type} ${user.access_token}`;

            storageService.setAccessToken(token);
            httpService.setAuthToken(token);
        } catch (error) {
            console.log("signinSilent failed", error);
            throw error;
        }
    };
}

const authService = new AuthService();

export { authService };