
import { AddBankTransferOutgoing, BankTransferOutgoing, BankTransferRequest, BankTransferResult, BankTransferReviewRequest, BankTransferSourceOfFunds, SourceOfFundsUploadRequest} from "../../../models/Orchestrator";
import { PagedResult, Result } from "../../../models/Result";
import { SearchRequestBaseModel } from "../../../models/SearchRequestModel";
import { httpService } from "../../HttpService";
import { ICrudApiService } from "../../ICrudApiService";

class OrchestratorApiService implements ICrudApiService<BankTransferOutgoing, AddBankTransferOutgoing> {
    baseApiUrl: string = process.env.REACT_APP_ORCHESTRATOR_API_BASE_URL ?? ""; 
    path: string = "api/v1/BankTransfers";
    pathSourceOfFunds: string = "api/v1/SourceofFunds";

    getAllPaged = async (searchRequest: SearchRequestBaseModel): Promise<PagedResult<BankTransferOutgoing[]>> => 
        await httpService.getPage<BankTransferOutgoing[]>(`${this.path}/get-all`, searchRequest, { baseURL: this.baseApiUrl });

    getPendingPaged = async (searchRequest: SearchRequestBaseModel): Promise<PagedResult<BankTransferOutgoing[]>> => 
        await httpService.getPage<BankTransferOutgoing[]>(`${this.path}/get-pending`, searchRequest, { baseURL: this.baseApiUrl });

    getSourceOfFunds = async (request:BankTransferRequest): Promise<Result<BankTransferSourceOfFunds[]>> => await httpService.post<BankTransferSourceOfFunds[]>(`/${this.pathSourceOfFunds}/get-source-of-funds`, request, { baseURL: this.baseApiUrl });

    updateSourceOfFunds = async (request:BankTransferSourceOfFunds): Promise<Result<BankTransferSourceOfFunds>> => await httpService.put<BankTransferSourceOfFunds>(`/${this.pathSourceOfFunds}/source-of-funds`, request, { baseURL: this.baseApiUrl });
        
    uploadSourceOfFunds = async (request:SourceOfFundsUploadRequest): Promise<Result<BankTransferResult>> => await httpService.post<BankTransferResult>(`/${this.pathSourceOfFunds}/upload-source-of-funds`, request, { baseURL: this.baseApiUrl });
            
    setReviewResolution = async (request:BankTransferReviewRequest): Promise<Result<BankTransferResult>> => await httpService.post<BankTransferResult>(`/${this.path}/outgoing-review`, request, { baseURL: this.baseApiUrl });

    setRiskNote = async (request:BankTransferOutgoing): Promise<Result<BankTransferOutgoing>> => await httpService.put<BankTransferOutgoing>(`/${this.path}/risk-note`, request, { baseURL: this.baseApiUrl });

    get = async (userId: number): Promise<Result<BankTransferOutgoing>> => { throw new Error("Method not implemented."); };

    create = async (): Promise<Result<BankTransferOutgoing>> => { throw new Error("Method not implemented."); };

    update = async (): Promise<Result<BankTransferOutgoing>> => { throw new Error("Method not implemented."); };

    delete = async (entityId: number): Promise<Result<void>> => { throw new Error("Method not implemented."); };
}

const orchestratorApiService = new OrchestratorApiService();

export { orchestratorApiService };
