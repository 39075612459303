import React, { Fragment } from "react";
import cx from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "reactstrap";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";

class AppMobileMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            mobile: false,
            activeSecondaryMenuMobile: false,
        };
    }

    toggleMobileSidebar = () => {
        let { enableMobileMenu, setEnableMobileMenu } = this.props;
        setEnableMobileMenu(!enableMobileMenu);
    };

    toggleMobileSmall = () => {
        let { enableMobileMenuSmall, setEnableMobileMenuSmall } = this.props;
        setEnableMobileMenuSmall(!enableMobileMenuSmall);
    };

    state = {
        openLeft: false,
        openRight: false,
        relativeWidth: false,
        width: 280,
        noTouchOpen: false,
        noTouchClose: false,
    };

    render() {
        return (
            <Fragment>
                <div className="app-header__menu">
                    <span onClick={this.toggleMobileSmall}>
                        <Button
                            size="sm"
                            className={cx("btn-icon btn-icon-only", {
                                active: this.state.activeSecondaryMenuMobile,
                            })}
                            color="primary"
                            onClick={() =>
                                this.setState({
                                    activeSecondaryMenuMobile: !this.state.activeSecondaryMenuMobile,
                                })
                            }
                        >
                            <div className="btn-icon-wrapper">
                                <FontAwesomeIcon icon={faEllipsisV} />
                            </div>
                        </Button>
                    </span>
                </div>
            </Fragment>
        );
    }
}

export default AppMobileMenu;
