import moment from "moment";
import { FilterMatchMode } from "primereact/api";
import { Column } from "primereact/column";
import { DataTable, DataTableFilterMeta, DataTablePFSEvent } from "primereact/datatable";
import { SplitButton } from "primereact/splitbutton";
import { Toast } from "primereact/toast";
import { useEffect, useState } from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import { TransactionReportResponse, TransType } from "../../models/Transactions";
import { httpService } from "../../services/HttpService";
import { paginatorTemplate } from "../Paginator";
import TransactionDetailsDialog from "../Users/TransactionDetails";
import { DateFilterTemplate, InputTextFilerTemplate, TransactionTypeFilterTemplate } from "./TransactionFilterTemplates";
import "./style.css";
import React from "react";
import { areOperationsAllowed } from "../../authGuard";

const PendingTransactions: React.FC = () => {
    let toast: Toast | null = null;
    const [transactions, setTransactions] = useState<TransactionReportResponse[]>([] as TransactionReportResponse[]);
    const [first, setFirst] = useState<number>(0);
    const [rows, setRows] = useState<number>(10);
    const [viewTransactionDetails, setViewTransactionDetails] = useState<boolean>(false);
    const [selectedTransaction, setSelectedTransaction] = useState<TransactionReportResponse | null>(null);
    const [editableTransactionDetails, setEditableTransactionDetails] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedMerchantId, setSelectedMerchantId] = useState<number>(33);
    const [filters] = useState<DataTableFilterMeta>({
        type: { value: null, matchMode: FilterMatchMode.EQUALS },
        senderEmail: { value: null, matchMode: FilterMatchMode.CONTAINS },
        senderName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        senderSource: { value: null, matchMode: FilterMatchMode.CONTAINS },
        senderMerchant: { value: null, matchMode: FilterMatchMode.CONTAINS },
        recepientName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        recepientDestination: { value: null, matchMode: FilterMatchMode.CONTAINS },
        timeStamp: { value: null, matchMode: FilterMatchMode.DATE_IS },
    });

    const onPageChange = (event: DataTablePFSEvent) => {
        setFirst(event.first);
        setRows(event.rows);
    };

    const actionBodyTemplate = (rowData: TransactionReportResponse) => {
        const items = [
            {
                label: "Approve",
                icon: "pi pi-check",
                command: (e: any) => onApprove(rowData),
            },
            {
                label: "Cancel",
                icon: "pi pi-times",
                command: (e: any) => onCancel(rowData),
            },
            {
                label: "Revert",
                icon: "pi pi-undo",
                command: (e: any) => onRevert(rowData),
            },
            {
                label: "Edit",
                icon: "pi pi-pencil",
                command: (e: any) => onEdit(rowData),
            },
        ];

        return (
            <SplitButton label="" icon="pi pi-info-circle" className="p-button-outlined" model={items} onClick={() => onView(rowData)}></SplitButton>
        );
    };

    const onActionButtonClick = (rowData: TransactionReportResponse) => { };

    const renderIcon = (rowData: TransactionReportResponse) => {
        return (
            <i
                className={
                    rowData.type == TransType.D ? "pi pi-arrow-circle-left" : "pi pi-arrow-circle-right"
                }
                style={{ fontSize: "1.5em" }}
            ></i>
        );
    };

    const onView = (rowData: TransactionReportResponse) => {
        setSelectedTransaction(rowData);
        setViewTransactionDetails(true);
        setEditableTransactionDetails(false);
    };

    const onEdit = (rowData: TransactionReportResponse) => {
        setSelectedTransaction(rowData);
        setViewTransactionDetails(true);
        setEditableTransactionDetails(false);
    };

    const onApprove = (rowData: TransactionReportResponse) => {
        setIsLoading(true);

        setTimeout(() => {
            setIsLoading(false);
            toast?.show({ severity: "success", summary: "Action Completed", detail: "Approved." });
        }, 2000);
    };

    const onCancel = (rowData: TransactionReportResponse) => {
        setIsLoading(true);

        setTimeout(() => {
            setIsLoading(false);
            toast?.show({ severity: "success", summary: "Action Completed", detail: "Cancelled." });
        }, 2000);
    };

    const onRevert = (rowData: TransactionReportResponse) => {
        setIsLoading(true);

        setTimeout(() => {
            setIsLoading(false);
            toast?.show({ severity: "success", summary: "Action Completed", detail: "Reverted." });
        }, 2000);
    };

    const hideTransactionDetailsView = () => {
        setViewTransactionDetails(false);
    };

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const data = await httpService.getPendingTransactions();
            setTransactions(data);
            setIsLoading(false);
        };

        fetchData().catch(console.error);
    }, []);

    return (
        <Card className="mb-3">
            {/* {viewTransactionDetails && selectedTransaction != null && (
                <TransactionDetailsDialog
                    isVisible={true}
                    onHide={hideTransactionDetailsView}
                    transaction={selectedTransaction}
                    editable={editableTransactionDetails}
                />
            )} */}

            <Toast ref={(el) => (toast = el)} position={"bottom-center"} baseZIndex={99999}></Toast>
            <CardHeader className="card-header-tab">
                <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                    <i className="header-icon lnr-laptop-phone mr-3 text-muted opacity-6"> </i>
                    Pending Transactions
                </div>
            </CardHeader>
            <CardBody>
                <DataTable
                    value={transactions}
                    stripedRows={true}
                    paginator
                    loading={isLoading}
                    paginatorTemplate={paginatorTemplate}
                    first={first}
                    rows={rows}
                    onPage={onPageChange}
                    filters={filters}
                    filterDisplay="menu"
                    responsiveLayout="scroll"
                    paginatorClassName="p-jc-end"
                >
                    <Column field="id" header="Id" sortable />

                    <Column header="Icon" body={(rowData: TransactionReportResponse) => renderIcon(rowData)} sortable />

                    <Column
                        field="type"
                        filterField="type"
                        header="TRX Type"
                        body={(rowData: TransactionReportResponse) => <p>{rowData.type}</p>}
                        filter
                        filterElement={TransactionTypeFilterTemplate}
                        showFilterMatchModes={false}
                        sortable
                    />

                    <Column
                        field="senderEmail"
                        header="Sender"
                        filter
                        filterField="senderEmail"
                        filterElement={(e) => InputTextFilerTemplate(e, "Search by sender email")}
                        showFilterMatchModes={false}
                        sortable
                    />

                    <Column
                        field="senderName"
                        header="Sender Name"
                        filter
                        filterField="senderName"
                        filterElement={(e) => InputTextFilerTemplate(e, "Search by sender name")}
                        showFilterMatchModes={false}
                        sortable
                    />

                    <Column
                        field="senderSource"
                        header="Sender IBAN"
                        filter
                        filterField="senderSource"
                        filterElement={(e) => InputTextFilerTemplate(e, "Search by sender source")}
                        showFilterMatchModes={false}
                        sortable
                    />

                    <Column
                        field="senderMerchant"
                        header="Merchant"
                        filter
                        filterField="senderMerchant"
                        filterElement={(e) => InputTextFilerTemplate(e, "Search by sender merchant")}
                        showFilterMatchModes={false}
                        sortable
                    />

                    <Column
                        field="amount"
                        header="Amount"
                        body={(rowData: TransactionReportResponse) => (
                            <p>
                                {rowData.amount} {rowData.currency}
                            </p>
                        )}
                        sortable
                    />

                    <Column
                        field="recepientName"
                        header="Recipient"
                        filter
                        filterField="recepientName"
                        filterElement={(e) => InputTextFilerTemplate(e, "Search by recipient")}
                        showFilterMatchModes={false}
                        sortable
                    />

                    <Column
                        field="recepientDestination"
                        header="Receiver IBAN"
                        filter
                        filterField="recepientDestination"
                        filterElement={(e) => InputTextFilerTemplate(e, "Search by receiver IBAN")}
                        showFilterMatchModes={false}
                        sortable
                    />

                    <Column field="reason" header="Reason" sortable />

                    <Column
                        field="timeStamp"
                        header="TimeStamp"
                        body={(rowData: TransactionReportResponse) => <p>{moment(rowData.timeStamp).format("DD/MM/YYYY hh:mm:ss")}</p>}
                        filter
                        filterField="timeStamp"
                        filterElement={DateFilterTemplate}
                        style={{ minWidth: "12rem" }}
                        showFilterMatchModes={false}
                        sortable
                    />

                    {areOperationsAllowed() &&
                        <Column
                            header="Details"
                            body={actionBodyTemplate}
                            headerStyle={{ width: "8em", textAlign: "center" }}
                            bodyStyle={{ textAlign: "center", overflow: "visible" }}
                        />}
                </DataTable>
            </CardBody>
        </Card>
    );
};

export default PendingTransactions;
