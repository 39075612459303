import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Fieldset } from "primereact/fieldset";
import { Component } from "react";
import { Col } from "reactstrap";
import classNames from "classnames";
import { Toast } from "primereact/toast";
import ReactLoader from "react-loaders";
import { InputSwitch } from "primereact/inputswitch";
import { Merchant } from "../../models/MerchantModel";
import { merchantApiService } from "../../services/api/merchants/MerchantApiService";
import { fileService } from "../../services/FileService";
import { InputNumber } from "primereact/inputnumber";
import { utils } from "../../utils/utils";

interface ScaSectionsProps {
    merchantId: number | undefined;
    merchant: Merchant;
}

interface ScaSectionsState {
    merchantData?: Merchant | null;
    isLoading: boolean;
    [key: string]: any;
}

export default class ScaSectionsComponent extends Component<ScaSectionsProps, ScaSectionsState> {
    toast: Toast | null = null;
    constructor(props: ScaSectionsProps | Readonly<ScaSectionsProps>) {
        super(props);
        this.state = {
            merchantData: null,
            isLoading: true,
        };
    }

    async componentDidMount() {
        await this.loadMerchant();
    }

    loadMerchant = async () => {
        if (this.props.merchantId == undefined || this.props.merchantId == 0) {
            return;
        }

        const merchantResult = await merchantApiService.get(this.props.merchantId!);
        if (!merchantResult.success) {
            this.setState({ isLoading: false });
            this.toast?.show({ severity: "error", summary: "Error", detail: merchantResult.message, life: 3000 });
            return;
        }

        this.setState({ merchantData: merchantResult.data });
        this.setState({
            isLoading: false,
            merchantData: merchantResult.data,
        });
        this.loadImage();
    };
    loadImage = async () => {
        if (!this.state.merchantData || !this.state.merchantData.logo || this.state.merchantData.logo.length === 0) {
            return;
        }

        const logoResult = await fileService.GetFile(this.state.merchantData.logo);

        if (!logoResult.success) {
            this.toast?.show({
                severity: "error",
                summary: "Error",
                detail: "Could not load Merchant Logo. Please contact your administrator",
                life: 3000,
            });
            return;
        }

        const merchant = this.state.merchantData;
        merchant.logo = logoResult.data;

        this.setState({
            merchantData: merchant,
        });
    };

    handleSwitchChange = async (stateKey: keyof Merchant) => {
        const { merchantData } = this.state;

        if (!merchantData) {
            return;
        }
        this.setState((prevState) => ({ ...prevState, [stateKey]: !prevState[stateKey] }));

        let updatedMerchant: Merchant;
        if (stateKey === "scaEnabled") {
            updatedMerchant = {
                ...merchantData,
                scaEnabled: !merchantData.scaEnabled,
                scaEnabledForLogin: false,
                scaEnabledForRecurringLogin: false,
                scaEnabledForMobileNumber: false,
                scaEnabledForPaymentOperation: false,
                scaEnabledForPassword: false,
                scaEnabledForTemplatePayment: false,
                scaEnabledForPsdLogin: false,
            };
        } else if (stateKey === "scaEnabledForRecurringLogin") {
            updatedMerchant = {
                ...merchantData,
                scaEnabledForRecurringLogin: !merchantData.scaEnabledForRecurringLogin,
                scaEnabledForLogin: false,
            };
        } else if (stateKey === "scaEnabledForLogin") {
            updatedMerchant = {
                ...merchantData,
                scaEnabledForLogin: !merchantData.scaEnabledForLogin,
                scaEnabledForRecurringLogin: false,
            };
        } else {
            updatedMerchant = {
                ...merchantData,
                [stateKey]: !merchantData[stateKey],
            };
        }

        try {
            this.setState({ isLoading: true });
            const lookupResult = await merchantApiService.update(updatedMerchant);
            if (!lookupResult.success) {
                this.setState({ isLoading: false });
                this.toast?.show({ severity: "error", summary: "Error", detail: lookupResult.message, life: 3000 });
                return;
            } else {
                this.setState({
                    isLoading: false,
                    merchantData: updatedMerchant,
                });
                this.toast?.show({ severity: "success", summary: "success", detail: "SCA Updated Sucessfully", life: 3000 });
            }
        } catch (error) {
            this.setState({
                isLoading: false,
            });
            console.error("Error updating merchant:", error);
            this.toast?.show({
                severity: "error",
                summary: "Error",
                detail: "Failed to update merchant",
                life: 3000,
            });

            this.setState((prevState) => ({ ...prevState, [stateKey]: !prevState[stateKey] }));
        }
    };
    handleInputNumberChange = async (value: number, stateKey: keyof Merchant) => {
        const { merchantData } = this.state;

        if (!merchantData) {
            return;
        }

        // Construct the updated merchant object
        const updatedMerchant: Merchant = { ...merchantData };
        let saveDateKey: keyof Merchant;

        switch (stateKey) {
            case "scaEnabledDaysForLogin":
                updatedMerchant.scaEnabledDaysForLogin = value;
                saveDateKey = "lastDateForLogin";
                break;
            case "scaEnabledDaysForRecurringLogin":
                updatedMerchant.scaEnabledDaysForRecurringLogin = value;
                saveDateKey = "lastDateForRecurringLogin";
                break;
            case "scaEnabledDaysForMobileNumber":
                updatedMerchant.scaEnabledDaysForMobileNumber = value;
                saveDateKey = "lastDateForMobileNumber";
                break;
            case "scaEnabledDaysForPaymentOperation":
                updatedMerchant.scaEnabledDaysForPaymentOperation = value;
                saveDateKey = "lastDateForPaymentOperation";
                break;
            case "scaEnabledDaysForPassword":
                updatedMerchant.scaEnabledDaysForPassword = value;
                saveDateKey = "lastDateForPassword";
                break;
            case "scaEnabledDaysForTemplatePayment":
                updatedMerchant.scaEnabledDaysForTemplatePayment = value;
                saveDateKey = "lastDateForTemplatePayment";
                break;
            case "scaEnabledDaysPsdLogin":
                updatedMerchant.scaEnabledDaysForTemplatePayment = value;
                saveDateKey = "lastDateForPsdLogin";
                break;
            default:
                // No match, return without updating
                return;
        }

        // Set SaveDate property to current date and time
        updatedMerchant[saveDateKey] = utils.convertDateToUTC(new Date());

        try {
            this.setState({ isLoading: true });
            const lookupResult = await merchantApiService.update(updatedMerchant);
            if (!lookupResult.success) {
                this.setState({ isLoading: false });
                this.toast?.show({ severity: "error", summary: "Error", detail: lookupResult.message, life: 3000 });
                return;
            } else {
                this.setState({
                    isLoading: false,
                    merchantData: updatedMerchant,
                });
                this.toast?.show({ severity: "success", summary: "success", detail: "SCA Updated Sucessfully", life: 3000 });
            }
        } catch (error) {
            this.setState({
                isLoading: false,
            });
            console.error("Error updating merchant:", error);
            this.toast?.show({
                severity: "error",
                summary: "Error",
                detail: "Failed to update merchant",
                life: 3000,
            });

            this.setState((prevState) => ({ ...prevState, [stateKey]: !prevState[stateKey] }));
        }
    };

    sacOnOffTemplate = (rowData: any) => {
        return (
            <>
                <InputSwitch checked={this.state.merchantData?.scaEnabled ?? false} onChange={() => this.handleSwitchChange("scaEnabled")} />
            </>
        );
    };
    loginOnOffTemplate = (rowData: any) => {
        return (
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", padding: "5px" }}>
                <div style={{ flex: 1 }}>
                    <InputSwitch
                        disabled={!this.state.merchantData?.scaEnabled ?? false}
                        checked={this.state.merchantData?.scaEnabledForLogin}
                        onChange={() => this.handleSwitchChange("scaEnabledForLogin")}
                    />
                </div>
                <span style={{ fontSize: "12px" }}>Check Each</span>
                <InputNumber
                    tooltip="Please enter the number of days for each schedule. Set it to 0 if you want it daily."
                    tooltipOptions={{ position: "top" }}
                    disabled={!this.state.merchantData?.scaEnabled || !this.state.merchantData?.scaEnabledForLogin}
                    value={this.state.merchantData?.scaEnabledDaysForLogin ?? 0}
                    onValueChange={(e) => this.handleInputNumberChange(e.value !== null ? e.value : 0, "scaEnabledDaysForLogin")}
                    style={{ width: "2.5rem", marginLeft: "5px" }} // Added margin left for spacing
                    mode="decimal"
                    min={0}
                    max={360}
                    inputStyle={{ fontSize: "9px", width: "2.5rem" }}
                />
                <span style={{ fontSize: "12px" }}>Day</span>
            </div>
        );
    };
    PSDloginTemplate = (rowData: any) => {
        return (
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", padding: "5px" }}>
                <div style={{ flex: 1 }}>
                    <InputSwitch
                        disabled={!this.state.merchantData?.scaEnabled ?? false}
                        checked={this.state.merchantData?.scaEnabledForPsdLogin}
                        onChange={() => this.handleSwitchChange("scaEnabledForPsdLogin")}
                    />
                </div>
                <span style={{ fontSize: "12px" }}>Check Each</span>
                <InputNumber
                    tooltip="Please enter the number of days for each schedule. Set it to 0 if you want it daily."
                    tooltipOptions={{ position: "top" }}
                    disabled={!this.state.merchantData?.scaEnabled || !this.state.merchantData?.scaEnabledForPsdLogin}
                    value={this.state.merchantData?.scaEnabledDaysForLogin ?? 0}
                    onValueChange={(e) => this.handleInputNumberChange(e.value !== null ? e.value : 0, "scaEnabledDaysForLogin")}
                    style={{ width: "2.5rem", marginLeft: "5px" }} // Added margin left for spacing
                    mode="decimal"
                    min={0}
                    max={360}
                    inputStyle={{ fontSize: "9px", width: "2.5rem" }}
                />
                <span style={{ fontSize: "12px" }}>Day</span>
            </div>
        );
    };
    recurringloginOnOffTemplate = (rowData: any) => {
        return (
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", padding: "5px" }}>
                <div style={{ flex: 1 }}>
                    <InputSwitch
                        disabled={!this.state.merchantData?.scaEnabled ?? false}
                        checked={this.state.merchantData?.scaEnabledForRecurringLogin}
                        onChange={() => this.handleSwitchChange("scaEnabledForRecurringLogin")}
                    />
                </div>
                <span style={{ fontSize: "12px" }}>Check Each</span>
                <InputNumber
                    tooltip="Please enter the number of days for each schedule. Set it to 0 if you want it daily."
                    tooltipOptions={{ position: "top" }}
                    disabled={!this.state.merchantData?.scaEnabled || !this.state.merchantData?.scaEnabledForRecurringLogin}
                    value={this.state.merchantData?.scaEnabledDaysForRecurringLogin ?? 0}
                    onValueChange={(e) => this.handleInputNumberChange(e.value !== null ? e.value : 0, "scaEnabledDaysForRecurringLogin")}
                    style={{ width: "2.5rem" }}
                    mode="decimal"
                    min={0}
                    max={360}
                    inputStyle={{ fontSize: "9px", width: "2.5rem" }}
                />
                <span style={{ fontSize: "12px" }}>Day</span>
            </div>
        );
    };
    mobilenumberOnOffTemplate = (rowData: any) => {
        return (
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", padding: "5px" }}>
                <div style={{ flex: 1 }}>
                    <InputSwitch
                        disabled={!this.state.merchantData?.scaEnabled ?? false}
                        checked={this.state.merchantData?.scaEnabledForMobileNumber}
                        onChange={() => this.handleSwitchChange("scaEnabledForMobileNumber")}
                    />
                </div>
                <span style={{ fontSize: "12px" }}>Check Each</span>
                <InputNumber
                    tooltip="Please enter the number of days for each schedule. Set it to 0 if you want it daily."
                    tooltipOptions={{ position: "top" }}
                    disabled={!this.state.merchantData?.scaEnabled || !this.state.merchantData?.scaEnabledForMobileNumber}
                    value={this.state.merchantData?.scaEnabledDaysForMobileNumber ?? 0}
                    onValueChange={(e) => this.handleInputNumberChange(e.value !== null ? e.value : 0, "scaEnabledDaysForMobileNumber")}
                    style={{ width: "2.5rem" }}
                    mode="decimal"
                    min={0}
                    max={360}
                    inputStyle={{ fontSize: "9px", width: "2.5rem" }}
                />
                <span style={{ fontSize: "12px" }}>Day</span>
            </div>
        );
    };
    paymentoperationsOnOffTemplate = (rowData: any) => {
        return (
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", padding: "5px" }}>
                <div style={{ flex: 1 }}>
                    <InputSwitch
                        disabled={!this.state.merchantData?.scaEnabled ?? false}
                        checked={this.state.merchantData?.scaEnabledForPaymentOperation}
                        onChange={() => this.handleSwitchChange("scaEnabledForPaymentOperation")}
                    />
                </div>
                <span style={{ fontSize: "12px" }}>Check Each</span>
                <InputNumber
                    tooltip="Please enter the number of days for each schedule. Set it to 0 if you want it daily."
                    tooltipOptions={{ position: "top" }}
                    disabled={!this.state.merchantData?.scaEnabled || !this.state.merchantData?.scaEnabledForPaymentOperation}
                    value={this.state.merchantData?.scaEnabledDaysForPaymentOperation ?? 0}
                    onValueChange={(e) => this.handleInputNumberChange(e.value !== null ? e.value : 0, "scaEnabledDaysForPaymentOperation")}
                    style={{ width: "2.5rem" }}
                    mode="decimal"
                    min={0}
                    max={360}
                    inputStyle={{ fontSize: "9px", width: "2.5rem" }}
                />
                <span style={{ fontSize: "12px" }}>Day</span>
            </div>
        );
    };
    passwordOnOffTemplate = (rowData: any) => {
        return (
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", padding: "5px" }}>
                <div style={{ flex: 1 }}>
                    <InputSwitch
                        disabled={!this.state.merchantData?.scaEnabled ?? false}
                        checked={this.state.merchantData?.scaEnabledForPassword}
                        onChange={() => this.handleSwitchChange("scaEnabledForPassword")}
                    />
                </div>
                <span style={{ fontSize: "12px" }}>Check Each</span>
                <InputNumber
                    tooltip="Please enter the number of days for each schedule. Set it to 0 if you want it daily."
                    tooltipOptions={{ position: "top" }}
                    disabled={!this.state.merchantData?.scaEnabled || !this.state.merchantData?.scaEnabledForPassword}
                    value={this.state.merchantData?.scaEnabledDaysForPassword ?? 0}
                    onValueChange={(e) => this.handleInputNumberChange(e.value !== null ? e.value : 0, "scaEnabledDaysForPassword")}
                    style={{ width: "2.5rem" }}
                    mode="decimal"
                    min={0}
                    max={360}
                    inputStyle={{ fontSize: "9px", width: "2.5rem" }}
                />
                <span style={{ fontSize: "12px" }}>Day</span>
            </div>
        );
    };
    templatepaymentOnOffTemplate = (rowData: any) => {
        return (
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", padding: "5px" }}>
                <div style={{ flex: 1 }}>
                    <InputSwitch
                        disabled={!this.state.merchantData?.scaEnabled ?? false}
                        checked={this.state.merchantData?.scaEnabledForTemplatePayment}
                        onChange={() => this.handleSwitchChange("scaEnabledForTemplatePayment")}
                    />
                </div>
                <span style={{ fontSize: "12px" }}>Check Each</span>
                <InputNumber
                    tooltip="Please enter the number of days for each schedule. Set it to 0 if you want it daily."
                    tooltipOptions={{ position: "top" }}
                    disabled={!this.state.merchantData?.scaEnabled || !this.state.merchantData?.scaEnabledForTemplatePayment}
                    value={this.state.merchantData?.scaEnabledDaysForTemplatePayment ?? 0}
                    onValueChange={(e) => this.handleInputNumberChange(e.value !== null ? e.value : 0, "scaEnabledDaysForTemplatePayment")}
                    style={{ width: "2.5rem" }}
                    mode="decimal"
                    min={0}
                    max={360}
                    inputStyle={{ fontSize: "9px", width: "2.5rem" }}
                />
                <span style={{ fontSize: "12px" }}>Day</span>
            </div>
        );
    };

    renderBadge(success: boolean, title: string) {
        return <div className={classNames("badge", "badge-" + (success ? "success" : "danger"))}>{title}</div>;
    }

    render() {
        if (this.state.isLoading) {
            return (
                <Col md={12} lg={12}>
                    <Fieldset legend="Strong Customer Authorization" toggleable={true} collapsed={false}>
                        <ReactLoader type="ball-pulse" active />
                    </Fieldset>
                </Col>
            );
        }

        return (
            <>
                <div className="toast-container">
                    <Toast ref={(el) => (this.toast = el)} position={"bottom-center"} baseZIndex={99999}></Toast>
                </div>
                <Col md={12} lg={12}>
                    <Fieldset legend="Strong Customer Authorization" toggleable={true} collapsed={false}>
                        <DataTable value={[this.state.merchantData]} loading={this.state.isLoading}>
                            <Column field="sca" header="SCA ON/OFF" body={this.sacOnOffTemplate}></Column>
                            <Column field="login" header="Login ON/OFF" body={this.loginOnOffTemplate}></Column>
                            <Column field="recurringlogin" header="RecurringLogin ON/OFF" body={this.recurringloginOnOffTemplate}></Column>
                            <Column field="psdlogin" header="PSD Login ON/OFF" body={this.PSDloginTemplate}></Column>
                            <Column field="mobilenumber" header="MobileNumber ON/OFF" body={this.mobilenumberOnOffTemplate}></Column>
                            <Column field="paymentoperations" header="PaymentOperations ON/OFF" body={this.paymentoperationsOnOffTemplate}></Column>
                            <Column field="password" header="Password ON/OFF" body={this.passwordOnOffTemplate}></Column>
                            <Column field="templatepayment" header="TemplatePayment ON/OFF" body={this.templatepaymentOnOffTemplate}></Column>
                        </DataTable>
                    </Fieldset>
                </Col>
            </>
        );
    }
}
