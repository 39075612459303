import React, { Fragment } from "react";
import { Container } from "reactstrap";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import PageTitle from "../../Layout/AppMain/PageTitle";
import { faList } from "@fortawesome/free-solid-svg-icons";

type props = {
    children: React.ReactNode;
};

const Transactions = ({ children }: props) => {
    return (
        <Fragment>
            <TransitionGroup appear={true} exit={false} enter={false}>
                <CSSTransition classNames="TabsAnimation" timeout={1500}>
                    <Fragment>
                        <Container fluid>
                            <PageTitle heading="Operations" icon={faList} />
                            {children}
                        </Container>
                    </Fragment>
                </CSSTransition>
            </TransitionGroup>
        </Fragment>
    );
};

export default Transactions;
