import { TennEntityType } from "./MoneyTransferModal";

export interface BankTransferOutgoing {
  id: number;
  sourceEntityType: EntityType;
  sourceEntityId: number;
  sourceUserBranchCode: string;
  sourceUserCustomerName: string;
  sourceUserCustomerAddress: string;
  sourceUserCustomerNumber: string;
  sourceCustomerAccount: string;
  sourceCustomerIBAN: string;
  beneficiaryCustomerBIC: string;
  beneficiaryCustomerIBAN: string;
  beneficiaryCustomerName: string;
  amount: number;
  currency: string;
  amountBGN?: number | null;
  chargeAmount: number;
  chargeCurrency: string;
  paymentNotes?: string | null;
  status: BankTransferStatus;
  riskStatusAuto: RiskStatusesAuto;
  riskRulesInfo: string;
  riskNotes: string;
  sourceOfFundsStatus: SourceOfFundsStatus;
  scaStatus: number;
  settlementStatus: number;
  reviewStatus: ReviewStatus;
  reviewResolution: ReviewRosulutionStatuses;
  flexCubePaymentReference: string;
  createDate: Date;
  executeOrderDate?: Date | null;
  executionDate?: Date | null;
  settlementDate?: Date | null;
}

export enum SourceOfFundsStatus {
  " " = 0,
  "NotRequired" = 1,
  "Required" = 2,
  "Provided" = 3,
  "Verified" = 4
}

export enum BankTransferStatus {
  " " = 0,
  "Initiated" = 1,
  "PendingExecution" = 2,
  "Rejected" = 3,
  "Canceled" = 4,
  "Executed" = 5,
  "RejectedByBorika" = 6,
  "ReturnedToCustomer" = 7
}

export enum RiskStatusesAuto {
  " " = 0,
  "Approve" = 1,
  "Reject" = 2,
  "Review" = 3
}

export enum ReviewStatus {
  " " = 0,
  "Required" = 1,
  "RequiredStopNext" = 2,
  "Approve" = 10,
  "Reject" = 11
}

export enum ReviewRosulutionStatuses {
  " " = 0,
  "Approve" = 1,
  "Reject" = 2,
  "ReturnToCustomer" = 3
}

export enum EntityType {
  "Individual" = 1,
  "Merchant" = 2
}

export enum BankTransferResponceCode {
  OK = 0,
  RiskReject = 1,
  SourceOfFundsRequired = 2
}

export interface BankTransferReviewRequest {
  sourceEntityType: EntityType;
  sourceEntityId: number;
  transferId: number;
  reviewResolution: ReviewRosulutionStatuses;
}

export interface BankTransferResult {
  sourceEntityType: EntityType;
  sourceEntityId: number;
  transferId: number;
  responceCode: BankTransferResponceCode;
  referenceNumber: string;
}

export interface SourceOfFundsUploadRequest {
  sourceEntityType: EntityType;
  sourceEntityId: number;
  transferId: number;
  fileName: string;
  fileData: string;
}


export interface BankTransferRequestModel {
	sourceEntityType: TennEntityType;
	sourceEntityId: number;
	transferId: number;
}

export interface AddBankTransferOutgoing {
  prop: string
}

export interface BankTransferRequest {
  sourceEntityType: EntityType;
  sourceEntityId: number;
  transferId: number;
}

export enum DocumentStatus {
  "New" = 0,
  "Approved" = 1,
  "Declined" = 2
}

export interface BankTransferSourceOfFunds {
  id: number | null;
  bankTransferId: number | null;
  entityType: EntityType | null;
  entityId: number | null;
  fileName: string;
  fileStorageId: string;
  status: DocumentStatus;
  reviewComments: string;
  uploadDate: Date | null;
  file: File | null;
  documentType: string;
  isUploaded: boolean;
  isModified: boolean;
  objectURL: string | null;
}
