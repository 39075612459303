import React, { Component } from "react";
import { classNames, DomHandler, ObjectUtils, ZIndexUtils } from "primereact/utils";
import PrimeReact from "primereact/api";
import { Portal } from "./Portal";
import { CSSTransition } from "react-transition-group";
import { Toast } from "primereact/toast";
import Loader from "../Loader";

export interface ImageProps {
    preview?: boolean;
    className?: string;
    downloadable?: boolean;
    style?: string;
    imageStyle?: string;
    imageClassName?: string;
    template?: any;
    src?: string;
    alt?: string;
    width?: string;
    height?: string;
    onShow?(): void;
    onHide?(): void;
    onPreview: () => Promise<string>;
}

export interface ImageState {
    rotate : number,
    maskVisible: boolean,
    previewVisible: boolean,
    scale: number,
    source?: string,
    isLoading: boolean
}

export class ExtendedImage extends Component<ImageProps, ImageState> {
    toast?: Toast;
    previewRef: React.RefObject<any>;
    previewClick: boolean | undefined;
    mask: HTMLDivElement | null = null;
    container: HTMLSpanElement | null = null;

    constructor(props: ImageProps | Readonly<ImageProps>) {
        super(props);
        this.state = {
            maskVisible: false,
            previewVisible: false,
            rotate: 0,
            scale: 1,
            source: "",
            isLoading: false
        };

        this.onImageClick = this.onImageClick.bind(this);
        this.onMaskClick = this.onMaskClick.bind(this);
        this.onDownload = this.onDownload.bind(this);
        this.rotateRight = this.rotateRight.bind(this);
        this.rotateLeft = this.rotateLeft.bind(this);
        this.zoomIn = this.zoomIn.bind(this);
        this.zoomOut = this.zoomOut.bind(this);
        this.onEntering = this.onEntering.bind(this);
        this.onEntered = this.onEntered.bind(this);
        this.onPreviewImageClick = this.onPreviewImageClick.bind(this);
        this.onExit = this.onExit.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);

        this.previewRef = React.createRef();
    }

    onImageClick = async () => {

        if (this.props.preview) {
            this.setState({ maskVisible: true });
            setTimeout(() => {
                this.setState({ previewVisible: true });
            }, 25);
        }
    };

    onPreviewImageClick = () => {
        this.previewClick = true;
    };

    onMaskClick = () => {
        if (!this.previewClick) {
            this.setState({ previewVisible: false });
            this.setState({ rotate: 0 });
            this.setState({ scale: 1 });
        }

        this.previewClick = false;
    };

    onDownload = () => {
        const { alt: name } = this.props;
        const source = this.state.source as any;
        DomHandler.saveAs({ name: name ?? "", url: source });
        this.previewClick = true;
    };

    rotateRight = () => {
        this.setState((prevState) => ({
            rotate: prevState.rotate + 90
        }));

        this.previewClick = true;
    };

    rotateLeft = () => {
        this.setState((prevState) => ({
            rotate: prevState.rotate - 90
        }));

        this.previewClick = true;
    };

    zoomIn = () => {
        this.setState((prevState) => ({
            scale: prevState.scale + 0.1
        }));

        this.previewClick = true;
    };

    zoomOut = () => {
        this.setState((prevState) => ({
            scale: prevState.scale - 0.1
        }));

        this.previewClick = true;
    };

    onEntering = () => {
        (ZIndexUtils as any).set("modal", this.mask, PrimeReact.autoZIndex, PrimeReact.zIndex!["modal"] );
    };

    onEntered = () => {
        if (this.props.onShow) {
            this.props.onShow();
        }
    };

    onExit = () => {
        DomHandler.addClass(this.mask!, "p-component-overlay-leave");
    };

    onExiting = () => {
        if (this.props.onHide) {
            this.props.onHide();
        }
    };

    onExited = (el: HTMLElement) => {
        ZIndexUtils.clear(el);

        this.setState({ maskVisible: false });
    };

    hidePreview = () => {

    };

    componentWillUnmount = () => {
        if (this.mask) {
            ZIndexUtils.clear(this.container!);
        }
    };

    runOnPreviewCallBack = async () => {
        this.setState({ isLoading: true });

        const source = await this.props.onPreview();

        this.setState({ isLoading: false, source: source });
    };

    loadImage = () => {
        
        if(this.state.isLoading) {
            return <Loader />;
        }

        if(this.state.source == null || this.state.source.length == 0) {
            this.runOnPreviewCallBack();
            return "";
        }

        const imagePreviewStyle = { transform: "rotate(" + this.state.rotate + "deg) scale(" + this.state.scale + ")" };

        return <img src={this.state.source} className="p-image-preview" style={imagePreviewStyle} onClick={this.onPreviewImageClick} alt={this.props.alt} />;
    };

    renderElement = () => {
        const { downloadable } = this.props;
        const zoomDisabled = this.state.scale <= 0.5 || this.state.scale >= 1.5;
        // const rotateClassName = 'p-image-preview-rotate-' + this.state.rotate;

        return (
            <div ref={(el) => this.mask = el} className="p-image-mask p-component-overlay p-component-overlay-enter" onClick={this.onMaskClick}>
                <div className="p-image-toolbar">
                    {
                        downloadable && (
                            <button className="p-image-action p-link" onClick={this.onDownload} type="button">
                                <i className="pi pi-download"></i>
                            </button>
                        )
                    }
                    <button className="p-image-action p-link" onClick={this.rotateRight} type="button">
                        <i className="pi pi-refresh"></i>
                    </button>
                    <button className="p-image-action p-link" onClick={this.rotateLeft} type="button">
                        <i className="pi pi-undo"></i>
                    </button>
                    <button className="p-image-action p-link" onClick={this.zoomOut} type="button" disabled={zoomDisabled}>
                        <i className="pi pi-search-minus"></i>
                    </button>
                    <button className="p-image-action p-link" onClick={this.zoomIn} type="button" disabled={zoomDisabled}>
                        <i className="pi pi-search-plus"></i>
                    </button>
                    <button className="p-image-action p-link" type="button" onClick={this.hidePreview}>
                        <i className="pi pi-times"></i>
                    </button>
                </div>
                <CSSTransition nodeRef={this.previewRef} classNames="p-image-preview" in={this.state.previewVisible} timeout={{ enter: 150, exit: 150 }}
                    unmountOnExit onEntering={this.onEntering} onEntered={this.onEntered} onExit={this.onExit} onExiting={this.onExiting} onExited={this.onExited}>
                    <div ref={this.previewRef}>
                        {this.loadImage()}
                    </div>
                </CSSTransition>
            </div>
        );
    };

    render() {
        const containerClassName = classNames("p-image p-component", this.props.className, {
            "p-image-preview-container": this.props.preview
        });
        const content = this.props.template ? ObjectUtils.getJSXElement(this.props.template, this.props) : <i className="p-image-preview-icon pi pi-eye"></i>;

        const { alt, width, height } = this.props;

        return (
            
            <span ref={(el) => this.container = el} className={containerClassName} style={this.props.style as any}>
                <img src="/document.png" className={this.props.imageClassName} width={width} height={height} style={this.props.imageStyle as any} alt={alt} />
                {
                    this.props.preview && <div className="p-image-preview-indicator" onClick={this.onImageClick} >
                        {content}
                    </div>
                }

                {this.state.maskVisible && <Portal element={this.renderElement()} appendTo={document.body} />}
            </span>

        );

    }
}