import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Fieldset } from "primereact/fieldset";
import { Component } from "react";
import { Col } from "reactstrap";
import { Toast } from "primereact/toast";
import ReactLoader from "react-loaders";
import { InputSwitch } from "primereact/inputswitch";
import { clientApiService } from "../../services/api/clients/ClientApiService";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";

interface MerchantClientProps {
    merchantId: number | undefined;
}

interface MerchantClientState {
    hasPortalAccess: boolean;
    hasApiAccess: boolean;
    isLoading: boolean;
    showCertificateDialog: boolean;
    certificateValue: string;
    certificateErrorMessage: string;
}

export default class MerchantClientComponent extends Component<MerchantClientProps, MerchantClientState> {
    toast: Toast | null = null;
    constructor(props: MerchantClientProps | Readonly<MerchantClientProps>) {
        super(props);
        this.state = {
            hasPortalAccess: false,
            hasApiAccess: false,
            isLoading: true,
            showCertificateDialog: false,
            certificateValue: "",
            certificateErrorMessage: "",
        };
    }
    certificate = "tumb";

    async componentDidMount() {
        await this.loadInitialMerchantPortalAccess();
    }
    reject = () => {
        this.toast?.show({ severity: "warn", summary: "Rejected", detail: "You have rejected", life: 3000 });
    };
    loadInitialMerchantPortalAccess = async () => {
        try {
            this.setState({ isLoading: true });
            const merchantResult = await clientApiService.GetMerchantAccess({
                merchantId: this.props.merchantId!,
            });
            if (!merchantResult.success) {
                this.setState({ isLoading: false });
                this.toast?.show({ severity: "error", summary: "Error", detail: merchantResult.message, life: 3000 });
                return;
            }
            this.setState({ hasPortalAccess: merchantResult.data.hasPortalAccess, hasApiAccess: merchantResult.data.hasApiAccess, isLoading: false });
        } catch (error) {
            this.setState({ isLoading: false });
        }
    };

    handlePortalAccessChange = async (e: { value: boolean }) => {
        try {
            if (e.value == true) {
                this.setState({ isLoading: true });
                const merchantResult = await clientApiService.AddMerchantPortalAccess({
                    merchantId: this.props.merchantId!,
                });

                if (!merchantResult.success) {
                    this.setState({ isLoading: false });
                    this.toast?.show({ severity: "error", summary: "Error", detail: merchantResult.message, life: 3000 });
                    return;
                }
                this.setState({ hasPortalAccess: e.value, isLoading: false });
                window.location.reload();
            } else {
                this.setState({ isLoading: true });
                const merchantResult = await clientApiService.RemoveMerchantPortalAccess(this.props.merchantId!);

                if (!merchantResult.success) {
                    this.setState({ isLoading: false });
                    this.toast?.show({ severity: "error", summary: "Error", detail: merchantResult.message, life: 3000 });
                    return;
                }
                this.setState({ hasPortalAccess: e.value, isLoading: false });
                window.location.reload();
            }

            // Handle success case if necessary
        } catch (error) {
            this.setState({ isLoading: false });
        }
    };
    removeMerchantApiAccess = async () => {
        this.setState({ isLoading: true });

        const merchantResult = await clientApiService.RemoveMerchantApiAccess(this.props.merchantId!);

        if (!merchantResult.success) {
            this.setState({ isLoading: false });
            this.toast?.show({ severity: "error", summary: "Error", detail: merchantResult.message, life: 3000 });
            return;
        } else {
            this.setState({ hasApiAccess: false, isLoading: false });
        }
        this.setState({ isLoading: false });
        window.location.reload();
    };
    handleAPIAccessChange = async (e: { value: boolean }) => {
        try {
            if (e.value == true) {
                this.setState({ showCertificateDialog: true, certificateErrorMessage: "" });
            } else {
                confirmDialog({
                    message: "Are you sure you want to remove the merchant API access right? Once you remove it, old clients will be deleted.",
                    header: "Confirmation",
                    icon: "pi pi-exclamation-triangle",
                    accept: () => {
                        this.removeMerchantApiAccess();
                    },
                    reject: this.reject,
                });
            }
        } catch (error) {
            this.setState({ isLoading: false });
        }
    };
    titleTemplate = (rowData: any) => {
        return (
            <>
                <h6>Merchant has Access to: </h6>
            </>
        );
    };
    ApiTemplate = (rowData: any) => {
        return (
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", padding: "5px" }}>
                <div style={{ flex: 1 }}>
                    <InputSwitch checked={this.state.hasApiAccess} onChange={this.handleAPIAccessChange} />
                </div>
            </div>
        );
    };
    merchantPortalTemplate = (rowData: any) => {
        return (
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", padding: "5px" }}>
                <div style={{ flex: 1 }}>
                    <InputSwitch checked={this.state.hasPortalAccess} onChange={this.handlePortalAccessChange} />
                </div>
            </div>
        );
    };
    handleCertificateSubmit = async () => {
        try {
            if (this.state.certificateValue) {
                this.setState({ isLoading: true });
                const merchantResult = await clientApiService.AddMerchantApiAccess({
                    merchantId: this.props.merchantId!,
                    certificate: this.state.certificateValue,
                });

                if (!merchantResult.success) {
                    this.setState({ isLoading: false, certificateErrorMessage: merchantResult.message });
                    this.toast?.show({ severity: "error", summary: "Error", detail: merchantResult.message, life: 3000 });
                    setTimeout(() => {
                        this.setState({ certificateErrorMessage: "" });
                    }, 3000);
                    return;
                }
                this.setState({ hasApiAccess: true });
                window.location.reload();
            }

            this.setState({ showCertificateDialog: false, isLoading: false, certificateValue: "" });
        } catch (error) {
            this.setState({ showCertificateDialog: false, isLoading: false, certificateValue: "" });
        }
    };
    render() {
        if (this.state.isLoading) {
            return (
                <Col md={12} lg={12}>
                    <Fieldset legend="Change Merchant Client" toggleable={true} collapsed={false}>
                        <ReactLoader type="ball-pulse" active />
                    </Fieldset>
                </Col>
            );
        }

        return (
            <>
                <Dialog
                    header="Please Provide Certificate"
                    visible={this.state.showCertificateDialog}
                    style={{ width: "50vw" }}
                    onHide={() => {
                        if (!this.state.showCertificateDialog) return;
                        this.setState({ showCertificateDialog: false, certificateErrorMessage: "" });
                    }}
                >
                    <div>
                        <InputTextarea
                            autoResize
                            value={this.state.certificateValue}
                            onChange={(e) => this.setState({ certificateValue: e.target.value })}
                            rows={15}
                            cols={90}
                        />
                        <div style={{ textAlign: "right", marginTop: "1rem" }}>
                            <Button label="Submit" onClick={this.handleCertificateSubmit} />
                        </div>
                        {this.state.certificateErrorMessage && (
                            <div style={{ textAlign: "left", marginTop: "1rem", color: "red" }}>
                                <label>Error : {this.state.certificateErrorMessage}</label>
                            </div>
                        )}
                    </div>
                </Dialog>
                <div className="toast-container">
                    <Toast ref={(el) => (this.toast = el)} position={"bottom-right"} baseZIndex={99999}></Toast>
                </div>
                <Col md={12} lg={12}>
                    <Fieldset legend="Change Merchant Access" toggleable={true} collapsed={false}>
                        <DataTable value={["ok"]} loading={this.state.isLoading}>
                            <Column field="title" header="Title" body={this.titleTemplate}></Column>
                            <Column field="merchantPortal" header="MerchantPortal" body={this.merchantPortalTemplate}></Column>
                            <Column field="api" header="API Access" body={this.ApiTemplate}></Column>
                        </DataTable>
                    </Fieldset>
                </Col>
            </>
        );
    }
}
