import { Dialog } from "primereact/dialog";
import React, { Component, Fragment } from "react";
import { Col, Container, FormGroup, Label, Row } from "reactstrap";
import { Button } from "primereact/button";
import "../Merchants/tariffDetails.scss";
import { CSSTransitionComponent } from "../../components/CSSTransition";
import { Toast } from "primereact/toast";
import Loader from "../../components/Loader";
import { KycSubmissionComment } from "../../models/KycSubmission";

interface AddKycCommentProps {
    onHide: () => void;
     comment: KycSubmissionComment;
     onCommentUpdate: (comment: KycSubmissionComment) => void;
}

interface AddKycCommentState {
    comment: KycSubmissionComment;
    isLoading: boolean;
}

export default class AddKycComment extends Component<AddKycCommentProps, AddKycCommentState> {
    toast: Toast | null = null;
    validationErrors: Map<string, boolean> = new Map<string, boolean>();
    constructor(props: AddKycCommentProps | Readonly<AddKycCommentProps>) {
        super(props);

        this.state = {
            comment: this.props.comment,
            isLoading: false,
        };
    }

    updateValue = (fieldName: string, value: string) => {
        const kycComment : any = this.state.comment;
        kycComment[fieldName] = value;
        this.setState({ comment: kycComment });
    };

    onSave = () => {
        this.props.onCommentUpdate(this.state.comment);
    };

    render() {
        if (this.state.comment == null) {
            return (
                <>
                    <Toast ref={(el) => (this.toast = el)} />
                    <Loader />;
                </>
            );
        }
        return (
            <Dialog header="KYC Comment" visible={true} style={{ width: "60vw" }} onHide={this.props.onHide}>
                <CSSTransitionComponent>
                    <Fragment>
                        <Toast ref={(el) => (this.toast = el)} />
                        <Container fluid>
                            <Row className="d-flex justify-content-center">
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>Comment</Label>
                                        <br />
                                        <textarea
                                            rows={4}
                                            cols={50}                                
                                            value={this.state.comment?.comment}
                                            onChange={(e) => this.updateValue("comment", e.target.value)}
                                        ></textarea>
                                              
                                    </FormGroup>
                                </Col>
                            </Row>
                            <div className="align-right" style={{ padding: "10px" }}>
                                <Button label="Save" icon="pi pi-check" loading={this.state.isLoading} onClick={() => {this.onSave(); this.props.onHide();}} />
                            </div>
                        </Container>
                    </Fragment>
                </CSSTransitionComponent>
            </Dialog>
        );
    }
}