import React from "react";
import { Container, Row, Col, Label, FormGroup } from "reactstrap";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { SelectItem } from "../../models/SelectItem";
import { Button } from "primereact/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { paymentService } from "../../services/PaymentService";
import { CardRequest } from "../../models/EWalletRequest";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons";

interface DepositViaEPayProps {
    OnBack: () => void;
    OnSuccess: (amount: number, selectedItem: SelectItem) => void;
}

interface DepositViaEPayState {
    entityType: number,
    entityId: number,
    accountNumber: string,
    description: string,
    amount: number,
    currencyCode: string,
    transactionCharge: number,
    chargeCurrency: string,
    language: string,
    eWalletResponse: any
    redirectToPaymentUrl: string;
}

export class DepositViaEPay extends React.Component<DepositViaEPayProps, DepositViaEPayState> {

    formRef: any;
    languages: SelectItem[] = [
        { label: "en", value: "en" },
        { label: "bg", value: "bg" }
    ];
    
    entityTypes: SelectItem[] = [
        { label: "Individual", value: "1" },
        { label: "Merchant", value: "2" }
    ];

    currencies: SelectItem[] = [
        { label: "BGN", value: "BGN" }
    ];

    constructor(props: any) {
        super(props);

        this.state = {
            entityType: 1,
            entityId: 0,
            accountNumber: "",
            description: "Payment operation description",
            amount: 0,
            currencyCode: "BGN",
            transactionCharge: 0,
            chargeCurrency: "BGN",
            language: "en",
            eWalletResponse: null!,
            redirectToPaymentUrl: "",
        };
    }

    OnSuccess = async (amount: number) => {
        const payment: CardRequest = {
            paymentProvider: 50, //Epay = 50
            paymentOperation: 1, // Funding = 1, Withdrawal = 2, FundingReversal = 3,Withdrawaleversal = 4
            entityType: this.state.entityType, //1=Individual, 2=Merchant
            entityId: this.state.entityId, //IndividualId or MerchantId   
            accountNumber: this.state.accountNumber, //Individual or Merchant Account number
            description: this.state.description, // Let Ask Radko what will be the description. It may come from the user or be a const.
            amount: this.state.amount, // User enters the amount. From card is withdrawed "amount+charge". Notify user how much will be withdrawed from the card!!
            currencyCode: this.state.currencyCode, // Read it from CRM via API: IndividualPayment/get-money-transfer-charge -> transactionCurrency 
            transactionCharge: this.state.transactionCharge, // Read it from CRM via API: IndividualPayment/get-money-transfer-charge -> transactionFee
            chargeCurrency: this.state.chargeCurrency,  // Read it from CRM via API: IndividualPayment/get-money-transfer-charge -> transactionFeeCurrency
            customerEmail: "", //empty is fine. Used in DSK payment. Not in ePay
            language: this.state.language, //en bg
            returnUrl: window.location.href,
            cancelUrl: window.location.href,
            failUrl: window.location.href //''
        };   

        try {
            const payload = await paymentService.GetCardPayload(payment);
            this.setState({ eWalletResponse: JSON.parse(payload.data.data), redirectToPaymentUrl: payload.data.redirectToPaymentUrl });
            this.formRef.submit();
        } catch (error) {
            console.error(error);
        }
    };

    render(): React.ReactNode {
        return (<Container fluid>
            <FontAwesomeIcon size="5x" icon={faCreditCard as any} className="text-info" style={{ margin: "auto", display: "block" }} />
            <Row>
                <Col>
                    <FormGroup>
                        <Label>Entity type</Label>
                        <Dropdown
                            style={{ width: "100%" }}
                            value={this.state.entityType}
                            options={this.entityTypes}
                            onChange={(e) => {this.setState({ entityType: e.value });}}
                            appendTo={document.body}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label>Entity Id</Label>
                        <InputText
                            value={this.state.entityId}
                            min={0}
                            type="number"
                            onChange={(event) => {const parsed = parseInt(event.target.value); if(!isNaN(parsed)) {this.setState({ entityId: parsed });}}}
                            className={"form-control"}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label>Account Number</Label>
                        <InputText 
                            value={this.state.accountNumber} 
                            onChange={(e) => this.setState({accountNumber: e.target.value})} 
                            className={"form-control"} />
                    </FormGroup>

                    <FormGroup>
                        <Label>Description</Label>
                        <InputText 
                            value={this.state.description} 
                            onChange={(e) => this.setState({description: e.target.value})} 
                            className={"form-control"} />
                    </FormGroup>

                    <FormGroup>
                        <Label>Amount</Label>
                        <InputText
                            value={this.state.amount}
                            min={0}
                            type="number"
                            onChange={(event) => {const parsed = parseInt(event.target.value); if(!isNaN(parsed)) {this.setState({ amount: parsed });}}}
                            className={"form-control"}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label>Currency code</Label>
                        {/* <Dropdown
                            style={{ width: "100%" }}
                            value={this.state.currencyCode}
                            options={this.currencies}
                            onChange={(e) => {this.setState({ currencyCode: e.value });}}
                            appendTo={document.body}
                        /> */}
                        <InputText 
                            value={this.state.currencyCode} 
                            onChange={(e) => this.setState({currencyCode: e.target.value})} 
                            className={"form-control"} />                        
                    </FormGroup>


                    <FormGroup>
                        <Label>Transaction Charge</Label>
                        <InputText
                            value={this.state.transactionCharge}
                            min={0}
                            type="number"
                            onChange={(event) => {const parsed = parseInt(event.target.value); if(!isNaN(parsed)) {this.setState({ transactionCharge: parsed });}}}
                            className={"form-control"}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label>Charge Currency code</Label>
                        {/* <Dropdown
                            style={{ width: "100%" }}
                            value={this.state.chargeCurrency}
                            options={this.currencies}
                            onChange={(e) => {this.setState({ chargeCurrency: e.value });}}
                            appendTo={document.body}
                        /> */}
                        <InputText 
                            value={this.state.chargeCurrency} 
                            onChange={(e) => this.setState({chargeCurrency: e.target.value})} 
                            className={"form-control"} />                        
                    </FormGroup>

                    <FormGroup>
                        <Label>Language</Label>
                        <Dropdown
                            style={{ width: "100%" }}
                            value={this.state.language}
                            options={this.languages}
                            onChange={(e) => {this.setState({ language: e.value });}}
                            appendTo={document.body}
                        />
                    </FormGroup>

                </Col>
            </Row>
            <Row>
                <Col>
                    <Button
                        style={{ width: "100%" }}
                        label="Back"
                        type="button"
                        onClick={() => this.props.OnBack()}
                        className="p-button-outlined"
                    ></Button>
                </Col>
                <Col>
                    <Button
                        style={{ width: "100%" }}
                        label="Deposit"
                        type="button"
                        onClick={() => this.OnSuccess(this.state.amount)}
                        className="p-button-success"
                    ></Button>
                </Col>
                <div hidden={true}>
                    {this.state.eWalletResponse && <form ref={(ev) => this.formRef = ev} action={this.state.redirectToPaymentUrl} method="POST">
                        <input type="hidden" name="PAGE" value={this.state.eWalletResponse["PAGE"]}></input>
                        <input type="hidden" name="ENCODED" value={this.state.eWalletResponse["ENCODED"]}></input>
                        <input type="hidden" name="CHECKSUM" value={this.state.eWalletResponse["CHECKSUM"]}></input>
                        <input type="hidden" name="URL_OK" value={this.state.eWalletResponse["URL_OK"]}></input>
                        <input type="hidden" name="URL_CANCEL" value={this.state.eWalletResponse["URL_CANCEL"]}></input>
                        <input type="submit"></input>
                    </form>}
                </div>
            </Row>
        </Container>);
    }
}