import { Calendar } from "primereact/calendar";
import { ColumnFilterElementTemplateOptions } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import TransactionStatusHelper from "./TransactionStatusHelper";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import React from "react";
import { PaymentStatus } from "../../models/Transactions";

export const StatusFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (
        <Dropdown
            value={options.value}
            options={TransactionStatusHelper.Statuses}
            onChange={(e) => options.filterApplyCallback(e.value)}
            placeholder="Select a Status"
            className="p-column-filter"
            showClear
        />
    );
};

export const InputTextFilerTemplate = (options: ColumnFilterElementTemplateOptions, placeholder: string | null = null) => {
    return (
        <InputText
            value={options.value??""}
            onChange={(e) => options.filterApplyCallback(e.target.value)}
            placeholder={placeholder??"Enter search text"}
            className="p-column-filter"
        />
    );
};

export const TransactionTypeFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (
        <Dropdown
            value={options.value}
            options={TransactionStatusHelper.TransactionTypes}
            onChange={(e) => options.filterApplyCallback(e.value)}
            placeholder="Select a TRX Type"
            className="p-column-filter"
            showClear
        />
    );
};

export const DateFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (
        <Calendar
            value={options.value}
            onChange={(e) => options.filterApplyCallback(e.value)}
            dateFormat="dd/mm/yy"
            placeholder="dd/mm/yyyy"
            mask="99/99/9999"
        />
    );
};

export const RenderStatusBadge = (status: string) => {
    const className = TransactionStatusHelper.ClassesMap.get(status);
    return <div className={classNames("badge", "badge-" + className)}>{status}</div>;
};

export const RenderPaymentStatusBadge = (status: PaymentStatus) => {
    const className = TransactionStatusHelper.PaymentStatusClassesMap.get(status);
    return <div className={classNames("badge", "badge-" + className)}>{PaymentStatus[status]}</div>;
};

type AccountBodyProps = {
    onClickAction: (data: any) => void,
    data: any
}

export function ActionBodyTemplate({ onClickAction, data}: AccountBodyProps) {
    return (
        <React.Fragment>
            <Button icon="pi pi-info" className="p-button-rounded" onClick={() => onClickAction(data)} />
        </React.Fragment>
    );
}

export const TransactionSystemFilterTemplate = (options: ColumnFilterElementTemplateOptions) => { 
    return (
        <InputText
            value={options.value}
            onChange={(e) => options.filterCallback(e.target.value.toUpperCase(), options.index)}
            placeholder={"Search by Payment System"}
            className="p-column-filter"
        />
    );
};