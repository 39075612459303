import { Dialog } from "primereact/dialog";
import React, { Fragment } from "react";
import { CreateCardPaymentMethodRequest, CreateIbanPaymentMethodRequest, CreateNonIbanPaymentMethodRequest, MerchantPaymentMethod } from "../../models/MerchantModel";
import { DepositViaBank } from "./DepositViaBank";
import { DepositViaCard } from "./DepositViaCard";
import { Button } from "primereact/button";
import { DepositPaymentMethodType, DepositSelectPaymentMethod } from "./DepositSelectPaymentMethod";
import { DepositViaEPay } from "./DepositViaEPay";
import { DepositViaNonBank } from "./DepositViaNonBank";
import { Container } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactLoader from "react-loaders";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

interface DepositViaPaymentMethodDialogProps{
    onHide: () => void;
    onSuccess:(item: CreateCardPaymentMethodRequest | CreateIbanPaymentMethodRequest | CreateNonIbanPaymentMethodRequest) => void;
}

interface DepositViaPaymentMethodDialogState {
    paymentMethod: DepositPaymentMethodType | null
    currentStage: CurrentStage
}

enum CurrentStage {
    PaymentMethod,
    EnterDetails,
    Success,
    Failed,
    Loading,
}

export class DepositViaPaymentMethodDialog extends React.Component<DepositViaPaymentMethodDialogProps, DepositViaPaymentMethodDialogState>{

    constructor(props: DepositViaPaymentMethodDialogProps | Readonly<DepositViaPaymentMethodDialogProps>){
        super(props);
        this.state = {
            paymentMethod: null,
            currentStage: CurrentStage.PaymentMethod
        };
    }

    async componentDidMount() {
    }

    selectPaymentMethod = (type: DepositPaymentMethodType | null) => {
        this.setState({ paymentMethod: type, currentStage: CurrentStage.EnterDetails });
    };

    onBack = () => {
        this.setState({ paymentMethod: null, currentStage: CurrentStage.PaymentMethod });
    };

    onSuccess = (amount: number, selectedItem: MerchantPaymentMethod | undefined) => {
        // TODO. Add actual api here
        this.setState({ currentStage: CurrentStage.Success });
    };

    renderBody = () => {
        if (this.state.currentStage === CurrentStage.Loading) {
            return <ReactLoader type="ball-pulse" active />;
        }

        if (this.state.currentStage === CurrentStage.PaymentMethod) {
            return <DepositSelectPaymentMethod OnSelect={(type) => { this.selectPaymentMethod(type); }} showEPay={true} />;
        }

        if (this.state.currentStage === CurrentStage.EnterDetails && this.state.paymentMethod === DepositPaymentMethodType.Card) {
            return <DepositViaCard OnSuccess={this.onSuccess}/>;
        }

        if (this.state.currentStage === CurrentStage.EnterDetails && this.state.paymentMethod === DepositPaymentMethodType.Bank) {
            return <DepositViaBank OnSuccess={this.onSuccess}/>;
        }

        if (this.state.currentStage === CurrentStage.EnterDetails && this.state.paymentMethod === DepositPaymentMethodType.NonIBAN) {
            return <DepositViaNonBank OnSuccess={this.onSuccess}/>;
        }

        if (this.state.currentStage === CurrentStage.Success) {
            return (<Container>
                <FontAwesomeIcon size="5x" icon={faCheck as any} className="text-success" style={{ margin: "auto", display: "block" }} />
                <div className="align-center">Success!. Your money is on the way</div>
            </Container>);
        }

        if (this.state.paymentMethod == DepositPaymentMethodType.EPay) {
            return <DepositViaEPay OnBack={this.onBack} OnSuccess={() => {}} />;    
        }

        return "";
    };

    renderHeader = () => {
        if(this.state.currentStage != CurrentStage.PaymentMethod && this.state.currentStage != CurrentStage.Success && this.state.currentStage != CurrentStage.Failed) {
            return <Button icon="pi pi-arrow-left" className="p-button-rounded p-button-outlined p-button-outlined p-dialog-header-icon" aria-label="Cancel" onClick={() => this.onBack()} />;
        }

        return "Deposit";
    };

    render() {
        return (
            <Dialog header={this.renderHeader()} visible={true} style={{ width: "60vw" }} onHide={this.props.onHide}>
                <Fragment>
                    {this.renderBody()}
                </Fragment>
            </Dialog>
        );
    }
}