import React from "react";
import { Container, Row, Col, Card } from "reactstrap";
import { HoveredWidget } from "../../components/HoveredWidget";
import { faCreditCard, faMoneyCheck } from "@fortawesome/free-solid-svg-icons";

export enum PaymentMethodType {
    Bank,
    Card,
    NonIBAN
}

interface SelectPaymentMethodProps {
    OnSelect: (type: PaymentMethodType) => void;
}

export class SelectPaymentMethod extends React.Component<SelectPaymentMethodProps, any> {
    
    selectPaymentType = (type: PaymentMethodType) => {
        this.props.OnSelect(type);
    };

    render(): React.ReactNode {
        return (<Container fluid className="short-widget-numbers">
            <Row>
                <Col>
                    <Card className="main-card mb-3">
                        <div className="grid-menu grid-menu-2col">
                            <Row className="no-gutters">
                                {/* <Col>
                                    <HoveredWidget textClass="info" small={true} icon={faCreditCard} description="" title="Add Debit/Credit Card" onClick={() => this.selectPaymentType(PaymentMethodType.Card)} />
                                </Col> */}
                                <Col>
                                    <HoveredWidget textClass="info" small={true} icon={faMoneyCheck} description="" title="Add Bank Account" onClick={() => this.selectPaymentType(PaymentMethodType.Bank)} />
                                </Col>
                                <Col>
                                    <HoveredWidget textClass="info" small={true} icon={faCreditCard} description="" title="Add Non-IBAN" onClick={() => this.selectPaymentType(PaymentMethodType.NonIBAN)} />
                                </Col>
                            </Row>
                        </div>
                    </Card>
                </Col>
            </Row>
        </Container>);
    }
}