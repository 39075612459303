import sideBar6 from "./assets/utils/images/sidebar/city1.jpg";
const ThemeOptions = {
    backgroundColor: "",
    headerBackgroundColor: "",
    enableMobileMenuSmall: "",
    enableClosedSidebar: false,
    enableFixedHeader: true,
    enableHeaderShadow: true,
    enableSidebarShadow: true,
    enableFixedFooter: true,
    enableFixedSidebar: true,
    colorScheme: "white",
    enablePageTitleIcon: true,
    enablePageTitleSubheading: true,
    enablePageTabsAlt: true,
};

export { ThemeOptions };