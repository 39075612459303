import React, { Component } from "react";
import cx from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";

interface PageTitleProps {
    heading: string,
    icon: IconDefinition,
    subheading?: string | null,
}

class PageTitle extends Component<PageTitleProps, any> {

    render() {
        const {
            heading,
            icon,
            subheading,
        } = this.props;

        return (
            <div className="app-page-title">
                <div className="page-title-wrapper">
                    <div className="page-title-heading">
                        <div className={cx("page-title-icon")}>
                            <FontAwesomeIcon icon={icon as unknown as IconProp} color="#3366ff" />
                        </div>
                        <div>
                            {heading}
                            <div className={cx("page-title-subheading")}>
                                {subheading}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PageTitle;
