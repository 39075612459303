import { Comments } from "./Comments";
export interface KycSubmission {
    id: number,
    endUserFirstName: string,
    endUserLastName: string,
    endUserEmail: string,
    endUserPhone: string,
    status: number,
    submissionTimeStamp: Date | null,
    reviewerFirstName: string | null
    reviewerLastName: string | null
    reason: string | null
    reviewStartedTimeStamp : Date | null 
    reviewCompletedTimeStamp : Date | null
    documentsCount: number,
    merchantName:string;

}

export interface AddKycDocument {
    submissionId: number,
    fileName: string,
    fileData: string,
    type: string ,
}


export interface DocumentFile {
    file: File | null;
    documentType: string;
    isUploaded: boolean;
    objectURL: string | null;
    fileName: string;
    fileStorageId: string;
}


export class KycSubmissionDocument {
    fileName: string = "";
    fileStorageId: string = "";
    documentType: string = "";
}    

export enum modifyStatus {
    New = "New",
    Updated = "Updated",
    Deleted = "Deleted",
    Unmodified = ""
  }

export class KycSubmissionComment {
    submissionId: number = 0;
    id: number | null = null;
    comment: string = "";
    isHiddenForClient: boolean = false;
    commentStamp: Date | null = null;
    commentedBy: number | null = null;
    modifyStatus: modifyStatus = modifyStatus.Unmodified;
}    
