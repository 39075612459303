import React, { Component, Fragment } from "react";
import { Card, CardHeader, Col, Container, Row, CardBody } from "reactstrap";
import { CSSTransitionComponent } from "../components/CSSTransition";
import { HoveredWidget } from "../components/HoveredWidget";
import PageTitle from "../Layout/AppMain/PageTitle";
import Users from "./Users/Users";
import { merchantApiService } from "../services/api/merchants/MerchantApiService";
import { DashboardMetrics } from "../models/DashboardMetrics";
import "./dashboard.scss";
import { faArrowRight, faDashboard, faPersonCircleCheck, faPersonCircleQuestion, faUserFriends, faUserGroup } from "@fortawesome/free-solid-svg-icons";

interface DashboardState {
    metrics: DashboardMetrics;
    isLoading: boolean;
}

export default class Dashboard extends Component<Record<string, never>, DashboardState> {

    constructor(props: Record<string, never>) {
        super(props);

        this.state = {
            isLoading: true,
            metrics: { 
                totalUsers: 0,
                newUsers: 0,
                usersPendingApproval: 0,
                totalUsersApproved: 0
            }
        };
    }

    componentDidMount(): void {
        this.loadMetrics();
    }

    loadMetrics = async () => {
        const metricsResult = await merchantApiService.getMetrics();

        if(!metricsResult.success) {
            this.setState({ isLoading: false });
            return;
        }

        this.setState({ metrics: metricsResult.data, isLoading: false });
    };

    renderWidgets = () => {
        return (<Container fluid>
            <Row>
                <Col lg={12} md={12} xl={12}>
                    <Card className="main-card mb-3">
                        <div className="grid-menu grid-menu-2col">
                            <Row className="no-gutters">
                                <Col sm="3">
                                    <HoveredWidget textClass="success" icon={faUserGroup} description="" title={`${this.state.metrics.totalUsers}`} subHeading="Total Number of Users" isLoading={this.state.isLoading} />
                                </Col>
                                <Col sm="3">
                                    <HoveredWidget textClass="info" icon={faUserFriends} arrowIcon={faArrowRight} description="Last 24 Hours" title={`${this.state.metrics.newUsers}`} subHeading="New Users" isLoading={this.state.isLoading} />
                                </Col>
                                <Col sm="3">
                                    <HoveredWidget textClass="danger" icon={faPersonCircleQuestion} title={`${this.state.metrics.usersPendingApproval}`} subHeading="Users Pending Approval" isLoading={this.state.isLoading} />
                                </Col>
                                <Col sm="3">
                                    <HoveredWidget textClass="warning" icon={faPersonCircleCheck} description="" title={`${this.state.metrics.totalUsersApproved}`} subHeading="Total KYC Approvals" isLoading={this.state.isLoading} />
                                </Col>
                            </Row>
                        </div>
                    </Card>
                </Col>
            </Row>
        </Container>);
    };

    render() {
        return (
            <Fragment>
                <CSSTransitionComponent>
                    <PageTitle heading="Dashboard" icon={faDashboard} />
                </CSSTransitionComponent>
                <CSSTransitionComponent>
                    {this.renderWidgets()}
                </CSSTransitionComponent>
                {/* <CSSTransitionComponent>
                    <Card>
                        <CardHeader>
                            Transactions
                        </CardHeader>
                        <CardBody>
                            <UserTransactions userId={null} showExpander={false} />
                        </CardBody>
                    </Card>
                </CSSTransitionComponent> */}
                <CSSTransitionComponent>
                    <Card>
                        <CardHeader>
                            Users
                        </CardHeader>
                        <CardBody>
                            <Users merchantId={null} />
                        </CardBody>
                    </Card>
                </CSSTransitionComponent>
            </Fragment>
        );
    }
}
