import React from "react";
import { Container, Row, Col, Card, Label, FormGroup } from "reactstrap";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { SelectItem } from "../../models/SelectItem";
import { Button } from "primereact/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { merchantPaymentMethodApiService } from "../../services/api/merchants/MerchantPaymentMethodApiService";
import { storageService } from "../../services/StorageService";
import { MerchantPaymentMethod, CreateNonIbanPaymentMethodRequest } from "../../models/MerchantModel";
import { AddNonIBAN } from "./AddNonIBAN";
import ReactLoader from "react-loaders";
import { Toast } from "primereact/toast";
import { faMoneyCheck } from "@fortawesome/free-solid-svg-icons";

interface DepositViaNonBankProps {
    OnSuccess: (amount: number, selectedItem: MerchantPaymentMethod | undefined) => void;
}

interface DepositViaNonBankState {
    amount: number,
    currentStage: CurrentStage,
    selectedBank: string | null,
    banks: MerchantPaymentMethod[],
    isLoading: boolean
}

enum CurrentStage {
    Deposit,
    AddNew
}

export class DepositViaNonBank extends React.Component<DepositViaNonBankProps, DepositViaNonBankState> {

    toast: Toast | null = null;

    constructor(props: DepositViaNonBankProps | Readonly<DepositViaNonBankProps>) {
        super(props);

        this.state = {
            amount: 0,
            currentStage: CurrentStage.Deposit,
            banks: [],
            selectedBank: null,
            isLoading: true
        };
    }

    componentDidMount(): void {
        this.onLoad();
    }

    onLoad = async () => {
        this.setState({ isLoading: true, currentStage: CurrentStage.Deposit });
        const methodsResult = await merchantPaymentMethodApiService.getAll(storageService.getLoggedInUserMerchantId());

        if (methodsResult.success) {
            const banks = methodsResult.data.filter(a => a.paymentType == "Non-IBAN");
            this.setState({ banks: banks });
        }

        this.setState({ isLoading: false });
    };

    renderBanks = () => {

        if (this.state.isLoading) {
            return <ReactLoader type="ball-pulse" active />;
        }

        if (this.state.banks.length <= 0) {
            return <div style={{ textAlign: "center", margin: "50px" }}>
                <b>No Non-IBANs Available</b>
            </div>;
        }

        return <>
            <Row>
                <Col>
                    <FormGroup>
                        <Label>Amount</Label>
                        <InputText
                            value={this.state.amount}
                            min={0}
                            type="number"
                            onChange={(event) => {
                                const parsed = parseInt(event.target.value);
                                if (!isNaN(parsed)) {
                                    this.setState({ amount: parsed });
                                }
                            }}
                            className={"form-control"}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <Label>Bank Account</Label>
                        <Dropdown style={{ width: "100%" }} value={this.state.selectedBank} options={this.state.banks.map(b => ({ label: `${b.ownerName} ${b.value}`, value: b.value } as SelectItem))} onChange={(e) => { this.setState({ selectedBank: e.value }); }} appendTo={document.body} />
                        {/* <Dropdown style={{ width: "100%" }} value={this.state.selectedBank} options={this.state.cards} onChange={(e) => { this.setState({ selectedCreditCard: e.value }) }} appendTo={document.body} /> */}
                    </FormGroup>
                </Col>
            </Row>
            {this.renderSelectedBank()}
        </>;
    };

    renderSelectedBank = () => {
        if(this.state.selectedBank == null) {
            return "";
        }

        const selectedBank = this.state.banks.find(a => a.value == this.state.selectedBank);

        if(selectedBank == null) {
            return "";
        }

        return <Row>
            <Col>
                <div>
                    Alias: <b>{selectedBank.alias}</b>
                </div>
                <div>
                    Account Holder: <b>{selectedBank.ownerName}</b>
                </div>
                <div>
                    Bank: <b>{selectedBank.bankName}</b>
                </div>
                <div>
                    IBAN: <b>{selectedBank.value}</b>
                </div>
                <div>
                    Swift: <b>{selectedBank.swift}</b>
                </div>
                <div>
                    Currency: <b>{selectedBank.currency}</b>
                </div>
            </Col>
        </Row>;
    };

    onSuccess = async (item: CreateNonIbanPaymentMethodRequest) => {
        const result = await merchantPaymentMethodApiService.create(storageService.getLoggedInUserMerchantId(), item);

        if (result.success) {
            this.onLoad();
        } else {
            this.toast?.show({ severity: "error", summary: "Error", detail: result.message, life: 3000 });
        }
    };

    render(): React.ReactNode {
        if(this.state.currentStage == CurrentStage.AddNew) {
            return <>
                <Toast ref={(el) => (this.toast = el)} />
                <AddNonIBAN OnSuccess={this.onSuccess} OnBack={() => this.onLoad()} />
            </>;
        }

        return (<Container>
            <Toast ref={(el) => (this.toast = el)} />
            <FontAwesomeIcon size="5x" icon={faMoneyCheck as any} className="text-info" style={{ margin: "auto", display: "block" }} />
            {this.renderBanks()}
            <Row>
                <Col xl={{ size: 4, offset: 5 }} lg={{ size: 5, offset: 3 }} md={{ size: 6 }} sm={{ size: 12 }}>
                    <Button
                        style={{ width: "100%" }}
                        label="Add New Non-IBAN"
                        type="button"
                        icon="pi pi-plus"
                        onClick={() => this.setState({ currentStage: CurrentStage.AddNew })}
                        className="p-button-outlined"
                    ></Button>
                </Col>
                <Col xl={{ size: 3 }} lg={{ size: 4 }} md={{ size: 6 }} sm={{ size: 12 }}>
                    <Button
                        style={{ width: "100%" }}
                        label="Deposit"
                        type="button"
                        disabled={this.state.selectedBank == null}
                        onClick={() => this.props.OnSuccess(this.state.amount, this.state.banks.find(a => a.value == this.state.selectedBank))}
                        className="p-button-success"
                    ></Button>
                </Col>

            </Row>

        </Container>);
    }
}