import React, { Component, Fragment } from "react";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { TabPanel, TabView } from "primereact/tabview";
import LookupItemsForm from "./LookupItems";
import { Card, CardBody } from "reactstrap";
import IndividualRiskMatrixForm from "./IndividualRiskMatrix";
import RiskExpressionsForm from "./RiskExpressions";

interface RiskSettingsProps {
    onHide: () => void; 
}

interface RiskSettingsState {
    // isReadOnly:boolean;
}

export default class RiskSettingsModal extends Component<RiskSettingsProps, RiskSettingsState> {
    toast: Toast | null = null;
    validationErrors: Map<string, boolean> = new Map<string, boolean>();
    countryNames: Map<string, string> = new Map<string, string>();

    constructor(props: RiskSettingsProps | Readonly<RiskSettingsProps>) {
        super(props);

        this.state = {
            // isReadOnly: false,
        };
    }

    componentDidMount = async () => {
        // this.setState({ isLoading: true });
        // this.setState({ isLoading: false });
    };

    render() {
        return (
            <Dialog header="Risk Configuration" visible={true} style={{ width: "70vw" }} modal onHide={this.props.onHide}>
                <Fragment>
                    <Card className="mb-3">
                        {/* <CardHeader className="card-header-tab d-flex justify-content-between" >
                            <span>Risk Settings</span>
                        </CardHeader> */}
                        <CardBody>
                            <TabView activeIndex={0}>
                                <TabPanel header="Individual Risk Matrix">
                                    <IndividualRiskMatrixForm onHide={this.props.onHide} />
                                </TabPanel>
                                <TabPanel header="Risk Values">
                                    <LookupItemsForm onHide={this.props.onHide} />
                                </TabPanel>
                                <TabPanel header="Risk Expressions">
                                    <RiskExpressionsForm onHide={this.props.onHide} />
                                </TabPanel>
                            </TabView>
                        </CardBody>
                    </Card>
                </Fragment>
            </Dialog>
        );
    }
}
