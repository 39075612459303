import React from "react";
import { Container, Row, Col, Card } from "reactstrap";
import { HoveredWidget } from "../../components/HoveredWidget";
import { faCreditCard, faMoneyCheck } from "@fortawesome/free-solid-svg-icons";

export enum WithdrawPaymentMethodType {
    Bank,
    Card,
    NonIBAN,
    EPay
}

interface WithdrawSelectPaymentMethodProps {
    OnSelect: (type: WithdrawPaymentMethodType) => void;
    showEPay: boolean;
}

export class WithdrawSelectPaymentMethod extends React.Component<WithdrawSelectPaymentMethodProps, any> {
    
    selectPaymentType = (type: WithdrawPaymentMethodType) => {
        this.props.OnSelect(type);
    };

    render(): React.ReactNode {
        return (<Container className="p-fluid">
            <Row>
                <Col>
                    <Card className="main-card mb-3">
                        <div className="grid-menu grid-menu-2col">
                            <Row className="no-gutters">
                                {/* <Col>
                                    <HoveredWidget textClass="info" small={true} icon={faCreditCard} description="" title="Via Debit/Credit Card" onClick={() => this.selectPaymentType(WithdrawPaymentMethodType.Card)} />
                                </Col>  */} 
                                <Col className="d-flex justify-content-center align-items-center"> 
                                {/* show other column then remove col classname for better view */}
                                    <HoveredWidget textClass="info" small={true} icon={faMoneyCheck} description="" title="Via Bank Account" onClick={() => this.selectPaymentType(WithdrawPaymentMethodType.Bank)} />
                                </Col>
                                {/* <Col>
                                    <HoveredWidget textClass="info" small={true} icon={faCreditCard} description="" title="Via Non-IBAN" onClick={() => this.selectPaymentType(WithdrawPaymentMethodType.NonIBAN)} />
                                </Col> */}
                            </Row>
                        </div>
                    </Card>
                </Col>
            </Row>
        </Container>);
    }
}