import React, { Component, Fragment } from "react";
import { Card, Container } from "reactstrap";
import { Toast } from "primereact/toast";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import _ from "lodash";
import moment from "moment";
import { Column } from "primereact/column";
import { numberFilterMatchModeOptions, stringFilterMatchModeOptions, dateFilterTemplate } from "../Paginator";
import { kycSubmissionApiService } from "../../services/api/Kyc/KycSubmissionApiService";
import { KycSubmission } from "../../models/KycSubmission";
import { Button } from "primereact/button";
import { SelectItem } from "../../models/SelectItem";
import classNames from "classnames";
import { fileService } from "../../services/FileService";
import { saveAs } from "file-saver";
import { SearchRequestBaseModel, SortOrderType } from "../../models/SearchRequestModel";
import ExtendedDataTable from "../../components/ExtendedDataTable";
import { utils } from "../../utils/utils";
import JSZip from "jszip";
interface PendingKycSubmissonsState {
    isLoading: boolean;
    submissions: KycSubmission[];
    viewKycSubmissionModal: boolean;
    selectedSubmission: KycSubmission | null;
    kycStatusTypes: SelectItem<number>[];
    totalRecords: number;
}
export default class ClosedKycSubmissions extends Component<any, PendingKycSubmissonsState> {
    toast: Toast | null = null;
    private dataTable: ExtendedDataTable | null = null;

    constructor(props: any | Readonly<any>) {
        super(props);

        this.state = {
            isLoading: false,
            submissions: [],
            viewKycSubmissionModal: false,
            selectedSubmission: null,
            kycStatusTypes: [],
            totalRecords: 0,
        };
    }

    loadSubmissionCompletedData = async (searchRequest: SearchRequestBaseModel) => {
        this.setState({ isLoading: true });

        searchRequest.filters = utils.manageDataTableFilters(searchRequest.filters);

        const submissionResult = await kycSubmissionApiService.getAllCompleted(searchRequest);

        this.setState({ isLoading: false });

        if (!submissionResult.success) {
            this.toast?.show({ severity: "error", summary: "Error", detail: submissionResult.message, life: 3000 });
            return;
        }

        this.setState({ submissions: submissionResult.data, totalRecords: submissionResult.totalCount });
    };

    async componentDidMount() {
        this.setState({ isLoading: true });

        const kycStatusTypesResult = await kycSubmissionApiService.getKycStatusTypes();

        if (!kycStatusTypesResult.success) {
            this.toast?.show({ severity: "error", summary: "Error", detail: kycStatusTypesResult.message, life: 3000 });
            return;
        }

        this.setState({ kycStatusTypes: kycStatusTypesResult.data });
    }

    onActionButtonClick = (row: KycSubmission) => {
        this.setState({ viewKycSubmissionModal: true, selectedSubmission: row });
    };

    actionBodyTemplate = (row: KycSubmission) => {
        return <Button label="Review" type="button" className="p-button p-button-warning" onClick={() => this.onActionButtonClick(row)}></Button>;
    };

    renderStatusBadge(status: number) {
        const kycStatus = this.state.kycStatusTypes.filter((f) => f.value == status)[0];

        if (kycStatus == null || kycStatus == undefined) {
            console.error(`No Status found for status=${status}`);
            return;
        }

        const title = kycStatus.label;
        const className = title == "Approved" ? "success" : "danger";

        return <div className={classNames("badge", "badge-" + className)}>{title}</div>;
    }

    downloadDocuments = async (submissionId: number) => {
        this.setState({ isLoading: true });

        const documentsResult = await kycSubmissionApiService.getDocuments(submissionId);

        if (!documentsResult.success) {
            this.setState({ isLoading: false });
            this.toast?.show({ severity: "error", summary: "Error", detail: documentsResult.message, life: 3000 });
            return;
        }

        const zip = new JSZip();
        const photoZip = zip.folder("documents");

        const data = documentsResult.data;
        for (let i = 0; i < data.length; i++) {
            const result = data[i];
            await this.onDownloadFromFileStorage(result.fileStorageId, result.fileName, photoZip);
        }
        /// waits for "await DownloadFileFromS3, then executes the save as which saves the zipped folder created in "DownloadFileFromS3"
        const content = await zip.generateAsync({ type: "blob" });
        saveAs(content, "documents");
        this.setState({ isLoading: false });
    };

    onDownloadFromFileStorage = async (fileStorageId: string, secondaryLabel: string, photoZip: any): Promise<void> => {
        const imagePath = await fileService.GetFile(fileStorageId);

        if (!imagePath.success) {
            this.setState({ isLoading: false });
            this.toast?.show({ severity: "error", summary: "Error", detail: imagePath.message, life: 3000 });
            return;
        }

        photoZip.file(secondaryLabel, imagePath.data, { base64: true });

        this.setState({ isLoading: false });
    };

    render() {
        return (
            <Fragment>
                <TransitionGroup appear={true} exit={false} enter={false}>
                    <CSSTransition classNames="TabsAnimation" timeout={1500}>
                        <Fragment>
                            <Toast ref={(el) => (this.toast = el)} position={"bottom-center"} baseZIndex={99999}></Toast>
                            <Container fluid>
                                <Card>
                                    <ExtendedDataTable
                                        value={this.state.submissions}
                                        totalRecords={this.state.totalRecords}
                                        onChange={this.loadSubmissionCompletedData}
                                        loading={this.state.isLoading}
                                        ref={(ref) => (this.dataTable = ref)}
                                        defaultSortOrder={SortOrderType.Descending}
                                        defaultSortField="submissionTimeStamp"
                                    >
                                        <Column field="id" header="Submission ID" filterMatchModeOptions={numberFilterMatchModeOptions}></Column>
                                        <Column
                                            header="End User First Name"
                                            field="endUserFirstName"
                                            body={(rowData: KycSubmission) => <span>{rowData.endUserFirstName}</span>}
                                            filterMatchModeOptions={stringFilterMatchModeOptions}
                                        ></Column>
                                        <Column
                                            field="merchantName"
                                            header="Merchant Name"
                                            sortable
                                            filterMatchModeOptions={stringFilterMatchModeOptions}
                                        ></Column>

                                        <Column
                                            header="End User Last Name"
                                            field="endUserLastName"
                                            body={(rowData: KycSubmission) => <span>{rowData.endUserLastName}</span>}
                                            filterMatchModeOptions={stringFilterMatchModeOptions}
                                        ></Column>
                                        <Column
                                            field="endUserPhone"
                                            header="End User Phone"
                                            sortable
                                            filterMatchModeOptions={stringFilterMatchModeOptions}
                                        ></Column>
                                        <Column
                                            field="endUserEmail"
                                            header="End User Email"
                                            sortable
                                            filterMatchModeOptions={stringFilterMatchModeOptions}
                                        ></Column>
                                        <Column
                                            field="submissionTimeStamp"
                                            header="Submission TimeStamp"
                                            body={(rowData: KycSubmission) => (
                                                <p>{moment.utc(rowData.submissionTimeStamp).local().format("DD/MM/YYYY hh:mm:ss A")}</p>
                                            )}
                                            dataType="date"
                                            filterElement={dateFilterTemplate}
                                            filterMatchModeOptions={numberFilterMatchModeOptions}
                                        ></Column>
                                        <Column
                                            body={(rowData: KycSubmission) => (
                                                <Button
                                                    label="Download"
                                                    type="button"
                                                    className="p-button"
                                                    disabled={rowData.documentsCount === 0}
                                                    onClick={() => this.downloadDocuments(rowData.id)}
                                                />
                                            )}
                                            header="Uploaded Files"
                                        ></Column>
                                        <Column field="reviewerFirstName" header="KYC Officer"></Column>
                                        <Column field="reason" header="Reason" filterMatchModeOptions={stringFilterMatchModeOptions}></Column>
                                        <Column
                                            field="reviewStartedTimeStamp"
                                            header="Review Started"
                                            body={(rowData: KycSubmission) => (
                                                <p>{moment.utc(rowData.reviewStartedTimeStamp).local().format("DD/MM/YYYY hh:mm:ss A")}</p>
                                            )}
                                            filterMatchModeOptions={numberFilterMatchModeOptions}
                                            dataType="date"
                                            filterElement={dateFilterTemplate}
                                        ></Column>
                                        <Column
                                            field="reviewCompletedTimeStamp"
                                            header="Review Closed"
                                            sortField=""
                                            body={(rowData: KycSubmission) => (
                                                <p>{moment.utc(rowData.reviewCompletedTimeStamp).local().format("DD/MM/YYYY hh:mm:ss A")}</p>
                                            )}
                                            filterMatchModeOptions={numberFilterMatchModeOptions}
                                            dataType="date"
                                            filterElement={dateFilterTemplate}
                                        ></Column>
                                        <Column
                                            field="status"
                                            header="Status"
                                            body={(rowData: KycSubmission) => this.renderStatusBadge(rowData.status)}
                                        ></Column>
                                    </ExtendedDataTable>
                                </Card>
                            </Container>
                        </Fragment>
                    </CSSTransition>
                </TransitionGroup>
            </Fragment>
        );
    }
}
