import React, { Component, Fragment } from "react";
import { Container, Card, CardBody } from "reactstrap";

import { Merchant, MerchantStatus } from "../../models/MerchantModel";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import history from "../../history";
import PageTitle from "../../Layout/AppMain/PageTitle";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import moment from "moment";
import { Toast } from "primereact/toast";
import { merchantApiService } from "../../services/api/merchants/MerchantApiService";
import { utils } from "../../utils/utils";
import _ from "lodash";
import { fileService } from "../../services/FileService";
import { SearchRequestBaseModel, SortOrderType } from "../../models/SearchRequestModel";
import ExtendedDataTable from "../../components/ExtendedDataTable";
import { Column } from "primereact/column";
import { stringFilterMatchModeOptions, numberFilterMatchModeOptions, dateFilterTemplate } from "../Paginator";
import { getUserPermittedRoutes, isMerchantCreateNewAllowed, isMerchantUpdateAllowed } from "../../authGuard";
import { faUserGroup } from "@fortawesome/free-solid-svg-icons";

interface UsersState {
    merchants: Merchant[];
    globalFilterValue: string;
    isLoading: boolean;
    totalRecords: number;
}

export default class Merchants extends Component<Record<string, never>, UsersState> {
    private toast: Toast | null = null;
    private dataTable: ExtendedDataTable | null = null;

    constructor(props: Record<string, never>) {
        super(props);

        this.state = {
            merchants: [],
            globalFilterValue: "",
            isLoading: false,
            totalRecords: 0,
        };
    }

    loadMerchantsData = async (searchRequest: SearchRequestBaseModel) => {
        this.setState({ isLoading: true });
        searchRequest.filters = utils.manageDataTableFilters(searchRequest.filters);
        const merchantsResult = await merchantApiService.getAll(searchRequest);

        this.setState({ isLoading: false });

        if (!merchantsResult.success) {
            this.toast?.show({ severity: "error", summary: "Error", detail: merchantsResult.message, life: 3000 });
        }

        this.setState({ merchants: merchantsResult.data, totalRecords: merchantsResult.totalCount });

        this.loadMerchantImages();
    };

    updateMerchantData = (merchantId: number, updatedData: Record<string, string>) => {
        const merchantIndex = this.state.merchants.findIndex((f) => f.id == merchantId);

        if (merchantIndex === -1) {
            return;
        }

        const updatedList = [
            ...this.state.merchants.slice(0, merchantIndex),
            Object.assign({}, this.state.merchants[merchantIndex], updatedData),
            ...this.state.merchants.slice(merchantIndex + 1),
        ];

        this.setState({
            merchants: updatedList,
        });
    };

    loadMerchantImages = async () => {
        _.forEach(this.state.merchants, async (merchant) => {
            // logo was never assigned. Ignore
            if (merchant.logo == null || merchant.logo.length == 0) {
                return;
            }

            const logoResult = await fileService.GetFile(merchant.logo);

            if (!logoResult.success) {
                return;
            }

            this.updateMerchantData(merchant.id, { logo: utils.appendBase64Data(logoResult.data) });
        });
    };

    onMerchantDetailsActionButtonClick = (merchantId: number) => {
        history.push(`/merchant/${merchantId}`);
    };

    actionBodyTemplate = (rowData: Merchant) => {
        return (
            <Button
                type="button"
                icon="pi pi-info-circle"
                className="p-button-outlined"
                onClick={() => this.onMerchantDetailsActionButtonClick(rowData.id)}
            ></Button>
        );
    };

    renderStatusBadge(success: boolean, title: string) {
        return <div className={classNames("badge", "badge-" + (success ? "success" : "danger"))}>{title}</div>;
    }

    renderMerchantLogo(rowData: Merchant) {
        return (
            <div>
                <img
                    alt="merchant logo"
                    src={rowData.logo == null || rowData.logo.length === 0 ? "/dummy.jpg" : rowData.logo}
                    style={{ maxHeight: "100%", maxWidth: "100px" }}
                />
            </div>
        );
    }

    onGlobalFilterChange = (e: { target: { value: any } }) => {
        const value = e.target.value;

        this.setState({ globalFilterValue: value });
    };

    renderHeader = () => {
        const path = "/merchant/create";
        if (!getUserPermittedRoutes().some((s) => s.path == path)) {
            return <span></span>;
        }

        return (
            <span className="p-input-icon-left" style={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                    type="button"
                    icon="pi pi-filter-slash"
                    label="Clear"
                    className="p-button-outlined"
                    onClick={() => {
                        this.dataTable!.reset();
                    }}
                />
                <Button
                    label="Add Merchant"
                    icon="pi pi-plus"
                    className="p-button-outlined"
                    onClick={() => {
                        history.push(path);
                    }}
                />
            </span>
        );
    };

    render() {
        return (
            <Fragment>
                <TransitionGroup appear={true} exit={false} enter={false}>
                    <CSSTransition classNames="TabsAnimation" timeout={3500}>
                        <Fragment>
                            <Container fluid>
                                <PageTitle heading="Merchants" icon={faUserGroup} />

                                <Toast ref={(el) => (this.toast = el)} />
                                <Card className="mb-3">
                                    <CardBody>
                                        <ExtendedDataTable
                                            header={this.renderHeader}
                                            ref={(ref) => (this.dataTable = ref)}
                                            value={this.state.merchants}
                                            onChange={this.loadMerchantsData}
                                            loading={this.state.isLoading}
                                            totalRecords={this.state.totalRecords}
                                            defaultSortField="createdTimeStamp"
                                            defaultSortOrder={SortOrderType.Descending}
                                        >
                                            <Column field="id" header="Id" filterMatchModeOptions={numberFilterMatchModeOptions}></Column>
                                            <Column field="logo" header="Logo" body={this.renderMerchantLogo}></Column>
                                            <Column field="name" header="Name" filterMatchModeOptions={stringFilterMatchModeOptions}></Column>
                                            <Column
                                                field="status"
                                                header="KYC Status"
                                                body={(rowData: Merchant) =>
                                                    this.renderStatusBadge(
                                                        rowData.status == MerchantStatus.Active,
                                                        rowData.status == MerchantStatus.Active ? "Active" : "Inactive"
                                                    )
                                                }
                                                filterMatchModeOptions={stringFilterMatchModeOptions}
                                            ></Column>
                                            <Column
                                                field="createdTimeStamp"
                                                header="Registration Date"
                                                body={(rowData: Merchant) => (
                                                    <p>{moment.utc(rowData.createdTimeStamp).local().format("DD/MM/YYYY")}</p>
                                                )}
                                                filterMatchModeOptions={numberFilterMatchModeOptions}
                                                dataType="date"
                                                filterElement={dateFilterTemplate}
                                            ></Column>
                                            {(isMerchantCreateNewAllowed() || isMerchantUpdateAllowed()) && (
                                                <Column
                                                    header="Details"
                                                    body={this.actionBodyTemplate}
                                                    headerStyle={{ width: "8em", textAlign: "center" }}
                                                    bodyStyle={{ textAlign: "center", overflow: "visible" }}
                                                />
                                            )}
                                        </ExtendedDataTable>
                                    </CardBody>
                                </Card>
                            </Container>
                        </Fragment>
                    </CSSTransition>
                </TransitionGroup>
            </Fragment>
        );
    }
}
