import React, { Component, Fragment } from "react";
import { Container } from "reactstrap";

import { TransitionGroup, CSSTransition } from "react-transition-group";
import _ from "lodash";
import { Toast } from "primereact/toast";
import Loader from "../../components/Loader";
import { merchantPaymentMethodApiService } from "../../services/api/merchants/MerchantPaymentMethodApiService";
import { CreateCardPaymentMethodRequest, CreateIbanPaymentMethodRequest, CreateNonIbanPaymentMethodRequest, MerchantPaymentMethod } from "../../models/MerchantModel";
import { PaymentMethods } from "../../models/FinancialData";
import { AddMerchantPaymentMethod } from "./AddMerchantPaymentMethod";
import { PaymentMethodCrudComponent } from "../Banking/PaymentMethodCrudComponent";
import ReactLoader from "react-loaders";

interface MerchantPaymentMethodsState {
    isLoading: boolean;
    paymentMethods: PaymentMethods[];
    isAddPaymentMethodVisible: boolean;
}

interface MerchantPaymentMethodsProps {
    merchantId: number;
    viewOnly: boolean;
}

export default class MerchantPaymentMethodsComponent extends Component<MerchantPaymentMethodsProps, MerchantPaymentMethodsState> {
    toast: Toast | null = null;

    constructor(props: MerchantPaymentMethodsProps | Readonly<MerchantPaymentMethodsProps>) {
        super(props);

        this.state = {
            isLoading: true,
            isAddPaymentMethodVisible: false,
            paymentMethods: [],
        };
    }

    async componentDidMount() {
        await this.loadMethods();
    }

    loadMethods = async () => {
        this.setState({ isLoading: true });

        const paymentMethodsResult = await merchantPaymentMethodApiService.getAll(this.props.merchantId);

        if (!paymentMethodsResult.success) {
            this.setState({ isLoading: false });
            if(!paymentMethodsResult.message.includes("Merchant has missing data"))
                this.toast?.show({ severity: "error", summary: "Error", detail: paymentMethodsResult.message, life: 3000 });
            return;
        }

        this.setState({ paymentMethods: paymentMethodsResult.data, isLoading: false });
    };

    addPaymentMethod = () => {
        this.setState({ isAddPaymentMethodVisible: true });
    };

    deletePaymentMethod = async (rowData: MerchantPaymentMethod | PaymentMethods) => {
        const deleteResult = await merchantPaymentMethodApiService.delete(this.props.merchantId, rowData.value);

        if (deleteResult.success) {
            this.setState({ paymentMethods: this.state.paymentMethods.filter((a) => a.value !== rowData.value) });
        } else {
            this.toast?.show({ severity: "error", summary: "Error", detail: deleteResult.message, life: 3000 });
        }
    };

    updatePaymentMethod = async (rowData: CreateCardPaymentMethodRequest | CreateIbanPaymentMethodRequest | CreateNonIbanPaymentMethodRequest) => {
        const updateResult = await merchantPaymentMethodApiService.update(this.props.merchantId, rowData);

        if (updateResult.success) {
            this.toast?.show({ severity: "success", summary: "Action Completed", detail: updateResult.message ?? "Payment method updated." });
        } else {
            this.toast?.show({ severity: "error", summary: "Error", detail: updateResult.message ?? "Operation not allowed.", life: 3000 });
        }
        this.loadMethods();
    };

    onHide = (): void => {
        this.setState({ isAddPaymentMethodVisible: false });
        this.loadMethods();
    };

    render() {
        if(this.state.isLoading) {
            return (
                <ReactLoader type="ball-pulse" active />
            );
        }
        return (
            <Fragment>
                <TransitionGroup appear={true} exit={false} enter={false}>
                    <CSSTransition classNames="TabsAnimation" timeout={1500}>
                        <Fragment>
                            <Container fluid>
                                {this.state.isAddPaymentMethodVisible && (
                                    <AddMerchantPaymentMethod merchantId={this.props.merchantId} onHide={() => this.onHide()} />
                                )}
                                <Toast ref={(el) => (this.toast = el)} position={"bottom-center"} baseZIndex={99999}></Toast>
                                <PaymentMethodCrudComponent deletePaymentMethod={this.deletePaymentMethod} updatePaymentMethod={this.updatePaymentMethod} addPaymentMethod={this.addPaymentMethod} paymentMethods={this.state.paymentMethods} />
                            </Container>
                        </Fragment>
                    </CSSTransition>
                </TransitionGroup>
            </Fragment>
        );
    }
}
