import React, { Component, Fragment } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Container, Card, CardHeader, CardBody } from "reactstrap";
import PageTitle from "../../Layout/AppMain/PageTitle";
import { Toast } from "primereact/toast";
import { TabPanel, TabView } from "primereact/tabview";
import { isKYCApproveAllowed, isKYCAssessAllowed } from "../../authGuard";
import ListBankTransfersOutgoing, { BankTransfersListType } from "./ListBankTransfersOutgoing";
import { faBank } from "@fortawesome/free-solid-svg-icons";

interface ReviewBankTransfersOutgoingState {
    activeTabIndex: number;
}

export default class ReviewBankTransfersOutgoing extends Component<Record<string, never>, ReviewBankTransfersOutgoingState> {
    tabNames: string[] = ["Pending KYC Submissions", "KYC Submissions Under Review", "Closed KYC Submissions"];
    toast: Toast | null = null;

    constructor(props: Record<string, never> | Readonly<Record<string, never>>) {
        super(props);

        this.state = {
            activeTabIndex: 0
        };
    }

    render() {
        return (
            <Fragment>
                <TransitionGroup appear={true} exit={false} enter={false}>
                    <CSSTransition classNames="TabsAnimation" timeout={1500}>
                        <Fragment>
                            <Container fluid>
                                <PageTitle heading="OUTGOING BANK TRANSFERS" icon={faBank} />
                                <Card className="mb-3">
                                    <CardHeader className="card-header-tab d-flex justify-content-between" >
                                        <span>REVIEW OUTGOING BANK TRANSFERS</span>
                                    </CardHeader>
                                    <CardBody>
                                        <TabView activeIndex={this.state.activeTabIndex}
                                            onTabChange={(e) => {
                                                this.setState({ activeTabIndex: e.index });
                                            }}>
                                            {(isKYCAssessAllowed() || isKYCApproveAllowed()) &&<TabPanel header="All Bank Transfers">
                                                <ListBankTransfersOutgoing listType = {BankTransfersListType.All} />
                                            </TabPanel>}
                                            {isKYCApproveAllowed() && 
                                                <TabPanel header="Pending Bank Transfers">
                                                	<ListBankTransfersOutgoing listType = {BankTransfersListType.Pending} /> 
                                                </TabPanel>}
                                        </TabView>
                                    </CardBody>
                                </Card>
                            </Container>
                        </Fragment>
                    </CSSTransition>
                </TransitionGroup>
            </Fragment>
        );
    }
}
