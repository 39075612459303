// KycSubmissionCommentForm.tsx
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { Component } from "react";
import { Button } from "primereact/button";
import { kycSubmissionApiService } from "../../services/api/Kyc/KycSubmissionApiService";
import { KycSubmissionComment, modifyStatus } from "../../models/KycSubmission";
import { Toast } from "primereact/toast";
import AddKycComment from "./KycCommentsUpdate";
import moment from "moment";

interface KycSubmissionCommentProps {
  submissionId: number;
  comments: KycSubmissionComment[]; 
  submitToOwner: (comments: KycSubmissionComment[]) => void;
}

interface KycSubmissionCommentState {
  submissionId: number;
  comments: KycSubmissionComment[]; 
  selectedComment: KycSubmissionComment ;
  isCommentDetailVisible: boolean;
  errors: {
    [key: string]: string;
  };
}

class KycSubmissionCommentForm extends Component<KycSubmissionCommentProps, KycSubmissionCommentState> {
    toast: Toast | null = null;
    constructor(props: KycSubmissionCommentProps) {
        super(props);
        this.state = {
            submissionId: props.submissionId,
            comments: props.comments,
            selectedComment: this.getCommentObject(null),
            isCommentDetailVisible: false,
            errors: {},
        };
    }

    componentDidMount() {
        this.fetchComments();
    }

    fetchComments = async () => {
        const commentsResult = await kycSubmissionApiService.getComments(this.props.submissionId);
        if (!commentsResult.success) {
            this.toast?.show({ severity: "error", summary: "Error", detail: commentsResult.message, life: 3000 });
            return;
        }
        this.setState({ comments: commentsResult.data });
    };

    validateForm() {
        return true;
        // Validate form logic
    }

    getCommentObject = (comment: KycSubmissionComment | null) =>  {
        let objToPost = comment;
        if (objToPost == null)
        {
            let maxCommentId: number = 0; 
            if (this.state)
                maxCommentId = this.state.comments.reduce((maxId, comment) => Math.max(maxId, comment.id ?? 0), 0);
            objToPost = {
                submissionId: this.props.submissionId,
                id: maxCommentId+1,
                comment: "",
                isHiddenForClient: true,
                commentStamp: null,
                commentedBy: null,
                modifyStatus: modifyStatus.New
            } as KycSubmissionComment;
        }
        return objToPost;
    };

    addorEditComment = (comment: KycSubmissionComment | null) =>  {
        this.setState({  selectedComment: this.getCommentObject(comment), isCommentDetailVisible: true });
    };

    onRemoveComment(index: number) {
    }

    onCommentUpdate = (currentComment: KycSubmissionComment) => {
        //Update local comments
        const localComments = this.state.comments;

        const objToPost: KycSubmissionComment = {
            submissionId: currentComment.submissionId,
            id: currentComment.id,
            comment: currentComment.comment,
            isHiddenForClient: currentComment.isHiddenForClient,
            commentStamp: currentComment.commentStamp,
            commentedBy: currentComment.commentedBy,
            modifyStatus: modifyStatus.New
        } as KycSubmissionComment;

        const existingComment = localComments.find(comment => comment.id === objToPost.id);
        if (existingComment)
        {
            objToPost.modifyStatus = modifyStatus.Updated;
            Object.assign(existingComment, objToPost);
        }
        else 
        {
            objToPost.modifyStatus = modifyStatus.New;
            localComments.push(objToPost);
        }
        this.setState({ comments: localComments, isCommentDetailVisible: false });
    
        //Populate updates to the calling form
        this.props.submitToOwner(this.state.comments);
    };

    onCommentDetailsModalClose = async () => {
        this.setState({ isCommentDetailVisible: false });
    };

    actionBodyTemplate = (index: number) => {
        return (
            <>
                <button type="button" className="p-row-editor-cancel p-link" tabIndex={0} onClick={() => this.onRemoveComment(index)}>
                    <span className="p-row-editor-cancel-icon pi pi-fw pi-times"></span>
                </button>
            </>
        );
    };

    renderHeader = () => {
        return (
            <div className="table-header">
                <span className="p-input-icon-left align-right">
                    <i className="pi" />
                    <Button label="Add Comment" className="p-button-outlined" icon="pi pi-plus" onClick={() => this.addorEditComment(null)} />
                </span>
            </div>
        );
    };

    render() {
        return (
            <div>
                <DataTable header={this.renderHeader} value={this.state.comments}>
                    {/* <Column field="id" header="Id"/> */}
                    {/* <Column field="commentStamp" header="Date"
                    body={(rowData: KycSubmissionComment) => {
                      <p>
                      {rowData.commentStamp && moment.utc(rowData.commentStamp).local().format("DD/MM/YYYY hh:mm A")}
                      </p>}}                                                
                  ></Column> */}
                    <Column field="comment" header="Comment"/>
                    {/* <Column field="commentedBy" header="Commented By"/>
                  <Column field="isHiddenForClient" header="Hidden for client"/> */}
                    {/* <Column
                      header=""
                      body={(rowData: KycSubmissionComment, props) => this.actionBodyTemplate(props.rowIndex)}
                      headerStyle={{ width: "8em", textAlign: "center" }}
                      bodyStyle={{ textAlign: "center", overflow: "visible" }}
                  />                   */}
                </DataTable>

                {this.state.isCommentDetailVisible && (
                    <AddKycComment
                        onHide={this.onCommentDetailsModalClose}
                        comment={this.state.selectedComment} 
                        onCommentUpdate={this.onCommentUpdate}
                    />
                )}
            </div>              
        );
    }
}

export default KycSubmissionCommentForm;
