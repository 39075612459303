import React, { Component, Fragment } from "react";
import { CardHeader, Container, Card, CardBody, Row, Col, Label, FormGroup } from "reactstrap";
import PageTitle from "../../Layout/AppMain/PageTitle";

import { User } from "../../models/User";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { classNames } from "primereact/utils";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";

import GeneralDataComponent from "./GeneralData";
import UserKycSubmissionsComponent from "./UserKycSubmissionsComponent";
import FinancialDataComponent from "./FinancialData";
import UserTransactions from "./UserTransactions";
import UserJournalingComponent from "./UserJournalingComponent";
import MiscComponent from "./MiscComponent";
import InlineEditableInput, { InputType } from "../../components/InlineEditableInput";
import { Toast } from "primereact/toast";

import "./userprofile.scss";
import { userApiService } from "../../services/api/users/UsersApiService";
import { SelectItem } from "../../models/SelectItem";
import { utils } from "../../utils/utils";
import { ProfileImage } from "../../components/Image/ProfileImage";
import { fileService } from "../../services/FileService";
import { merchantGroupsApiService } from "../../services/api/merchants/MerchantGroupsApiService";
import { MerchantGroup } from "../../models/MerchantModel";
import { kycSubmissionApiService } from "../../services/api/Kyc/KycSubmissionApiService";

import history from "../../history";
import { TabPanel, TabView } from "primereact/tabview";
import _ from "lodash";
import { isIndividualTransactionViewAllowed, isJournalingAllowed, isUserProfileUpdateAllowed } from "../../authGuard";
import { TriStateCheckboxChangeParams } from "primereact/tristatecheckbox";
import MerchantWalletsComponent, { WalletType } from "../Merchants/Wallets";
import ReactLoader from "react-loaders";
import { storageService } from "../../services/StorageService";
import { confirmDialog } from "primereact/confirmdialog";
import { faUserGear } from "@fortawesome/free-solid-svg-icons";

interface UserProfileState {
    userId: number;
    activeTabName: number;
    user: User | null;
    isLoading: boolean;
    groupsSelectItem: SelectItem<number>[];
}


export default class UserProfileComponent extends Component<Record<string, never>, UserProfileState> {
    tabNames: string[] = ["General", "KYC", "Financial", "Misc", "Journaling", "Transactions"];
    toast: Toast | null = null;

    userStatusClassesMap: Map<string, string> = new Map<string, string>([
        ["Active", "success"],
        ["Deactivated", "danger"],
        ["Closed", "danger"],
        ["New", "success"],
        ["Pending Verification", "warning"],
        ["Blocked", "danger"],
    ]);
    reject = () => {
        this.toast?.show({ severity: "warn", summary: "Rejected", detail: "You have rejected", life: 3000 });
    };
    resetEmailDialog = () => {
        confirmDialog({
            message: "Are you sure to reset email address?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => {
                this.resetEmail(this.state.userId);
            },
            reject: this.reject
        });
    };
    resetMobileDialog = () => {
        confirmDialog({
            message: "Are you sure to reset mobile?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => {
                this.resetMobile(this.state.userId);
            },
            reject: this.reject
        });
    };


    resetKycStatusDialog = () => {
        confirmDialog({
            message: "Are you sure to reset KYC Status?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => {
                this.resetKycStatus(this.state.userId);
            },
            reject: this.reject
        });
    };
    deactivateDialog = () => {
        confirmDialog({
            message: "Are you sure to deactivate user status?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => {
                this.deactiveOrActiveUserAccount(this.state.userId);
            },
            reject: this.reject
        });
    };
    userKycStatusClassesMap: Map<string, string> = new Map<string, string>([
        ["Pending Review", "warning"],
        ["Under Review", "warning"],
        ["Approved", "success"],
        ["Rejected", "danger"],
        ["New", "primary"],
        ["Closed", "dark"],
    ]);

    cbsOnboardedClassesMap: Map<boolean, string> = new Map<boolean, string>([
        [true, "success"],
        [false, "danger"],
    ]);

    constructor(props: Record<string, never>) {
        super(props);

        this.state = {
            userId: utils.getIdentifierFromPath(),
            activeTabName: 0,
            user: null,
            isLoading: false,
            groupsSelectItem: [],
        };
    }

    statusTypes: Map<number, string> = new Map<number, string>();
    kycStatusTypes: Map<number, string> = new Map<number, string>();
    kycLevels: SelectItem<number>[] = [];

    async componentDidMount() {

        this.setState({ isLoading: true });

        const userProfile = await userApiService.get(this.state.userId);

        if (!userProfile.success) {
            this.setState({ isLoading: false });
            this.toast?.show({ severity: "error", summary: "Error", detail: userProfile.message, life: 3000 });
            return;
        }

        storageService.setSelectedMerchantId(userProfile.data.merchantId.toString());

        this.setState({ isLoading: false });

        const statusTypes = await userApiService.getStatusTypes();

        if (!statusTypes.success) {
            this.setState({ isLoading: false });
            this.toast?.show({ severity: "error", summary: "Error", detail: statusTypes.message, life: 3000 });
            return;
        }

        statusTypes.data.forEach((item) => {
            this.statusTypes.set(item.value, item.label);
        });

        const KycStatusTypes = await kycSubmissionApiService.getKycStatusTypes();

        if (!KycStatusTypes.success) {
            this.setState({ isLoading: false });
            this.toast?.show({ severity: "error", summary: "Error", detail: KycStatusTypes.message, life: 3000 });
            return;
        }

        KycStatusTypes.data.forEach((item) => {
            this.kycStatusTypes.set(item.value, item.label);
        });

        const kycLevels = await kycSubmissionApiService.getKycLevels();

        if (!kycLevels.success) {
            this.setState({ isLoading: false });
            this.toast?.show({ severity: "error", summary: "Error", detail: kycLevels.message, life: 3000 });
            return;
        }

        this.kycLevels = kycLevels.data;

        this.setState({ isLoading: false });

        const groupResult = await merchantGroupsApiService.getAll(userProfile.data.merchantId);

        if (!groupResult.success) {
            this.setState({ isLoading: false });
            this.toast?.show({ severity: "error", summary: "Error", detail: groupResult.message, life: 3000 });
            return;
        }

        this.setState({ user: userProfile.data, groupsSelectItem: this.getSelectMerchantGroupItems(groupResult.data) });

        this.loadImage();
    }

    loadImage = async () => {
        if (this.state.user?.photo == null) {
            return;
        }
        const photoResult = await fileService.GetFile(this.state.user?.photo);

        if (!photoResult.success) {
            this.toast?.show({
                severity: "error",
                summary: "Error",
                detail: "Could not load User Photo. Please contact your administrator",
                life: 3000,
            });
            return;
        }

        const user = this.state.user;
        user.photo = photoResult.data;
        this.setState({ user: user });
    };

    getSelectMerchantGroupItems = (options: MerchantGroup[]): SelectItem<number>[] => {
        return options.map(
            (data) =>
            ({
                label: data.name,
                value: data.id,
            } as SelectItem<number>)
        );
    };

    getVerificationClassName = (param: boolean): string => {
        return param === true ? "success" : "danger";
    };

    getCryptoCustomerClassName = (param: boolean): string => {
        return param === true ? "text-primary" : "text-danger";
    };

    getStatusVerificationClassName = (param: number): string => {
        const item = this.statusTypes.get(param);
        return item != null ? this.userStatusClassesMap.get(item)! : "danger";
    };

    getKycStatusVerificationClassName = (param: number): string => {
        const item = this.kycStatusTypes.get(param);
        return item != null ? this.userKycStatusClassesMap.get(item)! : "danger";
    };

    getCbsOnboardedClassName = (param: boolean): string => {
        return param != null ? this.cbsOnboardedClassesMap.get(param)! : "danger";
    };

    getKycLevel = (param: number): string => {
        const list = _.filter(this.kycLevels, (c) => c.value == param);
        return list.length > 0 ? list[0].label : "N/A";
    };
    updateValue = (fieldName: string, value: string) => {
        const user = this.state.user as any;

        user[fieldName] = value;

        this.setState({ user: user });
    };

    handleCryptoStatusChange(e: TriStateCheckboxChangeParams) {
        const checked = (e as any).checked;
        const user = this.state.user as User;
        user.cryptoCustomer = checked;

        this.setState({ user: user });
    }

    onKycLevelSubmit = async (kycLevelId: number) => {
        this.setState({ isLoading: true });

        const objToPost: User = {
            id: this.state.userId,
            kycLevelId: kycLevelId,
        } as User;

        const KycLevel = await userApiService.updatesKycLevel(objToPost);
        const user = this.state.user as User;

        if (!KycLevel.success) {
            this.setState({ isLoading: false });
            const kycStatus = this.kycStatusTypes.get(this.state.user?.kycStatus ?? 1);
            const message = kycStatus === "New" ? KycLevel.message : `Kyc Level can not be changed at ${kycStatus} status.`;
            this.toast?.show({ severity: "error", summary: "Error", detail: message, life: 3000 });
            return;
        }

        user.kycLevel = kycLevelId;
        this.setState({ user: user, isLoading: false });
    };

    showToast = (message: string) => {
        this.toast?.show({ severity: "success", summary: "Action Completed", detail: message });
    };

    deactiveOrActiveUserAccount = async (id: number) => {
        if (this.state.user?.status && this.statusTypes.get(this.state.user.status) === "Active") {
            this.deactivateUser(id);
            return;
        }

        this.activateUser(id);
    };

    deactivateUser = async (id: number) => {
        this.setState({ isLoading: true });
        const result = await userApiService.deactivateUser(id);

        if (!result.success) {
            this.setState({ isLoading: false });
            this.toast?.show({ severity: "error", summary: "Error", detail: result.message, life: 3000 });
            return;
        }

        // TODO. Fix it.

        const userProfile = await userApiService.get(this.state.userId);

        if (!userProfile.success) {
            this.setState({ isLoading: false });
            this.toast?.show({ severity: "error", summary: "Error", detail: userProfile.message, life: 3000 });
            return;
        }

        this.setState({ user: userProfile.data, isLoading: false });

        this.toast?.show({ severity: "success", summary: "Action Completed", detail: "Account Deactivated." });
    };

    closeUser = async (id: number) => {
        this.setState({ isLoading: true });
        const result = await userApiService.delete(id);

        if (!result.success) {
            this.setState({ isLoading: false });
            this.toast?.show({ severity: "error", summary: "Error", detail: result.message, life: 3000 });
            return;
        }

        // TODO. Fix it.

        const userProfile = await userApiService.get(this.state.userId);

        if (!userProfile.success) {
            this.setState({ isLoading: false });
            this.toast?.show({ severity: "error", summary: "Error", detail: userProfile.message, life: 3000 });
            return;
        }

        this.setState({ user: userProfile.data, isLoading: false });

        this.toast?.show({ severity: "success", summary: "Action Completed", detail: "Account Closed." });
    };

    activateUser = async (id: number) => {
        this.setState({ isLoading: true });
        const activeUser = await userApiService.activateUser(id);

        if (!activeUser.success) {
            this.setState({ isLoading: false });
            this.toast?.show({ severity: "error", summary: "Error", detail: activeUser.message, life: 3000 });
            return;
        }

        const userProfile = await userApiService.get(this.state.userId);

        if (!userProfile.success) {
            this.setState({ isLoading: false });
            this.toast?.show({ severity: "error", summary: "Error", detail: userProfile.message, life: 3000 });
            return;
        }

        this.setState({ user: userProfile.data, isLoading: false });

        this.toast?.show({ severity: "success", summary: "Action Completed", detail: "Account Activated." });
    };

    blockUserAccount = async (id: number) => {
        this.setState({ isLoading: true });

        const result = await userApiService.blockUser(id);

        this.setState({ isLoading: false });
        if (!result.success) {
            this.toast?.show({ severity: "error", summary: "Error", detail: result.message, life: 3000 });
            return;
        }

        //history.push("/users");
    };

    resetKycStatus = async (id: number) => {
        this.setState({ isLoading: true });

        const result = await kycSubmissionApiService.resetKycStatus(id);

        this.setState({ isLoading: false });
        if (!result.success) {
            this.toast?.show({ severity: "error", summary: "Error", detail: result.message, life: 3000 });
            return;
        }

        window.location.reload();
    };

    resetEmail = async (id: number) => {
        this.setState({ isLoading: true });

        const result = await userApiService.resetEmail(id);

        this.setState({ isLoading: false });
        if (!result.success) {
            this.toast?.show({ severity: "error", summary: "Error", detail: result.message, life: 3000 });
            return;
        }
        window.location.reload();
    };

    resetMobile = async (id: number) => {
        this.setState({ isLoading: true });

        const result = await userApiService.resetPhone(id)

        this.setState({ isLoading: false });
        if (!result.success) {
            this.toast?.show({ severity: "error", summary: "Error", detail: result.message, life: 3000 });
            return;
        }

        window.location.reload();
    };

    renderBadge(success: boolean, title: string) {
        return <div className={classNames("badge", "badge-" + (success ? "success" : "danger"))}>{title}</div>;
    }

    onImageSelected = async (image: File) => {
        const userDetails = this.state.user;
        userDetails!.photo = (await utils.convertFileToBase64(image)) as string;
        this.setState({ user: userDetails });
    };

    fileInput = (input: boolean) => {
        if (!input) {
            this.toast?.show({ severity: "error", summary: "File Name", detail: "File Name Exceeds 128 Characters.", life: 3000 });
            return;
        }

    };
    getImageSource = (): string => {
        return this.state.user?.photo === null ? "" : utils.appendBase64Data(this.state.user!.photo);
    };

    onFieldUpdate = (fieldName: string, value: any) => {
        const userData = this.state.user as any;
        userData[fieldName] = value;
        this.setState({ user: userData });
    };

    renderUserDetails = () => {
        const isReadOnly = this.state.user?.kycStatus != null && this.kycStatusTypes.get(this.state.user?.kycStatus ?? -1) !== "New" ? true : false;

        if (this.state.isLoading || !this.state.user) {
            return (
                <Row>
                    <Col md={12} lg={12}>
                        <Card style={{ minHeight: "500px" }} className="align-center">
                            <ReactLoader type="ball-pulse" active />
                        </Card>
                    </Col>
                </Row>
            );
        }

        return <Row>
            <Col md="3" lg="3" sm="12" style={{ padding: "2em" }}>
                <ProfileImage
                    imageStyle={{ width: "100%" }}
                    className="rounded-circle"
                    src={this.getImageSource()}
                    alt=""
                    onImageSelected={this.onImageSelected}
                    fileInput={this.fileInput}
                />
            </Col>
            <Col md={9} lg={9} sm="12">
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="firstName">First Name</Label>
                            <InputText
                                value={this.state.user.firstName}
                                readOnly={isReadOnly}
                                className={"form-control"}
                                id="firstName"
                                onChange={(e) => this.onFieldUpdate("firstName", e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="lastName">Last Name</Label>
                            <InputText
                                value={this.state.user.lastName}
                                readOnly={isReadOnly}
                                className={"form-control"}
                                id="lastName"
                                onChange={(e) => this.onFieldUpdate("lastName", e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="nonLatinFirstName">Non-Latin First Name</Label>
                            <InputText
                                value={this.state.user.nonLatinFirstName}
                                readOnly={isReadOnly}
                                className={"form-control"}
                                id="nonLatinFirstName"
                                onChange={(e) => this.onFieldUpdate("nonLatinFirstName", e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="nonLatinLastName">Non-Latin Last Name</Label>
                            <InputText
                                value={this.state.user.nonLatinLastName}
                                readOnly={isReadOnly}
                                className={"form-control"}
                                id="nonLatinLastName"
                                onChange={(e) => this.onFieldUpdate("nonLatinLastName", e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="personalIdentificationNumber">Personal Identification Number</Label>
                            <InputText
                                value={this.state.user.personalIdentificationNumber}
                                className={"form-control"}
                                type="text"
                                name="personalIdentificationNumber"
                                id="personalIdentificationNumber"
                                onChange={(e) => this.onFieldUpdate("personalIdentificationNumber", e.target.value)}
                                readOnly={isReadOnly}
                            />
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="email">Email Address</Label>
                            <InputText
                                value={this.state.user.email}
                                readOnly={isReadOnly}
                                className={"form-control"}
                                type="email"
                                name="email"
                                id="email"
                                onChange={(e) => this.onFieldUpdate("email", e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                    <Col
                        md={3}
                        style={{ display: "flex", alignItems: "center", padding: "15px 10px 5px 10px" }}
                    >
                        <div
                            className={classNames(
                                "badge",
                                "badge-" + this.getVerificationClassName(this.state.user.isEmailVerified)
                            )}
                        >
                            {this.state.user.isEmailVerified ? "Verified" : "Not Verified"}
                        </div>
                    </Col>
                    {isUserProfileUpdateAllowed() && <Col md={3} style={{ padding: "35px 10px" }}>
                        <a
                            href="#"
                            onClick={this.resetEmailDialog}
                        >
                            Reset Email Address
                        </a>
                        <br></br>
                        <a
                            href="#"
                            onClick={this.resetMobileDialog}
                        >
                            Reset Mobile
                        </a>
                        <br></br>
                        {
                            this.kycStatusTypes.get(this.state.user.kycStatus ?? 1) !== "New" && (

                                <a href="#" onClick={this.resetKycStatusDialog}>
                                    Reset Kyc Status
                                </a>

                            )
                        }
                        <br></br>
                        {isUserProfileUpdateAllowed() && this.statusTypes.get(this.state.user.status) === "Deactivated" && (
                            <>
                                <a
                                    href="#"
                                    onClick={() => this.deactiveOrActiveUserAccount(this.state.userId)}
                                >
                                    Activate
                                </a>
                                <span> | </span>
                                <a
                                    style={{ padding: "0 2px" }}
                                    href="#"
                                    onClick={() => this.closeUser(this.state.userId)}
                                >
                                    Close
                                </a>
                                <span> | </span>
                                <a
                                    style={{ padding: "0 2px" }}
                                    href="#"
                                    onClick={() => this.blockUserAccount(this.state.userId)}
                                >
                                    Add to Blacklist
                                </a>
                            </>
                        )}

                        {isUserProfileUpdateAllowed() && this.statusTypes.get(this.state.user.status) === "Active" && (
                            <>
                                <a
                                    href="#"
                                    onClick={this.deactivateDialog}
                                >
                                    Deactivate
                                </a>
                            </>
                        )}




                    </Col>}
                </Row>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="mobile">Mobile</Label>
                            <InputText
                                value={this.state.user.phoneNumber}
                                readOnly={isReadOnly}
                                className={"form-control"}
                                type="text"
                                name="phoneNumber"
                                id="phoneNumber"
                                onChange={(e) => this.onFieldUpdate("phoneNumber", e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                    <Col
                        md={3}
                        style={{ display: "flex", alignItems: "center", padding: "15px 10px 5px 10px" }}
                    >
                        <div
                            className={classNames(
                                "badge",
                                "badge-" +
                                this.getVerificationClassName(this.state.user.isPhoneNumberVerified)
                            )}
                        >
                            {this.state.user.isPhoneNumberVerified === true ? "Verified" : "Not Verified"}
                        </div>
                    </Col>
                    {isUserProfileUpdateAllowed() && <Col md={3} style={{ padding: "35px 10px" }}>

                    </Col>}
                </Row>
                <Row form>
                    <Col md={3}>
                        <FormGroup>
                            <Label>
                                <b>Kyc Status</b>
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col className="pl-0" md={3}>
                        <div
                            className={classNames(
                                "badge",
                                "align-top",
                                "badge-" +
                                this.getKycStatusVerificationClassName(this.state.user.kycStatus ?? 1)
                            )}
                        >
                            {this.kycStatusTypes.get(this.state.user.kycStatus ?? 1)}
                        </div>
                    </Col>

                </Row>
                <Row form>
                    <Col md={3}>
                        <Label>
                            <b>CBS Onboarded</b>
                        </Label>
                    </Col>
                    <Col className="pl-0" md={1.5}>
                        <b>
                            <div className={classNames("badge", "badge-" + this.getCbsOnboardedClassName(this.state.user.cbsOnboarded))}>
                                {this.state.user.cbsOnboarded ? "Onboarded" : "Not-onboared"}
                            </div>
                        </b>
                    </Col>
                </Row>
                <Row>
                    <Col md={3}>
                        <FormGroup>
                            <Label>
                                <b>User Status</b>
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col className="pl-0" md={3}>
                        <div
                            className={classNames(
                                "badge",
                                "badge-" + this.getStatusVerificationClassName(this.state.user.status)
                            )}
                        >
                            {this.statusTypes.get(this.state.user.status)}
                        </div>
                    </Col>
                    {isUserProfileUpdateAllowed() && this.statusTypes.get(this.state.user.status) === "Deactivated" && (
                        <Col>
                            <a
                                href="#"
                                onClick={() => this.deactiveOrActiveUserAccount(this.state.userId)}
                            >
                                Activate
                            </a>
                            <span> | </span>
                            <a
                                style={{ padding: "0 2px" }}
                                href="#"
                                onClick={() => this.closeUser(this.state.userId)}
                            >
                                Close
                            </a>
                            <span> | </span>
                            <a
                                style={{ padding: "0 2px" }}
                                href="#"
                                onClick={() => this.blockUserAccount(this.state.userId)}
                            >
                                Add to Blacklist
                            </a>
                        </Col>
                    )}
                    {isUserProfileUpdateAllowed() && this.statusTypes.get(this.state.user.status) === "Active" && (
                        <Col>

                        </Col>
                    )}
                </Row>
                <Row form>
                    <Col md={3}>
                        <Label>
                            <b>Crypto Status</b>
                        </Label>
                    </Col>
                    <Col className="pl-0" md={3}>
                        <Checkbox
                            type="checkbox"
                            checked={this.state.user.cryptoCustomer}
                            onChange={e => this.handleCryptoStatusChange(e)}
                            disabled={isReadOnly}
                        />
                        <span className={classNames(this.getCryptoCustomerClassName(this.state.user.cryptoCustomer))}>
                            <b className="ml-1">{this.state.user.cryptoCustomer ? "Crypto customer" : "Non-Crypto customer"}</b>
                        </span>
                    </Col>
                </Row>
                <Row form>
                    <Col md={3}>
                        <Label>
                            <b>Primary Language</b>
                        </Label>
                    </Col>
                    <Col className="pl-0" md={3}>
                        <Label>{this.state.user.primaryLanguage}</Label>
                    </Col>
                </Row>
                <Row form>
                    <Col md={3}>
                        <Label>
                            <b>Merchant</b>
                        </Label>
                    </Col>
                    <Col className="pl-0" md={3}>
                        {this.state.user.merchant}&emsp;&emsp;<b className="text-danger">Branch - {this.state.user.flexCubeBranch}</b>
                    </Col>
                </Row>
                <Row form>
                    <Col md={6}>
                        <InlineEditableInput
                            inputType={InputType.Text}
                            readOnly={true}
                            title="Tariff"
                            value={this.state.user.tariff}
                            onSubmit={(value) => {
                                this.updateValue("tariff", value);
                            }}
                            className='pl-0'
                        />
                    </Col>
                </Row>
                <Row form>
                    <Col md={6}>
                        {this.state.user.kycStatus != null && (
                            <InlineEditableInput
                                inputType={InputType.DropDown}
                                readOnly={true}
                                title="Kyc Level"
                                options={this.kycLevels}
                                value={this.getKycLevel(this.state.user.kycLevel)!}
                                onSubmit={(value) => {
                                    this.onKycLevelSubmit(parseInt(value));
                                }}
                            />
                        )}
                    </Col>
                </Row>
            </Col>
        </Row>;
    };

    render() {
        return (
            <Fragment>
                <TransitionGroup component="div" appear={true} exit={false} enter={false}>
                    <CSSTransition classNames="TabsAnimation" timeout={1500}>
                        <PageTitle heading="User Profile" icon={faUserGear} />
                    </CSSTransition>
                    <CSSTransition classNames="TabsAnimation" timeout={1500}>
                        <Fragment>
                            <Toast ref={(el) => (this.toast = el)} position={"bottom-center"} baseZIndex={99999}></Toast>
                            <Container fluid>
                                <div>
                                    <Row>
                                        <Col md="12" lg="12">
                                            <Card className="mb-3">
                                                <CardHeader className="card-header-tab">
                                                    <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                                                        <i className="header-icon lnr-laptop-phone mr-3 text-muted opacity-6"> </i>
                                                        User Details
                                                    </div>
                                                </CardHeader>
                                                <CardBody>
                                                    {this.renderUserDetails()}
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col md={12} lg={12}>
                                            {this.state.user && <MerchantWalletsComponent Id={this.state.userId} cbsId={this.state.user.cbsId} walletType={WalletType.User} />}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12" lg="12">
                                            <Card className="mb-3">
                                                <CardHeader className="card-header-tab"></CardHeader>
                                                <CardBody>
                                                    <TabView activeIndex={this.state.activeTabName}>
                                                        <TabPanel header="General">
                                                            <GeneralDataComponent
                                                                userData={this.state.user}
                                                                groupsSelectItem={this.state.groupsSelectItem}
                                                                kycStatusTypes={this.kycStatusTypes}
                                                            />
                                                        </TabPanel>
                                                        <TabPanel header="KYC">
                                                            {this.state.user && <UserKycSubmissionsComponent
                                                                individualId={this.state.userId}
                                                                kycStatusTypes={this.kycStatusTypes}
                                                                userKycStatusClassesMap={this.userKycStatusClassesMap}
                                                                kycStatusValue={this.state.user.kycStatus}
                                                            />}
                                                        </TabPanel>
                                                        <TabPanel header="Financial">
                                                            <FinancialDataComponent userId={this.state.userId} cbsOnboarded={this.state.user?.cbsOnboarded}/>
                                                        </TabPanel>
                                                        {isIndividualTransactionViewAllowed() &&
                                                            <TabPanel header="Transactions">
                                                            <UserTransactions userEmail={this.state.user?.email} userId={this.state.userId} showExpander={false} />
                                                        </TabPanel>}
                                                        {isJournalingAllowed() &&
                                                            <TabPanel header="Journaling">
                                                                <UserJournalingComponent userId={this.state.userId} />
                                                            </TabPanel>}
                                                        <TabPanel header="Misc">
                                                            <MiscComponent userId={this.state.userId} />
                                                        </TabPanel>
                                                    </TabView>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </div>
                            </Container>
                        </Fragment>
                    </CSSTransition>
                </TransitionGroup>
            </Fragment>
        );
    }
}