import React, { Component } from "react";
import { ExtendedSelectItem, SelectItem } from "../../models/SelectItem";
import { InputText } from "primereact/inputtext";
import { Container, Row, Col, FormGroup, Label, Card, CardBody, CardTitle } from "reactstrap";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactLoader from "react-loaders";
import { merchantPaymentMethodApiService } from "../../services/api/merchants/MerchantPaymentMethodApiService";
import { CreateIbanPaymentMethodRequest, MerchantPaymentMethod } from "../../models/MerchantModel";
import { AddBank } from "./AddBank";
import { Toast } from "primereact/toast";
import { faCheck, faMoneyCheckAlt } from "@fortawesome/free-solid-svg-icons";
import { utils } from "../../utils/utils";
import { MerchantWalletBalanceRequest, Wallets } from "../../models/FinancialData";
import * as Yup from "yup";
import { Amount_Valid_REGEX, AppConstants, Notes_And_Reason_Length, Withdraw_Max_Amount } from "../../models/AppConstants";
import { ErrorMessage, Formik, FormikErrors, FormikHelpers, FormikTouched } from "formik";
import { merchantWalletApiService } from "../../services/api/merchants/MerchantWalletApiService";
import { CalculateMoneyTransferChargeRequest, PaymentMethodStatus, PaymentMethodType, PaymentType, WithdrawToAnyIBANMoneyTransferRequest, WithdrawToAnyIBANMoneyTransferRequestDetails } from "../../models/MoneyTransferModal";
import { InputTextarea } from "primereact/inputtextarea";
import { merchantPaymentApiService } from "../../services/api/merchants/MerchantPaymentApiService";
import WithdrawConfirmDialogue from "./WithdrawConfirmDialogue";
import { OperatorDataModel } from "../../models/User";

interface WithdrawDialogProps {
    loggedUserData: OperatorDataModel | null;
    sourceAccountsOptions: ExtendedSelectItem[];
    sourceWallets: Wallets[];
    merchantId: number;
}

enum CurrentStage {
    Withdraw,
    AddNew
}

interface WithdrawModal {
    selectSourceAccount: string;
    selectBeneficiaryBank: string;
    amount: string;
    paymentNotes: string;
    selectSourceName: string;
    selectSourceCurrency: string;
    sourceBalance: number;
    selectBeneficiaryName: string;
    beneficiaryCurrency: string;
    beneficiaryCustomerBankName: string
}

interface WithdrawDialogState {
    amount: number,
    isLoading: boolean;
    maxFunds: number;
    currentStage: CurrentStage;
    selectedBank: string | null;
    banks: MerchantPaymentMethod[];
    isSubmitting: boolean;
    btnLoader: boolean,
    beneficiaryPaymentMethodOptions: ExtendedSelectItem[],
    withdrawRequestDetails: WithdrawToAnyIBANMoneyTransferRequestDetails | null,
    successAlert: boolean;
    tID: string;
}

const validationSchema = Yup.object().shape({
    selectSourceAccount: Yup.string().required("Please select source account number."),
    selectBeneficiaryBank: Yup.string().required("Please select beneficiary account number"),
    amount: Yup.string()
        .required("Please enter amount.")
        .test("isValidAmount", "Please enter a valid amount, maximum.", (inputAmount) => {
            const amountValue = Number(inputAmount.replace(",", ""));
            return amountValue <= Withdraw_Max_Amount && Amount_Valid_REGEX.test(inputAmount);
        })
        .test("isSufficientBalance", "You have insufficient funds in your wallet. Please choose another account.", (inputAmount, { parent }) => {
            const amountValue = Number(inputAmount.replace(",", ""));
            return amountValue <= parent.sourceBalance;
        }),
    paymentNotes: Yup.string().required("Please enter payment notes.").max(Notes_And_Reason_Length, "Notes and reasons must not exceed " + Notes_And_Reason_Length + " characters."),
    sourceBalance: Yup.number(),
    selectSourceCurrency: Yup.string(),
    // beneficiaryCurrency: Yup.string()
    //     .test("CurrencyMatch", "Selected Source and Beneficiary Currencies Do No Match", (inputCurrency, { parent }) => {
    //         const SourceCurrency = parent.selectSourceCurrency;
    //         const beneficiaryCurrency = parent.beneficiaryCurrency;
    //         if (SourceCurrency !== beneficiaryCurrency) {
    //             return false;
    //         }
    //         return true;
    //     })
});

export default class WithdrawComponentDialog extends Component<WithdrawDialogProps, WithdrawDialogState> {
    formikInitialValue: WithdrawModal;
    toast: Toast | null = null;

    constructor(props: WithdrawDialogProps | Readonly<WithdrawDialogProps>) {
        super(props);

        this.state = {
            amount: 0,
            currentStage: CurrentStage.Withdraw,
            isLoading: true,
            banks: [],
            maxFunds: Number.MAX_VALUE,
            selectedBank: null,
            isSubmitting: false,
            btnLoader: false,
            beneficiaryPaymentMethodOptions: [],
            withdrawRequestDetails: null,
            successAlert: false,
            tID: "",
        },
            this.formikInitialValue = {
                selectSourceAccount: "",
                selectBeneficiaryBank: "",
                amount: "",
                paymentNotes: "",
                selectSourceName: "",
                selectSourceCurrency: "",
                sourceBalance: 0,
                selectBeneficiaryName: "",
                beneficiaryCurrency: "",
                beneficiaryCustomerBankName: "",
            }
    }

    componentDidMount(): void {
        this.onLoad();
    }

    componentWillUnmount(): void {
        this.toast?.clear();
    }

    onLoad = async () => {
        this.setState({ isLoading: true, currentStage: CurrentStage.Withdraw });
        const paymentMethods = await merchantPaymentMethodApiService.getAll(this.props.merchantId);

        if (!paymentMethods.success) {
            this.setState({ isLoading: false });
            this.toast?.show({ severity: "error", summary: "Error", detail: paymentMethods.message, life: 3000 });
            return;
        }
        const bankPaymentMethodsApproved = paymentMethods.data.filter((a) => a.status == PaymentMethodStatus.Approve && a.paymentType.toLowerCase() == PaymentMethodType.Bank.toLowerCase() || a.status == PaymentMethodStatus.Approve && a.paymentType.toLowerCase() == PaymentMethodType.NonIBAN.toLowerCase());
        const beneficiaryWalletDropDown = bankPaymentMethodsApproved.map((method) => {
            return { label: `${method.ownerName} ${method.value}`, value: method.value, details: { faicons: "university", fullname: method.ownerName, subtitle: method.bankName, type: method.paymentType.toLocaleLowerCase() } } as ExtendedSelectItem;
        });

        this.setState({ isLoading: false, isSubmitting: false, banks: bankPaymentMethodsApproved, beneficiaryPaymentMethodOptions: beneficiaryWalletDropDown });
    };

    handleSubmit = async (values: WithdrawModal) => {
        this.setState({ btnLoader: true });
        await this.onContinue(values);
        this.setState({ btnLoader: false });
    };

    getWalletBalance = async (accountNumber: string) => {
        const merchantBalanceRequest: MerchantWalletBalanceRequest = {
            merchantId: this.props.merchantId,
            accountNumber: accountNumber
        }
        const balanceResponse = await merchantWalletApiService.getWalletBalance(merchantBalanceRequest);
        if (!balanceResponse.success) {
            this.setState({ isLoading: false });
            this.toast?.show({ severity: "error", summary: "Error", detail: balanceResponse.message, life: 3000 });
            return;
        }
        return balanceResponse.data;
    };

    handleSourceAccountChange = async (e: { value: string }, setFieldValue: FormikHelpers<WithdrawModal>['setFieldValue'], values: WithdrawModal) => {
        this.setState((prevState) => ({ ...prevState, isLoading: true }));
        const response = await this.getWalletBalance(this.props.sourceWallets.find((i) => i.ibanAccountNumber === e.value)?.id ?? "");
        if (response == null) {
            this.setState((prevState) => ({ ...prevState, isLoading: false }));
            return;
        }
        setFieldValue("selectSourceCurrency", response?.currency ?? "");
        setFieldValue("sourceBalance", response?.balance ?? 0);
        this.setState((prevState) => ({ ...prevState, isLoading: false }));
    };

    itemTemplate = (option: ExtendedSelectItem) => {
        return (
            <div>
                <div className="text-primary">{option.details?.fullname}</div>
                <small className="text-muted">{option.value}</small>
            </div>
        );
    }

    onSuccess = async (item: CreateIbanPaymentMethodRequest) => {
        this.setState({ isSubmitting: true });
        const result = await merchantPaymentMethodApiService.create(this.props.merchantId, item);

        if (result.success) {
            this.onLoad();
        } else {
            this.setState({ isSubmitting: false });
            this.toast?.show({ severity: "error", summary: "Error", detail: result.message, life: 3000 });
        }
    };


    onContinue = async (data: WithdrawModal) => {
        if (data.selectSourceCurrency != data.beneficiaryCurrency) {
            this.toast?.show({ severity: "error", summary: "Error", detail: "Source and Beneficiary Currencies Do No Match", life: 3000 });
            return;
        }
        const calculateMoneyTransferChargeRequest: CalculateMoneyTransferChargeRequest = {
            customerAccount: data.selectSourceAccount,
            amount: Number(data.amount),
            id: this.props.loggedUserData?.id ?? 0,
            PaymentType: PaymentType.P2IbanWithdrawal
        };

        const calculateMoneyTransferChargeReponse = await merchantPaymentApiService.getMoneyTransferCharge(calculateMoneyTransferChargeRequest);

        if (!calculateMoneyTransferChargeReponse.success) {
            this.setState({ btnLoader: false });
            this.toast?.show({ severity: "error", summary: "Error", detail: calculateMoneyTransferChargeReponse.message, life: 3000 });
            return;
        }

        const transactioDetails: WithdrawToAnyIBANMoneyTransferRequestDetails = {
            sourceCustomerAccount: data.selectSourceAccount,
            sourceCustomerName: data.selectSourceName,
            sourceCustomerCurrency: data.selectSourceCurrency,
            beneficiaryCustomerAccount: data.selectBeneficiaryBank,
            beneficiaryCustomerCurrency: data.beneficiaryCurrency,
            beneficiaryCustomerName: data.selectBeneficiaryName,
            transactionAmount: Number(data.amount),
            paymentNotes: data.paymentNotes,
            transactionFee: calculateMoneyTransferChargeReponse.data.transactionFee,
            sourceCurrentBalance: data.sourceBalance,
            beneficiaryCustomerBankName: data.beneficiaryCustomerBankName
        };
        this.setState({ withdrawRequestDetails: transactioDetails, btnLoader: false });
    };

    renderLaoding = () => {
        if (this.state.isLoading) {
            return (
                <div className="d-flex justify-content-center align-items-center">
                    <div className="m-3">
                        {/* <ProgressSpinner  style={{width: '50px', height: '50px'}}/> */}
                        <ReactLoader type="ball-grid-pulse" active />
                    </div>
                </div>
            )
        }

        if (this.state.banks.length <= 0) {
            return <div style={{ textAlign: "center", margin: "50px" }}>
                <b>No Bank Accounts Available</b>
            </div>;
        }

    }


    onSubmit = async () => {
        try {
            this.setState({ isSubmitting: true });
            const toAnyIbanWithdrawalObject: WithdrawToAnyIBANMoneyTransferRequest = {
                sourceCustomerAccount: this.state.withdrawRequestDetails?.sourceCustomerAccount!,
                beneficiaryCustomerAccount: this.state.withdrawRequestDetails?.beneficiaryCustomerAccount!,
                beneficiaryCustomerName: this.state.withdrawRequestDetails?.beneficiaryCustomerName!,
                amount: Number(this.state.withdrawRequestDetails?.transactionAmount),
                notes: this.state.withdrawRequestDetails?.paymentNotes,
                currency: this.state.withdrawRequestDetails?.sourceCustomerCurrency!
            };
            const toAnyIbanWithdrawalResponse = await merchantPaymentApiService.toAnyIbanWithdrawal(this.props.loggedUserData?.id ?? 0, toAnyIbanWithdrawalObject);
            if (!toAnyIbanWithdrawalResponse.success) {
                this.setState({ isSubmitting: false });
                this.toast?.show({ severity: "error", summary: "Error", detail: toAnyIbanWithdrawalResponse.message, life: 3000 });
                return;
            }
            this.setState({ isSubmitting: false, successAlert: true, tID: toAnyIbanWithdrawalResponse.data });
        }

        catch (error) {
            console.log("Exception occure in withdraw submitting----", error)
            this.setState({ isSubmitting: false });
        }

    };

    render(): React.ReactNode {

        if (this.state.currentStage == CurrentStage.AddNew) {
            return <>
                <Toast ref={(el) => (this.toast = el)} position={"bottom-center"} baseZIndex={99999}></Toast>
                <AddBank selectedMerchantId={this.props.merchantId} isSubmitting={this.state.isSubmitting} OnSuccess={this.onSuccess} OnBack={() => this.onLoad()} />
            </>;
        }

        if (this.state.successAlert) {
            return (<Container>
                <Card className="p-0 m-0">
                    <CardBody>
                    <FontAwesomeIcon size="5x" icon={faCheck as any} className="text-success" style={{ margin: "auto", display: "block" }} />
                        <CardTitle className="align-center">
                            <Row>
                                <Col>
                                    <Label >TID:</Label>
                                </Col>
                                <Col>
                                    <div>{this.state.tID}</div>
                                </Col>
                            </Row>
                        </CardTitle>
                        <div className="align-center" >
                            Success!. Your money is on the way
                        </div>
                    </CardBody>
                </Card>

            </Container>
            )
        }


        return (
            <>
                <Toast ref={(el) => (this.toast = el)} position={"bottom-center"} baseZIndex={99999}></Toast>
                {!this.state.successAlert &&
                    <Card className="p-0 m-0">
                        <CardBody>
                            <Container fluid>
                                <FontAwesomeIcon size="5x" icon={faMoneyCheckAlt as any} className="text-info" style={{ margin: "auto", display: "block" }} />
                                <Formik
                                    validateOnChange={true}
                                    validateOnBlur={true}
                                    initialValues={this.formikInitialValue}
                                    validationSchema={validationSchema}
                                    onSubmit={async (values) => {
                                        await this.handleSubmit(values);
                                    }}
                                >
                                    {({ values, errors, touched, handleSubmit, setFieldValue, handleBlur }) => (
                                        <>
                                            {this.renderLaoding()}
                                            <>
                                                {values.selectSourceCurrency &&
                                                    <div className="d-flex justify-content-end align-items-end">
                                                        <p>
                                                            Bal: <span className="text-success">{utils.formatBalance(values.sourceBalance, values.selectSourceCurrency)}</span>
                                                        </p>
                                                    </div>
                                                }

                                                <FormGroup>
                                                    <Label>Select source Account</Label>
                                                    <Dropdown
                                                        style={{ width: "100%" }}
                                                        appendTo={document.body}
                                                        value={values.selectSourceAccount}
                                                        onChange={(e) => {
                                                            this.handleSourceAccountChange(e, setFieldValue, values)
                                                            setFieldValue("selectSourceAccount", e.value)
                                                            setFieldValue("selectSourceName", this.props.sourceAccountsOptions.find(i => i.value == e.value)?.details?.fullname ?? "")
                                                        }}
                                                        name="selectSourceAccount"
                                                        options={this.props.sourceAccountsOptions}
                                                        required={true}
                                                        filter
                                                        disabled={this.state.beneficiaryPaymentMethodOptions.length == 0 || this.state.btnLoader}
                                                        className={`${errors.selectSourceAccount && touched.selectSourceAccount ? 'p-invalid' : ''}`}
                                                    />
                                                    <ErrorMessage component="small" name="selectSourceAccount" className="p-error" />
                                                </FormGroup>

                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>Select Beneficiary Account</Label>
                                                            <Dropdown
                                                                disabled={this.state.beneficiaryPaymentMethodOptions.length == 0 || this.state.btnLoader}
                                                                style={{ width: "100%" }}
                                                                appendTo={document.body}
                                                                value={values.selectBeneficiaryBank}
                                                                onChange={(e) => {
                                                                    const selectedBeneficiaryIBAN = this.state.banks.find((i) => i.value === e.value);
                                                                    setFieldValue("selectBeneficiaryName", selectedBeneficiaryIBAN?.ownerName ?? "");
                                                                    setFieldValue("beneficiaryCurrency", selectedBeneficiaryIBAN?.currency ?? "");
                                                                    setFieldValue("beneficiaryCustomerBankName", selectedBeneficiaryIBAN?.bankName ?? "");
                                                                    setFieldValue("selectBeneficiaryBank", e.value)
                                                                }}
                                                                name="selectBeneficiaryBank"
                                                                options={this.state.beneficiaryPaymentMethodOptions}
                                                                required={true}
                                                                filter
                                                                className={`${errors.selectBeneficiaryBank && touched.selectBeneficiaryBank ? 'p-invalid' : ''}`}
                                                                itemTemplate={this.itemTemplate}
                                                            />
                                                            <ErrorMessage component="small" name="selectBeneficiaryBank" className="p-error" />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>

                                                <FormGroup>
                                                    <Label>Amount</Label>
                                                    <InputText
                                                        style={{ backgroundColor: 'transparent' }}
                                                        required={true}
                                                        value={values.amount}
                                                        onChange={(inputValue) => {
                                                            const formattedAmount = utils.formatAmount(inputValue.target.value);
                                                            setFieldValue("amount", formattedAmount);
                                                        }}
                                                        disabled={values.selectSourceAccount == "" || this.state.btnLoader}
                                                        name="amount"
                                                        className={`form-control ${errors.amount && touched.amount ? 'p-invalid' : ''}`}
                                                        maxLength={10}
                                                    />
                                                    <ErrorMessage component="small" name="amount" className="p-error" />
                                                </FormGroup>

                                                <FormGroup>
                                                    <Label>Payment Notes</Label>
                                                    <InputTextarea
                                                        style={{ width: "100%" }}
                                                        required={true}
                                                        value={values.paymentNotes}
                                                        onChange={(value) => setFieldValue("paymentNotes", value.target.value)}
                                                        readOnly={values.selectSourceAccount == "" || this.state.btnLoader}
                                                        name="paymentNotes"
                                                        className={`${errors.paymentNotes && touched.paymentNotes ? 'p-invalid' : ''}`}
                                                        maxLength={AppConstants.NotesAndReasonLength}
                                                    />
                                                    <ErrorMessage component="small" name="paymentNotes" className="p-error" />
                                                </FormGroup>
                                            </>

                                            <Row>
                                                <Col xl={{ size: 4, offset: 5 }} lg={{ size: 5, offset: 3 }} md={{ size: 6 }} sm={{ size: 12 }}>
                                                    <Button
                                                        style={{ width: "100%" }}
                                                        label="Add New Bank"
                                                        type="button"
                                                        icon="pi pi-plus"
                                                        onClick={() => this.setState({ currentStage: CurrentStage.AddNew })}
                                                        className="p-button-outlined"
                                                    ></Button>
                                                </Col>
                                                <Col xl={{ size: 3 }} lg={{ size: 4 }} md={{ size: 6 }} sm={{ size: 12 }}>
                                                    <Button
                                                        iconPos="right"
                                                        style={{ width: "100%" }}
                                                        label="Continue"
                                                        type="button"
                                                        onClick={() => handleSubmit()} loading={this.state.btnLoader} disabled={this.state.btnLoader}
                                                        //  onClick={() => this.props.OnSuccess(this.state.amount, this.state.banks.find(a => a.value == this.state.selectedBank))}
                                                        className="p-button-primary"
                                                    ></Button>
                                                </Col>
                                            </Row>
                                        </>
                                    )}
                                </Formik>
                            </Container>
                        </CardBody>
                    </Card>
                }

                {this.state.withdrawRequestDetails != null && !this.state.successAlert &&
                    <WithdrawConfirmDialogue
                        withdrawToIBANDetails={this.state.withdrawRequestDetails}
                        isSubmitting={this.state.isSubmitting}
                        onSubmit={this.onSubmit}
                        onHide={() => {
                            this.setState({ isSubmitting: false, withdrawRequestDetails: null });
                        }} />
                }
            </>

        );

    }
}
