import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Card } from "reactstrap";
import { Toast } from "primereact/toast";
import React, { Component } from "react";
import Loader from "../../components/Loader";
import { MerchantAddresses } from "../../models/MerchantModel";
import { SelectItem } from "../../models/SelectItem";
import { merchantAddressApiService } from "../../services/api/merchants/MerchantAddressApiService";
import AddMerchantAddress from "./AddMerchantAddress";
import { confirmDialog } from "primereact/confirmdialog";
import { httpService } from "../../services/HttpService";
import { isMerchantProfileUpdateAllowed } from "../../authGuard";
import { utils } from "../../utils/utils";

interface MerchantAddressProps {
    merchantId: number;
    localMode: boolean;
    onLocalAddressesUpdated?: (contact: MerchantAddresses[]) => void;
    merchantAddresses?: MerchantAddresses[];
}

interface MerchantAddressState {
    address: MerchantAddresses[];
    isLoading: boolean;
    isAddressDetailVisible: boolean;
    selectedAddress: MerchantAddresses | null;
}

export default class MerchantAddress extends Component<MerchantAddressProps, MerchantAddressState> {
    toast: Toast | null = null;

    addressTypes: SelectItem[] = [];
    countries: SelectItem[] = [];
    constructor(props: MerchantAddressProps | Readonly<MerchantAddressProps>) {
        super(props);

        this.state = {
            address: this.props.localMode ? this.props.merchantAddresses! : [],
            isLoading: true,
            isAddressDetailVisible: false,
            selectedAddress: null,
        };
    }

    async componentDidMount() {
        const countriesResult = await utils.getCountriesResult();
        
        if (!countriesResult.success) {
            this.toast?.show({ severity: "error", summary: "Error", detail: countriesResult.message, life: 3000 });
            this.setState({ isLoading: false });
            return;
        }

        this.countries = utils.getCountries();

        const addressTypes = await merchantAddressApiService.getAddressTypes();

        if (!addressTypes.success) {
            this.toast?.show({ severity: "error", summary: "Error", detail: addressTypes.message, life: 3000 });
            this.setState({ isLoading: false });

            return;
        }

        this.setState({ isLoading: false });
        this.addressTypes = addressTypes.data;

        await this.loadAddress();
    }

    loadAddress = async () => {
        if(this.props.localMode) {
            return;
        }

        this.setState({ isLoading: true });
       
        const address = await merchantAddressApiService.getAll(this.props.merchantId);
       
        this.setState({ isLoading: false });

        if (!address.success) {
            this.toast?.show({ severity: "error", summary: "Error", detail: address.message, life: 3000 });
            return;
        }

        this.setState({ address: address.data });
    };

    renderHeader = () => {
        return (
            <div className="table-header">
                <span className="p-input-icon-left align-right">
                    <i className="pi" />
                    {isMerchantProfileUpdateAllowed() && <Button
                        label="Add Address"
                        className={"p-button-outlined"}
                        icon="pi pi-plus"
                        onClick={() => this.addorEditAddress(this.getEmptyAddress())}
                    />}
                </span>
            </div>
        );
    };

    getEmptyAddress = (): MerchantAddresses => {
        return { id: 0, addressLine: "", city: "", zipCode: "", countryId: "", addressType: "", stateProvinceCounty: "", merchantId: this.props.merchantId };
    };

    addorEditAddress = (rowData: MerchantAddresses) => {
        this.setState({ isAddressDetailVisible: true, selectedAddress: rowData });
    };

    onDetailsModalClose = async (address? : MerchantAddresses) => {
        this.setState({ isAddressDetailVisible: false });

        if(this.props.localMode && address) {
            const addresses = this.state.address;
            if(addresses.length < 2 )
            {
                if(addresses.find(i => i.addressType === address.addressType))   {
                    this.toast?.show({ severity: "error", summary: "Error", detail: "Address Type already exists", life: 3000 });
                } 
                else
                {
                    addresses.push(address!);
                    this.setState({ address: addresses });
                    this.props.onLocalAddressesUpdated!(this.state.address);
                }
            }
            else
            {
                this.toast?.show({ severity: "error", summary: "Error", detail: "Address limit exceeds.", life: 3000 });
            }
        }
        await this.loadAddress();
    };

    confirmDeleteAddress = async (address: MerchantAddresses) => {
        if(this.props.localMode) {
            const addresses = this.state.address;
            const index = addresses.findIndex(f => f.addressLine == address.addressLine && f.addressType == address.addressType && f.city == address.city && f.countryId == address.countryId && f.zipCode == address.zipCode);

            if (index >= 0) {
                addresses.splice(index, 1);
            }

            this.setState({ address: addresses });
    
            this.props.onLocalAddressesUpdated!(this.state.address);
            return;
        }
        
        confirmDialog({
            message: "This action will remove the address and is irreversible. Are you sure you want to proceed?",
            header: "Delete Address",
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            accept: () => this.deleteAddress(address),
            reject: () => {},
        });
    };

    deleteAddress = async (rowData: MerchantAddresses) => {
        const deleteResult = await merchantAddressApiService.delete(rowData.id);

        if (deleteResult.success) {
            this.setState({ address: this.state.address.filter((address) => address.id !== rowData.id) });
        } else {
            this.toast?.show({ severity: "error", summary: "Error", detail: deleteResult.message, life: 3000 });
        }
    };

    actionBodyTemplate = (rowData: MerchantAddresses) => {
        return (
            <>
                {this.props.localMode == false && (
                    <button type="button" className="p-row-editor-init p-link" tabIndex={0} onClick={() => this.addorEditAddress(rowData)}>
                        <span className="p-row-editor-init-icon pi pi-fw pi-pencil"></span>
                    </button>)}

                <button type="button" className="p-row-editor-cancel p-link" tabIndex={0} onClick={() => this.confirmDeleteAddress(rowData)}>
                    <span className="p-row-editor-cancel-icon pi pi-fw pi-times"></span>
                </button>
            </>
        );
    };


    addressEditTemplate = (rowData : MerchantAddresses) => {
        return <span>
            {rowData.addressLine} <br /> 
            {rowData.city} <br /> 
            {rowData.zipCode} <br />
            {rowData.countryId}<br />
            {this.addressTypes.filter(f => f.value === rowData.addressType)[0]?.label}<br/>
            {rowData.stateProvinceCounty}
        </span>;
    };

    render() {
        return (
            <Card>
                {this.state.isAddressDetailVisible && this.state.selectedAddress != null &&(
                    <AddMerchantAddress
                        onHide={this.onDetailsModalClose}
                        merchantId={this.props.merchantId}
                        address={this.state.selectedAddress}
                        countries={this.countries}
                        types={this.addressTypes}
                        localMode={this.props.localMode}
                    />
                )}
                <Toast ref={(el) => (this.toast = el)} position={"bottom-center"} baseZIndex={99999}/>
                <DataTable header={this.renderHeader} value={this.state.address} loading={this.state.isLoading}>
                    <Column header="Address" body={this.addressEditTemplate} style={{ width: "80%" }}></Column>
                    <Column
                        header=""
                        body={this.actionBodyTemplate}
                        headerStyle={{ width: "8em", textAlign: "center" }}
                        bodyStyle={{ textAlign: "center", overflow: "visible" }}
                    />
                </DataTable>
            </Card>
        );
    }
}
