import React, { Fragment } from "react";
import { Toast } from "primereact/toast";
import { CreateCardPaymentMethodRequest, CreateIbanPaymentMethodRequest, CreateNonIbanPaymentMethodRequest } from "../../models/MerchantModel";
import { AddPaymentMethodDialog } from "../Banking/AddPaymentMethodDialog";
import { Dialog } from "primereact/dialog";
import { merchantPaymentMethodApiService } from "../../services/api/merchants/MerchantPaymentMethodApiService";

interface AddMerchantPaymentMethodProps {
    onHide: () => void;
    merchantId: number;
}
interface AddMerchantPaymentMethodState {
    isSubmitting: boolean;
}

export class AddMerchantPaymentMethod extends React.Component<AddMerchantPaymentMethodProps, AddMerchantPaymentMethodState> {
    toast: Toast | null = null;

    constructor(props : AddMerchantPaymentMethodProps) {
        super(props);

        this.state = {
            isSubmitting: false
        };
    }

    onSuccess = async (item: CreateCardPaymentMethodRequest | CreateIbanPaymentMethodRequest | CreateNonIbanPaymentMethodRequest) => {
        const result = await merchantPaymentMethodApiService.create(this.props.merchantId, item);

        if (result.success) {
            this.props.onHide();
        } else {
            this.toast?.show({ severity: "error", summary: "Error", detail: result.message, life: 3000 });
        }
    };

    render() {
        return (
            <Dialog header="Add Payment Method" visible={true} style={{ width: "60vw" }} onHide={this.props.onHide}>
                <Toast ref={(el) => (this.toast = el)} position={"bottom-center"} baseZIndex={99999}></Toast>
                <Fragment>
                    <AddPaymentMethodDialog isSubmitting={this.state.isSubmitting} onHide={this.props.onHide} onSuccess={this.onSuccess}/>
                </Fragment>
            </Dialog>
        );
    }
}
