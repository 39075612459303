import { Result } from "../models/Result";
import { httpService } from "./HttpService";

class PermissionService {
   
    baseApiUrl: string = process.env.REACT_APP_AUTH_API_BASE_URL ?? "";

    GetPermissions = async () : Promise<Result<string[]>> => {
        return await httpService.get<string[]>("/api/v1/Permission/", { baseURL: this.baseApiUrl });
    };
}

const permissionService = new PermissionService();

export { permissionService };