import { PagedResult, Result } from "../../../models/Result";
import { Journaling, RequestLog } from "../../../models/Journaling";
import { httpService } from "../../HttpService";
import { SearchRequestBaseModel } from "../../../models/SearchRequestModel";
class JournalingsApiService {
    path: string = "journaling";

    getAll = async (request: SearchRequestBaseModel): Promise<PagedResult<Journaling[]>> => await httpService.getPage<Journaling[]>(`${this.path}/search`, request);

    getAllRequestLogs = async (request: SearchRequestBaseModel): Promise<PagedResult<RequestLog[]>> => await httpService.getPage<RequestLog[]>(`${this.path}/requestlogs/search`, request);

    getRequestLogsDetail = async (Id: number): Promise<Result<RequestLog>> => await httpService.get<RequestLog>(`/${this.path}/${Id}`);


}
const journalingsApiService = new JournalingsApiService();
export { journalingsApiService };