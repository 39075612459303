import React from "react";
import { Dropdown } from "primereact/dropdown";
import { PaginatorTemplateOptions, PaginatorRowsPerPageDropdownOptions } from "primereact/paginator";
import { DataTableFilterMeta, DataTableMultiSortMetaType, DataTableOperatorFilterMetaData, DataTablePFSEvent, DataTableSortMeta } from "primereact/datatable";
import { IFilterModel, ISortModel, SearchRequestBaseModel } from "../models/SearchRequestModel";
import { Calendar } from "primereact/calendar";
import { ColumnFilterElementTemplateOptions } from "primereact/column";
import { Label } from "reactstrap";

const stringFilterMatchModeOptions: any = [
    { label: "Contains", value: "Contains" },
    { label: "Equals", value: "Equals" },
    // { label: 'Starts With', value: FilterMatchMode.STARTS_WITH },
];

const numberFilterMatchModeOptions: any = [
    { label: "Equals", value: "Equals" },
    { label: "Greater Than", value: "GreaterThan" },
    { label: "Less Than", value: "LessThan" },
];
const statusFilterMatchModeOptions: any = [
    { label: "Equals", value: "Equals" },

];
const dateFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" mask="99/99/9999" />;
};

const dateRangeFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return(
        <>
            <Label for="dateRange">Date Range</Label><Calendar
                value={options.value}
                onChange={(e) => options.filterCallback(e.value, options.index)}
                dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy - mm/dd/yyyy" mask="99/99/9999 - 99/99/9999"
                selectionMode="range"
                maxDate={new Date()} />
        </>
    );
};

const paginatorTemplate: PaginatorTemplateOptions = {
    layout: "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
    RowsPerPageDropdown: (options: PaginatorRowsPerPageDropdownOptions) => {
        const dropdownOptions = [
            { label: 10, value: 10 },
            { label: 20, value: 20 },
            { label: 50, value: 50 },
            { label: 100, value: 100 },
        ];

        return (
            <>
                <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} appendTo={document.body} />
            </>
        );
    },
    CurrentPageReport: (options : DataTablePFSEvent) => {
        return (
            <span>
                Showing {options.first } to {options.last} of {options.totalRecords}
            </span>
        );
    },
} as any;

export interface IPagedState {
    first: number;
    page: number;
    pageRowCount: number;
    multiSortMeta: DataTableSortMeta[]
    filters: DataTableFilterMeta;
}

const ConvertToISortModel = (multiSortMeta: DataTableMultiSortMetaType) : ISortModel[] => {
    // Backend Accepts Sort Enums as 1,2 while Frontend generates 1,-1. Converts accordingly 
    return (multiSortMeta ?? []).map(item => ({ sortBy: item.field, sortOrder: item.order == 1 ? 1 : 2 } as ISortModel));
};

const OnPageChange = async (component: React.Component<any, IPagedState>, event : DataTablePFSEvent, callback: (searchRequest: SearchRequestBaseModel) => Promise<any>) => {

    const page = (event.first == 0 ? 0 : (event.first / event.rows)) + 1;

    component.setState({
        first: event.first,
        page: page,
        pageRowCount: event.rows,
        filters: event.filters,
        multiSortMeta: event.multiSortMeta ?? []
    });

    const filters = Object.keys(event.filters ?? {})
        .flatMap(key => {
            const data = (event.filters[key] as DataTableOperatorFilterMetaData);

            return data.constraints.map(item => ({  columnName: capitalizeFirstLetter(key), matchMode: item.matchMode, value: item.value }));
        })
        .map(item => ({ columnName: item.columnName, operator: item.matchMode, value: item.value } as IFilterModel))
        .filter(f => f.value != null);

    const sortData = ConvertToISortModel(event.multiSortMeta);

    await callback({
        pageNumber: page, pageSize: event.rows,
        filters: filters,
        sortings: sortData
    });
};

function capitalizeFirstLetter(str: string) {
    return str[0].toUpperCase() + str.slice(1);
}

export {dateRangeFilterTemplate, paginatorTemplate, OnPageChange, stringFilterMatchModeOptions,statusFilterMatchModeOptions, numberFilterMatchModeOptions, dateFilterTemplate, ConvertToISortModel };