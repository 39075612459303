import React, { Component } from "react";

import { Toast } from "primereact/toast";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import moment from "moment";
import classNames from "classnames";
import { Button } from "primereact/button";
import { Card, CardBody, CardHeader } from "reactstrap";
import Loader from "../../components/Loader";
import { DeviceStatus, UserDevice } from "../../models/UserDevice";
import { userDevicesApiService } from "../../services/api/users/UserDevicesApiService";
import { OnPageChange, dateFilterTemplate, numberFilterMatchModeOptions, paginatorTemplate, stringFilterMatchModeOptions } from "../Paginator";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { SearchRequestBaseModel, SortOrderType } from "../../models/SearchRequestModel";
import { utils } from "../../utils/utils";

interface UserDevicesState {
    isLoading: boolean;
    devices: UserDevice[];
}

interface UserDevicesProps {
    userId: number
}

export default class UserDevicesComponent extends Component<UserDevicesProps, UserDevicesState> {
    toast: Toast | null = null;
    deviceStatusClassesMap: Map<DeviceStatus, string> = new Map<DeviceStatus, string>([
        [DeviceStatus.Active, "success"],
        [DeviceStatus.Inactive, "warning"],
        [DeviceStatus.Deleted, "danger"],
    ]);

    private dataTable: DataTable | null = null;

    constructor(props: any | Readonly<any>) {
        super(props);

        this.state = {
            isLoading: false,
            devices: [],
        };
    }

    componentDidMount(): void {
        this.setState({ isLoading: true });
        userDevicesApiService.getAll(this.props.userId).then(response => {
            if(!response.success) {
                this.setState({ isLoading: false });
                this.toast?.show({ severity: "error", summary: "Error", detail: response.message, life: 3000 });
                return;  
            }
            this.setState({ isLoading: false, devices: response.data });
        }).catch(err => {
            this.setState({ isLoading: false });
            this.toast?.show({ severity: "error", summary: "Error", detail: err.message, life: 3000 });
        });
    }

    actionBodyTemplate = (rowData: UserDevice) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => this.onRemoveDevice(rowData)} />
            </React.Fragment>
        );
    };

    onRemoveDevice = (rowData: UserDevice) => {
        this.setState({ devices: this.state.devices.filter((device) => device.deviceFingerprint != rowData.deviceFingerprint) });
    };

    renderStatusBadge = (status: DeviceStatus) => {
        const className = this.deviceStatusClassesMap.get(status);
        return <div className={classNames("badge", "badge-" + className)}>{DeviceStatus[status]}</div>;
    };

    renderHeader = () => {
        return (
            <span className="p-input-icon-left" style={{ display: "flex", justifyContent: "space-between" }}>
                <Button type="button" icon="pi pi-filter-slash" label="Clear" className="p-button-outlined" onClick={() => { this.dataTable!.reset(); }} />
            </span>
        );
    };

    onChange = async (searchRequest: SearchRequestBaseModel) => {
        this.setState({ isLoading: true });
        searchRequest.filters = utils.manageDataTableFilters(searchRequest.filters);
    };


    render() {
        return (
            <Card>
                <CardHeader className="card-header-tab">
                    <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                        <i className="header-icon lnr-laptop-phone mr-3 text-muted opacity-6"> </i>
                        Devices
                    </div>
                </CardHeader>
                <TransitionGroup appear={true} exit={false} enter={false}>
                    <CSSTransition classNames="TabsAnimation" timeout={1500}>
                        <CardBody>
                            <Toast ref={(el) => this.toast = el} />
                            <DataTable
                                ref={(reference) => this.dataTable = reference}
                                value={this.state.devices}
                                header={this.renderHeader}
                                loading={this.state.isLoading}
                                emptyMessage="No Devices available"
                                stripedRows={true}
                                showGridlines
                                paginator
                                paginatorClassName="p-jc-end"
                                paginatorTemplate={paginatorTemplate}
                                rows={10}
                                sortField="lastUsed"
                                sortOrder={SortOrderType.Descending} //1 for ascending order and -1 for descending order
                                // onFilter={(event) => OnPageChange(this, event, this.onChange)}
                                // onSort={(event) => OnPageChange(this, event, this.onChange)}
                            >
                                <Column
                                    field="ipAddress"
                                    header="Ip Address"
                                    showFilterOperator={false}
                                    showAddButton={false}
                                    filter={numberFilterMatchModeOptions}
                                    sortable={numberFilterMatchModeOptions}
                                ></Column>
                                <Column
                                    field="platform"
                                    header="Platform"
                                    showFilterOperator={false}
                                    showAddButton={false}
                                    filter={stringFilterMatchModeOptions}
                                    sortable={stringFilterMatchModeOptions}
                                ></Column>
                                <Column
                                    field="os"
                                    header="Device OS"
                                    showFilterOperator={false}
                                    showAddButton={false}
                                    filter={stringFilterMatchModeOptions}
                                    sortable={stringFilterMatchModeOptions}
                                    body={(item: UserDevice) => `${item.os} ${item.osVersion}`}
                                ></Column>
                                <Column
                                    field="name"
                                    header="Device Name"
                                    showFilterOperator={false}
                                    showAddButton={false}
                                    filter={stringFilterMatchModeOptions}
                                    sortable={stringFilterMatchModeOptions}
                                ></Column>
                                <Column
                                    field="browserName"
                                    header="Browser Name"
                                    showFilterOperator={false}
                                    showAddButton={false}
                                    filter={stringFilterMatchModeOptions}
                                    sortable={stringFilterMatchModeOptions}
                                ></Column>
                                <Column
                                    field="browserVersion"
                                    header="Browser Version"
                                    showFilterOperator={false}
                                    showAddButton={false}
                                    filter={numberFilterMatchModeOptions}
                                    sortable={numberFilterMatchModeOptions}
                                ></Column>
                                <Column
                                    field="vendor"
                                    header="Vendor"
                                    showFilterOperator={false}
                                    showAddButton={false}
                                    filter={stringFilterMatchModeOptions}
                                    sortable={stringFilterMatchModeOptions}
                                ></Column>
                                <Column
                                    field="registrationDate"
                                    header="Start Using Date"
                                    body={(rowData) => <p>{moment(rowData.registrationDate).format("DD/MM/YYYY hh:mm:ss")}</p>}
                                    showFilterOperator={false}
                                    showAddButton={false}
                                    filter={numberFilterMatchModeOptions}
                                    sortable={numberFilterMatchModeOptions}
                                    dataType="date"
                                    filterElement={dateFilterTemplate}
                                ></Column>
                                <Column
                                    field="lastUsed"
                                    header="Last Used"
                                    body={(rowData) => <p>{moment(rowData.lastUsed).format("DD/MM/YYYY hh:mm:ss")}</p>}
                                    showFilterOperator={false}
                                    showAddButton={false}
                                    filter={numberFilterMatchModeOptions}
                                    sortable={numberFilterMatchModeOptions}
                                    dataType="date"
                                    filterElement={dateFilterTemplate}
                                ></Column>
                                <Column
                                    field="status"
                                    header="Status"
                                    body={(rowData: UserDevice) => this.renderStatusBadge(rowData.deviceStatus)}
                                ></Column>
                                {/* <Column body={this.actionBodyTemplate}></Column> */}
                            </DataTable>
                        </CardBody>
                    </CSSTransition>
                </TransitionGroup>
            </Card >
        );
    }
}
// browserName: string;
// browserVersion: string;
