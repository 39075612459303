import { permissionService } from "./services/PermissionService";
import { storageService } from "./services/StorageService";
import { routes as SideMenuRoutes, Route } from "./sidemenu";
import _ from "lodash";
import { operatorsApiService } from "./services/api/users/OperatorApiService";

const updatePermissions = async () => {
    storageService.setPermissions(((await permissionService.GetPermissions()).data));
    storageService.setSelectedMerchantId(storageService.getLoggedInUserMerchantId().toString())
};

const updateRoles = async () => {
    const userRoles = (await operatorsApiService.getRoles()).data.map(n=>n.name);
    storageService.setRoles(userRoles);
};

const isSuperAdmin = () => {
    return storageService.getRoles().map(a => a.toLowerCase()).includes("Super Admin".toLowerCase());
};

const isDashboardAllowed = () => {
    return hasPermission("crm:dashboard:view");
};

const isMerchantDashboardAllowed = () => {
    return hasPermission("crm:merchantdashboard:view");
};

const isMerchantDashboardUpdateAllowed = () => {
    return hasPermission("crm:merchantdashboard:update");
};

const isCRMUserAllAllowed = () => {
    return hasPermission("crm:users:all");
};

const isCRMUserAllowed = () => {
    return hasPermission("crm:users:view");
};

const isCRMUserCreateNewAllowed = () => {
    return hasPermission("crm:users:create");
};

const isUserProfileViewDetailsAllowed = () => {
    return hasPermission("crm:users:viewdetails");
};

const isUserPaymentMethodUpdateAllowed = () => {
    return hasPermission("crm:users:payment-methods");
};

const isMerchantAllAllowed = () => {
    return hasPermission("crm:merchants:all");
};

const isMerchantCreateNewAllowed = () => {
    return hasPermission("crm:merchants:create");
};

const isMerchantUpdateAllowed = () => {
    return hasPermission("crm:merchants:update");
};

const isMerchantPaymentMethodUpdateAllowed = () => {
    return hasPermission("crm:merchants:payment-methods");
};

const isIndividualPaymentMethodUpdateAllowed = () => {
    return hasPermission("crm:individual:payment");
};

const isIndividualDepositAllowed = () => {
    return hasPermission("crm:individual:deposit");
};

const isIndividualWithdrawAllowed = () => {
    return hasPermission("crm:individual:withdraw");
};

const isMerchantAllowed = () => {
    return hasPermission("crm:merchants:view");
};

const isMerchantClientsAllowed = () => {
    return hasPermission("crm:merchant-clients:view");
};

const isMerchantViewDetailAllowed = () => {
    return hasPermission("crm:merchants:viewdetails");
};

const isWalletsViewAllowed = () => {
    return hasPermission("crm:wallets:view");
};

const isWalletCreateAllowed = () => {
    return hasPermission("crm:wallets:create");
};

const isIbanCreateAllowed = () => {
    return hasPermission("crm:iban:create");
};

const isWalletCloseAllowed = () => {
    return hasPermission("crm:wallets:close");
};

const isTariffViewAllowed = () => {
    return hasPermission("crm:tariff:view");
};

const isTariffUpdateAllowed = () => {
    return hasPermission("crm:tariff:update");
};

const isKycSubmissionAllAllowed = () => {
    return hasPermission("crm:kyc-submission:all");
};

const isKycSubmissionViewAllowed = () => {
    return hasPermission("crm:kyc-submission:view");
};

const isKycSubmissionCreateAllowed = () => {
    return hasPermission("crm:kyc-submission:create");
};

const isKycDocumentAllAllowed = () => {
    return hasPermission("crm:kyc-documents:all");
};

const isJournalingAllowed = () => {
    return hasPermission("crm:journaling:view");
};

const isPortalAdminAllowed = () => {
    return hasPermission("crm:portal:admin");
};

const isCRMAllAllowed = () => {
    return hasPermission("crm:all:all");
};

const areOperatorsAndRolesAllAllowed = () => {
    return hasPermission("crm:operators-and-roles:all");
};

const areOperatorsAndRolesViewUserAllowed = () => {
    return hasPermission("crm:operators-and-roles:view-user");
};

const areOperatorsAndRolesCreateNewOperatorAllowed = () => {
    return hasPermission("crm:operators-and-roles:create-new-operator");
};

const areOperatorsAndRolesUpdateOperatorAllowed = () => {
    return hasPermission("crm:operators-and-roles:update-operator");
};

const areOperatorsAndRolesViewUsersRolesAllowed = () => {
    return hasPermission("crm:operators-and-roles:view-user-roles");
};

const areOperatorsAndRolesCreateNewRoleAllowed = () => {
    return hasPermission("crm:operators-and-roles:create-new-role");
};

const areOperatorsAndRolesUpdateRoleAllowed = () => {
    return hasPermission("crm:operators-and-roles:update-role");
};

const areOperatorsAndRolesDeleteRoleAllowed = () => {
    return hasPermission("crm:operators-and-roles:delete-role");
};

const isSftpDocumentCreateAllowed = () => {
    return hasPermission("crm:sftp:create-document");
};

const isSftpDocumentViewAllowed = () => {
    return hasPermission("crm:sftp:view-document");
};

const isMerchantTransactionsViewAllowed = () => {
    return hasPermission("crm:merchant-transactions:view");
};

const isIndividualTransactionViewAllowed = () => {
    return hasPermission("crm:individual-transactions:view");
};

const isOpenBankingViewUserAllowed = () => {
    return hasPermission("crm:open-banking:view-user");
};

const isOpenBankingViewTransactionAllowed = () => {
    return hasPermission("crm:open-banking:view-transaction");
};

const isOpenBankingViewWalletAllowed = () => {
    return hasPermission("crm:open-banking:view-wallet");
};

const isOpenBankingIbanTransferAllowed = () => {
    return hasPermission("crm:open-banking:iban-transfer");
};

const areOperationsAllowed = () => {
    return hasPermission("crm:operation:view");
};

const isUserProfileUpdateAllowed = () => {
    return hasPermission("crm:users:update");
};

const isKYCAssessAllowed = () => {
    return hasPermission("crm:kyc-submission:assess");
};

const isKYCApproveAllowed = () => {
    return hasPermission("crm:kyc-submission:approve");
};

const isMerchantProfileUpdateAllowed = () => {
    return hasPermission("crm:merchants:update");
};

const isOperatorsUpdateAllowed = () => {
    return hasPermission("crm:operators-and-roles:update-operator");
};

const isMerchantsClientsCreateAllowed = () => {
    return hasPermission("crm:merchant-clients:create");
};

const hasPermission = (permission: string): boolean => {
    if(isSuperAdmin()) {
        return true;
    }

    const permissions = storageService.getPermissions();

    return permissions.some(s => s.toLowerCase() == permission.toLowerCase());
};

const getUserPermittedRoutesForNav = (): Route[] => {
    const routes = getUserPermittedRoutes()
        .filter(f => f.visibleInNav);

    return routes;
};

const canDeleteMerchant = () => {
    return hasPermission("crm:merchants:update");
};

const getUserPermittedRoutes = (): Route[] => {
    if(isSuperAdmin()) {
        return SideMenuRoutes;
    }

    const permissions = storageService.getPermissions();
    const routes = SideMenuRoutes.filter((route) => _.some(permissions, (item) => item.toLowerCase() === route.permissionName.toLowerCase()));

    return routes;
};

export {
    isDashboardAllowed,
    isMerchantDashboardAllowed,
    isMerchantDashboardUpdateAllowed,
    isCRMUserAllAllowed,
    isCRMUserAllowed,
    isCRMUserCreateNewAllowed,
    isUserProfileViewDetailsAllowed,
    isUserPaymentMethodUpdateAllowed,
    isMerchantAllAllowed,
    isMerchantCreateNewAllowed,
    isMerchantUpdateAllowed,
    isMerchantPaymentMethodUpdateAllowed,
    isIndividualPaymentMethodUpdateAllowed,
    isIndividualDepositAllowed,
    isIndividualWithdrawAllowed,
    isMerchantAllowed,
    isMerchantClientsAllowed,
    isMerchantViewDetailAllowed,
    isWalletsViewAllowed,
    isWalletCreateAllowed,
    isIbanCreateAllowed,
    isWalletCloseAllowed,
    isTariffViewAllowed,
    isTariffUpdateAllowed,
    isKycSubmissionAllAllowed,
    isKycSubmissionViewAllowed,
    isKycSubmissionCreateAllowed,
    isKycDocumentAllAllowed,
    isJournalingAllowed,
    isPortalAdminAllowed,
    isCRMAllAllowed,
    areOperatorsAndRolesAllAllowed,
    areOperatorsAndRolesViewUserAllowed,
    areOperatorsAndRolesCreateNewOperatorAllowed,
    areOperatorsAndRolesUpdateOperatorAllowed,
    areOperatorsAndRolesViewUsersRolesAllowed,
    areOperatorsAndRolesCreateNewRoleAllowed,
    areOperatorsAndRolesUpdateRoleAllowed,
    areOperatorsAndRolesDeleteRoleAllowed,
    isSftpDocumentCreateAllowed,
    isSftpDocumentViewAllowed,
    isMerchantTransactionsViewAllowed,
    isIndividualTransactionViewAllowed,
    isOpenBankingViewUserAllowed,
    isOpenBankingViewTransactionAllowed,
    isOpenBankingViewWalletAllowed,
    isOpenBankingIbanTransferAllowed,
    areOperationsAllowed,
    isUserProfileUpdateAllowed,
    isMerchantProfileUpdateAllowed,
    isOperatorsUpdateAllowed,
    isKYCAssessAllowed,
    isKYCApproveAllowed,
    updatePermissions,
    updateRoles,
    getUserPermittedRoutes,
    getUserPermittedRoutesForNav,
    canDeleteMerchant,
    isMerchantsClientsCreateAllowed
};