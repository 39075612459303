export interface UserDevice {
    deviceFingerprint: string;
    browserName: string;
    browserVersion: string;
    name: string;
    vendor: string;
    os: string;
    osVersion: string;
    platform: string;
    ipAddress: string;
    registrationDate: Date
    lastUsed: Date
    deviceStatus: DeviceStatus
}

export enum DeviceStatus {
    Active,
    Inactive,
    Deleted
}