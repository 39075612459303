export const AppConstants = {
    NAME_REGEX: /^([a-zA-Za-åa-ö-w-я ]+ )+[a-zA-Za-åa-ö-w-я ]+$|^[a-zA-Za-åa-ö-w-я ]*$/,
    NUMARIC_ONLY_REGEX: /^[0-9]+$/,
    CITY_REGEX: /^[a-zA-Z ]*$/,
    ADDRESS_LINE_LENGTH: 500,

    timeStampFiltered: [
        "CreatedTimeStamp",
        "SubmissionTimeStamp",
        "ReviewStartedTimeStamp",
        "ReviewCompletedTimeStamp",
        "MadeOn",
        "ActionTimeStamp",
        "FileTimeStamp",
        "TransactionTimestamp",
        "TimeStampTo"
    ],
    AmountValidRegex: /^\d+(\.\d{1,2})?$/,
	AmountChangeRegex: /^[1-9]\d*(\.\d{0,2})?$/,
	TitleRegex: /^(?=[a-zA-Z].*[a-zA-Z])[-0-9a-zA-Z\s,.#]+(?<!\s{2,})$/,
	MessageRegex: /^(?=[a-zA-Z].*[a-zA-Z])[-0-9a-zA-Z\s,.#]+(?<!\s{2,})$/,
	ScaOtpRegex: /^[0-9]{0,6}$/,
	InstitutionNameLength: 100,
	NameLength: 128,
	AmountMax: 1000000,
	NotesAndReasonLength: 200,
	IbanMaxLength: 40,
	IbanMinLength: 10,
	IbanNumberRegex: /^[a-zA-Z0-9]{10,}$/,
	emojiRegex : /([\u{1F600}-\u{1F64F}]|[\u{1F300}-\u{1F5FF}]|[\u{1F680}-\u{1F6FF}]|[\u{1F700}-\u{1F77F}]|[\u{1F780}-\u{1F7FF}]|[\u{1F800}-\u{1F8FF}]|[\u{1F900}-\u{1F9FF}]|[\u{1FA00}-\u{1FA6F}]|[\u{1FA70}-\u{1FAFF}]|[\u{2600}-\u{26FF}]|[\u{2700}-\u{27BF}]|[\u{1F1E6}-\u{1F1FF}])/gu,

};

export const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const PASSWORD_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])[^ ]{8,}$/;
export const Amount_Valid_REGEX = /^\d+(\.\d{1,2})?$/;
export const Personal_Id_REGEX = /^\d{10}$/;
export const National_Id_REGEX = /^\d{9}$/;
export const Only_Digit_REGEX = /^\d*$/;
export const Max_Amount = 1000000;
export const Transaction_Charge = 1;
export const Withdraw_Max_Amount = 100000;
export const Notes_And_Reason_Length = 200;
export const Profile_picture_width = 400;
export const Profile_picture_height = 400;
