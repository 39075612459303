import { type } from "os";
import { Result } from "../../../models/Result";
import { httpService } from "../../HttpService";
import { ICrudApiService } from "../../ICrudApiService";
import {  AllTransactionRequestModel, TransactionReportResponse, TransactionReport, TransType, AllTransactionReportResponse, TransactionRequestModel, TransactionReportDetails } from "../../../models/Transactions";
import { AllOutgoingTransaction, OutgoingTransactionFilter, OutgoingTransactionReport } from "../../../models/AllOutgoingTransaction";
import { IncomingAcquiringTransaction, IncomingCardDepositsTransaction, IncomingCardDepositsTransactionFilter } from "../../../models/IncomingAcquiring";
import { BankAccountTransaction, IncomingIBANTransactionFilter, IncomingIbanTransfersTransaction } from "../../../models/IncomingBankAccount";

class TransactionsReportApiService implements ICrudApiService<TransactionReport> {
    path: string = "TransactionReport";

    getAllTransactionsReportResponse = async (request: AllTransactionRequestModel): Promise<Result<AllTransactionReportResponse>> => await httpService.post<AllTransactionReportResponse>(`${this.path}/get-all-transactions-report`, request);

    getTransactionById = async (request: TransactionRequestModel): Promise<Result<TransactionReportDetails>> => await httpService.post<TransactionReportDetails>(`${this.path}/get-transaction`, request);

    getAll = async (request: AllTransactionRequestModel): Promise<Result<TransactionReport[]>> => await httpService.post<TransactionReport[]>(`${this.path}/get-all-transactions-report`, request);

    // getAllInternallP2p = async (request: AllTransactionRequestModel): Promise<Result<TransactionReport[]>> => await httpService.post<TransactionReport[]>(`${this.path}/get-internal-p2p-transactions-report`, request);

    // getAllOutgoingBG = async (request: OutgoingTransactionFilter): Promise<Result<OutgoingTransactionReport[]>> => await httpService.post<OutgoingTransactionReport[]>(`${this.path}/get-outgoing-bg-transactions-report`, request);

    // getAllOutgoingSepa = async (request: OutgoingTransactionFilter): Promise<Result<OutgoingTransactionReport[]>> => await httpService.post<OutgoingTransactionReport[]>(`${this.path}/get-outgoing-sepa-transactions-report`,request);

    // getAllOutgoingSwift = async (request: OutgoingTransactionFilter): Promise<Result<OutgoingTransactionReport[]>> => await httpService.post<OutgoingTransactionReport[]>(`${this.path}/get-outgoing-swift-transactions-report`, request);

    getIncomingIbanTransfers = async (request: IncomingIBANTransactionFilter): Promise<Result<IncomingIbanTransfersTransaction[]>> => await httpService.post<IncomingIbanTransfersTransaction[]>(`${this.path}/get-incoming-iban-transactions-report`, request);

    getIncomingCardDeposits = async (request: IncomingCardDepositsTransactionFilter): Promise<Result<IncomingCardDepositsTransaction[]>> => await httpService.post<IncomingCardDepositsTransaction[]>(`${this.path}/get-incoming-card-deposits-transactions-report`, request);

    get = async (Id: number): Promise<Result<TransactionReport>> => await httpService.get<TransactionReport>(`/${this.path}/${Id}`);

    create = async (transaction: TransactionReport): Promise<Result<TransactionReport>> => await httpService.post<TransactionReport>(`/${this.path}`, transaction);

    update = async (transaction: TransactionReport): Promise<Result<TransactionReport>> => await httpService.put<TransactionReport>(`/${this.path}`, transaction);

    delete = async (Id: number): Promise<Result<void>> => await httpService.delete<void>(`/${this.path}/${Id}`);

    getAllTransactionsReport = async (request: AllTransactionRequestModel): Promise<Result<TransactionReportResponse[]>> => {
        const transactionsResult = await transactionsReportApiService.getAll(request);
        return this.getAllTransactionsResult(transactionsResult);
    };

    getAllTransactionsResult = async (responseData: Result<TransactionReport[]>): Promise<Result<TransactionReportResponse[]>> => {
        if (!responseData.success) {
            return {
                success: false,
                message: responseData.message
            } as Result<TransactionReportResponse[]>;
        }

        const response = responseData.data.map(function (item) {
            let transaction = null;
            if (item.sender != null) {
                transaction = {
                    id: item.sender.transactionId,
                    type: TransType[item.sender.type as keyof typeof TransType],
                    timeStamp: item.sender.timestamp,
                    amount: item.sender.amount,
                    currency: item.sender.currency,
                    fee: item.sender.transactionFee,
                    senderEmail: item.sender.userEmail,
                    senderMerchant: item.sender.merchant,
                    senderName: item.sender.sideA,
                    recepientName: item.sender.sideB,
                    senderSource: item.sender.senderIban,
                    walletId: item.sender.accountNumber,
                    paymentSystem: item.sender.paymentSystem,
                    transactionCategory: item.sender.transactionCategory,
                    reason: item.sender.reference,
                    accountNumber: item.sender.accountNumber,
                    feeCurrency: item.sender.feeCurrency,
                    receiverBic: item.sender.receiverBic,
                    senderBic: item.sender.senderBic,
                    senderIban: item.sender.senderIban,
                    receiverIban: item.sender.receiverIban,
                    recepientDestination: item.sender.receiverIban,
                    cardNetwork: item.sender.cardNetwork,
                    cardPan: item.sender.cardPan,
                    cardAlias: item.sender.cardAlias,
                    paymentProvider: item.sender.paymentProvider,
                    purposeDet: item.sender.purposeDet,
                    // status: PaymentStatus.Processed     //flexCube column missing  
                    

                } as TransactionReportResponse;
            } else {
                transaction = {
                    id: item.receiver.transactionId,
                    type: TransType[item.receiver.type as keyof typeof TransType],
                    timeStamp: item.receiver.timestamp,
                    amount: item.receiver.amount,
                    currency: item.receiver.currency,
                    fee: item.receiver.transactionFee,
                    senderEmail: item.receiver.userEmail,
                    senderMerchant: item.receiver.merchant,
                    senderName: item.receiver.sideA,
                    recepientName: item.receiver.sideB,
                    senderSource: item.receiver.senderIban,
                    walletId: item.receiver.accountNumber,
                    paymentSystem: item.receiver.paymentSystem,
                    transactionCategory: item.receiver.transactionCategory,
                    reason: item.receiver.reference,
                    accountNumber: item.receiver.accountNumber,
                    feeCurrency: item.receiver.feeCurrency,
                    receiverBic: item.receiver.receiverBic,
                    senderBic: item.receiver.senderBic,
                    senderIban: item.receiver.senderIban,
                    receiverIban: item.receiver.receiverIban,
                    recepientDestination: item.receiver.receiverIban,
                    // status: PaymentStatus.Processed     //flexCube column missing  
                    cardNetwork: item.receiver.cardNetwork,
                    cardPan: item.receiver.cardPan,
                    cardAlias: item.receiver.cardAlias,
                    paymentProvider: item.receiver.paymentProvider,
                    purposeDet: item.receiver.purposeDet,

                } as TransactionReportResponse;
            }
            return transaction;
        });
        return {
            success: true,
            data: response
        } as Result<TransactionReportResponse[]>;
    };


    // getOutgoingSepaTransactionsReport = async (request: OutgoingTransactionFilter): Promise<Result<AllOutgoingTransaction[]>> => {
    //     var transactionsResult = await transactionsReportApiService.getAllOutgoingSepa(request);
    //     return this.getAllOutgoingTransactionsResult(transactionsResult);
    // }

    // getOutgoingBGTransactionsReport = async (request: OutgoingTransactionFilter): Promise<Result<AllOutgoingTransaction[]>> => {
    //     var transactionsResult = await transactionsReportApiService.getAllOutgoingBG(request);
    //     return this.getAllOutgoingTransactionsResult(transactionsResult);
    // }

    // getOutgoingSwiftTransactionsReport = async (request: OutgoingTransactionFilter): Promise<Result<AllOutgoingTransaction[]>> => {
    //     var transactionsResult = await transactionsReportApiService.getAllOutgoingSwift(request);
    //     return this.getAllOutgoingTransactionsResult(transactionsResult);
    // }

    getAllOutgoingTransactionsResult = async (responseData: Result<OutgoingTransactionReport[]>): Promise<Result<AllOutgoingTransaction[]>> => {
        if (!responseData.success) {
            return {
                success: false,
                message: responseData.message
            } as Result<AllOutgoingTransaction[]>;
        }

        const response = responseData.data.map(function (item) {
            let transaction = null;

            if (item.sender != null) {
                transaction = {
                    recipient: item.sender.recipient,
                    amount: item.sender.amount,
                    merchant: item.sender.merchant,
                    recipientBIC: item.sender.recipientBIC,
                    recipientIBAN: item.sender.recipientIBAN,
                    reason: item.sender.reason,
                    timeStamp: item.sender.timestamp,
                    sender: item.sender.sender,
                    senderIBAN: item.sender.senderIban,
                    currency: item.sender.currency,

                    id: item.sender.transactionId,
                    fee: item.sender.transactionFee,
                    senderEmail: item.sender.userEmail,
                    type: item.sender.type,

                    //  status: SepaTransactionStatus.Processed //flexCube column missing
                } as unknown as AllOutgoingTransaction;
            }
            else {
                transaction = {
                    recipient: item.receiver.recipient,
                    amount: item.receiver.amount,
                    merchant: item.receiver.merchant,
                    recipientBIC: item.receiver.recipientBIC,
                    recipientIBAN: item.receiver.recipientIBAN,
                    reason: item.receiver.reason,
                    timeStamp: item.receiver.timestamp,
                    sender: item.receiver.sender,
                    senderIBAN: item.receiver.senderIban,
                    currency: item.receiver.currency,

                    id: item.receiver.transactionId,
                    fee: item.receiver.transactionFee,
                    senderEmail: item.receiver.userEmail,
                    type: item.receiver.type,

                    //  recipientBANK: "",
                    //  status: SepaTransactionStatus.Processed //flexCube column missing
                } as unknown as AllOutgoingTransaction;
            }
            return transaction;
        });

        return {
            success: true,
            data: response
        } as Result<AllOutgoingTransaction[]>;
    };


    getAllIncomingIbanTransfersResult = async (request: IncomingIBANTransactionFilter): Promise<Result<BankAccountTransaction[]>> => {

        const transactionsResult = await this.getIncomingIbanTransfers(request);

        if (!transactionsResult.success) {
            return {
                success: false,
                message: transactionsResult.message
            } as Result<BankAccountTransaction[]>;
        }
        const response = transactionsResult.data.map(function (item) {
            let transaction = null;
            if (item.sender != null) {
                transaction = {
                    sender: item.sender.sender,
                    senderIBAN: item.sender.senderIBAN,
                    bankAccount: item.sender.bankAccount, 
                    merchant: item.sender.merchant,
                    amount: item.sender.amount,
                    currency: item.sender.currency,
                    recipientWalletId: item.sender.recipient_wallet,
                    recipientIBAN: item.sender.recipientIBAN,
                    reason: item.sender.reason,
                    timeStamp: item.sender.timestamp,
                    //   status: BankAccountTransactionStatus.Processed     //flexCube column missing

                    id: item.sender.transactionId,
                    fee:item.sender.transactionFee,
                    type: item.sender.type,  
                    senderEmail: item.sender.userEmail,
                    recepientName: item.sender.recipientName   
                } as unknown as BankAccountTransaction;
            }
            else {
                transaction = {
                    sender: item.receiver.sender,
                    senderIBAN: item.receiver.senderIBAN,
                    bankAccount: item.receiver.bankAccount, 
                    merchant: item.receiver.merchant,
                    amount: item.receiver.amount,
                    currency: item.receiver.currency,
                    recipientWalletId: item.receiver.recipient_wallet,
                    recipientIBAN: item.receiver.recipientIBAN,
                    reason: item.receiver.reason,
                    timeStamp: item.receiver.timestamp,
                    //   status: BankAccountTransactionStatus.Processed     //flexCube column missing

                    id: item.receiver.transactionId,
                    fee:item.receiver.transactionFee,
                    type: item.receiver.type,  
                    senderEmail: item.receiver.userEmail,
                    recepientName: item.receiver.recipientName   
                } as unknown as BankAccountTransaction;
            }
            return transaction;
        });

        return {
            success: true,
            data: response
        } as Result<BankAccountTransaction[]>;
    };


    getAllIncomingCardDepositsTransfersResult = async (request: IncomingCardDepositsTransactionFilter): Promise<Result<IncomingAcquiringTransaction[]>> => {

        const transactionsResult = await this.getIncomingCardDeposits(request);

        if (!transactionsResult.success) {
            return {
                success: false,
                message: transactionsResult.message
            } as Result<IncomingAcquiringTransaction[]>;
        }


        const response = transactionsResult.data.map(function (item) {
            let transaction = null;

            if (item.sender != null) {
                transaction = {
                    sender: item.sender.sender,
                    amount: item.sender.amount,
                    merchant: item.sender.merchant,
                    vPosId: item.sender.vposId,
                    currency: item.sender.currency,
                    timeStamp: item.sender.timestamp,
                    transactionType: TransType[item.sender.type as keyof typeof TransType],
                    recipient:item.sender.recipient,
                    recipientWalletId: item.sender.recipientwallet,
                    
                    id: item.sender.transactionId,
                    reason: item.sender.reason,
                    recepientDestination: item.sender.recipientIBAN
                    //  cardType: NetworkType.MasterCard,       //flexCube column missing  
                    //  orderId: "001",                        //flexCube column missing
                    //  status: IncomingAcquiringTransactionStatus.Processed    //flexCube column missing
                    
                } as unknown as IncomingAcquiringTransaction;
            }
            else {
                transaction = {
                    sender: item.receiver.sender,
                    amount: item.receiver.amount,
                    merchant: item.receiver.merchant,
                    vPosId: item.receiver.vposId,
                    currency: item.receiver.currency,
                    timeStamp: item.receiver.timestamp,
                    transactionType: TransType[item.receiver.type as keyof typeof TransType],
                    recipient:item.receiver.recipient,
                    recipientWalletId: item.receiver.recipientwallet,

                    id: item.receiver.transactionId,
                    reason: item.receiver.reason,
                    recepientName: item.receiver.recipient,
                    recepientDestination: item.receiver.recipientIBAN
                    //  cardType: NetworkType.MasterCard,       //flexCube column missing  
                    //  orderId: "001",                        //flexCube column missing 
                    //  status: IncomingAcquiringTransactionStatus.Processed    //flexCube column missing
                    
                } as unknown as IncomingAcquiringTransaction;
            }
            return transaction;
        });


        return {
            success: true,
            data: response
        } as Result<IncomingAcquiringTransaction[]>;
    };
}



var transactionsReportApiService = new TransactionsReportApiService();

export { transactionsReportApiService };
