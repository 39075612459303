import React, { ChangeEvent } from "react";
import { Container, Row, Col, Label, FormGroup } from "reactstrap";
import { InputText } from "primereact/inputtext";
import { SelectItem } from "../../models/SelectItem";
import { Button } from "primereact/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CreateCardPaymentMethodRequest, PaymentMethodStatus } from "../../models/MerchantModel";
import { Dropdown } from "primereact/dropdown";
import { CardConstant } from "../../models/FinancialData";
import { AppConstants } from "../../models/AppConstants";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons";

interface AddCardProps {
    OnBack: () => void;
    OnSuccess: (item: CreateCardPaymentMethodRequest) => void;
}

interface AddCardState {
    cardNumber: string,
    cardAlias: string;
    holderName: string;
    cardType: string;
    month: string;
    year: string;
    cvv: string;
}

export class AddCard extends React.Component<AddCardProps, AddCardState> {

    cards: SelectItem[] = [
        { label: "MASTER", value: "MASTER" },
        { label: "VISA", value: "VISA" },
        { label: "AMEX", value: "AMEX" },
        { label: "OTHER", value: "OTHER" }
    ];

    months: SelectItem[] = [
        { label: "1", value: "01" },
        { label: "2", value: "02" },
        { label: "3", value: "03" },
        { label: "4", value: "04" },
        { label: "5", value: "05" },
        { label: "6", value: "06" },
        { label: "7", value: "07" },
        { label: "8", value: "08" },
        { label: "9", value: "09" },
        { label: "10", value: "10" },
        { label: "11", value: "11" },
        { label: "12", value: "12" }
    ];

    years: SelectItem[] = this.getYears();
        

    constructor(props: any) {
        super(props);

        this.state = {
            cardNumber: "",
            cardType: "",
            cvv: "",
            month: "",
            year: "",
            holderName: "",
            cardAlias: ""
        };
    }

    onChangeCardNumber = (e: ChangeEvent<HTMLInputElement>) => {
        if (!isNaN(Number(e.target.value))) {
            this.setState({ cardNumber: e.target.value });
        }
    };

    onChangeCardYear = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({ year: (e.target.value.slice(0, CardConstant.cardYearLength)) });
    };

    onChangeCardholderName = (e: ChangeEvent<HTMLInputElement>) => {
        if (AppConstants.NAME_REGEX.test(e.target.value)) {
            this.setState({ holderName: e.target.value });
        }
    };

    getYears(){
        const currentYear =  new Date().getFullYear();
        const years = new Array(CardConstant.cardYearsRange);
        for(let i=0;i<CardConstant.cardYearsRange;i++)
        {
            const yearValue = (currentYear+i).toString().slice(-2);
            years.push( { label: yearValue, value: yearValue } as SelectItem );
        }
        return years;
    }

    render(): React.ReactNode {
        return (<Container fluid>
            <FontAwesomeIcon size="5x" icon={faCreditCard as any} className="text-info" style={{ margin: "auto", display: "block" }} />
            <Row>
                <Col md={6}>
                    <FormGroup>
                        <Label>Card Holder Name</Label>
                        <InputText
                            value={this.state.holderName}
                            type="text"
                            pattern="[0-9\s]"
                            minLength={3}
                            maxLength={32}
                            onChange={(event) => this.onChangeCardholderName(event)}
                            className={"form-control"}
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label>Card Alias</Label>
                        <InputText
                            value={this.state.cardAlias}
                            type="text"
                            onChange={(event) => { this.setState({ cardAlias: event.target.value }); }}
                            className={"form-control"}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <FormGroup>
                        <Label>Card Number</Label>
                        <InputText
                            value={this.state.cardNumber}
                            type="text"
                            inputMode="text"
                            pattern="[0-9\s]"
                            maxLength={CardConstant.cardNumbeMaxLength}
                            placeholder="xxxx xxxx xxxx xxxx"
                            onChange={(event) => this.onChangeCardNumber(event)}
                            className={"form-control"}

                        />

                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label>Network Type</Label>
                        <Dropdown
                            style={{ width: "100%" }}
                            value={this.state.cardType}
                            options={this.cards}
                            onChange={(e) => {
                                this.setState({ cardType: e.value });
                            }}
                            appendTo={document.body}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={4}>
                    <FormGroup>
                        <Label>Month</Label>
                        <Dropdown
                            style={{ width: "100%" }}
                            value={this.state.month}
                            options={this.months}
                            onChange={(event) => { this.setState({ month: event.value }); }}
                            appendTo={document.body}
                        />
                    </FormGroup>
                </Col>
                <Col md={4}>
                    <FormGroup>
                        <Label>Year</Label>
                        <Dropdown
                            style={{ width: "100%" }}
                            value={this.state.year}
                            options={this.years}
                            onChange={(event) => {  this.setState({ year: event.value }); }}
                            appendTo={document.body}
                        />
                    </FormGroup>
                </Col>
                <Col md={4}>
                    <FormGroup>
                        <Label>CVV</Label>
                        <InputText
                            value={this.state.cvv}
                            type="text"
                            pattern="[0-9\s]"
                            minLength={3}
                            maxLength={3}
                            onChange={(event) => { if (!isNaN(Number(event.target.value))) { this.setState({ cvv: event.target.value }); } }}
                            className={"form-control"}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xl={{ size: 3, offset: 6 }} lg={{ size: 4, offset: 4 }} md={{ size: 6 }} sm={{ size: 12 }}>
                    <Button
                        style={{ width: "100%" }}
                        label="Back"
                        type="button"
                        onClick={() => this.props.OnBack()}
                        className="p-button-outlined"
                    ></Button>
                </Col>
                <Col xl={{ size: 3 }} lg={{ size: 4 }} md={{ size: 6 }} sm={{ size: 12 }}>
                    <Button
                        style={{ width: "100%" }}
                        label="Submit"
                        type="button"
                        onClick={() => {
                            const request = new CreateCardPaymentMethodRequest();
                            request.cardNumber = this.state.cardNumber;
                            request.cardAlias = this.state.cardAlias;
                            request.cardType = this.state.cardType;
                            request.validityMonth = this.state.month;
                            request.validityYear = this.state.year;
                            request.cvvOrCvcCode = this.state.cvv;
                            request.cardHolderName = this.state.holderName;
                            request.status = PaymentMethodStatus.Approve;
                            this.props.OnSuccess(request);
                        }}
                        className="p-button-success"
                    ></Button>
                </Col>

            </Row>
        </Container>);
    }
}