import React, { Component, Fragment } from "react";

import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Col, Container, Label, Row } from "reactstrap";
import { PaymentStatus, TransactionReportDetails, TransactionRequestModel } from "../../models/Transactions";
import moment from "moment";
import { Fieldset } from "primereact/fieldset";
import { InputText } from "primereact/inputtext";
import Loader from "../../components/Loader";
import { transactionsReportApiService } from "../../services/api/operations/TransactionsReportApiService";

interface ReturnTransactionDetailsProps {
    onHide: () => void;
    transaction: TransactionReportDetails | null;
}

interface ReturnTransactionDetailstate { }

export default class ReturnTransactionDetailsDialog extends Component<ReturnTransactionDetailsProps, ReturnTransactionDetailstate> {
    toast: Toast | null = null;
    constructor(props: ReturnTransactionDetailsProps | Readonly<ReturnTransactionDetailsProps>) {
        super(props);

        this.state = {};
    }

    render() {
        if (this.props.transaction == null) {
            return "";
        }

        return (
            <Dialog header="Return Transaction Details" visible={true} style={{ width: "70vw" }} onHide={this.props.onHide}>
                <TransitionGroup component="div" appear={true} exit={false} enter={false}>
                    <CSSTransition classNames="TabsAnimation" timeout={1500}>
                        <Fragment>
                            <Toast ref={(el) => (this.toast = el)} position={"bottom-center"} baseZIndex={99999}></Toast>
                            <Container fluid>
                                <Fieldset legend="">
                                    {renderDualRow(
                                        "Transaction Id",
                                        this.props.transaction.transactionId,

                                        "Type",
                                        // TransactionType[this.props.transaction.type],
                                        this.props.transaction.transactionType,
                                    )}
                                    {renderDualRow(
                                        "Timestamp",
                                        moment(this.props.transaction.timeStamp).format("DD/MM/YYYY hh:mm:ss"),

                                        "Amount",
                                        this.props.transaction.amount,
                                    )}
                                    {renderDualRow(
                                        "Transaction Fee",
                                        this.props.transaction.transactionFee,

                                        "Currency",
                                        this.props.transaction.currency,

                                    )}
                                    {renderDualRow("Status",
                                        this.props.transaction.wireTransferReturnStatus ? this.props.transaction.wireTransferReturnStatus : PaymentStatus[0],
                                        "Transaction Category",
                                        this.props.transaction.transactionCategory,

                                    )}

                                </Fieldset>

                                <h5></h5>

                                <Fieldset legend="Sender Info">
                                    {renderDualRow(
                                        // "User",
                                        // this.props.transaction.senderEmail,

                                        "Name",
                                        this.props.transaction.senderName,

                                    )}
                                    {renderDualRow(
                                        "Source",
                                        this.props.transaction.senderIban,

                                        "Merchant",
                                        this.props.transaction.merchant,

                                    )}
                                </Fieldset>

                                <h5></h5>

                                <Fieldset legend="Recepient Info">
                                    {renderDualRow(
                                        // "User",
                                        // this.props.transaction.receiverEmail,

                                        "Name",
                                        this.props.transaction.receiverName,

                                    )}
                                    {renderDualRow("Destination", this.props.transaction.receiverIban)}
                                </Fieldset>
                                <h5></h5>
                            </Container>

                            <br></br>
                            <Fieldset legend="Return Transaction Details">
                                <pre>
                                    <code>
                                        {this.props.transaction ? JSON.stringify(this.props.transaction, null, 2) : ""}
                                    </code>
                                </pre>
                            </Fieldset>

                        </Fragment>
                    </CSSTransition>
                </TransitionGroup>
            </Dialog>
        );
    }
}

export function renderDualRow(
    title1: string,
    val1: string | number,

    title2: string | null = null,
    val2: string | number = null as any,

    link?: boolean,
    linkFun?: () => void,
) {
    return (
        <Row>
            <Col md={6}>
                <Row>
                    <Col md={6}>
                        <Label>
                            <b>{title1} :</b>
                        </Label>
                    </Col>
                    <Col md={6}>
                        {

                            link ?
                                (<a
                                    href="#"
                                    style={{ color: 'blue', textDecoration: 'underline' }}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        linkFun!();
                                    }}
                                >
                                    {val1}
                                </a>
                                )
                                :
                                (
                                    <Label>{val1}</Label>
                                )}
                    </Col>
                </Row>
            </Col>
            {title2 != null && (
                <Col md={6}>
                    <Row>
                        <Col md={6}>
                            <Label>
                                <b>{title2} :</b>
                            </Label>
                        </Col>
                        <Col md={6}>

                            <Label>{val2}</Label>

                        </Col>
                    </Row>
                </Col>
            )}
        </Row>
    );
}