import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { Component } from "react";
import { Card, Col, Container, FormGroup, Label, Row } from "reactstrap";
import { MerchantClients } from "../../models/MerchantModel";
import { SelectItem } from "../../models/SelectItem";
import { Toast } from "primereact/toast";
import _ from "lodash";
import { clientsApiService } from "../../services/api/clients/ClientsApiService";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import ReactLoader from "react-loaders";

interface MerchantIntegrationProps {
    merchantId: number;
}

interface MerchantIntegrationState {
    items: MerchantClients[];
    isLoading: boolean;
    notificationUrl: string;
    notificationStatusUrl: string;
    notificationRefreshUrl: string;
    notification3DUrl: string;
}

export default class MerchantIntegration extends Component<MerchantIntegrationProps, MerchantIntegrationState> {
    toast: Toast | null = null;

    contactTypes: SelectItem[] = [];

    constructor(props: MerchantIntegrationProps | Readonly<MerchantIntegrationProps>) {
        super(props);

        this.state = {
            items: [],
            isLoading: true,
            notification3DUrl: "",
            notificationRefreshUrl: "",
            notificationStatusUrl: "",
            notificationUrl: ""
        };
    }

    async componentDidMount() {
        const merchantClientsResult = await clientsApiService.getForMerchant(this.props.merchantId);

        if (!merchantClientsResult.success) {
            this.toast?.show({ severity: "error", summary: "Error", detail: merchantClientsResult.message, life: 3000 });
            this.setState({ isLoading: false });
            return;
        }

        this.setState({
            isLoading: false,
            items: merchantClientsResult.data
        });
    }

    copyToClipboard = (val: string) => {
        navigator.clipboard.writeText(val);

        this.toast?.show({ severity: "success", summary: "Action Completed", detail: "Text Copied." });
    };

    renderIntegrationData = (rowData: MerchantClients) => {
        return (
            <Row>
                <Col md={12}>
                    <FormGroup>
                        <Row>
                            <Col md={2}>
                                <Label>Client Id: </Label>
                            </Col>
                            <Col md={9}>
                                <Label> {rowData.clientId}</Label>
                            </Col>
                            <Col md={1}>
                                <button
                                    type="button"
                                    className="p-row-editor-init p-link"
                                    tabIndex={0}
                                    onClick={() => this.copyToClipboard(rowData.clientId ?? "")}
                                >
                                    <span className="p-row-editor-init-icon pi pi-fw pi-copy"></span>
                                </button>
                            </Col>
                        </Row>
                    </FormGroup>
                </Col>
                <Col md={12}>
                    <FormGroup>
                        <Row>
                            <Col md={2}>
                                <Label>Secret Key:</Label>
                            </Col>
                            <Col md={9}>
                                <Label> {rowData.isMtlsClient ? "This is an MTLS Client. Please Contact Admin for Certificate Access" : rowData.secret}</Label>
                            </Col>
                            {!rowData.isMtlsClient && rowData.secret && <Col md={1}>
                                <button
                                    type="button"
                                    className="p-row-editor-init p-link"
                                    tabIndex={0}
                                    onClick={() => this.copyToClipboard(rowData.secret ?? "")}>
                                    <span className="p-row-editor-init-icon pi pi-fw pi-copy"></span>
                                </button>
                            </Col>}
                        </Row>
                    </FormGroup>
                </Col>
                <Col md={12}>
                    <FormGroup>
                        <Row>
                            <Col md={2}>
                                <Label>Allowed Origins</Label>
                            </Col>
                            <Col md={9}>
                                <Label>{rowData.allowedCorsOrigins.length > 0 ? this.renderHostNamesBadge(rowData.allowedCorsOrigins) : "All Host names are allowed."}</Label>
                            </Col>
                        </Row>
                    </FormGroup>
                </Col>
            </Row>
        );
    };

    renderSubmit = () => {
        return (
            <>
                <Row style={{ marginTop: "20px" }}>
                    <Col md={12}>
                        <FormGroup>
                            <Label>Notification Url</Label>
                            <InputText
                                value={this.state.notificationUrl}
                                disabled
                                className={"form-control"}
                                onChange={(e) => this.updateIntegrationValue("notificationUrl", e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <FormGroup>
                            <Label>Notification Status Url</Label>
                            <InputText
                                value={this.state.notificationStatusUrl}
                                disabled
                                className={"form-control"}
                                onChange={(e) => this.updateIntegrationValue("notificationStatusUrl", e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <FormGroup>
                            <Label>Notification Refresh Url</Label>
                            <InputText
                                value={this.state.notificationRefreshUrl}
                                disabled
                                className={"form-control"}
                                onChange={(e) => this.updateIntegrationValue("notificationRefreshUrl", e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <FormGroup>
                            <Label>Notification 3DUrl</Label>
                            <InputText
                                value={this.state.notification3DUrl}
                                disabled
                                className={"form-control"}
                                onChange={(e) => this.updateIntegrationValue("notification3DUrl", e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                {/* <Row>
                    <Col md={12}>
                        <FormGroup>
                            <Row className="align-right">
                                {isMerchantProfileUpdateAllowed() && (
                                    <div style={{ padding: "15px" }}>
                                        <Button
                                            type="button"
                                            label="Submit"
                                            icon="pi pi-check"
                                            className="p-button"
                                            onClick={this.onIntegrationSubmitButtonClick}
                                        ></Button>
                                    </div>
                                )}
                            </Row>
                        </FormGroup>
                    </Col>
                </Row> */}
            </>
        );
    };

    updateIntegrationValue = (fieldName: string, value: string) => {
        const state = this.state as any;
        state[fieldName] = value;
        this.setState(state);
    };

    onIntegrationSubmitButtonClick = async () => {
        this.toast?.show({ severity: "error", summary: "Error", detail: "This functionality is not yet available.", life: 3000 });
        // var result = await merchantApiService.updateMerchantIntegration(this.state.merchant!);
        // if (result.success) {
        //     history.push(`/merchants`);
        // } else {
        // }
    };

    renderHostNamesBadge(titles: string[]) {
        return (
            <div>
                {titles.map((title, idx) => (
                    <div key={idx} style={{ margin: "5px" }} className={classNames("badge", "badge-success")}>
                        {title}
                    </div>
                ))}
            </div>
        );
    }

    render() {
        if (this.state.isLoading) {
            return <Col md={12} lg={12}>
                <Card style={{ minHeight: "500px" }} className="align-center">
                    <ReactLoader type="ball-pulse" active />
                </Card>
            </Col>;
        }

        return (
            <Card>
                <Toast ref={(el) => (this.toast = el)} />
                <Container fluid>
                    <DataTable value={this.state.items} stripedRows>
                        <Column body={this.renderIntegrationData} style={{ width: "80%" }}></Column>
                    </DataTable>
                    {this.renderSubmit()}
                </Container>
            </Card>
        );
    }
}
