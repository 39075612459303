import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import React, { Component, Fragment } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Card, CardBody, CardHeader, Col, Container, Label, FormGroup, Row } from "reactstrap";
import PageTitle from "../../Layout/AppMain/PageTitle";
import { Merchant, MerchantAddresses, MerchantContact, MerchantDocument, MerchantStatus } from "../../models/MerchantModel";
import { Dropdown } from "primereact/dropdown";
import "./flags.css";
import "./merchantdetails.scss";
import { SelectItem } from "../../models/SelectItem";
import { Button } from "primereact/button";
import history from "../../history";
import { Toast } from "primereact/toast";
import _ from "lodash";
import { merchantApiService } from "../../services/api/merchants/MerchantApiService";
import { clientApiService } from "../../services/api/clients/ClientApiService";
import { httpService } from "../../services/HttpService";
import ExtendedInputText from "../../components/ExtendedInputText";
import StringUtils from "../../utils/stringUtils";
import Loader from "../../components/Loader";
import { utils } from "../../utils/utils";
import { ProfileImage } from "../../components/Image/ProfileImage";
import MerchantContactComponent from "./MerchantContact";
import MerchantAddress from "./MerchantAddress";
import MerchantDocuments from "./MerchantDocuments";
import { CSSTransitionComponent } from "../../components/CSSTransition";
import { CurrencyModel } from "../../models/CurrencyModel";
import { MultiSelect } from "primereact/multiselect";
import { currenciesApiService } from "../../services/api/merchants/CurrenciesApiService";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";

interface MerchantDetailsState {
    isLoading: boolean;
    merchant: Merchant;
    currencies: SelectItem[];
    isSubmit: boolean;
}
export enum currencies {
    BGN = 1,
    USD = 2,
    EUR = 3,
    AAA = 4
}  
export default class CreateMerchantComponent extends Component<Record<string, never>, MerchantDetailsState> {
    toast: Toast | null = null;

    countries: SelectItem[] = [];
    merchantClients: SelectItem[] = [];

    validationErrors: Map<string, boolean> = new Map<string, boolean>();

    constructor(props: Record<string, never>) {
        super(props);

        this.state = {
            merchant: new Merchant(),
            isLoading: true,
            currencies: [],
            isSubmit: false
        };
    }

    async componentDidMount() {

        this.setState({ isLoading: true });
        
        const countriesResult = await utils.getCountriesResult();
        if (!countriesResult.success) {
            this.toast?.show({ severity: "error", summary: "Error", detail: countriesResult.message, life: 3000 });
            this.setState({ isLoading: false });
            return;
        }

        this.countries = utils.getCountries();

        const clientsResult = await clientApiService.getClients();
        if (!clientsResult.success) {
            this.setState({ isLoading: false });
            this.toast?.show({ severity: "error", summary: "Error", detail: clientsResult.message, life: 3000 });
            return;
        }
        this.merchantClients= clientsResult.data;

        const currenciesResult = await currenciesApiService.getActive();

        if (!currenciesResult.success) {
            this.setState({ isLoading: false });
            this.toast?.show({ severity: "error", summary: "Error", detail: currenciesResult.message, life: 3000 });
            return;
        }

        const currencies = currenciesResult.data.map((m: CurrencyModel) => ({ label: m.charCode, value: m.id } as SelectItem));

        this.setState({ isLoading: false, currencies: currencies });
    }

    updateValue = (fieldName: string, value: string) => {
        const merchantDetails = this.state.merchant as any;

        merchantDetails[fieldName] = value;

        this.setState({ merchant: merchantDetails });
    };

    validateInput = (fieldName: string):boolean => {
        const merchantDetails = this.state.merchant as any;
        return this.state.isSubmit &&  (merchantDetails[fieldName] === "" || undefined) ? true : false;
    };
    
    validateDropdown = (fieldName: string) => {
        const merchantDetails = this.state.merchant as any;
        if(this.state.isSubmit && (merchantDetails[fieldName].length === 0 || undefined ))
            return ("multiselect-custom p-invalid");
        else
            return ("multiselect-custom");
    };

    onImageSelected = async (image: File) => {
        const merchantDetails = this.state.merchant;
        merchantDetails!.logo = (await utils.convertFileToBase64(image) as string);
        this.setState({ merchant: merchantDetails });
    };

    fileInput = (input: boolean) => {
        if (!input) {
            this.toast?.show({ severity: "error", summary: "File Name", detail: "File Name Exceeds 128 Characters.", life: 3000 });
            return;
        }
    };

    countryOptionTemplate(option: SelectItem) {
        return (
            <div className="country-item">
                <img
                    alt={option.label}
                    src="showcase/demo/images/flag_placeholder.png"
                    onError={(e) => ((e.target as any).src = "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")}
                    className={`flag flag-${option.value.toLowerCase()}`}
                />
                <div>{option.label}</div>
            </div>
        );
    }

    onUpdateCurrencyMultiSelect = (ids: number[]) => {
        if (this.state.merchant != null) {

       
            const merchant = this.state.merchant;
            if (ids.includes(currencies.AAA)) {
                // Select all currency IDs

                merchant.merchantCurrencies = this.state.currencies
                    .filter(currency => parseInt(currency.value) !== currencies.AAA)
                    .map(currency => parseInt(currency.value));            
            } 
            else {
                merchant.merchantCurrencies = ids;
            }
            this.setState({ merchant: merchant });
        }
    };

    onSubmitButtonClick = async () => {
        this.setState({ isLoading: true });

        const result = await merchantApiService.create(this.state.merchant!);

        this.setState({ isLoading: false, isSubmit: true });

        if (result.success) {
            history.push("/merchants");
        } else {
            this.toast?.show({ severity: "error", summary: "Error", detail: result.message, life: 6000 });
        }
    };

    onIntegrationSubmitButtonClick = async () => {
        const result = await merchantApiService.updateMerchantIntegration(this.state.merchant!);
        if (result.success) {
            history.push("/merchants");
        } else {
            this.toast?.show({ severity: "error", summary: "Error", detail: result.message, life: 3000 });
        }
    };

    setMerchantStatus = (status: MerchantStatus) => {
        const merchant = this.state.merchant;
        merchant!.status = status;
        this.setState({ merchant: merchant });
    };

    renderBadge(success: boolean, title: string) {
        return <div className={classNames("badge", "badge-" + (success ? "success" : "danger"))}>{title}</div>;
    }

    defaultImage = () => {
        return "/dummy.jpg";
    };

    onContactsUpdated = (contacts: MerchantContact[]) => {
        this.state.merchant!.merchantContacts = contacts;
    };

    onContactDeleted = (contact: MerchantContact) => {
        const index = this.state.merchant!.merchantContacts.findIndex(f => f.email == contact.email && f.name == contact.name && f.phone == contact.phone && f.type == contact.type);

        if (index >= 0) {
            this.state.merchant!.merchantContacts.splice(index, 1);
        }
    };

    onDocumentsUpdated = (documents: MerchantDocument[]) => {
        this.state.merchant!.merchantDocuments = documents;
    };

    onAddressUpdated = (address: MerchantAddresses[]) => {
        this.state.merchant!.merchantAddresses = address;
    };

    render() {
        if (this.state.isLoading) {
            return (
                <>
                    <Toast ref={(el) => (this.toast = el)} />
                    <Loader />
                </>
            );
        }

        return (
            <Fragment>
                <TransitionGroup component="div" appear={true} exit={false} enter={false}>
                    <CSSTransition classNames="TabsAnimation" timeout={1500}>
                        <PageTitle
                            heading="Merchant Details"
                            icon={faUserPlus}
                        />
                    </CSSTransition>
                    <CSSTransition classNames="TabsAnimation" timeout={1500}>
                        <Fragment>
                            <Toast ref={(el) => (this.toast = el)} position={"bottom-center"} baseZIndex={99999}></Toast>
                            <Container fluid>
                                <div>
                                    <Row>
                                        <Col md="12" lg="12">
                                            <Card className="mb-3">
                                                <CardHeader className="card-header-tab">
                                                    <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                                                        <i className="header-icon lnr-laptop-phone mr-3 text-muted opacity-6"> </i>
                                                        Merchant Details
                                                    </div>
                                                </CardHeader>
                                                <CardBody>
                                                    <Row>
                                                        <Col md="3" lg="3" sm="12" style={{ padding: "2em" }}>
                                                            <Row style={{ cursor: "pointer" }}>
                                                                <ProfileImage
                                                                    imageStyle={{ width: "100%" }}
                                                                    className="rounded-circle"
                                                                    src={(this.state.merchant.logo == null || this.state.merchant.logo.length === 0) ? this.defaultImage() : utils.appendBase64Data(this.state.merchant.logo)}
                                                                    alt="merchant logo"
                                                                    onImageSelected={this.onImageSelected}
                                                                    fileInput={this.fileInput}
                                                                />
                                                            </Row>
                                                        </Col>
                                                        <Col md={9} lg={9} sm="12">
                                                            <Row>
                                                                <Col md={6}>
                                                                    <FormGroup>
                                                                        <Label>Name</Label>
                                                                        <ExtendedInputText
                                                                            name="Name"
                                                                            value={this.state.merchant.name}
                                                                            required={this.validateInput("name")}
                                                                            onChange={(value) => this.updateValue("name", value)}
                                                                            onValidStatusChanged={(value: boolean) =>
                                                                                (this.validationErrors.set("name", value))
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <FormGroup>
                                                                        <Label>Email Address</Label>
                                                                        <ExtendedInputText
                                                                            type="email"
                                                                            value={this.state.merchant.email}
                                                                            onChange={(value) => this.updateValue("email", value)}
                                                                            validation={() => this.state.isSubmit && this.state.merchant.email === "" ? false : this.state.merchant.email === "" ? true  : StringUtils.isValidEmail(this.state.merchant.email!)}
                                                                            name="Email"
                                                                            onValidStatusChanged={(value: boolean) =>
                                                                                (this.validationErrors.set("email", value))
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={6}>
                                                                    <FormGroup>
                                                                        <Label>Website Url</Label>
                                                                        <ExtendedInputText
                                                                            required={this.validateInput("websiteUrl")}
                                                                            value={this.state.merchant.websiteUrl}
                                                                            onChange={(e) => this.updateValue("websiteUrl", e)}
                                                                            name="WebsiteUrl"
                                                                            onValidStatusChanged={(value: boolean) =>
                                                                                (this.validationErrors.set("websiteUrl", value))}
                                                                        /> 
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <FormGroup>
                                                                        <Label>Phone</Label>
                                                                        <ExtendedInputText
                                                                            required={this.validateInput("phone")}
                                                                            value={this.state.merchant.phone}
                                                                            type="text"
                                                                            onChange={(value) => this.updateValue("phone", value)}
                                                                            name="Phone"
                                                                            onValidStatusChanged={(value: boolean) =>
                                                                                (this.validationErrors.set("phone", value))}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>

                                                            <Row>
                                                                <Col md={6}>
                                                                    <FormGroup>
                                                                        <Label>VAT Number</Label>
                                                                        <ExtendedInputText
                                                                            required={this.validateInput("vatNumber")}
                                                                            value={this.state.merchant.vatNumber}
                                                                            type="text"
                                                                            onChange={(value) => this.updateValue("vatNumber", value)}
                                                                            name="VatNumber"
                                                                            onValidStatusChanged={(value: boolean) =>
                                                                                (this.validationErrors.set("VatNumber", value))}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <Label>Currency</Label>
                                                                    <FormGroup>
                                                                        <MultiSelect
                                                                            value={this.state.merchant.merchantCurrencies}
                                                                            options={this.state.currencies}
                                                                            onChange={(e) => this.onUpdateCurrencyMultiSelect(e.value)}
                                                                            placeholder="Select Currency"
                                                                            filter
                                                                            style={{ width: "100%" }}
                                                                            className={`${this.validateDropdown("merchantCurrencies")}`}                                                                             />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={6}>
                                                                    <FormGroup>
                                                                        <Label>Flexcube Branch</Label>
                                                                        <ExtendedInputText
                                                                            required={this.validateInput("flexCubeBranch")}
                                                                            type="text"
                                                                            value={this.state.merchant.flexCubeBranch}
                                                                            onChange={(value) => this.updateValue("flexCubeBranch", value)}
                                                                            name="FlexCube Branch"
                                                                            onValidStatusChanged={(value: boolean) => (this.validationErrors.set("flexCubeBranch", value))}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <Label>Merchant Client</Label>
                                                                    <FormGroup>
                                                                        <MultiSelect
                                                                            className={`${this.validateDropdown("clientIds")}`}
                                                                            value={this.state.merchant.clientIds}
                                                                            options={this.merchantClients}
                                                                            optionLabel="label"
                                                                            optionValue="value"
                                                                            onChange={(e) => this.updateValue("clientIds", e.target.value)}
                                                                            placeholder={"Select Merchant Clients"}
                                                                            itemTemplate={(option) => {
                                                                                return <span>{option.label}</span>;
                                                                            }}
                                                                            style={{ width: "100%" }}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={6}>
                                                                    <FormGroup>
                                                                        <Label>Minimum Transaction Limit</Label>
                                                                        <ExtendedInputText
                                                                            type="number"
                                                                            min="0.01"
                                                                            step="0.01"
                                                                            value={this.state.merchant.minimumTransactionAmount}
                                                                            onChange={(value) => this.updateValue("minimumTransactionAmount", value)}
                                                                            validation={() => (this.state.merchant.minimumTransactionAmount ?? 0) > 0}
                                                                            name="Minimum Transaction Limit"
                                                                            onValidStatusChanged={(value: boolean) => (this.validationErrors.set("minimumTransactionAmount", value))}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12" lg="12">
                                            <CSSTransitionComponent>
                                                <Card className="mb-3">
                                                    <CardHeader>
                                                        Merchant Contacts
                                                    </CardHeader>
                                                    <CardBody>
                                                        <MerchantContactComponent merchantContacts={this.state.merchant.merchantContacts} merchantId={0} localMode={true} onLocalContactUpdated={this.onContactsUpdated} />
                                                    </CardBody>
                                                </Card>
                                            </CSSTransitionComponent>
                                            <CSSTransitionComponent>
                                                <Card className="mb-3">
                                                    <CardHeader>
                                                        Merchant Addresses
                                                    </CardHeader>
                                                    <CardBody>
                                                        <MerchantAddress merchantAddresses={this.state.merchant.merchantAddresses} merchantId={0} localMode={true} onLocalAddressesUpdated={this.onAddressUpdated} />
                                                    </CardBody>
                                                </Card>
                                            </CSSTransitionComponent>
                                            <CSSTransitionComponent>
                                                <Card className="mb-3">
                                                    <CardHeader>
                                                        Merchant Documents
                                                    </CardHeader>
                                                    <CardBody>
                                                        <MerchantDocuments merchantDocuments={this.state.merchant.merchantDocuments} merchantId={0} localMode={true} onLocalDocumentUpdated={this.onDocumentsUpdated} />
                                                    </CardBody>
                                                </Card>
                                            </CSSTransitionComponent>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12" lg="12">
                                            <Card className="mb-3">
                                                <CardBody>
                                                    <Row className="align-right">
                                                        <div style={{ padding: "15px" }}>
                                                            <Button
                                                                type="button"
                                                                disabled={Object.values(this.validationErrors).filter((f) => f == false).length > 0}
                                                                label="Submit"
                                                                icon="pi pi-check"
                                                                className="p-button"
                                                                onClick={this.onSubmitButtonClick}
                                                            ></Button>
                                                        </div>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </div>
                            </Container>
                        </Fragment>
                    </CSSTransition>
                </TransitionGroup>
            </Fragment>
        );
    }
}