import React, { Component, Fragment } from "react";
import { Card, Col, Container, Label, Row } from "reactstrap";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { SelectItem } from "../../models/SelectItem";
import classNames from "classnames";
import moment from "moment";
import { Toast } from "primereact/toast";
import { User, UserAddress, UserProfileUpdate } from "../../models/User";
import { MultiSelect } from "primereact/multiselect";
import { userApiService } from "../../services/api/users/UsersApiService";
import { httpService } from "../../services/HttpService";
import _ from "lodash";
import { Button } from "primereact/button";
import history from "../../history";
import ExtendedDropdown from "../../components/ExtendedDropdown";
import ExtendedInputText from "../../components/ExtendedInputText";
import AddUserAddress from "./UserAddressUpdate";
import { isUserProfileUpdateAllowed } from "../../authGuard";
import ReactLoader from "react-loaders";
import { utils } from "../../utils/utils";

interface GeneralDataProps {
    userData: User | null;
    groupsSelectItem: SelectItem<number>[];
    kycStatusTypes: Map<number, string>;
}
interface GeneralDataState {
    isLoading: boolean;
    userData: User | null;
    isAddressDetailVisible: boolean;
    selectedAddress: UserAddress | null;
    multiSelectLimit: number;
}

export default class GeneralDataComponent extends Component<GeneralDataProps, GeneralDataState> {
    toast: Toast | null = null;
    validationErrors: Map<string, boolean> = new Map<string, boolean>();
    countryNames: Map<string, string> = new Map<string, string>();
    previousState:GeneralDataState|null=null;
    
    constructor(props: GeneralDataProps | Readonly<GeneralDataProps>) {
        super(props);
        this.state = {
            isLoading: false,
            userData: this.props.userData,
            multiSelectLimit: 5,
            isAddressDetailVisible: false,
            selectedAddress: null,
        };
        this.previousState = JSON.parse(JSON.stringify(this.state));
    }
    genderSelectItem = [
        { label: "Male", value: "M" },
        { label: "Female", value: "F" }
    ];
    sourceOfFunds: SelectItem[] = [];

    countries: SelectItem[] = [];

    async componentDidMount() {
        this.setState({ isLoading: true });

        const countriesResult = await utils.getCountriesResult();
        if (!countriesResult.success) {
            this.toast?.show({ severity: "error", summary: "Error", detail: countriesResult.message, life: 3000 });
            this.setState({ isLoading: false });
            return;
        }

        this.countries = utils.getCountries();

        const sourceOfFunds = await userApiService.getSourceOfFunds();

        if (!sourceOfFunds.success) {
            this.toast?.show({ severity: "error", summary: "Error", detail: sourceOfFunds.message, life: 3000 });
            return;
        }

        this.sourceOfFunds = sourceOfFunds.data;
        this.setState({ isLoading: false });
    }

    componentDidUpdate(prevProps: Readonly<GeneralDataProps>, prevState: Readonly<GeneralDataState>, snapshot?: any): void {
        this.state.userData==null? this.setState({ userData : this.props.userData }):this.setState({ userData : this.state.userData });        
        this.previousState = JSON.parse(JSON.stringify(this.state));
    }

    shouldComponentUpdate(nextProps: Readonly<GeneralDataProps>, nextState: Readonly<GeneralDataState>, nextContext: any): boolean {
        return (!_.isEqual(this.props, nextProps) || !_.isEqual(this.previousState, nextState));
    }

    onFieldUpdate = (fieldName: string, value: any) => {    
        const userData = this.state.userData as any;
        userData[fieldName] = value;
        this.setState({ userData: userData });
    };

    updateNationalities = (value: any) => {

        const userData = this.state.userData;
        userData!.nationalities = value;
        if(userData!.nationalities.length > this.state.multiSelectLimit) {
        	this.toast?.show({ severity: "error", summary: "Warning", detail: `Select only ${this.state.multiSelectLimit} Nationalities`, life: 3000 });
        } else {
        	this.setState({ userData: userData});
        }
    };

    updateTaxResidence = (value: any) => {
        const userData = this.state.userData;
        userData!.taxResidences = value;
        if(userData!.taxResidences.length > this.state.multiSelectLimit) {
        	this.toast?.show({ severity: "error", summary: "Warning", detail: `Select only ${this.state.multiSelectLimit} Tax Residences`, life: 3000 });
        } else {
        	this.setState({ userData: userData });
        }
    };

    renderBadge(success: boolean, title: string) {
        return <div className={classNames("badge", "badge-" + (success ? "success" : "danger"))}>{title}</div>;
    }

    countryOptionTemplate(option: SelectItem) {
        return (
            <div className="country-item">
                <img
                    alt={option.label}
                    src="showcase/demo/images/flag_placeholder.png"
                    onError={(e) => ((e.target as any).src = "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")}
                    className={`flag flag-${option.value.toLowerCase()}`}
                />
                <div>{option.label}</div>
            </div>
        );
    }

    renderDateBadge = (date: Date) => {
        if (date == null) {
            return this.renderBadge(false, "Pending");
        }

        return (
            <>
                {this.renderBadge(true, "Accepted")}
                {` at ${moment(date).utc().format("DD-MM-YYYY")}`}
            </>
        );
    };

    onSubmitButtonClick = async () => {
        this.setState({ isLoading: true });

        const newData = this.state.userData as User;

        const objToPost: UserProfileUpdate = {
            id: newData.id,
            firstName: newData.firstName,
            lastName: newData.lastName,
            nonLatinFirstName : newData.nonLatinFirstName,
            nonLatinLastName : newData.nonLatinLastName,
            email: newData.email,
            photo: newData.photo,
            birthDate: newData.birthDate,
            phoneNumber: newData.phoneNumber,
            cryptoCustomer: newData.cryptoCustomer,
            sourceOfFundId: newData.sourceOfFundId,
            residenceAddress: newData.residenceAddress,
            correspondenceAddress: newData.correspondenceAddress,
            nationalities: newData.nationalities,
            taxResidences: newData.taxResidences,
            nationalIdNumber: newData.nationalIdNumber,
            primaryLanguage: newData.primaryLanguage,
            secondaryLanguage: newData.secondaryLanguage,
            merchantGroupId: newData.merchantGroupId,
            accountPurposeCode: newData.accountPurposeCode,
            financingMethodCode: newData.financingMethodCode,
            mainActivityCode: newData.mainActivityCode,
            personalIdentificationNumber: newData.personalIdentificationNumber,
            gender:newData.gender
            
        } as UserProfileUpdate;

        const result = await userApiService.update(objToPost);

        this.setState({ isLoading: false });

        if (result.success) {
            history.push(`/user-profile/${newData.id}`);
        } else {
            this.toast?.show({ severity: "error", summary: "Error", detail: result.message, life: 3000 });
        }
    };


    addorEditAddress = (type: string, address: UserAddress) => {
        address.type = type;
        this.setState({ isAddressDetailVisible: true, selectedAddress: address });
    };

    onAddressObj = (obj: UserAddress | null | undefined): UserAddress => {
        if (obj === null || obj === undefined) {
            return {
                countryId: "",
                city: "",
                stateProvinceCounty: "",
                zipCode: "",
                addressLine: "",
            };
        }
        return obj;
    };

    onAddressDetailsModalClose = async () => {
        this.setState({ isAddressDetailVisible: false });
    };

    renderAddress = (rowData: UserAddress) => {
        return (
            <span>
                {rowData?.addressLine} {rowData?.city} {rowData?.stateProvinceCounty} {rowData?.zipCode} {this.countryNames.get(rowData?.countryId)}
            </span>
        );
    };

    onAddressUpdate = (address: UserAddress) => {

        const userDataDetail = this.state.userData;

        const objToPost: UserAddress = {
            countryId: address.countryId,
            city: address.city,
            stateProvinceCounty: address.stateProvinceCounty,
            zipCode: address.zipCode,
            addressLine: address.addressLine,
        } as UserAddress;

        if (address.type === "correspondence") {
            userDataDetail!.correspondenceAddress = objToPost;
        } else {
            userDataDetail!.residenceAddress = objToPost;
        }

        this.setState({ userData: userDataDetail, isAddressDetailVisible: false });
    };

    render() {
        if (this.state.isLoading || this.state.userData == null) {
            return (
                <Container fluid>
                    <Toast ref={(el) => (this.toast = el)} />
                    <Card>
                        <Row className="align-center">
                            <ReactLoader type="ball-pulse" active />
                        </Row>
                    </Card>
                </Container>
            );
        }
        // TODO. Handle Criteria.
        const isReadOnly = this.state.userData.kycStatus != null && this.props.kycStatusTypes.get(this.state.userData.kycStatus) !== "New" ? true : false;

        return (
            <Fragment>
                {this.state.isAddressDetailVisible && (
                    <AddUserAddress
                        onHide={this.onAddressDetailsModalClose}
                        address={this.state.selectedAddress!}
                        countries={this.countries}
                        onAddressUpdate={this.onAddressUpdate}
                    />
                )}

                <TransitionGroup appear={true} exit={false} enter={false}>
                    <CSSTransition classNames="TabsAnimation" timeout={1500}>
                        <Fragment>
                            <Toast ref={(el) => (this.toast = el)} position={"bottom-center"} baseZIndex={99999}></Toast>
                            <Container fluid>
                                <Card>
                                    <Row style={{ padding: "20px" }}>
                                        <Col style={{ padding: "20px" }} sm={12} md={4} lg={4} xl={3}>
                                            <Label for="UserId">User Id</Label>
                                            <InputText value={this.state.userData?.id} className={"form-control"} id="id" readOnly={true} />
                                        </Col>
                                        <Col style={{ padding: "20px" }} sm={12} md={4} lg={4} xl={3}>
                                            <Label for="UserName">User Name</Label>
                                            <InputText value={this.state.userData?.email} className={"form-control"} id="UserName" readOnly={true} />
                                        </Col>
                                        <Col style={{ padding: "20px" }} sm={12} md={4} lg={4} xl={3}>
                                            <Label for="FirstName">First Name</Label>
                                            <InputText
                                                value={this.state.userData?.firstName}
                                                className={"form-control"}
                                                id="firstName"
                                                readOnly={true}
                                            />
                                        </Col>
                                        <Col style={{ padding: "20px" }} sm={12} md={4} lg={4} xl={3}>
                                            <Label for="LastName">Last Name</Label>
                                            <InputText
                                                value={this.state.userData?.lastName}
                                                className={"form-control"}
                                                id="lastName"
                                                readOnly={true}
                                            />
                                        </Col>
                                        <Col style={{ padding: "20px" }} sm={12} md={4} lg={4} xl={3}>
                                            <Label for="EmailAddress">Email Address</Label>
                                            <InputText value={this.state.userData?.email} className={"form-control"} id="email" readOnly={true} />
                                        </Col>
                                        <Col style={{ padding: "20px" }} sm={12} md={4} lg={4} xl={3}>
                                            <Label for="RegisterationDate">Registeration Date</Label>
                                            <Calendar
                                                id="registerationStamp"
                                                style={{ width: "100%" }}
                                                value={new Date(this.state.userData?.registerationStamp)}
                                                disabled={true}
                                            />
                                        </Col>
                                        <Col style={{ padding: "20px" }} sm={12} md={4} lg={4} xl={3}>
                                            <Label for="Merchant">Merchant</Label>
                                            <InputText
                                                value={this.state.userData?.merchant}
                                                className={"form-control"}
                                                id="merchant"
                                                readOnly={true}
                                            />
                                        </Col>
                                        <Col style={{ padding: "20px" }} sm={12} md={4} lg={4} xl={3}>
                                            <Label for="MerchantGroup">Merchant Group</Label>
                                            <ExtendedDropdown
                                                name="merchantGroup"
                                                style={{ display: "flex" }}
                                                value={this.state.userData?.merchantGroupId}
                                                options={this.props.groupsSelectItem}
                                                filter
                                                filterBy="label"
                                                required={true}
                                                onChange={(e) => this.onFieldUpdate("merchantGroupId", e.value)}
                                                placeholder={"Select Merchant Group"}
                                                onValidStatusChanged={(value: boolean) => this.validationErrors.set("merchantGroup", value)}
                                                disabled={isReadOnly}
                                            />
                                        </Col>
                                        <Col style={{ padding: "20px" }} sm={12} md={4} lg={4} xl={3}>
                                            <Label for="Mobile">Mobile</Label>
                                            <InputText
                                                value={this.state.userData?.phoneNumber}
                                                className={"form-control"}
                                                id="phoneNumber"
                                                onChange={(e) => this.onFieldUpdate("phoneNumber", e.target.value)}
                                                readOnly={true}
                                            />
                                        </Col>
                                        <Col style={{ padding: "20px" }} sm={12} md={4} lg={4} xl={3}>
                                            <Label for="DateofBirth">Date of Birth</Label>
                                            <Calendar
                                                value={new Date(this.state.userData?.birthDate)}
                                                style={{ width: "100%" }}
                                                id="birthDate"
                                                maxDate={new Date()}
                                                onChange={(e) => this.onFieldUpdate("birthDate", e.value)}
                                                disabled={isReadOnly}
                                            />
                                        </Col>

                                        <Col style={{ padding: "20px" }} sm={12} md={4} lg={4} xl={3}>
                                            <Label for="personalIdentificationNumber">Personal Identification Number</Label>
                                            <InputText
                                                value={this.state.userData?.personalIdentificationNumber}
                                                className={"form-control"}
                                                id="personalIdentificationNumber"
                                                onChange={(e) => this.onFieldUpdate("personalIdentificationNumber", e.target.value)}
                                                minLength={9}
                                                maxLength={20}
                                                readOnly={true}
                                            />
                                        </Col>
                                        <Col style={{ padding: "20px" }} sm={12} md={4} lg={4} xl={3}>
                                            <Label for="Gender">Gender</Label>
                                            <ExtendedDropdown
                                                name="gender"
                                                style={{ display: "flex" }}
                                                value={this.state.userData?.gender}
                                                options={this.genderSelectItem}
                                                filter
                                                filterBy="label"
                                                required={true}
                                                onChange={(e) => this.onFieldUpdate("gender", e.value)}
                                                placeholder={"Please Select Gender"}
                                                onValidStatusChanged={(value: boolean) => this.validationErrors.set("gender", value)}
                                                disabled={isReadOnly}
                                            />
                                        </Col>
                                        <Col style={{ padding: "20px" }} sm={12} md={6} lg={8} xl={6}>
                                            <Label for="SourceofFunds">Source of Funds</Label>
                                            <ExtendedDropdown
                                                style={{ display: "flex" }}
                                                value={this.state.userData?.sourceOfFundId.toString()}
                                                options={this.sourceOfFunds}
                                                onChange={(e) => this.onFieldUpdate("sourceOfFundId", e.value)}
                                                filter
                                                filterBy="label"
                                                required={true}
                                                name="Source Of Funds"
                                                onValidStatusChanged={(value: boolean) => this.validationErrors.set("sourceOfFunds", value)}
                                                disabled={isReadOnly}
                                            />
                                        </Col>

                                        <Col style={{ padding: "20px" }} sm={12} md={4} lg={4} xl={6}>
                                            <Label for="AddressOfResidence">Address Of Residence</Label>

                                            <div className="square border rounded border-2 p-2">
                                                {this.renderAddress(this.state.userData?.residenceAddress)}
                                                <button
                                                    type="button"
                                                    className="p-row-editor-init p-link float-right"
                                                    tabIndex={0}
                                                    onClick={() => this.addorEditAddress("residence", this.onAddressObj(this.state.userData?.residenceAddress))}
                                                    disabled={isReadOnly}
                                                >
                                                    <span className="p-row-editor-init-icon pi pi-fw pi-pencil"></span>
                                                </button>
                                            </div>
                                        </Col>
                                        <Col style={{ padding: "20px" }} sm={12} md={4} lg={4} xl={6}>
                                            <Label for="AddressOfCorrespondence">Address Of Correspondence</Label>

                                            <div className="square border rounded border-2 p-2">
                                                {this.renderAddress(this.state.userData?.correspondenceAddress)}
                                                <button
                                                    type="button"
                                                    className="p-row-editor-init p-link float-right"
                                                    tabIndex={0}
                                                    onClick={() =>
                                                        this.addorEditAddress("correspondence", this.onAddressObj(this.state.userData?.correspondenceAddress))
                                                    }
                                                    disabled={isReadOnly}
                                                >
                                                    <span className="p-row-editor-init-icon pi pi-fw pi-pencil"></span>
                                                </button>
                                            </div>
                                        </Col>
                                        <Col style={{ padding: "20px" }} sm={12} md={4} lg={4} xl={6}>
                                            <Label for="Nationalities">Nationalities</Label>
                                            <MultiSelect
                                                id="nationalities"
                                                style={{ display: "flex" }}
                                                filter
                                                filterBy="label"
                                                optionLabel="label"
                                                optionValue="value"
                                                showSelectAll={false}
                                                value={this.state.userData?.nationalities}
                                                options={this.countries}
                                                onChange={(e) => this.updateNationalities(e.value)}
                                                display="chip"
                                                //selectedItemTemplate={this.selectedCountryTemplate}
                                                itemTemplate={this.countryOptionTemplate}
                                                disabled={isReadOnly}
                                            />
                                        </Col>

                                        <Col style={{ padding: "20px" }} sm={12} md={4} lg={4} xl={6}>
                                            <Label for="TaxResidence1">Tax Residence</Label>
                                            <MultiSelect
                                                id="taxResidences"
                                                style={{ display: "flex" }}
                                                filter
                                                filterBy="label"
                                                optionLabel="label"
                                                optionValue="value"
                                                showSelectAll={false}
                                                value={this.state.userData?.taxResidences}
                                                options={this.countries}
                                                onChange={(e) => this.updateTaxResidence(e.value)}
                                                display="chip"
                                                // selectedItemTemplate={this.selectedCountryTemplate}
                                                itemTemplate={this.countryOptionTemplate}
                                                disabled={isReadOnly}
                                            />
                                        </Col>

                                        <Col style={{ padding: "20px" }} sm={12} md={4} lg={4} xl={4}>
                                            <Label for="NationalIdNumber">National Id Number</Label>
                                            <InputText
                                                value={this.state.userData?.nationalIdNumber}
                                                className={"form-control"}
                                                id="nationalIdNumber"
                                                onChange={(e) => this.onFieldUpdate("nationalIdNumber", e.target.value)}
                                                readOnly={isReadOnly}
                                            />
                                        </Col>

                                        <Col style={{ padding: "20px" }} sm={12} md={4} lg={4} xl={4}>
                                            <Label for="PrimaryLanguage">Primary Language</Label>
                                            <ExtendedInputText
                                                name="primaryLanguage"
                                                value={this.state.userData?.primaryLanguage}
                                                required={true}
                                                onChange={(value) => this.onFieldUpdate("primaryLanguage", value)}
                                                onValidStatusChanged={(value: boolean) => this.validationErrors.set("primaryLanguage", value)}
                                                readOnly={isReadOnly}
                                            />
                                        </Col>
                                        <Col style={{ padding: "20px" }} sm={12} md={4} lg={4} xl={4}>
                                            <Label for="SecondaryLanguage">Secondary Language</Label>
                                            <InputText
                                                value={this.state.userData?.secondaryLanguage}
                                                className={"form-control"}
                                                id="secondaryLanguage"
                                                onChange={(e) => this.onFieldUpdate("secondaryLanguage", e.target.value)}
                                                readOnly={isReadOnly}
                                            />
                                        </Col>
                                    </Row>
                                    <Row style={{ padding: "20px", textAlign: "center" }}>
                                        <Col sm={12} md={4} style={{ padding: "20px" }}>
                                            <label htmlFor="TermAndConditionsAcceptance">Terms And Conditions Acceptance</label>
                                            <div>{this.renderDateBadge(this.state.userData?.termsAndConditionsAcceptanceDate)}</div>
                                        </Col>
                                        <Col sm={12} md={4} style={{ padding: "20px" }}>
                                            <label htmlFor="PrivacyPolicy">Privacy Policy</label>
                                            <div>{this.renderDateBadge(this.state.userData?.privacyPolicyAcceptanceDate)}</div>
                                        </Col>
                                        <Col sm={12} md={4} style={{ padding: "20px" }}>
                                            <label htmlFor="TariffAcceptance">Tariff Acceptance</label>
                                            <div>{this.renderDateBadge(this.state.userData?.tariffAcceptanceDate)}</div>
                                        </Col>
                                    </Row>
                                    {isUserProfileUpdateAllowed() && <div style={{ padding: "15px" }} className="align-right">
                                        <Button
                                            type="button"
                                            disabled={isReadOnly}
                                            label="Submit"
                                            icon="pi pi-check"
                                            className="p-button"
                                            onClick={this.onSubmitButtonClick}
                                        ></Button>
                                    </div>}
                                </Card>
                            </Container>
                        </Fragment>
                    </CSSTransition>
                </TransitionGroup>
            </Fragment>
        );
    }
}
