export interface Wallets {
    id: string,
    cbsId: string,
    ibanAccountNumber: string
    publicToken: string,
    default: boolean,
    tariff: string,
    balance: number,
    currency: string,
    blockedAmount: number
    timestamp: Date
}

export interface MerchantWalletBalanceRequest {
    merchantId : number
    accountNumber : string
}

export interface IndividualWalletBalanceRequest {
    individualId : number
    accountNumber : string
}

export interface IndividualWalletCloseRequest {
    individualId : number
    walletId : string
}

export interface TennCards {
    id: string,
    network: NetworkType,
    type: string,
    provider: string,
    issueDate: Date,
    pan: string,
    expiryDate: Date,
    holderName: string
    walletId: string,
    tariff: string,
    active: boolean
}

export interface PaymentMethods {
    paymentType : string;
    value : string;
    ownerName : string;
    lastFundsDeposited : number;
    lastFundsWithdrawn : number;
    has2FAOr3DS : boolean;
    alias: string;
    cvvOrCvcCode: string;
    cardType: string;
    validityMonth: string;
    validityYear: string;
    status : string;
    bankName: string;
    bankStatement: string;
    swift: string;
    currency: string;
    accountHolderName: string;
    bankAddress: string;
}

export enum NetworkType {
    Visa,
    MasterCard
}

export class CardConstant {
    static readonly cardNumberMinlength: number = 13;
    static readonly cardNumbeMaxLength: number = 19;
    static readonly cardYearLength: number = 2;
    static readonly cardYearsRange: number = 15;
}

export const PaymentMethodStatusType = {
    "1" : "Approved",
    "3" : "Rejected",
    "2" : "Under review",
};

export const PaymentMethodType = {
    Card : "Card",
    Bank: "Bank",
    NonIBAN : "Non-IBAN"
};