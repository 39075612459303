import { Result } from "../../../models/Result";
import { httpService } from "../../HttpService";
import { ICrudApiService } from "../../ICrudApiService";
import { MerchantAddresses } from "../../../models/MerchantModel";
import { SelectItem } from "../../../models/SelectItem";

class MerchantAddressApiService implements ICrudApiService<MerchantAddresses> {
    path: string = "merchantAddress";

    getAll = async (merchantId?: number): Promise<Result<MerchantAddresses[]>> => await httpService.get<MerchantAddresses[]>(`${this.path}/${merchantId}/all`);

    get = async (Id: number): Promise<Result<MerchantAddresses>> => await httpService.get<MerchantAddresses>(`/${this.path}/${Id}`);

    create = async (address: MerchantAddresses): Promise<Result<MerchantAddresses>> => await httpService.post<MerchantAddresses>(`/${this.path}`, address);

    update = async (address: MerchantAddresses): Promise<Result<MerchantAddresses>> => await httpService.put<MerchantAddresses>(`/${this.path}`, address);

    delete = async (Id: number): Promise<Result<void>> => await httpService.delete<void>(`/${this.path}/${Id}`);

    getAddressTypes = async (): Promise<Result<SelectItem[]>> => await httpService.get<SelectItem[]>(`/${this.path}/addressTypes`);
}

const merchantAddressApiService = new MerchantAddressApiService();

export { merchantAddressApiService };
