import { Result } from "../../../models/Result";
import { httpService } from "../../HttpService";
import { SelectItem } from "../../../models/SelectItem";
import { MerchantBaseRequest, MerchantAccessStatusModel, MerchantMtlsAccessRequest } from "../../../models/MerchantModel";


class ClientApiService {
    path: string = "client";
    baseApiUrl: string = `${process.env.REACT_APP_AUTH_API_BASE_URL}/api/v1` ?? "";

    GetMerchantAccess = async (model:MerchantBaseRequest): Promise<Result<MerchantAccessStatusModel>> => await httpService.post<MerchantAccessStatusModel>(`/${this.path}/merchant-access`,model, { baseURL: this.baseApiUrl });
    AddMerchantPortalAccess = async (model: MerchantBaseRequest): Promise<Result<unknown>> => await httpService.post<unknown>(`/${this.path}/merchant-portal-access`, model, { baseURL: this.baseApiUrl });
    RemoveMerchantPortalAccess = async (merchantId: number): Promise<Result<unknown>> => await httpService.delete<unknown>(`${this.baseApiUrl}/${this.path}/merchant-portal-access/${merchantId}`);

    AddMerchantApiAccess = async (model: MerchantMtlsAccessRequest): Promise<Result<unknown>> => await httpService.post<MerchantMtlsAccessRequest>(`/${this.path}/api-access`, model, { baseURL: this.baseApiUrl });
    RemoveMerchantApiAccess = async (merchantId: number): Promise<Result<unknown>> => await httpService.delete<unknown>(`${this.baseApiUrl}/${this.path}/api-access/${merchantId}`);

    getClients = async (): Promise<Result<SelectItem[]>> => await httpService.get<SelectItem[]>(`/${this.path}/clients`);
}

const clientApiService = new ClientApiService();

export { clientApiService };
