import { Result } from "../../../models/Result";
import { httpService } from "../../HttpService";
import { MerchantClients } from "../../../models/MerchantModel";

class ClientsApiService {
    private baseUrl: string = process.env.REACT_APP_AUTH_API_BASE_URL ?? "";
    private baseApiUrl: string = `${this.baseUrl}/api/v1`;
    private path: string = "Client";

    getForMerchant = async (merchantId?: number): Promise<Result<MerchantClients[]>> => await httpService.get<MerchantClients[]>(`${this.path}/merchant/${merchantId}`, { baseURL: this.baseApiUrl });
}

const clientsApiService = new ClientsApiService();

export { clientsApiService };
