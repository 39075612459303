import { Dialog } from "primereact/dialog";
import React, { Fragment } from "react";
import { CreateCardPaymentMethodRequest, CreateIbanPaymentMethodRequest, CreateNonIbanPaymentMethodRequest } from "../../models/MerchantModel";
import { Button } from "primereact/button";
import WithdrawViaBank from "./WithdrawViaBank";
import { Container } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { WithdrawPaymentMethodType, WithdrawSelectPaymentMethod } from "./WithdrawSelectPaymentMethod";
import { ExtendedSelectItem } from "../../models/SelectItem";
import { Wallets } from "../../models/FinancialData";
import { OperatorDataModel } from "../../models/User";

interface WithdrawViaPaymentMethodDialogProps{
    loggedUserData: OperatorDataModel | null;
    sourceAccountsOptions: ExtendedSelectItem[];
    sourceWallets: Wallets[];
    merchantId: number;
    onHide: () => void;
}

interface WithdrawViaPaymentMethodDialogState {
    paymentMethod: WithdrawPaymentMethodType | null
    currentStage: CurrentStage
}

enum CurrentStage {
    PaymentMethod,
    EnterDetails,
    Success,
    Failed
}

export class WithdrawViaPaymentMethodDialog extends React.Component<WithdrawViaPaymentMethodDialogProps, WithdrawViaPaymentMethodDialogState>{

    constructor(props: WithdrawViaPaymentMethodDialogProps | Readonly<WithdrawViaPaymentMethodDialogProps>){
        super(props);
        this.state = {
            paymentMethod: null,
            currentStage: CurrentStage.PaymentMethod
        };
    }

    async componentDidMount() {
    }

    selectPaymentMethod = (type: WithdrawPaymentMethodType | null) => {
        this.setState({ paymentMethod: type, currentStage: CurrentStage.EnterDetails });
    };

    onBack = () => {
        this.setState({ paymentMethod: null, currentStage: CurrentStage.PaymentMethod });
    };

    renderBody = () => {
        if (this.state.currentStage === CurrentStage.PaymentMethod) {
            return <WithdrawSelectPaymentMethod OnSelect={(type) => { this.selectPaymentMethod(type); }} showEPay={false} />;
        }

        // if (this.state.currentStage === CurrentStage.EnterDetails && this.state.paymentMethod === WithdrawPaymentMethodType.Card) {
        //     return <WithdrawViaCard OnSuccess={this.onSuccess} />;
        // }

        if (this.state.currentStage === CurrentStage.EnterDetails && this.state.paymentMethod === WithdrawPaymentMethodType.Bank) {
            return <WithdrawViaBank loggedUserData={this.props.loggedUserData}  sourceAccountsOptions={this.props.sourceAccountsOptions} sourceWallets={this.props.sourceWallets} merchantId={this.props.merchantId} />;
        }

        // if (this.state.currentStage === CurrentStage.EnterDetails && this.state.paymentMethod === WithdrawPaymentMethodType.NonIBAN) {
        //     return <WithdrawViaNonBank OnSuccess={this.onSuccess} />;
        // }

        if (this.state.currentStage === CurrentStage.Success) {
            return (<Container>
                <FontAwesomeIcon size="5x" icon={faCheck as any} className="text-success" style={{ margin: "auto", display: "block" }} />
                <div className="align-center">Success!. Your money is on the way</div>
            </Container>);
        }

        return "";
    };

    renderHeader = () => {
        if(this.state.currentStage != CurrentStage.PaymentMethod && this.state.currentStage != CurrentStage.Success && this.state.currentStage != CurrentStage.Failed) {
            return <Button icon="pi pi-arrow-left" className="p-button-rounded p-button-outlined p-button-outlined p-dialog-header-icon" aria-label="Cancel" onClick={() => this.onBack()} />;
        }

        return "Withdraw";
    };

    render() {
        return (
            <Dialog header={this.renderHeader()}
                modal
                blockScroll
                draggable={false}
                resizable={false}
                visible={true}
                style={{ width: "60vw" }}
                onHide={this.props.onHide}
                >
                <Fragment>
                    {this.renderBody()}
                </Fragment>
            </Dialog>
        );
    }
}