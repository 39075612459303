import { Merchant } from "../../../models/MerchantModel";
import { PagedResult, Result } from "../../../models/Result";
import { SearchRequestBaseModel } from "../../../models/SearchRequestModel";
import { User } from "../../../models/User";
import { httpService } from "../../HttpService";
import { ICrudApiService } from "../../ICrudApiService";
import { SelectItem } from "../../../models/SelectItem";
import { DashboardMetrics } from "../../../models/DashboardMetrics";

class MerchantApiService implements ICrudApiService<Merchant> {
    path: string = "merchant";

    getMetrics = async (): Promise<Result<DashboardMetrics>> => await httpService.get<DashboardMetrics>(`${this.path}/metrics`);

    getAll = async (searchRequest: SearchRequestBaseModel): Promise<PagedResult<Merchant[]>> => await httpService.getPage<Merchant[]>(`${this.path}/search`, searchRequest);

    getSelectItem = async (): Promise<Result<SelectItem[]>> => await httpService.get<SelectItem[]>(`${this.path}`);

    get = async (merchantId: number): Promise<Result<Merchant>> => await httpService.get<Merchant>(`/${this.path}/${merchantId}`);

    activate = async (merchantId: number): Promise<Result<boolean>> => await httpService.put<boolean>(`/${this.path}/${merchantId}/activate`, null);

    deActivate = async (merchantId: number): Promise<Result<boolean>> => await httpService.delete<boolean>(`/${this.path}/${merchantId}`);

    create = async (merchant: Merchant): Promise<Result<Merchant>> => await httpService.post<Merchant>(`/${this.path}`, merchant);

    update = async (merchant: Merchant): Promise<Result<Merchant>> => await httpService.put<Merchant>(`/${this.path}`, merchant);

    delete = (entityId: number): Promise<Result<void>> => { throw new Error("Method not implemented."); };

    updateMerchantIntegration = async (merchant: Merchant): Promise<Result<Merchant>> => await httpService.put<Merchant>("/merchantIntegration", merchant);

    getUsers = async (merchantId: number): Promise<Result<User[]>> => await httpService.get<User[]>(`/${this.path}/${merchantId}/users`);
}

const merchantApiService = new MerchantApiService();

export { merchantApiService };
