import React, { useEffect, useState } from "react";
import cx from "classnames";
import { SelectItem } from "../models/SelectItem";
import { Dropdown, DropdownChangeParams, DropdownItemTemplateType, DropdownValueTemplateType } from "primereact/dropdown";

interface ExtendedDropdownProps {
    style?: object;
    value?: any;
    options?: SelectItem<number>[] | SelectItem<string>[];
    onChange?(e: DropdownChangeParams): void;
    filter?: boolean;
    filterBy?: string;
    placeholder?: string;
    valueTemplate?: DropdownValueTemplateType;
    itemTemplate?: DropdownItemTemplateType;
    name?: string | null;
    required?: boolean;
    disabled?: boolean;
    onValidStatusChanged?: (status: boolean) => void;
}

const ExtendedDropdown: React.FC<ExtendedDropdownProps> = (props) => {
    const [dropdownActive, setDropdownActive] = useState(false);
    const error = props.required && (!props.value || String(props.value).trim() === "") ? `${props.name} is required.` : "";

    useEffect(() => {
        if (dropdownActive) {
            document.body.classList.add("no-scroll");
            const childElements = document.getElementsByClassName("p-dialog-content");
            for (const childElement of childElements) {
                childElement.classList.add("no-scroll");
            }
        } else {
            document.body.classList.remove("no-scroll");
            const childElements = document.getElementsByClassName("p-dialog-content");
            for (const childElement of childElements) {
                childElement.classList.remove("no-scroll");
            }
        }
        return () => {
            document.body.classList.remove("no-scroll");
            const childElements = document.getElementsByClassName("p-dialog-content");
            for (const childElement of childElements) {
                childElement.classList.remove("no-scroll");
            }
        };
    }, [dropdownActive]);
      

 

    return (
        <>
            <Dropdown
                onShow={() => setDropdownActive(true)}
                onHide={() => setDropdownActive(false)}
                style={{ width: "100%" }}
                value={props.value}
                options={props.options}
                onChange={props.onChange}
                className={cx(props.required && error ? "p-invalid block" : "")}
                filter={props.filter}
                filterBy={props.filterBy}
                placeholder={props.placeholder}
                valueTemplate={props.valueTemplate}
                itemTemplate={props.itemTemplate}
                disabled={props.disabled}
            />
            {error && <small className="p-error block">{error}</small>}
        </>
    );
};

export default ExtendedDropdown;
