import React, { Component, Fragment } from "react";
import cx from "classnames";
import VerticalNavWrapper from "./AppNav/VerticalNavWrapper";
import HeaderLogo from "./AppLogo";
import { ThemeOptions } from "../ThemeOptions";
import { CSSTransitionComponent } from "../components/CSSTransition";

class AppSidebar extends Component {
    state = {};

    toggleMobileSidebar = () => {
    };

    render() {
        const { backgroundColor, enableSidebarShadow } = ThemeOptions;

        return (
            <Fragment>
                <div className="sidebar-mobile-overlay" onClick={this.toggleMobileSidebar} />
                <CSSTransitionComponent>
                    <div
                        className={cx("app-sidebar", backgroundColor, {
                            "sidebar-shadow": enableSidebarShadow,
                        })}
                    >
                        <HeaderLogo />
                        <div className="app-sidebar__inner">
                            <VerticalNavWrapper />
                        </div>
                    </div>
                </CSSTransitionComponent>
            </Fragment>
        );
    }
}

export default AppSidebar;
