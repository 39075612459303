import React, { Component, Fragment } from "react";
import { CardHeader, Container, Card, CardBody } from "reactstrap";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import PageTitle from "../Layout/AppMain/PageTitle";
import MerchantTariffsComponent from "./Merchants/MerchantTariffs";
import ExtendedDataTable from "../components/ExtendedDataTable";
import { Button } from "primereact/button";
import { ProductCode } from "../models/ProductCode";
import tariffApiService from "../services/api/tariff/TariffApiService";
import { Column } from "primereact/column";
import { numberFilterMatchModeOptions, stringFilterMatchModeOptions } from "./Paginator";
import { SearchRequestBaseModel, SortOrderType } from "../models/SearchRequestModel";
import { faMoneyBillWave } from "@fortawesome/free-solid-svg-icons";
import { storageService } from "../services/StorageService";
import Currencies from "./Currencies";

interface TariffState {
    productCodes: ProductCode[];
    isLoading: boolean;
    totalProductCodes: number;
    merchantId: number;
}

export default class Tariffs extends Component<Record<string, never>, TariffState> {
    private dataTable: ExtendedDataTable | null = null;

    constructor(props: Record<string, never> | Readonly<Record<string, never>>) {
        super(props);

        this.state = {
            productCodes: [],
            isLoading: false,
            totalProductCodes: 0,
            merchantId: Number(storageService.getSelectedMerchantId())
        };
    }

    loadProductCodes = async (searchResult: SearchRequestBaseModel) => {
        this.setState({ isLoading: true });
        const result = await tariffApiService.getProductCodes(searchResult);
        this.setState({ productCodes: result.data, totalProductCodes: result.totalCount });
        this.setState({ isLoading: false });
    };

    renderProductCodesTableHeader = () => {
        return (
            <span className="p-input-icon-left" style={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                    type="button"
                    icon="pi pi-filter-slash"
                    label="Clear"
                    className="p-button-outlined"
                    onClick={() => {
                        this.dataTable!.reset();
                    }}
                />
            </span>
        );
    };

    render() {
        return (
            <Fragment>
                <TransitionGroup appear={true} exit={false} enter={false}>
                    <CSSTransition classNames="TabsAnimation" timeout={1500}>
                        <Fragment>
                            <Container fluid>
                                <PageTitle
                                    heading="Tariffs and Currencies"
                                    icon={faMoneyBillWave}
                                />
                                <Card className="mb-3">
                                    <CardHeader className="card-header-tab"></CardHeader>
                                    <CardBody>
                                        {/* <MerchantTariffsComponent merchantId={""} /> */}
                                        <Currencies  merchantId={this.state.merchantId}/>
                                    </CardBody>
                                </Card>
                                <Card className="mb-3">
                                    <CardHeader className="card-header-tab">
                                        <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                                            <i className="header-icon lnr-laptop-phone mr-3 text-muted opacity-6"> </i>
                                            Product Codes
                                        </div>
                                    </CardHeader>
                                    <CardBody>
                                        <ExtendedDataTable
                                            header={this.renderProductCodesTableHeader}
                                            ref={(ref) => (this.dataTable = ref)}
                                            value={this.state.productCodes}
                                            loading={this.state.isLoading}
                                            totalRecords={this.state.totalProductCodes}
                                            onChange={this.loadProductCodes} 
                                            defaultSortField={"id"} 
                                            defaultSortOrder={SortOrderType.Descending} >
                                            <Column field="id" header="Id" filterMatchModeOptions={numberFilterMatchModeOptions} />
                                            <Column field="productId" header="Product Id" filterMatchModeOptions={numberFilterMatchModeOptions} />
                                            <Column field="productCode" header="Produtct Code" filterMatchModeOptions={stringFilterMatchModeOptions} />
                                            <Column field="productCodeDescription" header="Product Code Description" filterMatchModeOptions={stringFilterMatchModeOptions} />
                                            <Column field="currencyId" header="Currency Id" filterMatchModeOptions={numberFilterMatchModeOptions} />
                                            <Column field="currencyCode" header="Currency Code" filterMatchModeOptions={stringFilterMatchModeOptions} />
                                            <Column field="currencyDescription" header="Currency Description" filterMatchModeOptions={stringFilterMatchModeOptions} />
                                        </ExtendedDataTable>
                                    </CardBody>
                                </Card>
                            </Container>
                        </Fragment>
                    </CSSTransition>
                </TransitionGroup>
            </Fragment>
        );
    }
}
