import { PaymentMethods } from "./FinancialData";

export class Merchant {
    id: number = 0;
    logo: string = "" ;
    name: string = "";
    createdTimeStamp: Date = new Date();
    email: string = "";
    websiteUrl: string = "";
    phone: string = "";
    city: string = "";
    countryId: string = "";
    zipCode: string = "";
    flexCubeCustomerId: string = "";
    primaryContactName: string = "";
    primaryContactPhone: string = "";
    primaryContactEmail: string = "";
    technicalContactName: string = "";
    technicalContactPhone: string = "";
    technicalContactEmail: string = "";
    billingAddress: string = "";
    correspondenceAddress: string = "";
    currency: string = "";
    vatNumber: string = "";
    flexCubeBranch: string = "";
    cryptoCustomer:boolean= false;
    scaEnabled:boolean = false;
    scaEnabledForLogin:boolean = false;
    scaEnabledForRecurringLogin:boolean = false;
    scaEnabledForMobileNumber:boolean = false;
    scaEnabledForPaymentOperation:boolean = false;
    scaEnabledForPassword:boolean = false;
    scaEnabledForTemplatePayment:boolean = false;
    scaEnabledForPsdLogin:boolean = false;
    scaEnabledDaysForLogin: number = 0;
    scaEnabledDaysForRecurringLogin: number = 0;
    scaEnabledDaysForMobileNumber: number = 0;
    scaEnabledDaysForPaymentOperation: number = 0;
    scaEnabledDaysForPassword: number = 0;
    scaEnabledDaysForTemplatePayment: number = 0;
    scaEnabledDaysPsdLogin: number = 0;
    lastDateForLogin?: Date;
    lastDateForRecurringLogin?: Date;
    lastDateForMobileNumber?: Date;
    lastDateForPaymentOperation?: Date;
    lastDateForPassword?: Date;
    lastDateForTemplatePayment?: Date;
    lastDateForPsdLogin?: Date;
    status: MerchantStatus = MerchantStatus.Active;
    notificationUrl: string = "";
    notificationStatusUrl: string = "";
    notificationRefreshUrl: string = "";
    notification3DUrl: string = "";
    clientIds: number[] = [];
    merchantCurrencies: number[] = [];
    minimumTransactionAmount: number = 0.1;
    merchantDocuments: MerchantDocument[] = [];
    merchantContacts: MerchantContact[] = [];
    merchantAddresses: MerchantAddresses[] = [];
    allowAccessToAPI:boolean = false;
    allowAccessToMerchantPortal:boolean = false;
}
export const ClientAccessConstants = {
    AllowAccessToAPI: "AllowAccessToAPI",
    AllowAccessToMerchantPortal: "AllowAccessToMerchantPortal",
};

export enum MerchantStatus {
    Active,
    InActive
}
export interface MerchantBaseRequest {
    merchantId: number,

}
export interface MerchantBaseRequest {
    merchantId: number,

}
export interface MerchantMtlsAccessRequest {
    merchantId: number,
    certificate: string,

}
export interface MerchantAccessStatusModel {
    hasPortalAccess: boolean,
    hasApiAccess: boolean,

}
export interface MerchantTariff {
    id: string,
    merchantName: string,
    name: string,
    code: string,
    groupNames: string[],
    totalUsers: number,
    details: MerchantTariffDetail[]
}

export interface MerchantGroup {
    id: number,
    merchantId: number,
    tariffId : number,
    name: string,
    totalUsers: number,
    label?: string,
    value?: number,
    isDefault:boolean
}

export interface MerchantContact {
    id: number,
    merchantId: number;
    name: string,
    phone: string,
    email: string,
    type: string,
}

export interface MerchantClients {
    clientId: string;
    secret: string;
    isMtlsClient: boolean;
    allowedCorsOrigins: string[]
}

export interface MerchantUsers {
    merchantId: string,
    userId: string
}

export class MerchantTariffDetail {
    id: number = 0;
    operation: string = "";
    currency: string = "";
    feeAmount: number = 0;
    feePercent: number = 0;
    forexFeeAmount: number = 0;
    forexFeePercent: number = 0;
    FxRateMarkUp: number = 0;
    FxRateMarkDown: number = 0;
    fromRange: number = 0;
    toRange: number = 0;
    minAmount: number = 0;
    maxAmountTransactionAndDay: number = 0;
    maxAmount24H: number = 0;
    maxAmount5D: number = 0;
    minFee: number = 0;
    maxFee: number = 0;
    userLevel: string = "";
}

export class MerchantDocument {
    id: number = 0 ;
    merchantId: number = 0;
    fileName: string = "";
    fileStorageId: string = "";
    documentType: string = "";
    status: string = "";
    type: string = "";
    fileData: string = "";
}

export class CreateOrUpdateMerchantDocument {
    id: number = 0 ;
    merchantId: number = 0;
    fileName: string = "";
    fileData: string = "";
    type: string = "";
}

export class MerchantAddresses {
    id : number = 0 ;
    merchantId: number = 0;
    addressLine : string = "";
    city : string = "";
    zipCode : string = "";
    countryId : string = "";
    addressType : string = "";
    stateProvinceCounty: string = "";
}

export interface MerchantPaymentMethod extends PaymentMethods {
    alias : string;
    validityYear : string;
    validityMonth : string;
    swift : string;
    currency : string;
    bankName : string;
    cardType : string;
}

export class CreateCardPaymentMethodRequest {
    cardNumber : string = "";
    paymentType : string = "Card";
    cardAlias : string = "";
    cardHolderName : string = "";
    cardType : string = "";
    cvvOrCvcCode :  string = "";
    validityMonth :  string = "";
    validityYear : string = "";
    Currency: string = "BGN";       //temp value should fix later
    status: PaymentMethodStatus = PaymentMethodStatus.Approve;
}

export class CreateIbanPaymentMethodRequest {
    paymentType : string = "Bank";
    accountName : string = "";
    bankName : string = "";
    bankStatement : string = "";
    bankStatementFileName: string = "";
    ibanNumber : string = "";
    swift : string = "";
    currency: string = "";
    status: PaymentMethodStatus = PaymentMethodStatus.Approve;
}

export class CreateNonIbanPaymentMethodRequest {
    paymentType : string = "Non-IBAN";
    bankAccountName: string = "";
    accountHolderName: string = "";
    bankName: string = "";
    bankAccountNumber: string = "";
    currency: string = "";
    swift: string = "";
    bankAddress: string = "";
    bankStatement: string = "";
    bankStatementFileName: string = "";
    status: PaymentMethodStatus = PaymentMethodStatus.Approve;
}

export enum PaymentMethodStatus {
    Approve = "1",
    UnderReview = "2",
    Reject = "3"
}