import React, { Fragment } from "react";
import cx from "classnames";

import HeaderLogo from "../AppLogo";
import UserBox from "./Components/UserBox";
import HeaderDots from "./Components/HeaderDots";
import { ThemeOptions } from "../../ThemeOptions";
import { CSSTransitionComponent } from "../../components/CSSTransition";

class AppHeader extends React.Component<any, any> {
    render() {
        const { headerBackgroundColor, enableMobileMenuSmall, enableHeaderShadow } = ThemeOptions;
        return (
            <Fragment>
                <CSSTransitionComponent>
                    <div
                        className={cx("app-header", headerBackgroundColor, {
                            "header-shadow": enableHeaderShadow,
                        })}
                    >
                        <HeaderLogo />
                        <div
                            className={cx("app-header__content", {
                                "header-mobile-open": enableMobileMenuSmall,
                            })}
                        >
                            <div className="app-header-right">
                                <UserBox />
                            </div>
                        </div>
                    </div>
                </CSSTransitionComponent>
            </Fragment>
        );
    }
}

export default AppHeader;