import React, { Component, Fragment } from "react";
import {  Card, Container } from "reactstrap";

import { TransitionGroup, CSSTransition } from "react-transition-group";

import _ from "lodash";
import moment from "moment";
import { Column, ColumnFilterElementTemplateOptions } from "primereact/column";
import {  DataTableSortMeta } from "primereact/datatable";

import { TransactionReportDetails, AllTransactionRequestModel, PaymentStatus, Transaction, TransactionStatus } from "../../models/Transactions";
import { dateRangeFilterTemplate, statusFilterMatchModeOptions, stringFilterMatchModeOptions } from "../Paginator";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { SelectItem } from "../../models/SelectItem";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { ActionBodyTemplate, RenderPaymentStatusBadge, RenderStatusBadge, TransactionTypeFilterTemplate } from "../Operations/TransactionFilterTemplates";
import { transactionsReportApiService } from "../../services/api/operations/TransactionsReportApiService";
import ExtendedDataTable from "../../components/ExtendedDataTable";
import { SearchRequestBaseModel, SortOrderType } from "../../models/SearchRequestModel";
import { Button } from "primereact/button";
import { utils } from "../../utils/utils";
import { currenciesApiService } from "../../services/api/merchants/CurrenciesApiService";
import { storageService } from "../../services/StorageService";

interface TransactionsState {
    isLoading: boolean;
    transactions: TransactionReportDetails[];
    first: number;
    rows: number;
    filters?: SearchRequestBaseModel;
    multiSortMeta: DataTableSortMeta[],
    isTransactionDetailVisible: boolean,
    selectedTransaction: TransactionReportDetails | null;
    currencies: SelectItem[];
    totalRecords: number;
}

interface TransactionProps {
    userId: number;
    userEmail: string | undefined;
    showExpander: boolean;
}

export default class UserTransactions extends Component<TransactionProps, TransactionsState> {

    toast: Toast | null = null;
    private dataTable: ExtendedDataTable | null = null;
    
    constructor(props: TransactionProps | Readonly<TransactionProps>) {
        super(props);
        
        this.state = {
            isLoading: false,
            transactions: [],
            multiSortMeta: [],
            first: 0,
            rows: 10,
            isTransactionDetailVisible:false,
            selectedTransaction: null,
            currencies: [],
            totalRecords: 0,
        };
    }

    async componentDidMount() {
        this.loadCurrencies();
        this.loadTransactions();
    }

    loadCurrencies = async () => {
        this.setState({ isLoading: true });

        const currenciesResult = await currenciesApiService.getMerchantActiveCurrencies(storageService.getLoggedInUserMerchantId());

        this.setState({ isLoading: false });

        if (!currenciesResult.success) {
            this.toast?.show({ severity: "error", summary: "Error", detail: currenciesResult.message, life: 3000 });
        }
        const currenciesList: SelectItem[] = currenciesResult.data.map((currency) => ({
            label: currency.charCode,
            value: currency.charCode
        }));

        this.setState({ currencies: currenciesList });
    };

    onSorting = async (filters: SearchRequestBaseModel = {} as SearchRequestBaseModel,  transactions?: TransactionReportDetails[]) => {
        this.setState({ transactions: await utils.extendedDatatableSorting(filters, transactions?? this.state.transactions) });
    }

    loadTransactions = async (filters: SearchRequestBaseModel = {} as SearchRequestBaseModel) => {
        const filterRequest: AllTransactionRequestModel = await utils.extractFiltersData(filters);
        
        if(this.props.userId){
            this.setState({ isLoading: true });

            filterRequest.individualId = this.props.userId;
            filterRequest.dataBatch = filters.pageNumber ?? 1;
            filterRequest.dataBatchCount = filters.pageSize ?? 10;
            const transactionResult = await transactionsReportApiService.getAllTransactionsReportResponse(filterRequest);
    
            if (!transactionResult.success) {
                this.setState({ isLoading: false });
                this.toast?.show({ severity: "error", summary: "Error", detail: transactionResult.message, life: 3000 });
                return;
            }
    
            await this.onSorting(filters, transactionResult.data.transactions );
            this.setState({ isLoading: false, totalRecords: transactionResult.data.transactionCount });
        }
    };

    

    renderHeader = () => {
        return (
            <span className="p-input-icon-left" style={{ display: "flex", justifyContent: "space-between" }}>
                <Button type="button" icon="pi pi-filter-slash" label="Clear" className="p-button-outlined" onClick={() => { this.dataTable!.reset(); }} />
            </span>
        );
    };

    // actionBodyTemplate = (rowData: Transaction) => {
    //     return (
    //         <React.Fragment>
    //             <Button icon="pi pi-info" className="p-button-rounded" onClick={() => this.showTransactionDetails(rowData)} />
    //         </React.Fragment>
    //     );
    // };

    currencyFilterTemplate = (options: ColumnFilterElementTemplateOptions, columnName: string, placeHolder: string) => { 
        return (
            <Dropdown
                value={options.value}
                options={this.state.currencies}
                filter
                onChange={(e) => options.filterCallback(e.value, options.index)}
                placeholder={`Select ${placeHolder}`}
                className="p-column-filter"
                showClear
            />
        );
    };

    dateFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {        
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="dd/mm/yy" placeholder="mm/dd/yyyy" mask="99/99/9999" />;
    };

    showTransactionDetails = (rowData: TransactionReportDetails) => {
        this.setState({ isTransactionDetailVisible: true, selectedTransaction: rowData });
    };

    render() {
        return (
            <Fragment>
                <TransitionGroup appear={true} exit={false} enter={false}>
                    <CSSTransition classNames="TabsAnimation" timeout={1500}>
                        <Fragment>
                            <Toast ref={(el) => (this.toast = el)} position={"bottom-center"} baseZIndex={99999}></Toast>
                           
                            <Dialog header="Transaction Detail" visible={this.state.isTransactionDetailVisible} style={{ width: "70vw" }} 
                                onHide={() => this.setState({ isTransactionDetailVisible: false })}>
                                <div>
                                    <pre>
                                        <code>
                                            {this.state.selectedTransaction ? JSON.stringify(this.state.selectedTransaction, null, 2) : ""}
                                        </code>
                                    </pre>
                                </div>
                            </Dialog>


                            <Container fluid>
                                <Card>
                                    <ExtendedDataTable
                                        header={this.renderHeader}
                                        value={this.state.transactions}
                                        loading={this.state.isLoading}
                                        totalRecords={this.state.totalRecords}
                                        onChange={this.loadTransactions}
                                        ref={(ref) => this.dataTable = ref}
                                        defaultSortOrder={SortOrderType.Descending}
                                        defaultSortField='timeStamp'
                                        onSorting={this.onSorting}
                                    >
                                        <Column
                                            field="transactionType"
                                            header="Type"
                                            body={(rowData: TransactionReportDetails) => <p>{rowData.transactionType}</p>}
                                            filterMatchModeOptions={stringFilterMatchModeOptions}
                                            showFilterMatchModes={false}
                                            filterField="trxDirection"
                                            filterElement={TransactionTypeFilterTemplate}

                                        ></Column>
                                        <Column
                                            field="accountNumber"
                                            header="Wallet Id"
                                            body={(rowData: TransactionReportDetails) => <p>{rowData.accountNumber}</p>}
                                            //filterElement={(options => this.transactionTypeFilterTemplate(options, 'walletId', 'Wallet Id'))}
                                            showFilterMatchModes={false}
                                            filterField="accountID"
                                            filterPlaceholder="Search by Wallet ID"
                                            filterMatchModeOptions={stringFilterMatchModeOptions}
                                        />
                                        <Column
                                            field="timeStamp"
                                            header="Transaction Date"
                                            body={(rowData: TransactionReportDetails) => <p>{moment.utc(rowData.timeStamp).local().format("DD/MM/YYYY hh:mm:ss")}</p>}
                                            dataType="date"
                                            filterElement={dateRangeFilterTemplate}   
                                            filterField="timeStampTo"
                                            filterMatchModeOptions={statusFilterMatchModeOptions}
                                            showFilterMatchModes={false}
                                        ></Column>
                                        <Column
                                            field="amount"
                                            header="Amount"
                                            showFilterMatchModes={false}
                                            filterField="amountTo"
                                            filterPlaceholder="Search by Amount"
                                            filterMatchModeOptions={stringFilterMatchModeOptions}
                                            body={(rowData: TransactionReportDetails) => <p>{ rowData.amount }</p>}
                                        />
                                        <Column
                                            field="currency"
                                            header="Currency"
                                            body={(rowData: TransactionReportDetails) => <p>{rowData.currency}</p>}
                                            showFilterMatchModes={false}
                                            filterField="currency"
                                            filterPlaceholder="Search by Currency"
                                            filterMatchModeOptions={stringFilterMatchModeOptions}
                                            filterElement={(options => this.currencyFilterTemplate(options, "currency", "Currency"))}

                                        ></Column>
                                        <Column
                                            field="transactionId"
                                            header="Reference Number"
                                            body={(rowData: TransactionReportDetails) => <p>{rowData.transactionId}</p>}
                                            showFilterMatchModes={false}
                                            filterField="transactionID"
                                            filterPlaceholder="Search by Transaction ID"
                                            filterMatchModeOptions={stringFilterMatchModeOptions}
                                            //filterElement={(options => this.transactionTypeFilterTemplate(options, 'id', 'Reference Number'))}
                                        ></Column>
                                        <Column
                                            field="status"
                                            header="Status"
                                            body={(rowData: TransactionReportDetails) =>
                                                rowData.wireTransferReturnStatus.includes(TransactionStatus.Accepted) ? RenderStatusBadge(TransactionStatus.Accepted)
                                                :
                                                rowData.wireTransferReturnStatus.includes(TransactionStatus.Rejected) ? RenderStatusBadge(TransactionStatus.Rejected)
                                                :
                                                RenderPaymentStatusBadge(PaymentStatus.Processed)
                                            }
                                            //filterElement={(options => this.transactionTypeFilterTemplate(options, 'status', 'Status'))}
                                        ></Column>

                                        <Column
                                            field="details"
                                            header="Details"
                                            body={(rowData: TransactionReportDetails) => <ActionBodyTemplate data={rowData} onClickAction={this.showTransactionDetails} />}
                                            sortable
                                        ></Column>
                                    </ExtendedDataTable>
                                </Card>
                            </Container>
                        </Fragment>
                    </CSSTransition>
                </TransitionGroup>
            </Fragment>
        );
    }
}
