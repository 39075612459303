import _ from "lodash";
import { IFilterModel, SearchRequestBaseModel, SortOrderType } from "../models/SearchRequestModel";
import { AppConstants } from "../models/AppConstants";
import { AllTransactionRequestModel, TransactionReportDetails } from "../models/Transactions";
import moment from "moment";
import { httpService } from "../services/HttpService";
import { Result } from "../models/Result";
import { CountryModel } from "../models/CountryModel";
import { SelectItem } from "../models/SelectItem";

class Utils {
    private size: number = 128;
    private countriesCountryModel: CountryModel[] = [];
    private countriesSelectItem: SelectItem[] = [];
    private countryNames: Map<string, string> = new Map<string, string>();

    getCountriesResult = async (): Promise<Result<CountryModel[]>> => {
        try {
            if (!this.countriesCountryModel || this.countriesCountryModel.length == 0) {
                const countriesResult = await httpService.getCountries();

                if (countriesResult.success) {
                    this.countriesCountryModel = countriesResult.data;
                    this.countriesSelectItem = countriesResult.data.map((m) => {
                        this.countryNames.set(m.id, m.name);
                        return { label: m.name, value: m.id } as SelectItem;
                    });
                } else {
                    return {
                        success: false,
                        data: countriesResult.data,
                        message: countriesResult.message
                    }
                }
            }

            return {
                success: this.countriesCountryModel.length > 0,
                data: this.countriesCountryModel,
                message: ''
            };
        } catch (error) {
            
            return {
                success: false,
                data: [],
                message: 'Failed to retrieve countries.'
            };
        }
    };

    getCountries = (): SelectItem<string>[] => {
        return this.countriesSelectItem;
    }

    getCountryName = (id: string): string | undefined => {
        return this.countryNames?.get(id);
    }
    
    getQueryParameters = () => {
        return new URLSearchParams(window.location.hash.slice(window.location.hash.indexOf("?")));
    };

    getQueryParameter = (param: string) => {
        return this.getQueryParameters().get(param);
    };

    convertFileToBase64(file: File) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const fileData = reader.result?.toString();
                resolve(
                    fileData?.includes("application/pdf")
                        ? fileData.replace("data:application/pdf;base64,", "")
                        : fileData?.replace(/^data:image\/[a-z]+;base64,/, "")
                );
            };
            reader.onerror = reject;
        });
    }

    appendBase64Data = (data: string, fileName: string = "") => {
        return fileName.includes("pdf") ? `data:application/pdf;base64,${data}` : `data:image/jpeg;base64,${data}`;
    };

    getIdentifierFromPath = (): number => {
        return parseInt(_.last(window.location.pathname.split("/")) ?? "");
    };

    checkCharacterlength = (character: string): boolean => {
        return character.length <= this.size;
    };

    convertDateToUTC = (date: Date): Date => {
        return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    };

    manageDataTableFilters = (filters: IFilterModel[]) => {
        const filteredColumns = filters.map((obj) => {
            if (AppConstants.timeStampFiltered.includes(obj.columnName)) {
                return {
                    ...obj,
                    value: utils.convertDateToUTC(new Date(obj.value)).toISOString(),
                };
            } else {
                return obj;
            }
        });
        return filteredColumns;
    };

    isValidImage = (image: File) => {
        const fileExtension = image.name.split(".").pop()?.toLowerCase();
        if (fileExtension && (fileExtension === "jpeg" || fileExtension === "jpg" || fileExtension === "png")) {
            return true;
        }
        return false;
    };

    extractFiltersData = async (searchRequest: SearchRequestBaseModel) => {
        const requestData = {} as AllTransactionRequestModel;
        if (searchRequest.filters != null && searchRequest.filters.length > 0) {
            searchRequest.filters.forEach((filter) => {
                if (filter.value != null) {
                    if (AppConstants.timeStampFiltered.includes(filter.columnName)) {
                        const updatedEndDate = moment.utc(filter.value[1]).add(1, "day").toDate();
                        requestData.timestampFrom = moment(filter.value[0]).format("YYYY-MM-DD");
                        requestData.timestampTo = moment(updatedEndDate).format("YYYY-MM-DD");
                    } else {
                        (requestData as any)[filter.columnName] = filter.value as string;
                    }
                }
            });
        }
        return requestData;
    };

    trxDirection = (): SelectItem[] => {
        return [
            { label: "Credit", value: "C" },
            { label: "Debit", value: "D" },
        ];
    };

    extendedDatatableSorting = async (
        filters: SearchRequestBaseModel,
        transactions: TransactionReportDetails[]
    ): Promise<TransactionReportDetails[]> => {
        if (filters.sortings && filters.sortings.length > 0) {
            const sort = filters.sortings[0];
            const sortOrder = sort.sortOrder === SortOrderType.Ascending ? 1 : -1;
            const sortBy = sort.sortBy as keyof TransactionReportDetails;

            return transactions.toSorted((a, b) => {
                if (a[sortBy] < b[sortBy]) return -1 * sortOrder;
                if (a[sortBy] > b[sortBy]) return 1 * sortOrder;
                return 0;
            });
        }
        return transactions;
    };

    formatCurrency = (amount: any, currencyCode: any) => {
        const formattedAmount = parseFloat(amount).toLocaleString("en-US", {
            style: "currency",
            currency: currencyCode,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });

        return formattedAmount;
    };

    isNullOrEmptyString = (str?: string) => {
        return str === null || str === "" || str === undefined;
    };

    formatAmount(amount: any) {
        return amount
            .replace(/(\.\d*?)\./, "$1")
            .replace(/[^0-9.]/g, "")
            .replace(/(\.\d\d).+$/, "$1");
    }

    formatBalance(amount: any, currencyCode: any) {
        const formattedAmount = parseFloat(amount).toLocaleString("en-US", {
            style: "currency",
            currency: currencyCode,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });

        const currency = formattedAmount.replace(/[\d.,]/g, "").trim();
        const amountWithoutCurrency = formattedAmount.replace(currency, "").trim();
        return `${amountWithoutCurrency} ${currency}`;
    }

    validateIban = (input: string) => {
        const codeLengths = {
            AD: 24,
            AE: 23,
            AL: 28,
            AT: 20,
            AZ: 28,
            BA: 20,
            BE: 16,
            BG: 22,
            BH: 22,
            BI: 28,
            BR: 29,
            BY: 28,
            CH: 21,
            CR: 22,
            CY: 28,
            CZ: 24,
            DE: 22,
            DK: 18,
            DO: 28,
            EE: 20,
            EG: 29,
            ES: 24,
            LC: 32,
            FI: 18,
            FO: 18,
            FR: 27,
            GB: 22,
            GE: 22,
            GI: 23,
            GL: 18,
            GR: 27,
            GT: 28,
            HR: 21,
            HU: 28,
            IE: 22,
            IL: 23,
            IQ: 23,
            IS: 26,
            IT: 27,
            JO: 30,
            KW: 30,
            KZ: 20,
            LB: 28,
            LI: 21,
            LT: 20,
            LU: 20,
            LV: 21,
            LY: 25,
            MC: 27,
            MD: 24,
            ME: 22,
            MK: 19,
            MR: 27,
            MT: 31,
            MU: 30,
            NL: 18,
            NO: 15,
            PK: 24,
            PL: 28,
            PS: 29,
            PT: 25,
            QA: 29,
            RO: 24,
            RS: 22,
            SA: 24,
            SC: 31,
            SD: 18,
            SE: 24,
            SI: 19,
            SK: 24,
            SM: 27,
            ST: 25,
            SV: 28,
            TL: 23,
            TN: 24,
            TR: 26,
            UA: 29,
            VA: 22,
            VG: 24,
            XK: 20,
        };
        const iban = input.toUpperCase().replace(/[^A-Z0-9]/g, "");
        const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/);
        if (!code || iban.length !== (codeLengths as any)[code[1]]) {
            return false;
        }
        const digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, (letter: string) => {
            return (letter.charCodeAt(0) - 55).toString();
        });
        return this.mod97(digits) === 1;
    };

    mod97 = (digital: number | string) => {
        digital = digital.toString();
        let checksum: number | string = digital.slice(0, 2);
        let fragment = "";
        for (let offset = 2; offset < digital.length; offset += 7) {
            fragment = checksum + digital.substring(offset, offset + 7);
            checksum = parseInt(fragment, 10) % 97;
        }
        return checksum;
    };
}

var utils = new Utils();

export { utils };
