import { Route, Navigate, Routes } from "react-router-dom";
import React, { Suspense, lazy } from "react";
import EventEmitter, { Events } from "./services/eventemitter";
import Loader from "./components/Loader";
import ExtendedRouter from "./extendedRouter";
import history from "./history";
import MainLayout from "./Layout/MainLayout";
import { storageService } from "./services/StorageService";
import { httpService } from "./services/HttpService";
import { authService } from "./services/authService";
import AllTransactions from "./Pages/Operations/AllTransactions";
import IncomingIbanTransfers from "./Pages/Operations/IncomingIbanTransfers";
import IncomingCardDeposits from "./Pages/Operations/IncomingCardDeposits";
import InternalTransaction from "./Pages/Operations/InternalTransaction";
import OutgoingBGTransactions from "./Pages/Operations/OutgoingBGTransactions";
import OutgoingSEPATransactions from "./Pages/Operations/OutgoingSEPATransactions";
import OutgoingSwiftTransactions from "./Pages/Operations/OutgoingSwiftTransactions";
import PendingTransactions from "./Pages/Operations/PendingTransactions";
import SFTPTransactions from "./Pages/Operations/SFTPTransactions";
import Operations from "./Pages/Operations/Transactions";
import { areOperationsAllowed, getUserPermittedRoutes, updatePermissions, updateRoles } from "./authGuard";
import OwnProfileComponent from "./Pages/Users/OperatorProfileOwn";

const LazyExecute = lazy(() => import("./components/Execute"));
const LazyAuthResult = lazy(() => import("./Pages/Auth/AuthResult"));
const LazyLogout = lazy(() => import("./Pages/Auth/Logout"));

interface LoginState {
    isLoggedIn: boolean;
}

class Router extends React.Component<any, LoginState> {
    constructor(props: any) {
        super(props);

        this.state = {
            isLoggedIn: storageService.getAccessToken().length > 0,
        };

        EventEmitter.on(Events.onLogin, this.OnLogin);
        EventEmitter.on(Events.onLogout, this.onLogout);
    }

    OnLogin = async (token: string) => {
        this.setState({ isLoggedIn: true });
        storageService.setAccessToken(token);
        httpService.setAuthToken(token);

        await updatePermissions();
        await updateRoles();

        // Go to root page. necessary for router to take effect.
        window.location.href = "/";
    };

    onLogout = () => {
        this.setState({ isLoggedIn: false });
        storageService.clear();
        history.push("/");
    };

    render() {

        if (this.state.isLoggedIn === false) {
            return (
                <ExtendedRouter history={history}>
                    <Suspense fallback={<Loader />}>
                        <Routes>
                            <Route path="/login" element={<LazyExecute execute={() => httpService.login()} />} />
                            <Route path="/auth-result" element={<LazyAuthResult />} />
                            <Route path="/logout" element={<LazyLogout />} />
                            <Route path="*" element={<Navigate to="/login" replace />} />
                        </Routes>
                    </Suspense>
                </ExtendedRouter>
            );
        }

        return (
            <ExtendedRouter history={history}>
                <Suspense fallback={<Loader />}>
                    <MainLayout>
                        <Routes>
                            {getUserPermittedRoutes().map((route) => {
                                return (
                                    <Route
                                        key={route.path}
                                        path={route.path}
                                        element={React.createElement(route.component, { ...route.componentProps })}
                                    />
                                );
                            })}
                            {areOperationsAllowed() &&
                                <>
                                    <Route path="/all-transactions" element={<Operations> <AllTransactions /> </Operations>} />
                                	{/* <Route path="/outgoing-sepa-transactions" element={<Operations> <OutgoingSEPATransactions /> </Operations>} />
                                    <Route path="/outgoing-swift-transactions" element={<Operations> <OutgoingSwiftTransactions /> </Operations>} />
                                    <Route path="/outgoing-bg-transactions" element={<Operations> <OutgoingBGTransactions /> </Operations>} />
                                    <Route path="/internal-p2p-transactions" element={<Operations> <InternalTransaction /> </Operations>} />
                                    <Route path="/incoming-iban-transfers" element={<Operations> <IncomingIbanTransfers /> </Operations>} />
                                    <Route path="/incoming-card-deposits" element={<Operations> <IncomingCardDeposits /> </Operations>} /> */}
                                	<Route path="/pending-transactions" element={<Operations> <PendingTransactions /> </Operations>} />
                                	<Route path="/sftp-files-exchanged" element={<Operations> <SFTPTransactions /> </Operations>} />
                                </>}
                            <Route path="/" element={<Navigate to="/dashboard" replace />} />
                            <Route path="/profile" element={<OwnProfileComponent/>} />
                            <Route path="/silentRenew" element={<LazyExecute execute={() => authService.signinSilentCallback()} />} />
                            <Route path="/logout" element={<LazyLogout />} />
                        </Routes>
                    </MainLayout>
                </Suspense>
            </ExtendedRouter>
        );
    }
}

export default Router;
