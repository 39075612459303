export interface BankAccountTransaction {
    sender: string,
    senderIBAN: string,
    bankAccount: string,
    merchant: string,
    amount: number,
    currency: string,
    recipientWalletId: string,
    recipientIBAN: string,
    reason: string,
    timeStamp: Date,
    status: BankAccountTransactionStatus


    id: string,
    type: string,
    otherSide: string,
    fee: number,
    recepientName:string,
    senderEmail: string,
    recepientEmail: string,
    senderAuthToken: string,
    senderAuthData: string,
    senderIp: string,
    duration: string,
    cbsTransactionId: string,
    cbsDetails: string,
}

export interface IncomingIbanTransfersTransaction {
    sender: IncomingIbanSenderReceiver,
    receiver: IncomingIbanSenderReceiver
 }


export interface IncomingIbanSenderReceiver {

    amount: number,
    merchant: string,
    reason: string,
    bankAccount: string
    senderIBAN: string,
    recipientIBAN: string,
    transactionFee: string,
    timestamp: Date,
    sender: string,
    transactionId: string,
    recipient_wallet:string,
    userEmail:string,
    type: string, 
    recipientName: string,
    currency: string       
}

export enum BankAccountTransactionStatus {
    Processed = "Processed",
    Declined = "Declined"
}

export class BankAccountConstant {
    static readonly accountNumberMinlength: number = 8;
    static readonly accountNumbeMaxLength: number = 12;
}

export interface IncomingIBANTransactionFilter {
    trx_id: string;
    merchant: string;
    amount: string;
    timestampFrom: string;
    timestampTo: Date;
    sender: string;
    recipient_wallet: string;
    transaction_Fee: string;
    bank_account: string;
    sender_IBAN: string;
    recipient_IBAN: string;
    recipient_name: string;
    reason: string;
    user: string;
    type: string;
    currency: string;
}