import React, { Component, Fragment } from "react";
import { Container, Card, CardBody } from "reactstrap";

import { classNames } from "primereact/utils";
import PageTitle from "../Layout/AppMain/PageTitle";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Toast } from "primereact/toast";
import _ from "lodash";
import { SortOrderType } from "../models/SearchRequestModel";
import { Column } from "primereact/column";
import { stringFilterMatchModeOptions } from "./Paginator";
import { CurrencyModel } from "../models/CurrencyModel";
import { DataTable } from "primereact/datatable";
import { currenciesApiService } from "../services/api/merchants/CurrenciesApiService";
import { faMoneyBill1 } from "@fortawesome/free-solid-svg-icons";


interface CurrenciesProps {
    merchantId: number
}

interface CurrenciesState {
    currencies: CurrencyModel[];
    globalFilterValue: string;
    isLoading: boolean;
}

export default class Currencies extends Component<CurrenciesProps, CurrenciesState> {
    private toast: Toast | null = null;

    constructor(props: CurrenciesProps) {
        super(props);

        this.state = {
            currencies: [],
            globalFilterValue: "",
            isLoading: false,
        };
    }

    componentDidMount(): void {
        this.loadCurrencies();
    }


    loadCurrencies = async () => {
        this.setState({ isLoading: true });

        const currenciesResult = await currenciesApiService.getMerchantActiveCurrencies(this.props.merchantId);

        this.setState({ isLoading: false });

        if (!currenciesResult.success) {
            this.toast?.show({ severity: "error", summary: "Error", detail: currenciesResult.message, life: 3000 });
        }

        this.setState({ currencies: currenciesResult.data });
    };

    renderActiveBadge(active: boolean, title: string) {
        return <div className={classNames("badge", "badge-" + (active ? "success" : "danger"))}>{title}</div>;
    }

    render() {
        return (
            <Fragment>
                <TransitionGroup appear={true} exit={false} enter={false}>
                    <CSSTransition classNames="TabsAnimation" timeout={3500}>
                        <Fragment>
                            <Container fluid>
                                <PageTitle
                                    heading="Currencies"
                                    icon={faMoneyBill1}
                                />

                                <Toast ref={(el) => (this.toast = el)} />
                                <Card className="mb-3">
                                    <CardBody>
                                        <DataTable
                                            value={this.state.currencies}
                                            loading={this.state.isLoading}
                                            defaultSortOrder={SortOrderType.Descending}
                                        >
                                            <Column
                                                field="id"
                                                header="Id"
                                            ></Column>
                                            <Column
                                                field="name"
                                                header="Name"
                                            ></Column>
                                            <Column
                                                field="charCode"
                                                header="CharCode"
                                            ></Column>
                                            <Column
                                                field="status"
                                                header="Status"
                                                body={(rowData: CurrencyModel) =>
                                                    this.renderActiveBadge(
                                                        rowData.isActive,
                                                        rowData.isActive ? "Active" : "Inactive"
                                                    )
                                                }
                                                filterMatchModeOptions={stringFilterMatchModeOptions}
                                            ></Column>
                                        </DataTable>
                                    </CardBody>
                                </Card>
                            </Container>
                        </Fragment>
                    </CSSTransition>
                </TransitionGroup>
            </Fragment>
        );
    }
}
