import { IndividualRiskMatrix } from "../models/IndividualRiskMatrix";
import { PagedResult, Result } from "../models/Result";
import { DowJonesSearch, LookupItem } from "../models/Risk";
import { RiskExpressionTest, RiskExpressions } from "../models/RiskExpressions";
import { SearchRequestBaseModel } from "../models/SearchRequestModel";
import { httpService } from "./HttpService";
import { ICrudApiService } from "./ICrudApiService";

class RiskApiService implements ICrudApiService<LookupItem> {

    baseApiUrl: string = process.env.REACT_APP_RISK_API_BASE_URL ?? ""; 
    path: string = "api/v1/Setup";
    pathDJ: string = "api/v1/DowJones";

    //Risk Params Score
    getRiskGroupAll = async (): Promise<Result<LookupItem[]>> => 
        await httpService.get<LookupItem[]>(`${this.path}/account-purpose`, { baseURL: this.baseApiUrl });

    getRiskGroupAllPaged = async (riskGroup: string, searchRequest: SearchRequestBaseModel): Promise<PagedResult<LookupItem[]>> => 
        await httpService.getPage<LookupItem[]>(`${this.path}/risk-values/get-all/${riskGroup}`, searchRequest, { baseURL: this.baseApiUrl });

    updateRiskGroupValue = async (riskGroup: string, riskItem: LookupItem): Promise<Result<LookupItem>> => 
        await httpService.put<LookupItem>(`${this.path}/risk-values/${riskGroup}`, riskItem, { baseURL: this.baseApiUrl });

    deleteRiskGroupValue = async (riskGroup: string, code: string): Promise<Result<LookupItem>> => 
        await httpService.delete<LookupItem>(`${this.path}/risk-values/${riskGroup}/${code}`, { baseURL: this.baseApiUrl });

    createRiskGroupValue = async (riskGroup: string, riskItem: LookupItem): Promise<Result<LookupItem>> => 
        await httpService.post<LookupItem>(`${this.path}/risk-values/${riskGroup}`, riskItem, { baseURL: this.baseApiUrl });


    //Individual Risk Matrix weights
    getIndividualRiskMatrixPaged = async (searchRequest: SearchRequestBaseModel): Promise<PagedResult<IndividualRiskMatrix[]>> => 
        await httpService.getPage<IndividualRiskMatrix[]>(`${this.path}/individual-risk-matrix/get-all/`, searchRequest, { baseURL: this.baseApiUrl });

    updateIndividualRiskMatrixParam = async (riskParam: IndividualRiskMatrix): Promise<Result<IndividualRiskMatrix>> => 
        await httpService.put<IndividualRiskMatrix>(`${this.path}/individual-risk-matrix/`, riskParam, { baseURL: this.baseApiUrl });


    //Expressions
    getRiskExpressions = async (): Promise<Result<RiskExpressions[]>> => 
        await httpService.get<RiskExpressions[]>(`${this.path}/custom-expressions/get-all`, { baseURL: this.baseApiUrl });

    updateRiskExpression = async (riskExpression: RiskExpressions): Promise<Result<RiskExpressions>> => 
        await httpService.put<RiskExpressions>(`${this.path}/custom-expressions`, riskExpression, { baseURL: this.baseApiUrl });

    testRiskExpression = async (riskTest: RiskExpressionTest): Promise<Result<any>> => 
        await httpService.put<RiskExpressionTest>(`${this.path}/custom-expressions/test`, riskTest, { baseURL: this.baseApiUrl });

    //Dow Jones
    djSearchByName = async (djSearchRequest: DowJonesSearch): Promise<Result<any>> => 
        await httpService.post<RiskExpressions[]>(`${this.pathDJ}/search/name-fuzzy`, djSearchRequest, { baseURL: this.baseApiUrl });

    //Not implemented
    get = async (userId: number): Promise<Result<LookupItem>> => { throw new Error("Method not implemented."); };
    create = async (): Promise<Result<LookupItem>> => { throw new Error("Method not implemented."); };
    update = async (): Promise<Result<LookupItem>> => { throw new Error("Method not implemented."); };
    delete = async (entityId: number): Promise<Result<void>> => { throw new Error("Method not implemented."); };
}

const riskApiService = new RiskApiService();

export { riskApiService };
