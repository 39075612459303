import React, { Component, Fragment } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Container, Card, CardBody, Row, Col, Label, CardHeader } from "reactstrap";
import { AddKycDocument, DocumentFile, KycSubmission, KycSubmissionComment, modifyStatus } from "../../models/KycSubmission";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { kycSubmissionApiService } from "../../services/api/Kyc/KycSubmissionApiService";
import { Toast } from "primereact/toast";
import { utils } from "../../utils/utils";
import { fileService } from "../../services/FileService";
import { InputText } from "primereact/inputtext";
import { User, UserAddress, UserProfileUpdate } from "../../models/User";
import AddManualKyc from "../Users/AddManualKyc";
import { isKYCAssessAllowed, isKYCApproveAllowed } from "../../authGuard";
import { SelectItem } from "../../models/SelectItem";
import { userApiService } from "../../services/api/users/UsersApiService";
import { httpService } from "../../services/HttpService";
import AddUserAddress from "../Users/UserAddressUpdate";
import ExtendedDropdown from "../../components/ExtendedDropdown";
import { CSSTransitionComponent } from "../../components/CSSTransition";
import KycSubmissionCommentForm from "./KycComments";
import { Tag } from "primereact/tag";
import { TabMenu } from "primereact/tabmenu";
import _ from "lodash";
import { riskApiService } from "../../services/RiskService";
import { DowJonesSearch } from "../../models/Risk";
interface KycIndividualProps {
    submission: KycSubmission;
    onHide: () => void;
}

interface KycIndividualState {
    showRejectDialog: boolean;
    submission: KycSubmission;
    rejectionReason: string;
    isLoading: boolean;
    kycDocuments: DocumentFile[];
    kycComments: KycSubmissionComment[];
    individual: User | null;
    isReadOnly: boolean;
    isAddressDetailVisible: boolean;
    isDowJonesDetailVisible: boolean;
    djResult: [];
    selectedAddress: UserAddress | null;
    disableSubmitBtn: boolean;
    disableApproveBtn: boolean;
}

export default class KycIndividualModal extends Component<KycIndividualProps, KycIndividualState> {
    toast: Toast | null = null;
    validationErrors: Map<string, boolean> = new Map<string, boolean>();
    countryNames: Map<string, string> = new Map<string, string>();

    constructor(props: KycIndividualProps | Readonly<KycIndividualProps>) {
        super(props);

        this.state = {
            showRejectDialog: false,
            rejectionReason: "",
            isLoading: true,
            submission: this.props.submission,
            kycDocuments: [],
            kycComments: [],
            individual: null,
            isReadOnly: false,
            isAddressDetailVisible: false,
            isDowJonesDetailVisible: false,
            djResult: [],
            selectedAddress: null,
            disableSubmitBtn: false,
            disableApproveBtn: false,
        };
    }

    sourceOfFunds: SelectItem[] = [];
    countries: SelectItem[] = [];
    financingMethods: SelectItem[] = [];
    accountPurposes: SelectItem[] = [];
    mainActivities: SelectItem[] = [];

    componentDidMount = async () => {
        this.setState({ isLoading: true });

        const individualResult = await userApiService.getFromSubmission(this.props.submission.id);
        if (!individualResult.success) {
            this.toast?.show({ severity: "error", summary: "Error", detail: individualResult.message, life: 3000 });
            return;
        }
        
        this.setState({ individual: individualResult.data });

        const countriesResult = await utils.getCountriesResult();
        if (!countriesResult.success) {
            this.toast?.show({ severity: "error", summary: "Error", detail: countriesResult.message, life: 3000 });
            this.setState({ isLoading: false });
            return;
        }

        this.countries = utils.getCountries();

        const sourceOfFunds = await userApiService.getSourceOfFunds();
        if (!sourceOfFunds.success) {
            this.toast?.show({ severity: "error", summary: "Error", detail: sourceOfFunds.message, life: 3000 });
            this.setState({ isLoading: false });
            return;
        }
        this.sourceOfFunds = sourceOfFunds.data;

        const financingMethods = await userApiService.getFinancingMethod();
        if (!financingMethods.success) {
            this.toast?.show({ severity: "error", summary: "Error", detail: financingMethods.message, life: 3000 });
            this.setState({ isLoading: false });
            return;
        }
        this.financingMethods = financingMethods.data;

        const accountPurposes = await userApiService.getAccountPurpose();
        if (!accountPurposes.success) {
            this.toast?.show({ severity: "error", summary: "Error", detail: accountPurposes.message, life: 3000 });
            this.setState({ isLoading: false });
            return;
        }
        this.accountPurposes = accountPurposes.data;

        const mainActivities = await userApiService.getMainActivity();
        if (!mainActivities.success) {
            this.toast?.show({ severity: "error", summary: "Error", detail: mainActivities.message, life: 3000 });
            this.setState({ isLoading: false });
            return;
        }
        this.mainActivities = mainActivities.data;

        this.setState({ isLoading: false });
    };

    onSubmit = async () => {
        this.setState({ disableSubmitBtn: true });

        const saveResult = await this.onSaveDraft();
        if (saveResult) {
            const result = await kycSubmissionApiService.kycSubmit(this.props.submission!.id);

            if (!result.success) {
                this.setState({ disableSubmitBtn: false });
                this.toast?.show({ severity: "error", summary: "Error", detail: result.message, life: 3000 });
            } else {
                this.setState({ disableSubmitBtn: false });
                this.props.onHide();
            }
        }
        this.setState({ disableSubmitBtn: false });
    };

    onApprove = async () => {
        this.setState({ disableApproveBtn: true });

        const saveResult = await this.onSaveDraft();
        if (saveResult) {
            const result = await kycSubmissionApiService.transitionToApproved(this.props.submission!.id);

            if (!result.success) {
                this.toast?.show({ severity: "error", summary: "Error", detail: result.message, life: 3000 });
                this.setState({ disableApproveBtn: false });
            } else {
                this.setState({ disableApproveBtn: false });
                this.props.onHide();
            }
        }
        this.setState({ disableApproveBtn: false });
    };

    onReject = async () => {
        const saveResult = await this.onSaveDraft();
        if (saveResult) {
            this.setState({ showRejectDialog: true });
        }
    };

    onEscalate = async () => {
        const saveResult = await this.onSaveDraft();
        if (saveResult) {
            const result = await kycSubmissionApiService.kycEscalate(this.props.submission!.id);

            if (!result.success) {
                this.toast?.show({ severity: "error", summary: "Error", detail: result.message, life: 3000 });
            } else this.props.onHide();
        }
    };

    onSaveDraft = async () => {
        this.setState({ isLoading: true });

        //Save user
        const newData = this.state.individual as User;
        const objToPost: UserProfileUpdate = {
            id: newData.id,
            firstName: newData.firstName,
            lastName: newData.lastName,
            nonLatinFirstName: newData.nonLatinFirstName,
            nonLatinLastName: newData.nonLatinLastName,
            email: newData.email,
            photo: newData.photo,
            birthDate: newData.birthDate,
            phoneNumber: newData.phoneNumber,
            cryptoCustomer: newData.cryptoCustomer,
            sourceOfFundId: newData.sourceOfFundId,
            residenceAddress: newData.residenceAddress,
            correspondenceAddress: newData.correspondenceAddress,
            nationalities: newData.nationalities,
            taxResidences: newData.taxResidences,
            nationalIdNumber: newData.nationalIdNumber,
            primaryLanguage: newData.primaryLanguage,
            secondaryLanguage: newData.secondaryLanguage,
            merchantGroupId: newData.merchantGroupId,
            accountPurposeCode: newData.accountPurposeCode,
            mainActivityCode: newData.mainActivityCode,
            financingMethodCode: newData.financingMethodCode,
            personalIdentificationNumber: newData.personalIdentificationNumber,
            isPEP: newData.isPEP,
            isSIP: newData.isSIP,
            gender: newData.gender,
        } as UserProfileUpdate;

        const result = await userApiService.updateForCompliance(objToPost);
        if (!result.success) {
            this.toast?.show({ severity: "error", summary: "Error", detail: result.message, life: 3000 });
        }

        //Save Comments kycComments
        let kycCommentsResult;
        this.state.kycComments.forEach(async (comment) => {
            if (comment.modifyStatus == modifyStatus.New) {
                comment.id = null;
                kycCommentsResult = await kycSubmissionApiService.putkycComment(comment);
                if (!result.success) {
                    this.toast?.show({ severity: "error", summary: "Error", detail: kycCommentsResult.message, life: 3000 });
                    return;
                }
            }
        });

        //Save Documents
        const allDocuments = this.state.kycDocuments;

        if (allDocuments.length > 0) {
            this.setState({ isLoading: true });

            for (const file of allDocuments) {
                if (file.isUploaded === true || file.fileStorageId !== "") {
                    continue;
                }
                const fileObjToPost: AddKycDocument = {
                    submissionId: this.props.submission.id,
                    fileName: file.file?.name,
                    fileData: (await utils.convertFileToBase64(file.file!)) as string,
                    type: file.documentType,
                } as AddKycDocument;

                const document = await kycSubmissionApiService.putkycDocument(fileObjToPost);

                if (!document.success) {
                    this.setState({ isLoading: false });

                    this.toast?.show({ severity: "error", summary: "Error", detail: document.message, life: 3000 });
                    return;
                }
                file.isUploaded = true;

                this.setState({ kycDocuments: allDocuments });
            }
            this.setState({ isLoading: false });
        }

        this.setState({ isLoading: false });

        this.componentDidMount();
        return result.success;
    };

    onRejectWithReason = async () => {
        const result = await kycSubmissionApiService.transitionToRejected(this.props.submission!.id, this.state.rejectionReason);

        if (!result.success) {
            this.toast?.show({ severity: "error", summary: "Error", detail: result.message, life: 3000 });
        } else {
            this.props.onHide();
        }
        this.setState({ showRejectDialog: false });
    };

    addorEditAddress = (type: string, address: UserAddress) => {
        const objAddress = _.cloneDeep(address);
        objAddress.type = type;
        this.setState({ isAddressDetailVisible: true, selectedAddress: objAddress });
    };

    showDowJonesDetail = async () => {
        this.setState({ djResult: [] });

        const djRequest: DowJonesSearch = {
            name: this.state.individual?.firstName + " " + this.state.individual?.lastName,
            percentMatch: 0.8,
        };
        const djSearchResult = await riskApiService.djSearchByName(djRequest);

        if (!djSearchResult.success) {
            this.toast?.show({ severity: "error", summary: "Error", detail: djSearchResult.message, life: 3000 });
            return;
        }

        this.setState({ isDowJonesDetailVisible: true, djResult: djSearchResult.data });
    };

    renderDowJonesResult = () => {
        const listItems = this.state.djResult.map((item, index) => <pre key={index}>{JSON.stringify({ item }, null, 2)}</pre>);

        if (listItems.length === 0)
            return (
                <div>
                    {" "}
                    <label>No data found</label>{" "}
                </div>
            );
        else return <div>{listItems}</div>;
    };

    onAddressObj = (obj: UserAddress | null | undefined): UserAddress => {
        if (obj === null || obj === undefined) {
            return {
                countryId: "",
                city: "",
                stateProvinceCounty: "",
                zipCode: "",
                addressLine: "",
            };
        }
        return obj;
    };

    onAddressUpdate = (address: UserAddress) => {
        const userDataDetail = this.state.individual;

        const objToPost: UserAddress = {
            countryId: address.countryId,
            city: address.city,
            stateProvinceCounty: address.stateProvinceCounty,
            zipCode: address.zipCode,
            addressLine: address.addressLine,
        } as UserAddress;

        if (address.type === "correspondence") {
            userDataDetail!.correspondenceAddress = objToPost;
        } else {
            userDataDetail!.residenceAddress = objToPost;
        }

        this.setState({ individual: userDataDetail, isAddressDetailVisible: false });
    };

    onAddressDetailsModalClose = async () => {
        this.setState({ isAddressDetailVisible: false });
    };

    onFieldUpdate = (fieldName: string, value: any) => {
        const individualData = this.state.individual as any;
        individualData[fieldName] = value;
        this.setState({ individual: individualData });
    };

    onDownloadClick = async (fileStorageId: string, secondaryLabel: string): Promise<string> => {
        this.setState({ isLoading: true });
        const imagePath = await fileService.GetFile(fileStorageId);

        if (!imagePath.success) {
            this.toast?.show({ severity: "error", summary: "Error", detail: imagePath.message, life: 3000 });
            return "";
        }

        const url = utils.appendBase64Data(imagePath.data, fileStorageId);

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", secondaryLabel);
        document.body.appendChild(link);
        link.click();
        this.setState({ isLoading: false });
        return "ok";
    };

    onSubmitComment = async (comments: KycSubmissionComment[]) => {
        this.setState({ kycComments: comments });
    };

    onSubmitKycDocuments = async (documents: DocumentFile[]) => {
        this.setState({ kycDocuments: documents });
    };

    renderAddress = (rowData: UserAddress | null | undefined) => {
        if (rowData)
            return (
                <span>
                    {rowData?.addressLine} {rowData?.city} {rowData?.stateProvinceCounty} {rowData?.zipCode}{" "}
                    {utils.getCountryName(rowData?.countryId)}
                </span>
            );
    };

    getRiskTag = (riskLevel: number) => {
        switch (riskLevel) {
            case 1: //'Low risk':
                return <Tag className="mr-2" icon="pi pi-check" severity="success" value="Low risk"></Tag>;
            case 2: //'Average':
                return <Tag className="mr-2" icon="pi pi-info-circle" severity="info" value="Average risk"></Tag>;
            case 3: //'High':
                return <Tag className="mr-2" icon="pi pi-exclamation-triangle" severity="warning" value="High risk"></Tag>;
            case 4: //'Critical':
                return <Tag icon="pi pi-times" severity="danger" value="Critical risk"></Tag>;
            default:
                return null;
        }
    };

    renderRiskGrade = (riskGrade: number) => {
        const items = [
            {
                label: "Low",
                icon: riskGrade === 1 ? "pi pi-check" : "",
            },
            {
                label: "Average",
                icon: riskGrade === 2 ? "pi pi-check" : "",
            },
            {
                label: "High",
                icon: riskGrade === 3 ? "pi pi-check" : "",
            },
            {
                label: "Critical",
                icon: riskGrade === 4 ? "pi pi-check" : "",
            },
        ];
        const selectedIndex = riskGrade - 1;
        //return <Steps model={items} activeIndex={2} readOnly={true} />
        return <TabMenu model={items} activeIndex={selectedIndex} onChange={() => false} />;
    };

    render() {
        if (this.props.submission == null) {
            return <div></div>;
        }

        const isReadOnly = this.state.isReadOnly;
        return (
            <Dialog header="Individual Scorecard" visible={true} style={{ width: "70vw" }} modal onHide={this.props.onHide}>
                <Fragment>
                    {this.state.isAddressDetailVisible && (
                        <AddUserAddress
                            onHide={this.onAddressDetailsModalClose}
                            address={this.state.selectedAddress!}
                            countries={this.countries}
                            onAddressUpdate={this.onAddressUpdate}
                        />
                    )}

                    <Dialog
                        header="Dow Jones Found Records"
                        visible={this.state.isDowJonesDetailVisible}
                        style={{ width: "40vw" }}
                        onHide={() => this.setState({ isDowJonesDetailVisible: false })}
                    >
                        <Container fluid>
                            <Card className="mb-3">
                                <CardBody>{this.renderDowJonesResult()}</CardBody>
                            </Card>
                        </Container>
                    </Dialog>

                    <TransitionGroup appear={true} exit={false} enter={false}>
                        <CSSTransition classNames="TabsAnimation" timeout={1500}>
                            <Fragment>
                                <Toast ref={(el) => (this.toast = el)} position={"bottom-center"} baseZIndex={99999}></Toast>
                                <Container fluid>
                                    <Card className="mb-3">
                                        <CardBody>
                                            <Row style={{ padding: "20px" }}>
                                                <Col style={{ padding: "20px" }} sm={12} md={4} lg={2} xl={2}>
                                                    <Label for="MerchantGroupId">Merchant Group Id</Label>
                                                    <InputText
                                                        value={this.state.individual?.merchantGroupId}
                                                        className={"form-control"}
                                                        id="MerchantGroupId"
                                                        readOnly={true}
                                                    />
                                                </Col>
                                                <Col style={{ padding: "20px" }} sm={12} md={4} lg={4} xl={4}>
                                                    <Label for="MerchantGroup">Merchant Group Name</Label>
                                                    <InputText
                                                        value={this.state.individual?.merchantGroup}
                                                        className={"form-control"}
                                                        id="MerchantGroup"
                                                        readOnly={true}
                                                    />
                                                </Col>
                                                <Col style={{ padding: "20px" }} sm={12} md={4} lg={2} xl={2}>
                                                    <Label for="MerchantId">Merchant Id</Label>
                                                    <InputText
                                                        value={this.state.individual?.merchantId}
                                                        className={"form-control"}
                                                        id="MerchantId"
                                                        readOnly={true}
                                                    />
                                                </Col>
                                                <Col style={{ padding: "20px" }} sm={12} md={4} lg={4} xl={4}>
                                                    <Label for="Merchant">Merchant Name</Label>
                                                    <InputText
                                                        value={this.state.individual?.merchant}
                                                        className={"form-control"}
                                                        id="Merchant"
                                                        readOnly={true}
                                                    />
                                                </Col>

                                                <Col style={{ padding: "20px" }} sm={12} md={4} lg={2} xl={2}>
                                                    <Label for="SubmissionId">Submission Id</Label>
                                                    <InputText
                                                        value={this.state.submission?.id}
                                                        className={"form-control"}
                                                        id="SubmissionId"
                                                        readOnly={true}
                                                    />
                                                </Col>
                                                {/* <Col style={{ padding: "20px" }} sm={12} md={4} lg={4} xl={3}> */}
                                                <Col style={{ padding: "20px" }} sm={12} md={4} lg={2} xl={2}>
                                                    <Label for="IndividualId">User Id</Label>
                                                    <InputText
                                                        value={this.state.individual?.id}
                                                        className={"form-control"}
                                                        id="IndividualId"
                                                        readOnly={true}
                                                    />
                                                </Col>
                                                <Col style={{ padding: "20px" }} sm={12} md={4} lg={2} xl={2}>
                                                    <Label for="CbsId">CBS Id</Label>
                                                    <InputText
                                                        value={this.state.individual?.cbsId}
                                                        className={"form-control"}
                                                        id="CbsId"
                                                        readOnly={true}
                                                    />
                                                </Col>

                                                <Col style={{ padding: "20px" }} sm={12} md={4} lg={2} xl={2}>
                                                    <Label for="RegiteringCountry">Country of Registration</Label>
                                                    <InputText
                                                        value={utils.getCountryName(this.state.individual?.enrollCountryCode ?? "None")}
                                                        className={"form-control"}
                                                        id="RegiteringCountry"
                                                        readOnly={true}
                                                    />
                                                </Col>

                                                <Col style={{ padding: "20px" }} sm={12} md={4} lg={2} xl={2}>
                                                    <Label for="ipCountryCode">IP country</Label>
                                                    <InputText
                                                        value={utils.getCountryName(this.state.individual?.ipCountryCode ?? "None")}
                                                        className={"form-control"}
                                                        id="ipCountryCode"
                                                        readOnly={true}
                                                    />
                                                </Col>

                                                <Col style={{ padding: "20px" }} sm={12} md={4} lg={2} xl={2}>
                                                    <Label for="geoLocationCountryCode">Geolocation</Label>
                                                    <InputText
                                                        value={utils.getCountryName(this.state.individual?.geoLocationCountryCode ?? "None")}
                                                        className={"form-control"}
                                                        id="geoLocationCountryCode"
                                                        readOnly={true}
                                                    />
                                                </Col>

                                                <Col style={{ padding: "20px" }} sm={12} md={4} lg={4} xl={3}>
                                                    <Label for="FirstName">First Name</Label>
                                                    <InputText
                                                        value={this.state.individual?.firstName}
                                                        className={"form-control"}
                                                        id="FirstName"
                                                        onChange={(e) => this.onFieldUpdate("firstName", e.target.value)}
                                                        readOnly={isReadOnly}
                                                    />
                                                </Col>
                                                <Col style={{ padding: "20px" }} sm={12} md={4} lg={4} xl={3}>
                                                    <Label for="LastName">Last Name</Label>
                                                    <InputText
                                                        value={this.state.individual?.lastName}
                                                        className={"form-control"}
                                                        id="LastName"
                                                        onChange={(e) => this.onFieldUpdate("lastName", e.target.value)}
                                                        readOnly={isReadOnly}
                                                    />
                                                </Col>
                                                <Col style={{ padding: "20px" }} sm={12} md={4} lg={4} xl={3}>
                                                    <Label for="nonLatinFirstName">Non-Latin First Name</Label>
                                                    <InputText
                                                        value={this.state.individual?.nonLatinFirstName}
                                                        className={"form-control"}
                                                        id="nonLatinFirstName"
                                                        onChange={(e) => this.onFieldUpdate("nonLatinFirstName", e.target.value)}
                                                        readOnly={isReadOnly}
                                                    />
                                                </Col>
                                                <Col style={{ padding: "20px" }} sm={12} md={4} lg={4} xl={3}>
                                                    <Label for="nonLatinLastName">Non-Latin Last Name</Label>
                                                    <InputText
                                                        value={this.state.individual?.nonLatinLastName}
                                                        className={"form-control"}
                                                        id="nonLatinLastName"
                                                        onChange={(e) => this.onFieldUpdate("nonLatinLastName", e.target.value)}
                                                        readOnly={isReadOnly}
                                                    />
                                                </Col>

                                                <Col style={{ padding: "20px" }} sm={12} md={4} lg={4} xl={3}>
                                                    <Label for="NationalIdNumber">National Id Number</Label>
                                                    <InputText
                                                        value={this.state.individual?.nationalIdNumber}
                                                        className={"form-control"}
                                                        id="NationalIdNumber"
                                                        onChange={(e) => this.onFieldUpdate("nationalIdNumber", e.target.value)}
                                                        readOnly={isReadOnly}
                                                    />
                                                </Col>

                                                <Col style={{ padding: "20px" }} sm={12} md={4} lg={4} xl={3}>
                                                    <Label for="personalIdentificationNumber">Identification Number</Label>
                                                    <InputText
                                                        value={this.state.individual?.personalIdentificationNumber}
                                                        className={"form-control"}
                                                        id="personalIdentificationNumber"
                                                        onChange={(e) => this.onFieldUpdate("personalIdentificationNumber", e.target.value)}
                                                        readOnly={isReadOnly}
                                                    />
                                                </Col>

                                                <Col style={{ padding: "20px" }} sm={12} md={4} lg={4} xl={6}>
                                                    <Label for="AddressOfResidence">Address of Residence</Label>
                                                    <div className="square border rounded border-2 p-2">
                                                        {this.renderAddress(this.state.individual?.residenceAddress)}
                                                        <button
                                                            type="button"
                                                            className="p-row-editor-init p-link float-right"
                                                            tabIndex={0}
                                                            onClick={() =>
                                                                this.addorEditAddress(
                                                                    "residence",
                                                                    this.onAddressObj(this.state.individual?.residenceAddress)
                                                                )
                                                            }
                                                            disabled={isReadOnly}
                                                        >
                                                            <span className="p-row-editor-init-icon pi pi-fw pi-pencil"></span>
                                                        </button>
                                                    </div>
                                                </Col>
                                                <Col style={{ padding: "20px" }} sm={12} md={4} lg={4} xl={6}>
                                                    <Label for="AddressOfCorrespondence">Address of Correspondence</Label>
                                                    <div className="square border rounded border-2 p-2">
                                                        {this.renderAddress(this.state.individual?.correspondenceAddress)}
                                                        <button
                                                            type="button"
                                                            className="p-row-editor-init p-link float-right"
                                                            tabIndex={0}
                                                            onClick={() =>
                                                                this.addorEditAddress(
                                                                    "correspondence",
                                                                    this.onAddressObj(this.state.individual?.correspondenceAddress)
                                                                )
                                                            }
                                                            disabled={isReadOnly}
                                                        >
                                                            <span className="p-row-editor-init-icon pi pi-fw pi-pencil"></span>
                                                        </button>
                                                    </div>
                                                </Col>

                                                <Col style={{ padding: "20px" }} sm={12} md={4} lg={8} xl={3}>
                                                    <Label for="mainActivity">Main activity</Label>
                                                    <ExtendedDropdown
                                                        style={{ display: "flex" }}
                                                        value={this.state.individual?.mainActivityCode}
                                                        options={this.mainActivities}
                                                        onChange={(e) => this.onFieldUpdate("mainActivityCode", e.value)}
                                                        filter
                                                        filterBy="label"
                                                        required={true}
                                                        name="Main activity"
                                                        onValidStatusChanged={(value: boolean) => this.validationErrors.set("mainActivity", value)}
                                                        disabled={isReadOnly}
                                                    />
                                                </Col>

                                                <Col style={{ padding: "20px" }} sm={12} md={4} lg={8} xl={3}>
                                                    <Label for="SourceofFunds">Source of Funds</Label>
                                                    <ExtendedDropdown
                                                        style={{ display: "flex" }}
                                                        value={this.state.individual?.sourceOfFundId.toString()}
                                                        options={this.sourceOfFunds}
                                                        onChange={(e) => this.onFieldUpdate("sourceOfFundId", e.value)}
                                                        filter
                                                        filterBy="label"
                                                        required={true}
                                                        name="Source Of Funds"
                                                        onValidStatusChanged={(value: boolean) => this.validationErrors.set("sourceOfFunds", value)}
                                                        disabled={isReadOnly}
                                                    />
                                                </Col>

                                                <Col style={{ padding: "20px" }} sm={12} md={4} lg={8} xl={3}>
                                                    <Label for="financingMethods">Financing method</Label>
                                                    <ExtendedDropdown
                                                        style={{ display: "flex" }}
                                                        value={this.state.individual?.financingMethodCode}
                                                        options={this.financingMethods}
                                                        onChange={(e) => this.onFieldUpdate("financingMethodCode", e.value)}
                                                        filter
                                                        filterBy="label"
                                                        required={true}
                                                        name="Financing method"
                                                        onValidStatusChanged={(value: boolean) =>
                                                            this.validationErrors.set("financingMethods", value)
                                                        }
                                                        disabled={isReadOnly}
                                                    />
                                                </Col>

                                                <Col style={{ padding: "20px" }} sm={12} md={4} lg={8} xl={3}>
                                                    <Label for="accountPurpose">Account purpose</Label>
                                                    <ExtendedDropdown
                                                        style={{ display: "flex" }}
                                                        value={this.state.individual?.accountPurposeCode}
                                                        options={this.accountPurposes}
                                                        onChange={(e) => this.onFieldUpdate("accountPurposeCode", e.value)}
                                                        filter
                                                        filterBy="label"
                                                        required={true}
                                                        name="Account purpose"
                                                        onValidStatusChanged={(value: boolean) => this.validationErrors.set("accountPurposes", value)}
                                                        disabled={isReadOnly}
                                                    />
                                                </Col>

                                                <Col style={{ padding: "20px" }} sm={12} md={4} lg={4} xl={3}>
                                                    <Label for="DocumentAuthenticity">Autheticated by external system</Label> <br />
                                                    <input
                                                        type="checkbox"
                                                        checked={this.state.individual?.documentsAuthenticatedByThirdParty}
                                                        readOnly={true}
                                                        id="DocumentAuthenticity"
                                                    ></input>
                                                </Col>
                                                <Col style={{ padding: "20px" }} sm={12} md={2} lg={2} xl={1}>
                                                    <Label for="IsPEP">Is PEP</Label>
                                                    <br />
                                                    <input
                                                        type="checkbox"
                                                        checked={this.state.individual?.isPEP}
                                                        onChange={(e) => this.onFieldUpdate("isPEP", e.target.checked)}
                                                        readOnly={false}
                                                        id="IsPEP"
                                                    ></input>
                                                </Col>
                                                <Col style={{ padding: "20px" }} sm={12} md={2} lg={2} xl={2}>
                                                    <Label for="IsSIP">Is SIP</Label>
                                                    <br />
                                                    <input
                                                        type="checkbox"
                                                        checked={this.state.individual?.isSIP}
                                                        onChange={(e) => this.onFieldUpdate("isSIP", e.target.checked)}
                                                        readOnly={false}
                                                        id="IsSIP"
                                                    ></input>
                                                </Col>

                                                <Col style={{ padding: "20px" }} sm={12} md={2} lg={2} xl={1}>
                                                    <Label for="IsPEPCalculated">DJ PEP</Label>
                                                    <br />
                                                    <input
                                                        type="checkbox"
                                                        checked={this.state.individual?.isPEPCalculated}
                                                        disabled={true}
                                                        readOnly={true}
                                                        id="IsPEPCalculated"
                                                    ></input>
                                                </Col>
                                                <Col style={{ padding: "20px" }} sm={12} md={2} lg={2} xl={2}>
                                                    <Label for="IsSIPCalculated">DJ SIP</Label>
                                                    <br />
                                                    <input
                                                        type="checkbox"
                                                        checked={this.state.individual?.isSIPCalculated}
                                                        disabled={true}
                                                        readOnly={true}
                                                        id="IsSIPCalculated"
                                                    ></input>
                                                </Col>

                                                <Col style={{ padding: "20px" }} sm={12} md={1} lg={3} xl={3}>
                                                    <Label for="DJview">Dow Jones Records</Label>
                                                    <div>
                                                        <button
                                                            type="button"
                                                            className="p-row-editor-init p-link float-left"
                                                            tabIndex={0}
                                                            onClick={() => this.showDowJonesDetail()}
                                                            disabled={isReadOnly}
                                                        >
                                                            <span className="p-row-editor-init-icon pi pi-fw pi-search"></span>
                                                        </button>
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Container fluid>
                                                <CSSTransitionComponent>
                                                    <Card>
                                                        <CardHeader>Documents</CardHeader>
                                                        <CardBody>
                                                            <AddManualKyc
                                                                submissionId={this.state.submission.id}
                                                                submitToOwner={this.onSubmitKycDocuments}
                                                            />
                                                        </CardBody>
                                                    </Card>
                                                </CSSTransitionComponent>
                                            </Container>

                                            <Container fluid>
                                                <CSSTransitionComponent>
                                                    <Card>
                                                        <CardHeader>Comments</CardHeader>
                                                        <CardBody>
                                                            <KycSubmissionCommentForm
                                                                submissionId={this.state.submission.id}
                                                                comments={this.state.kycComments}
                                                                submitToOwner={this.onSubmitComment}
                                                            />
                                                        </CardBody>
                                                    </Card>
                                                </CSSTransitionComponent>
                                            </Container>

                                            {isKYCApproveAllowed() && (
                                                <Container fluid>
                                                    <CSSTransitionComponent>
                                                        <Card>
                                                            <CardHeader>Risk result</CardHeader>
                                                            <CardBody>
                                                                <Row style={{ padding: "20px" }}>
                                                                    <Col style={{ padding: "20px" }} sm={12} md={4} lg={4} xl={3}>
                                                                        <p>Risk score {this.state.individual?.riskScore}.</p>
                                                                        {/* <p>Risk grade {this.state.individual?.riskGrade}.</p> */}
                                                                        {/* <Label for="RiskScore">Risk score</Label>
                                                                <InputText value={this.state.individual?.riskScore} className={"form-control"} id="RiskScore" readOnly={true} /> */}
                                                                    </Col>
                                                                    {/* <Col style={{ padding: "20px" }} sm={12} md={4} lg={4} xl={3}>
                                                                {this.getRiskTag(this.state.individual?.riskGrade ?? 0)}
                                                            </Col>     */}
                                                                    {this.renderRiskGrade(this.state.individual?.riskGrade ?? 4)}
                                                                </Row>
                                                            </CardBody>
                                                        </Card>
                                                    </CSSTransitionComponent>
                                                </Container>
                                            )}

                                            <Row style={{ padding: "20px" }}>
                                                {isKYCAssessAllowed() && (
                                                    <Col style={{ textAlign: "center" }} className="d-flex flex-row">
                                                        <Button
                                                            label={"Submit"}
                                                            type="button"
                                                            icon="pi pi-check"
                                                            className="p-button-success"
                                                            disabled={this.state.disableSubmitBtn}
                                                            onClick={() => this.onSubmit()}
                                                        >
                                                            {this.state.disableSubmitBtn && (
                                                                <span
                                                                    className="spinner-grow spinner-grow-sm"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                ></span>
                                                            )}
                                                        </Button>
                                                    </Col>
                                                )}
                                                {isKYCApproveAllowed() && (
                                                    <Col style={{ textAlign: "center" }} className="d-flex flex-row">
                                                        <Button
                                                            label={"Approve"}
                                                            type="button"
                                                            icon="pi pi-check"
                                                            className="p-button-success"
                                                            disabled={this.state.disableApproveBtn}
                                                            onClick={() => this.onApprove()}
                                                        >
                                                            {this.state.disableApproveBtn && (
                                                                <span
                                                                    className="spinner-grow spinner-grow-sm"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                ></span>
                                                            )}
                                                        </Button>
                                                    </Col>
                                                )}
                                                {isKYCApproveAllowed() && (
                                                    <Col style={{ textAlign: "center" }}>
                                                        <Button
                                                            label={"Reject"}
                                                            type="button"
                                                            icon="pi pi-times"
                                                            className="p-button-danger"
                                                            onClick={() => this.onReject()}
                                                        ></Button>
                                                    </Col>
                                                )}
                                                {(isKYCApproveAllowed() || isKYCAssessAllowed()) && (
                                                    <Col style={{ textAlign: "center" }}>
                                                        <Button
                                                            label={"Save draft"}
                                                            type="button"
                                                            icon="pi pi-save"
                                                            className="p-button-default"
                                                            onClick={() => this.onSaveDraft()}
                                                        ></Button>
                                                    </Col>
                                                )}
                                                {isKYCAssessAllowed() && (
                                                    <Col style={{ textAlign: "center" }}>
                                                        <Button
                                                            label={"Escalate"}
                                                            type="button"
                                                            icon="pi pi-user"
                                                            className="p-button-danger"
                                                            onClick={() => this.onEscalate()}
                                                        ></Button>
                                                    </Col>
                                                )}
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Container>
                            </Fragment>
                        </CSSTransition>
                    </TransitionGroup>
                    {this.state.showRejectDialog && (
                        <Dialog
                            header="Reject Kyc Submission"
                            visible={true}
                            style={{ width: "60vw" }}
                            modal
                            onHide={() => {
                                this.setState({ showRejectDialog: false });
                            }}
                        >
                            <Container fluid>
                                <Row>
                                    <Col>
                                        <InputTextarea
                                            rows={3}
                                            cols={90}
                                            onChange={(e) => {
                                                this.setState({ rejectionReason: e.target.value });
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Button
                                            label={"Reject"}
                                            disabled={this.state.rejectionReason.length == 0}
                                            type="button"
                                            icon="pi pi-times"
                                            className="p-button-danger"
                                            onClick={() => this.onRejectWithReason()}
                                        ></Button>
                                    </Col>
                                </Row>
                            </Container>
                        </Dialog>
                    )}
                </Fragment>
            </Dialog>
        );
    }
}
