import React, { Component } from "react";
import { OperatorDataModel } from "../../models/User";
import { Toast } from "primereact/toast";
import "./userprofile.scss";
import Loader from "../../components/Loader";
import _ from "lodash";
import { operatorsApiService } from "../../services/api/users/OperatorApiService";
import EventEmitter, { Events } from "../../services/eventemitter";
import ProfileShared from "./ProfileShared";
import { RoleModel } from "../../models/Role";

interface ProfileState {
    user: OperatorDataModel | null;
    isLoading: boolean;
    roles: RoleModel[];
}

export default class OwnProfileComponent extends Component<{}, ProfileState> {
    toast: Toast | null = null;

    constructor(props: {}) {
        super(props);

        this.state = {
            user: null,
            isLoading: true,
            roles: [],
        };
    }

    async componentDidMount() {
        this.setState({ isLoading: true });

        const userProfile = await operatorsApiService.get();

        if (!userProfile.success) {
            this.setState({ isLoading: false });
            this.toast?.show({ severity: "error", summary: "Error", detail: userProfile.message, life: 3000 });
            return;
        }

        const roleOwnResult = await operatorsApiService.getRoles();
        if (!roleOwnResult.success) {
            this.setState({ isLoading: false });
            this.toast?.show({ severity: "error", summary: "Error", detail: roleOwnResult.message ?? "Bad Request", life: 3000 });
            return;
        }

        this.setState({ isLoading: false, user: userProfile.data, roles: roleOwnResult.data });
    }

    showToast = (message: string) => {
        this.toast?.show({ severity: "success", summary: "Action Completed", detail: message });
    };

    onSubmitButtonClick = async () => {
        this.setState({ isLoading: true });

        const newData = _.cloneDeep(this.state.user);

        const result = await operatorsApiService.updateOwn(newData!);

        this.setState({ isLoading: false });

        if (result.success) {
            EventEmitter.emit(Events.OnUserDataUpdate);
        } else {
            this.toast?.show({ severity: "error", summary: "Error", detail: result.message, life: 3000 });
        }
    };

    render() {
        if (this.state.isLoading || this.state.user === null) {
            return (
                <>
                    <Toast ref={(el) => (this.toast = el)} />
                    <Loader />
                </>
            );
        }

        return (
            <>
                <Toast ref={(el) => (this.toast = el)} />
                <ProfileShared
                    allowResetPhoneAndMobile={false}
                    isEditMode={false}
                    showMerchant={false}
                    showRoles={true}
                    showPassword={false}
                    merchants={[]}
                    roles={this.state.roles}
                    user={this.state.user}
                    onUpdate={(user) => this.setState({ user: user })}
                    onSubmit={this.onSubmitButtonClick}
                />
            </>
        );
    }
}
