import React, { Component, Fragment } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Container, Card, CardBody, Row, Col, Label, CardHeader } from "reactstrap";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";
import { utils } from "../../utils/utils";
import { InputText } from "primereact/inputtext";
import { isKYCAssessAllowed, isKYCApproveAllowed } from "../../authGuard";
import { SelectItem } from "../../models/SelectItem";
import { CSSTransitionComponent } from "../../components/CSSTransition";
import _ from "lodash";
import {
    BankTransferOutgoing,
    BankTransferReviewRequest,
    ReviewRosulutionStatuses,
    BankTransferSourceOfFunds,
    BankTransferRequest,
    SourceOfFundsUploadRequest,
} from "../../models/Orchestrator";
import { orchestratorApiService } from "../../services/api/Compliance/OrchestratorApiService";
import SourceOfFunds from "./SourceOfFunds";
import { confirmDialog } from "primereact/confirmdialog";
import { Fieldset } from "primereact/fieldset";

interface ReviewPendingBankTransferProps {
    bankTransfer: BankTransferOutgoing;
    onHide: () => void;
}

interface ReviewPendingBankTransferState {
    showConfirmDialog: boolean;
    confirmLabel: string;
    confirmAction: string;
    bankTransfer: BankTransferOutgoing;
    isLoading: boolean;
    sofDocuments: BankTransferSourceOfFunds[];
    isReadOnly: boolean;
    disableSubmitBtn: boolean;
    disableApproveBtn: boolean;
    disableRejectBtn: boolean;
    disableReturnBtn: boolean;
}

export default class ReviewPendingBankTransferModal extends Component<ReviewPendingBankTransferProps, ReviewPendingBankTransferState> {
    toast: Toast | null = null;
    validationErrors: Map<string, boolean> = new Map<string, boolean>();
    countryNames: Map<string, string> = new Map<string, string>();

    constructor(props: ReviewPendingBankTransferProps | Readonly<ReviewPendingBankTransferProps>) {
        super(props);

        this.state = {
            isLoading: true,
            bankTransfer: this.props.bankTransfer,
            sofDocuments: [],
            isReadOnly: false,
            disableSubmitBtn: false,
            disableApproveBtn: false,
            disableRejectBtn: false,
            disableReturnBtn: false,
            showConfirmDialog: false,
            confirmAction: "",
            confirmLabel: "",
        };
    }

    sourceOfFunds: SelectItem[] = [];
    countries: SelectItem[] = [];
    financingMethods: SelectItem[] = [];
    accountPurposes: SelectItem[] = [];
    mainActivities: SelectItem[] = [];

    componentDidMount = async () => {
        this.setState({ isLoading: true });
        //........
        this.setState({ isLoading: false });
    };
    closeConfirm = () => {
        this.toast?.show({ severity: "warn", summary: "Rejected", detail: "Confirm Close", life: 3000 });
    };
    rejectConfirmDialog = () => {
        confirmDialog({
            message: "Are you sure you want rejected this transaction?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: async () => {
                await this.onApproveConfirm();
            },
            reject: this.closeConfirm,
        });
    };
    acceptConfirmDialog = () => {
        confirmDialog({
            message: "Are you sure you want accept this transaction?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: async () => {
                await this.onRejectConfirm();
            },
            reject: this.closeConfirm,
        });
    };
    returncustomerConfirmDialog = () => {
        confirmDialog({
            message: "Are you sure return to customer?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: async () => {
                await this.onReturnConfirm();
            },
            reject: this.closeConfirm,
        });
    };

    onConfirm = async () => {
        switch (this.state.confirmAction) {
            case "Approve":
                await this.onApprove();
                break;
            case "Reject":
                await this.onReject();
                break;
            case "Return":
                await this.onReturn();
                break;
            default:
                return;
        }
    };

    onApprove = async () => {
        this.acceptConfirmDialog();
    };

    onApproveConfirm = async () => {
        this.setState({ disableApproveBtn: true });

        //Save Notes & Documents
        this.onSaveDraft();

        //Approve
        const objToPost: BankTransferReviewRequest = {
            sourceEntityType: this.props.bankTransfer.sourceEntityType,
            sourceEntityId: this.props.bankTransfer.sourceEntityId,
            transferId: this.props.bankTransfer.id,
            reviewResolution: ReviewRosulutionStatuses.Approve,
        } as BankTransferReviewRequest;

        const result = await orchestratorApiService.setReviewResolution(objToPost);

        if (!result.success) {
            this.toast?.show({ severity: "error", summary: "Error", detail: result.message, life: 3000 });
            this.setState({ disableApproveBtn: false });
        } else {
            this.setState({ disableApproveBtn: false });
            this.props.onHide();
        }

        this.setState({ disableApproveBtn: false });
    };

    onReject = async () => {
        this.rejectConfirmDialog();
    };

    onRejectConfirm = async () => {
        this.setState({ disableRejectBtn: true });

        //Save Notes & Documents
        this.onSaveDraft();

        //Reject
        const objToPost: BankTransferReviewRequest = {
            sourceEntityType: this.props.bankTransfer.sourceEntityType,
            sourceEntityId: this.props.bankTransfer.sourceEntityId,
            transferId: this.props.bankTransfer.id,
            reviewResolution: ReviewRosulutionStatuses.Reject,
        } as BankTransferReviewRequest;

        const result = await orchestratorApiService.setReviewResolution(objToPost);

        if (!result.success) {
            this.toast?.show({ severity: "error", summary: "Error", detail: result.message, life: 3000 });
            this.setState({ disableRejectBtn: false });
        } else {
            this.setState({ disableRejectBtn: false });
            this.props.onHide();
        }

        this.setState({ disableRejectBtn: false });
    };

    onReturn = async () => {
        this.returncustomerConfirmDialog();
    };

    onReturnConfirm = async () => {
        this.setState({ disableReturnBtn: true });

        //Save Notes & Documents
        this.onSaveDraft();

        //Return to Customer
        const objToPost: BankTransferReviewRequest = {
            sourceEntityType: this.props.bankTransfer.sourceEntityType,
            sourceEntityId: this.props.bankTransfer.sourceEntityId,
            transferId: this.props.bankTransfer.id,
            reviewResolution: ReviewRosulutionStatuses.ReturnToCustomer,
        } as BankTransferReviewRequest;

        const result = await orchestratorApiService.setReviewResolution(objToPost);

        if (!result.success) {
            this.toast?.show({ severity: "error", summary: "Error", detail: result.message, life: 3000 });
            this.setState({ disableReturnBtn: false });
        } else {
            this.setState({ disableReturnBtn: false });
            this.props.onHide();
        }

        this.setState({ disableReturnBtn: false });
    };

    onSaveDraft = async () => {
        this.setState({ isLoading: true });

        //Save Documents for Source of Funds
        const allDocuments = this.state.sofDocuments;

        if (allDocuments.length > 0) {
            this.setState({ isLoading: true });

            for (const file of allDocuments) {
                if (file.isUploaded === false || file.fileStorageId === "") {
                    const fileObjToPost: SourceOfFundsUploadRequest = {
                        sourceEntityType: file.entityType,
                        sourceEntityId: file.entityId,
                        transferId: file.bankTransferId,
                        fileName: file.file?.name,
                        fileData: (await utils.convertFileToBase64(file.file!)) as string,
                    } as SourceOfFundsUploadRequest;
                    const uploadResult = await orchestratorApiService.uploadSourceOfFunds(fileObjToPost);

                    if (!uploadResult.success) {
                        this.setState({ isLoading: false });

                        this.toast?.show({ severity: "error", summary: "Error", detail: uploadResult.message, life: 3000 });
                        return;
                    }
                    file.isUploaded = true;

                    this.setState({ sofDocuments: allDocuments });
                }

                if (file.isModified === true) {
                    const document = await orchestratorApiService.updateSourceOfFunds(file);

                    if (!document.success) {
                        this.setState({ isLoading: false });

                        this.toast?.show({ severity: "error", summary: "Error", detail: document.message, life: 3000 });
                        return;
                    }
                    file.isUploaded = true;

                    this.setState({ sofDocuments: allDocuments });
                }
            }
            this.setState({ isLoading: false });
        }

        //Save Notes to Customer
        const result = await orchestratorApiService.setRiskNote(this.state.bankTransfer);
        if (!result.success) {
            this.setState({ isLoading: false });

            this.toast?.show({ severity: "error", summary: "Error", detail: result.message, life: 3000 });
            return;
        }

        this.setState({ isLoading: false });

        this.componentDidMount();
        return true;
    };

    onFieldUpdate = (fieldName: string, value: any) => {
        const bankTransfer = this.state.bankTransfer as any;
        bankTransfer[fieldName] = value;
        this.setState({ bankTransfer: bankTransfer });
    };

    onSubmitDocuments = async (documents: BankTransferSourceOfFunds[]) => {
        this.setState({ sofDocuments: documents });
    };

    render() {
        if (this.props.bankTransfer == null) {
            return <div></div>;
        }

        const isReadOnly = this.state.isReadOnly;
        return (
            <Dialog header="Review Outgoing Bank Transfer" visible={true} style={{ width: "70vw" }} modal onHide={this.props.onHide}>
                <Fragment>
                    <TransitionGroup appear={true} exit={false} enter={false}>
                        <CSSTransition classNames="TabsAnimation" timeout={1500}>
                            <Fragment>
                                <Toast ref={(el) => (this.toast = el)} position={"bottom-center"} baseZIndex={99999}></Toast>
                                <Container fluid>
                                    <Card className="mb-3">
                                        <CardBody>
                                            <Row style={{ padding: "20px" }}>
                                                <Col style={{ padding: "20px" }} sm={12} md={4} lg={2} xl={2}>
                                                    <Label for="sourceCustomerAccount">Sender Acc</Label>
                                                    <InputText
                                                        value={this.state.bankTransfer.sourceCustomerAccount}
                                                        className={"form-control"}
                                                        id="sourceCustomerAccount"
                                                        readOnly={true}
                                                    />
                                                </Col>
                                                <Col style={{ padding: "20px" }} sm={12} md={4} lg={3} xl={3}>
                                                    <Label for="sourceCustomerIBAN">Sender IBAN</Label>
                                                    <InputText
                                                        value={this.state.bankTransfer.sourceCustomerIBAN}
                                                        className={"form-control"}
                                                        id="sourceCustomerIBAN"
                                                        readOnly={true}
                                                    />
                                                </Col>
                                                <Col style={{ padding: "20px" }} sm={12} md={4} lg={3} xl={3}>
                                                    <Label for="sourceUserCustomerName">Sender Name</Label>
                                                    <InputText
                                                        value={this.state.bankTransfer.sourceUserCustomerName}
                                                        className={"form-control"}
                                                        id="sourceUserCustomerName"
                                                        readOnly={true}
                                                    />
                                                </Col>
                                                <Col style={{ padding: "20px" }} sm={12} md={4} lg={2} xl={2}>
                                                    <Label for="sourceEntityId">{this.state.bankTransfer.sourceEntityType} Id</Label>
                                                    <InputText
                                                        value={this.state.bankTransfer.sourceEntityId}
                                                        className={"form-control"}
                                                        id="sourceEntityId"
                                                        readOnly={true}
                                                    />
                                                </Col>
                                                <Col style={{ padding: "20px" }} sm={12} md={4} lg={2} xl={2}>
                                                    <Label for="id">Transfer Id</Label>
                                                    <InputText
                                                        value={this.state.bankTransfer.id}
                                                        className={"form-control"}
                                                        id="id"
                                                        readOnly={true}
                                                    />
                                                </Col>
                                                <Col style={{ padding: "20px" }} sm={12} md={4} lg={2} xl={2}>
                                                    <Label for="beneficiaryCustomerBIC">Receiver BIC</Label>
                                                    <InputText
                                                        value={this.state.bankTransfer.beneficiaryCustomerBIC}
                                                        className={"form-control"}
                                                        id="beneficiaryCustomerBIC"
                                                        readOnly={true}
                                                    />
                                                </Col>
                                                <Col style={{ padding: "20px" }} sm={12} md={4} lg={3} xl={3}>
                                                    <Label for="beneficiaryCustomerIBAN">Receiver IBAN</Label>
                                                    <InputText
                                                        value={this.state.bankTransfer.beneficiaryCustomerIBAN}
                                                        className={"form-control"}
                                                        id="beneficiaryCustomerIBAN"
                                                        readOnly={true}
                                                    />
                                                </Col>
                                                <Col style={{ padding: "20px" }} sm={12} md={4} lg={3} xl={3}>
                                                    <Label for="beneficiaryCustomerName">Receiver Name</Label>
                                                    <InputText
                                                        value={this.state.bankTransfer.beneficiaryCustomerName}
                                                        className={"form-control"}
                                                        id="beneficiaryCustomerName"
                                                        readOnly={true}
                                                    />
                                                </Col>
                                                <Col style={{ padding: "20px" }} sm={12} md={4} lg={4} xl={4}>
                                                    <Label for="paymentNotes">Notes</Label>
                                                    <InputText
                                                        value={this.state.bankTransfer.paymentNotes ?? ""}
                                                        className={"form-control"}
                                                        id="paymentNotes"
                                                        readOnly={true}
                                                    />
                                                </Col>

                                                <Col style={{ padding: "20px" }} sm={12} md={4} lg={2} xl={2}>
                                                    <Label for="Amount">Amount</Label>
                                                    <InputText
                                                        value={this.state.bankTransfer.amount}
                                                        className={"form-control"}
                                                        id="amount"
                                                        readOnly={true}
                                                    />
                                                </Col>
                                                <Col style={{ padding: "20px" }} sm={12} md={4} lg={3} xl={3}>
                                                    <Label for="Currency">Currency</Label>
                                                    <InputText
                                                        value={this.state.bankTransfer.currency}
                                                        className={"form-control"}
                                                        id="currency"
                                                        readOnly={true}
                                                    />
                                                </Col>
                                                <Col style={{ padding: "20px" }} sm={12} md={4} lg={3} xl={3}>
                                                    <Label for="amountBGN">Amount BGN</Label>
                                                    <InputText
                                                        value={this.state.bankTransfer.amountBGN ?? 0}
                                                        className={"form-control"}
                                                        id="amountBGN"
                                                        readOnly={true}
                                                    />
                                                </Col>
                                                <Col style={{ padding: "20px" }} sm={12} md={4} lg={2} xl={2}>
                                                    <Label for="chargeAmount">Charge</Label>
                                                    <InputText
                                                        value={this.state.bankTransfer.chargeAmount ?? 0}
                                                        className={"form-control"}
                                                        id="chargeAmount"
                                                        readOnly={true}
                                                    />
                                                </Col>
                                                <Col style={{ padding: "20px" }} sm={12} md={4} lg={2} xl={2}>
                                                    <Label for="chargeCurrency">Charge Curr</Label>
                                                    <InputText
                                                        value={this.state.bankTransfer.chargeCurrency}
                                                        className={"form-control"}
                                                        id="chargeCurrency"
                                                        readOnly={true}
                                                    />
                                                </Col>

                                                <Col style={{ padding: "20px" }} sm={12} md={12} lg={12} xl={12}>
                                                    <Label for="riskRulesInfo">Risk Rules Info</Label>
                                                    <InputTextarea
                                                        value={this.state.bankTransfer.riskRulesInfo}
                                                        className={"form-control"}
                                                        id="riskRulesInfo"
                                                        readOnly={true}
                                                    />
                                                </Col>
                                                <Col style={{ padding: "20px" }} sm={12} md={12} lg={12} xl={12}>
                                                    <Label for="riskNotes">Notes to Customer</Label>
                                                    <InputText
                                                        value={this.state.bankTransfer.riskNotes}
                                                        className={"form-control"}
                                                        id="chargeCurrency"
                                                        onChange={(e) => {
                                                            this.onFieldUpdate("riskNotes", e.target.value);
                                                        }}
                                                        readOnly={false}
                                                    />
                                                </Col>

                                                {/* <Col style={{ padding: "20px" }} sm={12} md={4} lg={2} xl={2}>
                                                    <Label for="status">Status</Label> <br />
                                                    <InputText value={BankTransferStatus[this.state.bankTransfer.status]} className={"form-control"} id="status" readOnly={true} /> 
                                                    <Label for="status"style={{fontWeight:'bold'}}>{this.state.bankTransfer.status}</Label>
                                                </Col>
                                                <Col style={{ padding: "20px" }} sm={12} md={4} lg={2} xl={2}>
                                                    <Label for="sourceOfFundsStatus">Source of Funds</Label> <br />
                                                    <Label for="sourceOfFundsStatus"style={{fontWeight:'bold'}}>{this.state.bankTransfer.sourceOfFundsStatus}</Label>
                                                </Col> */}

                                                {/* Tik Stop Next */}
                                                {/* <Col style={{ padding: "20px" }} sm={12} md={4} lg={2} xl={2}>
                                                    <Label for="reviewStatus">review Status</Label> <br />
                                                    <Label for="reviewStatus"style={{fontWeight:'bold'}}>{this.state.bankTransfer.reviewStatus}</Label>
                                                </Col> */}

                                                {/* <Column
                                                    hidden={ !(this.props.listType === BankTransfersListType.All)}
                                                    header="Status"
                                                    field="status"
                                                    body={(rowData: BankTransferOutgoing) => <span>{BankTransferStatus[rowData.status]}</span>}
                                                    filterMatchModeOptions={stringFilterMatchModeOptions}>
                                                </Column> */}
                                            </Row>

                                            <Container fluid>
                                                <CSSTransitionComponent>
                                                    <Card>
                                                        <CardHeader>Documents</CardHeader>
                                                        <CardBody>
                                                            <SourceOfFunds
                                                                bankTransferData={
                                                                    {
                                                                        sourceEntityType: this.props.bankTransfer.sourceEntityType,
                                                                        sourceEntityId: this.props.bankTransfer.sourceEntityId,
                                                                        transferId: this.props.bankTransfer.id,
                                                                    } as BankTransferRequest
                                                                }
                                                                submitToOwner={this.onSubmitDocuments}
                                                            />
                                                        </CardBody>
                                                    </Card>
                                                </CSSTransitionComponent>
                                            </Container>
                                            <Fieldset legend="Actions">
                                                <Row className="p-2 d-flex justify-content-between flex-wrap">
                                                    {isKYCApproveAllowed() && (
                                                        <Col xs="auto" className="text-center">
                                                            <Button
                                                                label={"Approve"}
                                                                type="button"
                                                                icon="pi pi-check"
                                                                className="btn btn-success rounded-pill"
                                                                disabled={this.state.disableApproveBtn}
                                                                onClick={() => this.onApprove()}
                                                            />
                                                        </Col>
                                                    )}
                                                    {isKYCApproveAllowed() && (
                                                        <Col xs="auto" className="text-center">
                                                            <Button
                                                                label={"Reject"}
                                                                type="button"
                                                                icon="pi pi-times"
                                                                className="btn btn-danger rounded-pill"
                                                                disabled={this.state.disableRejectBtn}
                                                                onClick={() => this.onReject()}
                                                            />
                                                        </Col>
                                                    )}
                                                    {isKYCApproveAllowed() && (
                                                        <Col xs="auto" className="text-center">
                                                            <Button
                                                                label={"Return to Customer"}
                                                                type="button"
                                                                icon="pi pi-check"
                                                                className="btn btn-info rounded-pill"
                                                                disabled={this.state.disableReturnBtn}
                                                                onClick={() => this.onReturn()}
                                                            />
                                                        </Col>
                                                    )}
                                                    {isKYCApproveAllowed() && (
                                                        <Col xs="auto" className="text-center">
                                                            <Button
                                                                label={"Save"}
                                                                type="button"
                                                                icon="pi pi-check"
                                                                className="btn btn-success rounded-pill"
                                                                disabled={this.state.disableSubmitBtn}
                                                                onClick={() => this.onSaveDraft()}
                                                            />
                                                        </Col>
                                                    )}
                                                </Row>
                                            </Fieldset>
                                        </CardBody>
                                    </Card>
                                </Container>
                            </Fragment>
                        </CSSTransition>
                    </TransitionGroup>
                    {this.state.showConfirmDialog && (
                        <Dialog
                            header={this.state.confirmLabel}
                            visible={true}
                            style={{ width: "20vw" }}
                            modal
                            onHide={() => {
                                this.setState({ showConfirmDialog: false });
                            }}
                        >
                            <Container fluid>
                                <CardBody>
                                    {/* <Row>
                                <Col>
                                    <InputTextarea rows={3} cols={90} onChange={(e) => { this.setState({ rejectionReason: e.target.value }) }} />
                                </Col>
                            </Row> */}
                                    <Row>
                                        <Col className="text-right">
                                            <Button
                                                label={"Confirm"}
                                                disabled={false}
                                                type="button"
                                                icon="pi pi-check"
                                                className="p-button-success"
                                                onClick={() => this.onConfirm()}
                                            ></Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Container>
                        </Dialog>
                    )}
                </Fragment>
            </Dialog>
        );
    }
}
