import moment from "moment";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Fieldset } from "primereact/fieldset";
import { Component } from "react";
import { Col } from "reactstrap";
import { IndividualWalletCloseRequest, Wallets } from "../../models/FinancialData";
import classNames from "classnames";
import { merchantWalletApiService } from "../../services/api/merchants/MerchantWalletApiService";
import { Toast } from "primereact/toast";
import ReactLoader from "react-loaders";
import { userWalletApiService } from "../../services/api/users/UserWalletApiService";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { IndividualStatus } from "../../models/User";

interface MerchantWalletsProps {
    Id: number | undefined;
    walletType: WalletType;
    cbsId: number | string | undefined;
}

interface MerchantWalletsState {
    wallets: Wallets[];
    isLoading: boolean;
    isWalletBalanceLoading: boolean,
}

export enum WalletType {
    User,
    Merchant
}

export default class MerchantWalletsComponent extends Component<MerchantWalletsProps, MerchantWalletsState> {

    toast: Toast | null = null;

    constructor(props: MerchantWalletsProps | Readonly<MerchantWalletsProps>) {
        super(props);
        this.state = {
            wallets: [],
            isLoading: true,
            isWalletBalanceLoading: true
        };
    }

    async componentDidMount() {
        await this.loadWallets();
    }

    loadWallets = async () => {
        if (this.props.Id == undefined || this.props.cbsId == undefined || this.props.cbsId == "") {
            this.setState({ isLoading: false });
            return;
        }

        this.setState({ isLoading: true });

        const walletsResult = this.props.walletType == WalletType.Merchant ? await merchantWalletApiService.getWallets(this.props.Id) : await userWalletApiService.getWallets(this.props.Id);

        if (!walletsResult.success) {
            this.setState({ isLoading: false });
            this.toast?.show({ severity: "error", summary: "Error", detail: walletsResult.message, life: 3000 });
            return;
        }

        this.setState({ isLoading: false, wallets: walletsResult.data });

        let wallets = walletsResult.data;

        const balanceResult =  (await Promise.all(wallets.map(item => this.props.walletType == WalletType.Merchant ? merchantWalletApiService.getWalletBalance({ merchantId: this.props.Id!, accountNumber: item.id }) : userWalletApiService.getWalletBalance({ individualId: this.props.Id!, accountNumber: item.id }) )))
            .filter(a => a.success)
            .map(a => a.data);

        wallets = wallets.map(w => {
            w.balance = balanceResult.find(a => a.id == w.id)?.balance ?? 0;
            w.blockedAmount = balanceResult.find(a => a.id == w.id)?.blockedAmount ?? 0;
            w.cbsId = balanceResult.find(a => a.id == w.id)?.cbsId ?? "";

            return w;
        });

        this.setState({ wallets: wallets, isWalletBalanceLoading: false });
    };

    actionBodyTemplate = (rowData: Wallets) => {
        return (
            <>
                <button type="button" className="p-row-editor-cancel p-link" tabIndex={0} onClick={()=> this.confirmCloseWalletMethod(rowData)}>
                    <span className="p-row-editor-cancel-icon pi pi-fw pi-times"></span>
                </button>
            </>
        );
    };

    closeWalletMethod = async (item: Wallets)=>{
        const modal: IndividualWalletCloseRequest = {
            individualId: this.props.Id!,
            walletId: item.id
        }
        const walletsResult =   await userWalletApiService.closeWallet(modal);

        if (!walletsResult.success) {
            this.setState({ isLoading: false });
            this.toast?.show({ severity: "error", summary: "Error",
                detail: (walletsResult.message.includes("Close Mode cannot be blank")?"A wallet balance should be zero before closing it.":walletsResult.message) , life: 3000 });
            return;
        }
        this.toast?.show({ severity: "success", summary: "Action Completed", detail: "Wallet closed", life: 3000 });
        await this.loadWallets();
    };

    confirmCloseWalletMethod = async (item: Wallets) => {
        confirmDialog({
            message: "This action will close the wallet. Are you sure you want to proceed?",
            header: "Close Wallet",
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            accept: () => this.closeWalletMethod(item),
            reject: () => {},
        });
    };

    renderBadge(success: boolean, title: string) {
        return <div className={classNames("badge", "badge-" + (success ? "success" : "danger"))}>{title}</div>;
    }

    render() {

        if(this.state.isLoading) {
            return (
                <Col md={12} lg={12}>
                    <Fieldset legend="Wallet Info" toggleable={true} collapsed={false}>
                        <ReactLoader type="ball-pulse" active />
                    </Fieldset>
                </Col>
            );
        }

        return (
            
            <Col md={12} lg={12}>
                <ConfirmDialog />
                <Toast ref={(el) => (this.toast = el)} position={"bottom-center"} baseZIndex={99999}></Toast>
                <Fieldset legend="Wallet Info" toggleable={true} collapsed={false}>
                    <DataTable value={this.state.wallets} loading={this.state.isLoading}>
                        <Column field="id" header="Id"></Column>
                        <Column field="cbsId" header="CBS ID"></Column>
                        <Column field="ibanAccountNumber" header="IBAN Account Number"></Column>
                        <Column field="publicToken" header="Public Token"></Column>
                        <Column
                            field="default"
                            header="Default"
                            body={(rowData: Wallets) => this.renderBadge(rowData.default, rowData.default ? "Yes" : "No")}
                        ></Column>
                        <Column field="tariff" header="Tariff"></Column>
                        <Column field="balance" header="Balance" body={(rowData: Wallets) => this.state.isWalletBalanceLoading ? <ReactLoader type="ball-pulse" active /> : rowData.balance }></Column>
                        <Column field="currency" header="Currency"></Column>
                        <Column field="blockedAmount" header="Blocked Amount" body={(rowData: Wallets) => this.state.isWalletBalanceLoading ? <ReactLoader type="ball-pulse" active /> : rowData.blockedAmount }></Column>
                        <Column
                            field="timestamp"
                            header="Creation TimeStamp"
                            body={(rowData: Wallets) => <p>{moment(rowData.timestamp).format("dddd")}, {moment(rowData.timestamp).format("MMM DD, YYYY")}</p>}
                        ></Column>
                        <Column
                            header="Close Wallet"
                            body={this.actionBodyTemplate}
                            headerStyle={{ width: "8em", textAlign: "center" }}
                            bodyStyle={{ textAlign: "center", overflow: "visible" }}
                        />
                    </DataTable>
                </Fieldset>
            </Col>
        );
    }
}