import { Result } from "../../../models/Result";
import { httpService } from "../../HttpService";
import { SelectItem } from "../../../models/SelectItem";

class AuthUsersApiService {
    private baseUrl: string = process.env.REACT_APP_AUTH_API_BASE_URL ?? "";
    private baseApiUrl: string = `${this.baseUrl}/api/v1`;
    private path: string = "User";

    getSelectItem = async (): Promise<Result<SelectItem[]>> => await httpService.get<SelectItem[]>(`${this.path}`, { baseURL: this.baseApiUrl });
}

const authUsersApiService = new AuthUsersApiService();

export { authUsersApiService };
