import React from "react";
import ReactDOM from "react-dom";
import "./assets/base.scss";
import MainComponentWrapper from "./MainComponentWrapper";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
    <MainComponentWrapper />,
    document.getElementById("root")
);

serviceWorker.unregister();
