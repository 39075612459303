import React, { Component } from "react";
import { Toast } from "primereact/toast";


import _ from "lodash";

import { Journaling } from "../../models/Journaling";
import JournalingComponent from "../JournalingComponent";
import { SearchRequestBaseModel } from "../../models/SearchRequestModel";

interface JournalingState {
    isLoading: boolean;
    journaling: Journaling[];
}

interface JournalingProps {
    userId: number,
}
export default class UserJournalingComponent extends Component<JournalingProps, JournalingState> {
    toast: Toast | null = null;
    constructor(props : JournalingProps | Readonly<JournalingProps>) {
        super(props);

        this.state = {
            isLoading: false,
            journaling: [],
        };
    }

    async componentDidMount() {
    }

    filterRequest = (searchRequest: SearchRequestBaseModel) => {
        searchRequest.filters = searchRequest.filters.filter(f => f.columnName.toLocaleLowerCase() != "madeby");
        searchRequest.filters.push({ columnName: "madeBy", operator: "equals", value: this.props.userId.toString() });
    };

    render() {
        return (
            <JournalingComponent filterFunction={this.filterRequest} />
        );
    }
}
