import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import React, { Component, Fragment } from "react";
import { Col, Container, FormGroup, Label, Row } from "reactstrap";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { MerchantContact } from "../../models/MerchantModel";
import { SelectItem } from "../../models/SelectItem";
import "./tariffDetails.scss";
import { CSSTransitionComponent } from "../../components/CSSTransition";
import { merchantContactApiService } from "../../services/api/merchants/MerchantContactsApiService";
import { Toast } from "primereact/toast";
import { Result } from "../../models/Result";

interface AddMerchantContactProps {
    onHide: (contact?: MerchantContact) => void;
    contact: MerchantContact;
    types: SelectItem[];
    merchantId: number;
    localMode: boolean
}

interface AddMerchantContactState {
    contact: MerchantContact;
    isSaving: boolean;
}

export default class AddMerchantContact extends Component<AddMerchantContactProps, AddMerchantContactState> {
    toast: Toast | null = null;

    constructor(props: AddMerchantContactProps | Readonly<AddMerchantContactProps>) {
        super(props);

        this.state = {
            contact: this.props.contact,
            isSaving: false,
        };
    }

    updateValue = (fieldName: string, value: string) => {
        const merchantContactDetails : any  = this.state.contact;
        merchantContactDetails[fieldName] = value;
        this.setState({ contact: merchantContactDetails });
    };

    onSave = async () => {

        let result: Result<MerchantContact>;

        if(this.props.localMode) {
            const emptyKeys = Object.keys(this.state.contact).filter(key => {
                const value = (this.state.contact as any)[key];
                return value === null || value === "";
            });

            if (emptyKeys.length > 0) {
                const message = `The following fields are required: ${emptyKeys.join(",  ")  }`;
                this.toast?.show({ severity: "error", summary: "Error", detail: message, life: 3000 });
                return;
            }
      
            result = { success: true, data: null!, message: "" } as Result<MerchantContact>; 
        }
        else 
        {
            result = this.state.contact.id == 0 ?
                await merchantContactApiService.create(this.state.contact) :
                await merchantContactApiService.update(this.state.contact);
        }

        if (result.success)
        {
            this.setState({ isSaving: false });
            this.props.onHide(this.state.contact);
        }
        else
        {
            this.toast?.show({ severity: "error", summary: "Error", detail: result.message, life: 3000 });
        }
    };

    render() {
        if (this.state.contact == null) {
            return "";
        }
        return (
            <Dialog header="Contact" visible={true} style={{ width: "60vw" }} onHide={this.props.onHide}>
                <CSSTransitionComponent>
                    <Fragment>
                        <Toast ref={(el) => this.toast = el} />
                        <Container fluid>
                            <Row className="d-flex justify-content-center">
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>Contact Name</Label>
                                        <InputText
                                            value={this.state.contact.name}
                                            className={"form-control"}
                                            type="text"
                                            onChange={(e) => this.updateValue("name", e.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>Contact Phone</Label>
                                        <InputText
                                            value={this.state.contact.phone}
                                            className={"form-control"}
                                            type="text"
                                            onChange={(e) => this.updateValue("phone", e.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className="d-flex justify-content-center">
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>Contact Email</Label>
                                        <InputText
                                            value={this.state.contact.email}
                                            className={"form-control"}
                                            type="text"
                                            onChange={(e) => this.updateValue("email", e.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup style={{ display: "grid" }}>
                                        <Label>Contact Types</Label>
                                        <Dropdown
                                            value={this.state.contact.type}
                                            options={this.props.types}
                                            onChange={(e) => this.updateValue("type", e.target.value)}
                                            optionLabel="label"
                                            placeholder="Select a Contact Type"
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <div className="align-right" style={{ padding: "10px" }}>
                                <Button label="Submit" icon="pi pi-check" loading={this.state.isSaving} onClick={this.onSave} />
                            </div>
                        </Container>
                    </Fragment>
                </CSSTransitionComponent>
            </Dialog>
        );
    }
}
