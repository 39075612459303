import React from "react";
import { Router } from "react-router-dom";

// https://stackoverflow.com/a/70095819/3395204

interface ExtendedRouterProps {
    children : React.ReactNode
    history: any
}

function ExtendedRouter({children, history}: ExtendedRouterProps): JSX.Element {
    const [state, setState] = React.useState({
        action: history.action,
        location: history.location,
    });

    React.useLayoutEffect(() => history.listen(setState), [history]);

    return (
        <Router
            location={state.location}
            navigationType={state.action}
            navigator={history}>
            {children}
        </Router>
    );
}

export default ExtendedRouter;