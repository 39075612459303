import React, { Component, Fragment } from "react";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Col, Container, Label, Row } from "reactstrap";
import { RequestLog } from "../../models/Journaling";
import moment from "moment";
import { Fieldset } from "primereact/fieldset";
import Loader from "../../components/Loader";
import ReactJson from "react-json-view";

interface RequestLogDetailsDialogProps {
    isVisible: boolean;
    onHide: () => void;
    requestLog: RequestLog;
    editable: boolean;
    userEmail: string | undefined;
    merchantName: string | undefined;
}

interface RequestLogDetailsDialogState {
    isDetailVisible: boolean;
    requestLog: RequestLog;
}

export default class RequestLogDetailsDialog extends Component<RequestLogDetailsDialogProps, RequestLogDetailsDialogState> {
    toast: Toast | null = null;

    constructor(props: RequestLogDetailsDialogProps | Readonly<RequestLogDetailsDialogProps>) {
        super(props);

        this.state = {
            isDetailVisible: false,
            requestLog: this.props.requestLog,
        };
    }

    toggleAdvancedMode = () => {
        this.setState({ isDetailVisible: !this.state.isDetailVisible });
    };

    render() {
        if (this.state.isDetailVisible) {
            return (
                <>
                    <Toast ref={(el) => (this.toast = el)} />
                    <Loader />
                </>
            );
        }

        if (this.state.requestLog == null) {
            return "";
        }

        if (!this.props.isVisible) {
            return "";
        }

        return (
            <Dialog header="Request Log" visible={this.props.isVisible} style={{ width: "70vw" }} onHide={this.props.onHide}>
                <TransitionGroup component="div" appear={true} exit={false} enter={false}>
                    <CSSTransition classNames="TabsAnimation" timeout={1500}>
                        <Fragment>
                            <Toast ref={(el) => (this.toast = el)} position={"bottom-center"} baseZIndex={99999}></Toast>
                            <Container fluid>
                                <Fieldset legend="">
                                    {renderDualRow(
                                        "Controller Name",
                                        this.state.requestLog.controllerName,
                                        "Action Name",
                                        this.state.requestLog.actionName
                                    )}
                                    {renderDualRow(
                                        "Made On",
                                        moment.utc(this.state.requestLog.madeOn).local().format("DD/MM/YYYY hh:mm:ss A"),
                                        "Application",
                                        this.state.requestLog.application
                                    )}
                                    {renderDualRow(
                                        "User",
                                        this.props.userEmail || this.state.requestLog.userId,
                                        "Made From IP Address",
                                        this.state.requestLog.madeFromIPAddress
                                    )}
                                    {renderDualRow(
                                        "Merchant",
                                        this.props.merchantName || this.state.requestLog.merchantId,
                                        "Status Code",
                                        this.state.requestLog.statusCode
                                    )}
                                </Fieldset>
                                <h5></h5>
                                <Fieldset legend="Data Info">
                                    {renderDualRow(
                                        "Request",
                                        this.state.requestLog.request == "null" ? "" : this.state.requestLog.request,
                                        "Response",
                                        this.state.requestLog.response,
                                        true
                                    )}
                                    {/* {renderDualRow(
                                        "Response",
                                        this.state.requestLog.response,
                                        null, "", true
                                    )} */}
                                </Fieldset>
                            </Container>
                        </Fragment>
                    </CSSTransition>
                </TransitionGroup>
            </Dialog>
        );
    }
}

export function isValidJSON(obj: any) {
    const parsed = ParseSafe(obj);
    return toType(parsed) == "object" || toType(parsed) == "array";
}

export function toType(obj: any) {
    let type = getType(obj);
    // some extra disambiguation for numbers
    if (type === "number") {
        if (isNaN(obj)) {
            type = "nan";
        } else if ((obj | 0) != obj) {
            //bitwise OR produces integers
            type = "float";
        } else {
            type = "integer";
        }
    }
    return type;
}

export function ParseSafe(obj: string) {
    try {
        return JSON.parse(obj);
    } catch (error) {
        return null;
    }
}

function getType(obj: any) {
    return ({} as any).toString
        .call(obj)
        .match(/\s([a-zA-Z]+)/)[1]
        .toLowerCase();
}

export function renderDualRow(title1: string, val1: string, title2: string | null = null, val2: string = null as any, prettyFormat: boolean = false) {
    return (
        <Row>
            <Col md={6}>
                <Row>
                    <Col md={6}>
                        <Label>
                            <b>{title1} :</b>
                        </Label>
                    </Col>
                    {val1 && (
                        <Col md={12}>
                            {prettyFormat && isValidJSON(val1) ? (
                                <ReactJson src={JSON.parse(val1)} />
                            ) : (
                                <Label style={{ wordBreak: "break-all" }}>{val1}</Label>
                            )}
                        </Col>
                    )}
                </Row>
            </Col>
            {title2 != null && (
                <Col md={6}>
                    <Row>
                        <Col md={6}>
                            <Label>
                                <b>{title2} :</b>
                            </Label>
                        </Col>
                        {val2 && (
                            <Col md={12}>
                                {prettyFormat && isValidJSON(val2) ? (
                                    <ReactJson src={JSON.parse(val2)} />
                                ) : (
                                    <Label style={{ wordBreak: "break-all" }}>{val2}</Label>
                                )}
                            </Col>
                        )}
                    </Row>
                </Col>
            )}
        </Row>
    );
}
