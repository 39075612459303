import { Result } from "../models/Result";
import { httpService } from "./HttpService";

class FileService {
   
    baseApiUrl: string = process.env.REACT_APP_FILE_API_BASE_URL ?? "";

    GetFile = async (fileStorageId : string) : Promise<Result<string>> => {
        return await httpService.get<string>(`/api/v1/File/${fileStorageId}`, { baseURL: this.baseApiUrl });
    };
}

const fileService = new FileService();

export { fileService };