import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import bgn from "../images/bgn.webp";
import eur from "../images/eur.webp";
import usd from "../images/usd.webp";
import ReactLoader from "react-loaders";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";

interface HoveredWidgetProps {
    isLoading?: boolean | null,
    title: string,
    subHeading?: string | null,
    description?: string | null,
    arrowIcon?: IconDefinition | null,
    icon: string | IconDefinition,
    textClass: string,
    onClick?: () => void | null,
    small?: boolean | null,
    walletsWidget?: boolean
}

export class HoveredWidget extends Component<HoveredWidgetProps, Record<string, never>> {

    curenciesMap = new Map<string, string>([
        ["bgn", bgn],
        ["eur", eur],
        ["usd", usd],
    ]);

    renderFlag = () => {
        const iconName = typeof this.props.icon === "string" ? (this.props.icon as string ?? "").toLowerCase() : "";
        if(this.curenciesMap.has(iconName)) {
            return <img role="presentation" alt="" src={this.curenciesMap.get(iconName)} width={50} style={{ borderRadius: "100px", height: "50px", border: "0.5px solid #dbdbdb" }} />;
        }
        return typeof this.props.icon === "string" ?
            <i className={`${this.props.icon} text-${this.props.textClass}`} /> :
            <FontAwesomeIcon size="2x" icon={this.props.icon as unknown as IconProp} className={`text-${this.props.textClass}`} style={{ margin: "auto", display: "block" }} />;
    };

    render() {
        return <div className={`widget-chart ${this.props.walletsWidget ? "" : "widget-chart-hover"}`} onClick={() => this.props.onClick && this.props.onClick()}>
            <div className="icon-wrapper rounded-circle">
                <div className={`icon-wrapper-bg bg-${this.props.textClass}`} />
                {this.renderFlag()}
            </div>
            {this.props.isLoading == true ? <ReactLoader type="ball-pulse" active /> : <div className={`widget-numbers ${this.props.small ? "widget-numbers-small" : ""}`}>{this.props.title}</div>}
            <div className="widget-subheading">{this.props.subHeading}</div>
            <div className={`widget-description text-${this.props.textClass}`}>
                {this.props.arrowIcon && <FontAwesomeIcon icon={this.props.arrowIcon as unknown as IconProp} />}
                <span className="pl-1">{this.props.description}</span>
            </div>
        </div>;

    }
}


