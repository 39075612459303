import { MerchantContact } from "../../../models/MerchantModel";
import { Result } from "../../../models/Result";
import { httpService } from "../../HttpService";
import { ICrudApiService } from "../../ICrudApiService";
import { SelectItem } from "../../../models/SelectItem";

class MerchantContactsApiService implements ICrudApiService<MerchantContact> {
    path: string = "merchantContact";

    getAll = async (merchantId?: number): Promise<Result<MerchantContact[]>> => await httpService.get<MerchantContact[]>(`${this.path}/${merchantId}/all`);

    get = async (Id: number): Promise<Result<MerchantContact>> => await httpService.get<MerchantContact>(`/${this.path}/${Id}`);

    create = async (contact: MerchantContact): Promise<Result<MerchantContact>> => await httpService.post<MerchantContact>(`/${this.path}`, contact);

    update = async (contact: MerchantContact): Promise<Result<MerchantContact>> => await httpService.put<MerchantContact>(`/${this.path}`, contact);

    delete = async (entityId: number): Promise<Result<void>> => await httpService.delete<void>(`/${this.path}/${entityId}`);

    getContactTypes = async (): Promise<Result<SelectItem[]>> => await httpService.get<SelectItem[]>(`/${this.path}/contactTypes`);
}

const merchantContactApiService = new MerchantContactsApiService();

export { merchantContactApiService };
