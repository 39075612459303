import { Result } from "../../../models/Result";
import { httpService } from "../../HttpService";
import { UserDevice } from "../../../models/UserDevice";

class UserDevicesApiService {
    path: string = "IndividualDevice";

    getAll = async (id: number): Promise<Result<UserDevice[]>> => await httpService.get<UserDevice[]>(`${this.path}/${id}`);
}

const userDevicesApiService = new UserDevicesApiService();

export { userDevicesApiService };
