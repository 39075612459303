import React, { Component, Fragment } from "react";
import { CardHeader, Container, Card, CardBody, Row, Col, Label, FormGroup } from "reactstrap";
import PageTitle from "../../Layout/AppMain/PageTitle";

import { OperatorDataModel } from "../../models/User";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { classNames } from "primereact/utils";
import { InputText } from "primereact/inputtext";

import { Toast } from "primereact/toast";

import "./userprofile.scss";
import { SelectItem } from "../../models/SelectItem";
import { utils } from "../../utils/utils";
import { ProfileImage } from "../../components/Image/ProfileImage";
import { fileService } from "../../services/FileService";
import { Merchant, MerchantGroup } from "../../models/MerchantModel";

import _ from "lodash";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect";
import { RoleModel } from "../../models/Role";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { faUserGear } from "@fortawesome/free-solid-svg-icons";
import { storageService } from "../../services/StorageService";
import { operatorsApiService } from "../../services/api/users/OperatorApiService";
import { Tooltip } from "primereact/tooltip";

interface ProfileProps {
    user: OperatorDataModel;
    merchants: Merchant[];
    roles: RoleModel[];
    showMerchant: boolean;
    showRoles: boolean;
    showPassword: boolean;
    allowResetPhoneAndMobile: boolean;
    isEditMode: boolean;
    onMerchantChange?: (merchantId: number) => void;
    onUpdate: (user: OperatorDataModel) => void;
    onSubmit: () => void;
}
const Roles = {
    SUPER_ADMIN: "Super Admin",
    TennAdmin: "10n Admin",
    ComplianceOfficer: "Compliance Officer",
    ComplianceOfficerApprover: "Compliance Officer Approver",
};
enum UserStatus {
    Active = 1,
    Inactive = 2,
}
interface ProfileState {
    isLoading: boolean;
    showUserRoles: boolean;
    allowResetPhoneAndMobile: boolean;
    allowDisableUser: boolean;
    isSuperAdmin: boolean;
    userRoles: RoleModel[];
}

export default class ProfileComponent extends Component<ProfileProps, ProfileState> {
    toast: Toast | null = null;

    constructor(props: ProfileProps) {
        super(props);

        this.state = {
            isLoading: false,
            showUserRoles: false,
            allowResetPhoneAndMobile: false,
            allowDisableUser: false,
            isSuperAdmin: false,
            userRoles: [],
        };
    }

    componentDidMount(): void {
        this.getUserRoles();
        this.loadUserRoles();
        this.loadImage();
    }

    getUserRoles = async () => {
        const roles = storageService.getRoles();
        const superadmin = roles.includes(Roles.SUPER_ADMIN);

        this.setState({ isLoading: false, allowDisableUser: superadmin, isSuperAdmin: superadmin });
    };
    loadUserRoles = async () => {
        const filteredRoles = this.props.roles.filter((role) => this.props.user.userRoles.includes(role.id));
        this.setState({ isLoading: false, userRoles: filteredRoles, showUserRoles: true });
    };

    loadImage = async () => {
        if (this.props.user?.photoStorageId == null) {
            return;
        }
        const photoResult = await fileService.GetFile(this.props.user?.photoStorageId);

        if (!photoResult.success) {
            this.toast?.show({
                severity: "error",
                summary: "Error",
                detail: "Could not load User Photo. Please contact your administrator",
                life: 3000,
            });
            return;
        }

        const user = this.props.user;
        user.photo = photoResult.data;

        this.props.onUpdate(user);
    };

    getSelectMerchantGroupItems = (options: MerchantGroup[]): SelectItem<number>[] => {
        return options.map(
            (data) =>
                ({
                    label: data.name,
                    value: data.id,
                } as SelectItem<number>)
        );
    };

    getVerificationClassName = (param: boolean): string => {
        return param === true ? "success" : "danger";
    };

    updateValue = (fieldName: string, value: string) => {
        const user = this.props.user;

        (user as any)[fieldName] = value;

        this.props.onUpdate(user);
    };

    showToast = (message: string) => {
        this.toast?.show({ severity: "success", summary: "Action Completed", detail: message });
    };

    renderBadge(success: boolean, title: string) {
        return <div className={classNames("badge", "badge-" + (success ? "success" : "danger"))}>{title}</div>;
    }

    onImageSelected = async (image: File) => {
        const userDetails = this.props.user;
        if (utils.isValidImage(image)) {
            userDetails!.photo = (await utils.convertFileToBase64(image)) as string;
            this.props.onUpdate(userDetails!);
            return;
        }
        this.toast?.show({ severity: "error", summary: "File Format", detail: "Format not supported! Only Image files are allowed." });
        return false;
    };

    fileInput = (input: boolean) => {
        if (!input) {
            this.toast?.show({ severity: "error", summary: "File Name", detail: "File Name Exceeds 128 Characters.", life: 3000 });
            return;
        }
    };

    getImageSource = (): string => {
        if (!this.props.user?.photo) {
            return this.defaultImage();
        }
        return utils.appendBase64Data(this.props.user.photo!);
    };

    defaultImage = () => {
        return "/dummy.jpg";
    };

    onFieldUpdate = (fieldName: string, value: any) => {
        const userData = this.props.user;
        (userData as any)[fieldName] = value;
        this.props.onUpdate(userData!);
    };

    deactivateOperator = async (id: number) => {
        this.setState({ isLoading: true });
        const result = await operatorsApiService.deactivateoperator(id);

        if (!result.success) {
            this.setState({ isLoading: false });
            this.toast?.show({ severity: "error", summary: "Error", detail: result.message, life: 3000 });
            return;
        }
        const userProfileResult = await operatorsApiService.getById(this.props.user.id!);

        if (!userProfileResult.success) {
            this.setState({ isLoading: false });
            this.toast?.show({ severity: "error", summary: "Error", detail: userProfileResult.message, life: 3000 });
            return;
        }
        const updatedUser = userProfileResult.data;
        this.setState({ isLoading: false });
        this.props.onUpdate(updatedUser);
        this.toast?.show({ severity: "success", summary: "Action Completed", detail: "Account Deactivated." });
    };

    activateoperator = async (id: number) => {
        this.setState({ isLoading: true });
        console.log(id);
        const result = await operatorsApiService.activateoperator(id);

        if (!result.success) {
            this.setState({ isLoading: false });
            this.toast?.show({ severity: "error", summary: "Error", detail: result.message, life: 3000 });
            return;
        }
        const userProfileResult = await operatorsApiService.getById(this.props.user.id!);

        if (!userProfileResult.success) {
            this.setState({ isLoading: false });
            this.toast?.show({ severity: "error", summary: "Error", detail: userProfileResult.message, life: 3000 });
            return;
        }
        const updatedUser = userProfileResult.data;
        this.setState({ isLoading: false });
        this.props.onUpdate(updatedUser);
        this.toast?.show({ severity: "success", summary: "Action Completed", detail: "Account Activated." });
    };
    render() {
        return (
            <Fragment>
                <TransitionGroup component="div" appear={true} exit={false} enter={false}>
                    <CSSTransition classNames="TabsAnimation" timeout={1500}>
                        <PageTitle heading="User Profile" icon={faUserGear} />
                    </CSSTransition>
                    <CSSTransition classNames="TabsAnimation" timeout={1500}>
                        <Fragment>
                            <Toast ref={(el) => (this.toast = el)} position={"bottom-center"} baseZIndex={99999}></Toast>
                            <Container fluid>
                                <div>
                                    <Row>
                                        <Col md={12} lg={12}>
                                            <Card className="mb-3">
                                                <CardHeader className="card-header-tab">
                                                    <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                                                        <i className="header-icon lnr-laptop-phone mr-3 text-muted opacity-6"> </i>
                                                        User Details
                                                    </div>
                                                </CardHeader>
                                                <CardBody>
                                                    <Row>
                                                        <Col md="3" lg="3" sm="12" style={{ padding: "2em", textAlign: "center" }}>
                                                            <ProfileImage
                                                                imageStyle={{ width: "100%", maxHeight: "250px" }}
                                                                className="rounded-circle"
                                                                src={this.getImageSource()}
                                                                alt=""
                                                                onImageSelected={this.onImageSelected}
                                                                fileInput={this.fileInput}
                                                            />
                                                        </Col>
                                                        <Col md={9} lg={9} sm="12">
                                                            <Row>
                                                                <Col md={6}>
                                                                    <FormGroup>
                                                                        <Label for="firstName">First Name</Label>
                                                                        <InputText
                                                                            value={this.props.user.firstName}
                                                                            className={"form-control"}
                                                                            id="firstName"
                                                                            onChange={(e) => this.onFieldUpdate("firstName", e.target.value)}
                                                                            disabled={!this.state.isSuperAdmin}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <FormGroup>
                                                                        <Label for="lastName">Last Name</Label>
                                                                        <InputText
                                                                            value={this.props.user.lastName}
                                                                            className={"form-control"}
                                                                            id="lastName"
                                                                            onChange={(e) => this.onFieldUpdate("lastName", e.target.value)}
                                                                            disabled={!this.state.isSuperAdmin}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={6}>
                                                                    <FormGroup>
                                                                        <Label for="email">Email Address</Label>
                                                                        <InputText
                                                                            value={this.props.user.email}
                                                                            className={"form-control"}
                                                                            type="email"
                                                                            name="email"
                                                                            id="email"
                                                                            onChange={(e) => this.onFieldUpdate("email", e.target.value)}
                                                                            disabled={!this.state.isSuperAdmin}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col
                                                                    md={3}
                                                                    style={{ display: "flex", alignItems: "center", padding: "15px 10px 5px 10px" }}
                                                                >
                                                                    <div
                                                                        className={classNames(
                                                                            "badge",
                                                                            "badge-" + this.getVerificationClassName(this.props.user.isEmailVerified)
                                                                        )}
                                                                    >
                                                                        {this.props.user.isEmailVerified ? "Verified" : "Not Verified"}
                                                                    </div>
                                                                </Col>
                                                                {!this.props.allowResetPhoneAndMobile && this.props.isEditMode && (
                                                                    <Col md={3} style={{ padding: "35px 10px" }}>
                                                                        <a
                                                                            href="#"
                                                                            onClick={() =>
                                                                                this.showToast("Email to Reset Email Address Sent Successfully")
                                                                            }
                                                                        >
                                                                            Reset Email Address
                                                                        </a>
                                                                    </Col>
                                                                )}
                                                            </Row>
                                                            <Row>
                                                                <Col md={6}>
                                                                    <FormGroup>
                                                                        <Label for="mobile">Mobile</Label>
                                                                        <InputText
                                                                            value={this.props.user.phoneNumber}
                                                                            className={"form-control"}
                                                                            type="text"
                                                                            name="phoneNumber"
                                                                            id="phoneNumber"
                                                                            onChange={(e) => this.onFieldUpdate("phoneNumber", e.target.value)}
                                                                            disabled={!this.state.isSuperAdmin}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col
                                                                    md={3}
                                                                    style={{ display: "flex", alignItems: "center", padding: "15px 10px 5px 10px" }}
                                                                >
                                                                    <div
                                                                        className={classNames(
                                                                            "badge",
                                                                            "badge-" +
                                                                                this.getVerificationClassName(this.props.user.isPhoneNumberVerified)
                                                                        )}
                                                                    >
                                                                        {this.props.user.isPhoneNumberVerified === true ? "Verified" : "Not Verified"}
                                                                    </div>
                                                                </Col>
                                                                <Col md={3} style={{ padding: "35px 10px" }}>
                                                                    {!this.props.allowResetPhoneAndMobile && this.props.isEditMode && (
                                                                        <a
                                                                            href="#"
                                                                            onClick={() =>
                                                                                this.showToast("Email to Reset Mobile Phone Sent Successfully")
                                                                            }
                                                                        >
                                                                            Reset Mobile
                                                                        </a>
                                                                    )}
                                                                    <br></br>

                                                                    {this.state.allowDisableUser &&
                                                                        this.props.isEditMode &&
                                                                        this.props.user.status == UserStatus.Active && (
                                                                            <a href="#" onClick={() => this.deactivateOperator(this.props.user.id)}>
                                                                                Deactivate User
                                                                            </a>
                                                                        )}
                                                                    {this.state.allowDisableUser &&
                                                                        this.props.isEditMode &&
                                                                        this.props.user.status == UserStatus.Inactive && (
                                                                            <a href="#" onClick={() => this.activateoperator(this.props.user.id)}>
                                                                                Activate User
                                                                            </a>
                                                                        )}
                                                                </Col>
                                                            </Row>

                                                            <Row>
                                                                {this.props.showMerchant && (
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="merchant">Merchant</Label>
                                                                            <div>
                                                                                <Dropdown
                                                                                    style={{ width: "100%" }}
                                                                                    value={this.props.user.merchantId}
                                                                                    options={this.props.merchants}
                                                                                    onChange={(e) => {
                                                                                        this.onFieldUpdate("merchantId", e.target.value);
                                                                                        this.props.onMerchantChange &&
                                                                                            this.props.onMerchantChange(e.target.value as number);
                                                                                    }}
                                                                                    optionLabel="name"
                                                                                    optionValue="id"
                                                                                    filter
                                                                                    showClear
                                                                                    filterBy="name"
                                                                                    placeholder="Select Merchant"
                                                                                />
                                                                            </div>
                                                                        </FormGroup>
                                                                    </Col>
                                                                )}
                                                            </Row>
                                                            <Row>
                                                                {this.props.showPassword && (
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="password">Password</Label>
                                                                            <div>
                                                                                <InputText
                                                                                    value={this.props.user.password}
                                                                                    className={"form-control"}
                                                                                    type="password"
                                                                                    name="password"
                                                                                    id="password"
                                                                                    onChange={(e) => this.onFieldUpdate("password", e.target.value)}
                                                                                />
                                                                            </div>
                                                                        </FormGroup>
                                                                    </Col>
                                                                )}
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                            <Card className="mb-3">
                                                <CardHeader className="card-header-tab">
                                                    <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                                                        <i className="header-icon lnr-laptop-phone mr-3 text-muted opacity-6"> </i>
                                                        Roles
                                                    </div>
                                                </CardHeader>
                                                <CardBody>
                                                    {this.props.showRoles && (
                                                     this.state.isSuperAdmin && <MultiSelect
                                                            value={this.props.user.userRoles}
                                                            options={this.props.roles}
                                                            onChange={(e) => {
                                                                const user = this.props.user;
                                                                user!.userRoles = e.value;
                                                                this.props.onUpdate(user);
                                                            }}
                                                            optionLabel="name"
                                                            optionValue="id"
                                                            placeholder="Select Roles"
                                                            filter
                                                            className="multiselect-custom mb-4"
                                                        />
                                                    )}
                                                    {this.state.showUserRoles && (
                                                        <DataTable loading={this.state.isLoading} value={this.state.userRoles}>
                                                            <Column field="name" header="Roles" headerStyle={{ display: "none" }} />
                                                        </DataTable>
                                                    )}
                                                    {this.state.isSuperAdmin && 
                                                    <div style={{ padding: "15px" }} className="align-right">
                                                    <Button
                                                        type="button"
                                                        label="Submit"
                                                        icon="pi pi-check"
                                                        className="p-button"
                                                        onClick={this.props.onSubmit}
                                                    ></Button>
                                                </div>
                                                }
                                                    
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </div>
                            </Container>
                        </Fragment>
                    </CSSTransition>
                </TransitionGroup>
            </Fragment>
        );
    }
}
