import React, { Component, Fragment } from "react";
import { Container } from "reactstrap";
import { Toast } from "primereact/toast";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import _ from "lodash";
import moment from "moment";
import { Column } from "primereact/column";
import { kycSubmissionApiService } from "../../services/api/Kyc/KycSubmissionApiService";
import { KycSubmission } from "../../models/KycSubmission";
import { Button } from "primereact/button";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { SearchRequestBaseModel, SortOrderType } from "../../models/SearchRequestModel";
import ExtendedDataTable from "../../components/ExtendedDataTable";
import { dateFilterTemplate, numberFilterMatchModeOptions, stringFilterMatchModeOptions } from "../Paginator";
import KycIndividualModal from "./KycIndividualModal";
import { utils } from "../../utils/utils";
import { fileService } from "../../services/FileService";
import saveAs from "file-saver";
import JSZip from "jszip";

interface PendingKycSubmissonsState {
    isLoading: boolean;
    kycSubmission: KycSubmission[];
    viewKycSubmissionModal: boolean;
    selectedSubmission: KycSubmission | null;
    totalRecords: number;
}

export default class PendingKycSubmissons extends Component<{}, PendingKycSubmissonsState> {
    toast: Toast | null = null;
    private dataTable: ExtendedDataTable | null = null;

    constructor(props: {}) {
        super(props);

        this.state = {
            isLoading: false,
            kycSubmission: [],
            viewKycSubmissionModal: false,
            selectedSubmission: null,
            totalRecords: 0,
        };
    }

    loadSubmissionPendingReviewData = async (searchRequest: SearchRequestBaseModel) => {
        this.setState({ isLoading: true });

        searchRequest.filters = utils.manageDataTableFilters(searchRequest.filters);

        const submissionResult = await kycSubmissionApiService.getAllPendingReview(searchRequest);

        this.setState({ isLoading: false });

        if (!submissionResult.success) {
            this.toast?.show({ severity: "error", summary: "Error", detail: submissionResult.message, life: 3000 });
            return;
        }

        this.setState({ kycSubmission: submissionResult.data, totalRecords: submissionResult.totalCount });
    };

    onActionButtonClick = (rowData: KycSubmission) => {
        confirmDialog({
            message: "This action will start the review and is irreversible. Are you sure you want to proceed?",
            header: "Start Review",
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            accept: () => this.startReviewing(rowData),
            reject: () => {},
        });
    };
    downloadDocuments = async (submissionId: number) => {
        this.setState({ isLoading: true });

        const documentsResult = await kycSubmissionApiService.getDocuments(submissionId);

        if (!documentsResult.success) {
            this.setState({ isLoading: false });
            this.toast?.show({ severity: "error", summary: "Error", detail: documentsResult.message, life: 3000 });
            return;
        }

        const zip = new JSZip();
        const photoZip = zip.folder("documents");

        const data = documentsResult.data;
        for (let i = 0; i < data.length; i++) {
            const result = data[i];
            await this.onDownloadFromFileStorage(result.fileStorageId, result.fileName, photoZip);
        }
        /// waits for "await DownloadFileFromS3, then executes the save as which saves the zipped folder created in "DownloadFileFromS3"
        const content = await zip.generateAsync({ type: "blob" });
        saveAs(content, "documents");

        this.setState({ isLoading: false });
    };
    onDownloadFromFileStorage = async (fileStorageId: string, secondaryLabel: string, photoZip: any): Promise<void> => {
        const imagePath = await fileService.GetFile(fileStorageId);

        if (!imagePath.success) {
            this.setState({ isLoading: false });
            this.toast?.show({ severity: "error", summary: "Error", detail: imagePath.message, life: 3000 });
            return;
        }

        photoZip.file(secondaryLabel, imagePath.data, { base64: true });
    };
    startReviewing = async (row: KycSubmission) => {
        const result = await kycSubmissionApiService.transitionToUnderReview(row.id);

        if (!result.success) {
            this.toast?.show({ severity: "error", summary: "Error", detail: result.message, life: 3000 });
            return;
        }

        this.setState({ viewKycSubmissionModal: true, selectedSubmission: row });
    };

    actionBodyTemplate = (row: KycSubmission) => {
        return <Button label="Review" type="button" className="p-button p-button-warning" onClick={() => this.onActionButtonClick(row)}></Button>;
    };

    toggleKycSubmissionModalView = () => {
        this.setState({ viewKycSubmissionModal: false });
        this.loadSubmissionPendingReviewData({
            pageNumber: this.dataTable?.state.page!,
            pageSize: this.dataTable?.state.pageRowCount!,
            filters: [],
            sortings: [],
        });
    };

    render() {
        return (
            <Fragment>
                <TransitionGroup appear={true} exit={false} enter={false}>
                    <CSSTransition classNames="TabsAnimation" timeout={1500}>
                        <Fragment>
                            <Toast ref={(el) => (this.toast = el)} position={"bottom-center"} baseZIndex={99999}></Toast>
                            {
                                this.state.viewKycSubmissionModal && (
                                    <KycIndividualModal onHide={this.toggleKycSubmissionModalView} submission={this.state.selectedSubmission!} />
                                )
                                // <KycSubmissionModal
                                //     onHide={this.toggleKycSubmissionModalView}
                                //     submission={this.state.selectedSubmission!}
                                // />
                            }
                            <Container fluid>
                                <ConfirmDialog />
                                <ExtendedDataTable
                                    value={this.state.kycSubmission}
                                    totalRecords={this.state.totalRecords}
                                    onChange={this.loadSubmissionPendingReviewData}
                                    loading={this.state.isLoading}
                                    ref={(ref) => (this.dataTable = ref)}
                                    defaultSortOrder={SortOrderType.Descending}
                                    defaultSortField="submissionTimeStamp"
                                >
                                    <Column field="id" header="Submission ID" filterMatchModeOptions={numberFilterMatchModeOptions}></Column>
                                    <Column
                                        header="End User First Name"
                                        field="endUserFirstName"
                                        body={(rowData: KycSubmission) => <span>{rowData.endUserFirstName}</span>}
                                        filterMatchModeOptions={stringFilterMatchModeOptions}
                                    ></Column>
                                    <Column
                                        field="merchantName"
                                        header="Merchant Name"
                                        sortable
                                        filterMatchModeOptions={stringFilterMatchModeOptions}
                                    ></Column>
                                    <Column
                                        header="End User Last Name"
                                        field="endUserLastName"
                                        body={(rowData: KycSubmission) => <span>{rowData.endUserLastName}</span>}
                                        filterMatchModeOptions={stringFilterMatchModeOptions}
                                    ></Column>
                                    <Column
                                        field="endUserPhone"
                                        header="End User Phone"
                                        sortable
                                        filterMatchModeOptions={stringFilterMatchModeOptions}
                                    ></Column>
                                    <Column
                                        field="endUserEmail"
                                        header="End User Email"
                                        sortable
                                        filterMatchModeOptions={stringFilterMatchModeOptions}
                                    ></Column>
                                    <Column field="reason" header="Risk reason" filterMatchModeOptions={stringFilterMatchModeOptions}></Column>
                                    <Column
                                        field="submissionTimeStamp"
                                        header="Submission TimeStamp"
                                        body={(rowData: KycSubmission) => (
                                            <p>{moment.utc(rowData.submissionTimeStamp).local().format("DD/MM/YYYY hh:mm:ss A")}</p>
                                        )}
                                        filterMatchModeOptions={numberFilterMatchModeOptions}
                                        dataType="date"
                                        filterElement={dateFilterTemplate}
                                    />
                                    <Column
                                        body={(rowData: KycSubmission) => (
                                            <Button
                                                label="Download"
                                                type="button"
                                                className="p-button"
                                                disabled={rowData.documentsCount === 0}
                                                onClick={() => this.downloadDocuments(rowData.id)}
                                            />
                                        )}
                                        header="Uploaded Files"
                                    ></Column>
                                    <Column
                                        header="Action"
                                        body={this.actionBodyTemplate}
                                        headerStyle={{ width: "8em", textAlign: "center" }}
                                        bodyStyle={{ textAlign: "center", overflow: "visible" }}
                                    />
                                </ExtendedDataTable>
                            </Container>
                        </Fragment>
                    </CSSTransition>
                </TransitionGroup>
            </Fragment>
        );
    }
}
