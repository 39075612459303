import { Column, ColumnEditorOptions } from "primereact/column";
import { DataTable, DataTableRowEditCompleteParams } from "primereact/datatable";
import React, { ChangeEvent, Component, Fragment } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Container, Card, CardBody } from "reactstrap";
import { CreateOrUpdateMerchantDocument, MerchantDocument } from "../../models/MerchantModel";
import { Button } from "primereact/button";
import "./merchantdetails.scss";
import { merchantDocumentApiService } from "../../services/api/merchants/MerchantDocumentApiService";
import ExtendedInputText from "../../components/ExtendedInputText";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { SelectItem } from "../../models/SelectItem";
import { utils } from "../../utils/utils";
import { fileService } from "../../services/FileService";
import { ExtendedImage } from "../../components/Image/ExtendedImage";
import { confirmDialog } from "primereact/confirmdialog";
import _ from "lodash";
import { Result } from "../../models/Result";
import { isMerchantProfileUpdateAllowed } from "../../authGuard";

interface MerchantDocumentsProps {
    merchantId: number;
    localMode: boolean;
    onLocalDocumentUpdated?: (documents: MerchantDocument[]) => void;
    merchantDocuments?: MerchantDocument[]
}

interface MerchantDocumentsState {
    isLoading: boolean;
    documents: MerchantDocument[];
}

export default class MerchantDocuments extends Component<MerchantDocumentsProps, MerchantDocumentsState> {
    imageInput: HTMLInputElement | null = null;
    toast: Toast | null = null;

    validationErrors: Map<string, boolean> = new Map<string, boolean>();

    documentTypes: SelectItem[] = [];

    documentStatus: SelectItem[] = [];

    constructor(props: MerchantDocumentsProps | Readonly<MerchantDocumentsProps>) {
        super(props);

        this.state = {
            documents: this.props.localMode ? this.props.merchantDocuments! : [],
            isLoading: true,
        };
    }

    async componentDidMount() {
        this.setState({ isLoading: true });

        const documentTypes = await merchantDocumentApiService.getMerchantDocumentsTypes();

        if (!documentTypes.success) {
            this.toast?.show({ severity: "error", summary: "Error", detail: documentTypes.message, life: 3000 });
            this.setState({ isLoading: false });
            return;
        }

        this.documentTypes = documentTypes.data;

        const documentStatus = await merchantDocumentApiService.getDocumentsStatus();

        if (!documentStatus.success) {
            this.toast?.show({ severity: "error", summary: "Error", detail: documentStatus.message, life: 3000 });
            this.setState({ isLoading: false });
            return;
        }

        this.documentStatus = documentStatus.data;

        this.setState({ isLoading: false });

        await this.loadDocuments();
    }

    loadDocuments = async () => {
        if(this.props.localMode) {
            return;
        }
        this.setState({ isLoading: true });
        const documentResult = await merchantDocumentApiService.getAll(this.props.merchantId);
        
        if (!documentResult.success) {
            this.toast?.show({ severity: "error", summary: "Error", detail: documentResult.message, life: 3000 });
            this.setState({ isLoading: false });
            return;
        }

        this.setState({ documents: documentResult.data, isLoading: false });
    };

    onRowEditComplete = async (e: DataTableRowEditCompleteParams) => {
        this.setState({ isLoading: true });

        const newData = e.newData as MerchantDocument;
        const fileData = (newData as any).file;
        const objToPost: CreateOrUpdateMerchantDocument = {
            fileName: newData.fileName,
            fileData: fileData,
            type: newData.documentType,
            merchantId: this.props.merchantId,
            id: newData.id,
        } as CreateOrUpdateMerchantDocument;


        let result: Result<MerchantDocument>;
        if(this.props.localMode) {
            result = { success: true, data: { fileStorageId: "", id: "", status: "New" } as any, message: "" } as Result<MerchantDocument>;
        } else {
            result = newData.id != 0 ? await merchantDocumentApiService.update(objToPost) : await merchantDocumentApiService.create(objToPost);
        }
        
        this.setState({ isLoading: false });

        if (!result.success) {
            this.toast?.show({ severity: "error", summary: "Error", detail: result.message, life: 3000 });
            return;
        }

        const documents = this.state.documents;

        newData.id = result.data.id;
        newData.fileStorageId = result.data.fileStorageId;
        newData.status = result.data.status;
        newData.type = newData.documentType;
        newData.fileData = fileData;

        documents[e.index] = newData;

        this.setState({ documents: documents });

        if(this.props.localMode) {
            this.props.onLocalDocumentUpdated!(this.state.documents);
        }
    };

    renderHeader = () => {
        return (
            <div className="table-header">
                <span className="p-input-icon-left align-right">
                    <i className="pi" />
                    {isMerchantProfileUpdateAllowed() && <Button label="Add Document" className={"p-button-outlined"} icon="pi pi-plus" onClick={this.addDocument} />}
                </span>
            </div>
        );
    };

    addDocument = () => {
        const documents = this.state.documents;
        if(documents.length < this.documentTypes.length){
            const newDoc = new MerchantDocument();
            newDoc.id = 0;
            newDoc.merchantId = this.props.merchantId;
            documents.push(newDoc);
            this.setState({ documents: documents });
        }
        else{
            this.toast?.show({ severity: "error", summary: "Error", detail: "Add Documents limit exceeds. ", life: 3000 }); 
        }
        
    };

    textEditor = (options: ColumnEditorOptions) => {
        return (
            <ExtendedInputText
                type="text"
                value={options.value}
                onChange={(value) => options.editorCallback && options.editorCallback(value)}
                name={options.field}
            />
        );
    };

    statusEditor = (options: ColumnEditorOptions) => {
        const list = _.filter(this.documentStatus, (c) => c.value == options.value);
        return (
            <div>
                <span>{list.length > 0 ? list[0].label : "New"}</span>
            </div>
        );
    };

    typesEditor(options : ColumnEditorOptions) {
        return (
            <Dropdown
                value={options.value}
                options={this.documentTypes}
                optionLabel="label"
                optionValue="value"
                onChange={(e) => options.editorCallback && options.editorCallback(e.value)}
                placeholder="Select a Type"
                itemTemplate={(option) => {
                    return <span>{option.label}</span>;
                }}
            />
        );
    }

    fileEditor = (options: ColumnEditorOptions) => {
        if ((options.rowData as MerchantDocument).id == 0) {
            return (
                <div>
                    <Button label="Choose" className="p-fileupload-choose" icon="pi pi-check" onClick={() => this.imageInput?.click()} />
                    <input
                        ref={(el) => (this.imageInput = el)}
                        type="file"
                        style={{ display: "none" }}
                        accept="image/*,application/pdf"
                        onChange={(event) => this.onImageSelected(event, options.editorCallback!)}
                        required={true}
                    />
                </div>
            );
        }
        return (
            <div>                                                                                    
                <img
                    src={options.rowData?.file == null ? "/document.png" : utils.appendBase64Data(options.rowData?.file)}
                    alt="document"
                    width="50px" />
            </div>
        );
    };

    onImageSelected = async (event: ChangeEvent<HTMLInputElement>, editorCallback: (val: any) => void) => {
        const documentFile = event.target.files && event.target.files[0];
        if (!utils.checkCharacterlength(documentFile!.name) && this.imageInput) {
            this.toast?.show({ severity: "error", summary: "File Name", detail: "File Name Exceeds 128 Characters.", life: 3000 });
            this.imageInput.value = "";
        } else  editorCallback(await utils.convertFileToBase64(documentFile!));
    };

    loadDocument = async (fileStorageId: string): Promise<string> => {
        
        const imagePath = await fileService.GetFile(fileStorageId);

        if (!imagePath.success) {
            this.toast?.show({ severity: "error", summary: "Error", detail: imagePath.message, life: 3000 });
            return "";
        }

        return utils.appendBase64Data(imagePath.data,fileStorageId);
    };

    confirmDeleteDocument = async (rowData: MerchantDocument , rowIndex: number) => {

        if(this.props.localMode) {
            const documents = this.state.documents;
            const index = rowIndex
            if (index >= 0) {
                documents.splice(index, 1);
            }

            this.setState({ documents: documents });
            this.props.onLocalDocumentUpdated!(this.state.documents);
            return;
        }

        if(rowData.id === 0) {
            this.setState({ documents: this.state.documents.filter((document,index) => index !== rowIndex) });

            return;
        }

        confirmDialog({
            message: "This action will remove the document and is irreversible. Are you sure you want to proceed?",
            header: "Delete Document",
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            accept: () => this.deleteDocument(rowData),
            reject: () => {},
        });
    };

    deleteDocument = async (rowData: MerchantDocument) => {
        const deleteResult = await merchantDocumentApiService.delete(rowData.id);

        if (deleteResult.success) {
            this.setState({ documents: this.state.documents.filter((document) => document.id !== rowData.id) });
        } else {
            this.toast?.show({ severity: "error", summary: "Error", detail: deleteResult.message, life: 3000 });
        }
    };

    downloadDocuments = async (fileName:string) => {
        const url =  await this.loadDocument(fileName);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download","document.pdf");
        document.body.appendChild(link);
        link.click();
        link.parentNode!.removeChild(link);
        window.URL.revokeObjectURL(url);
        return "ok";
    };

    documentTypeBodyTemplate = (rowData: MerchantDocument): string => {
        const list = _.filter(this.documentTypes, (c) => c.value == rowData.documentType);
        return list.length > 0 ? list[0].label : "N/A";
    };

    documentStatusBodyTemplate = (rowData: MerchantDocument): string => {
        const list = _.filter(this.documentStatus, (c) => c.value == rowData.status);
        return list.length > 0 ? list[0].label : "N/A";
    };

    actionBodyTemplate = (rowData: MerchantDocument, index: any) => {
        return (
            <>
                <button type="button" className="p-row-editor-cancel p-link" tabIndex={0} onClick={() => this.confirmDeleteDocument( rowData, index.rowIndex)}>
                    <span className="p-row-editor-cancel-icon pi pi-fw pi-times"></span>
                </button>
            </>
        );
    };

    documentFileTemplate = (rowData: MerchantDocument) => {
        if(rowData.fileStorageId.includes(".pdf")){
            return (
                <>
                    <button
                        className="btn card-subtitle btn-text-primary border-0 pl-0"
                        onClick={() => this.downloadDocuments(rowData.fileStorageId)}
                        name="Document"
                    >
                        <li className="pi pi-file-pdf" style={{ fontSize: 60 }}></li>
                    </button> 
                </>
            );
        } 
        else{
            return (
                <>
                    <ExtendedImage
                        width="50px"
                        onPreview={() => this.loadDocument(rowData.fileStorageId)}
                        alt={rowData.fileStorageId}
                        downloadable={!this.props.localMode}
                        preview={!this.props.localMode}
                    />
                </>
            );
        }

      
    };

    render() {
        return (
            <Fragment>
                <TransitionGroup appear={true} exit={false} enter={false}>
                    <CSSTransition classNames="TabsAnimation" timeout={1500}>
                        <Fragment>
                            <Container fluid>
                                <Card>
                                    <Toast ref={(el) => (this.toast = el)} />
                                    <CardBody>
                                        <Toast ref={(el) => (this.toast = el)} position={"bottom-center"} baseZIndex={99999}></Toast>
                                        <DataTable
                                            header={this.renderHeader}
                                            editMode="row"
                                            dataKey="id"
                                            onRowEditComplete={this.onRowEditComplete}
                                            value={this.state.documents}
                                            loading={this.state.isLoading}
                                        >
                                            <Column field="id" header="Id"></Column>
                                            <Column
                                                field="fileName"
                                                header="File Name"
                                                editor={(options) => this.textEditor(options)}
                                                style={{ width: "20%" }}
                                            ></Column>
                                            <Column
                                                field="documentType"
                                                header="Type"
                                                body={this.documentTypeBodyTemplate}
                                                editor={(options) => this.typesEditor(options)}
                                                style={{ width: "20%" }}
                                            ></Column>
                                            <Column
                                                field="status"
                                                header="Status"
                                                editor={(options) => this.statusEditor(options)}
                                                body={this.documentStatusBodyTemplate}
                                                style={{ width: "20%" }}
                                            ></Column>
                                            <Column
                                                field="file"
                                                header="File"
                                                body={this.documentFileTemplate}
                                                editor={(options) => this.fileEditor(options)}
                                                style={{ width: "20%" }}
                                            ></Column>
                                            <Column
                                                rowEditor
                                                headerStyle={{ width: "10%", minWidth: "8rem" }}
                                                bodyStyle={{ textAlign: "center" }}
                                            ></Column>
                                            <Column
                                                header=""
                                                body={this.actionBodyTemplate}
                                                headerStyle={{ width: "8em", textAlign: "center" }}
                                                bodyStyle={{ textAlign: "center", overflow: "visible" }}
                                            ></Column>
                                        </DataTable>
                                    </CardBody>
                                </Card>
                            </Container>
                        </Fragment>
                    </CSSTransition>
                </TransitionGroup>
            </Fragment>
        );
    }
}
