import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { TransactionReportResponse, TransType, PaymentStatus } from "../models/Transactions";
import { MerchantTariff } from "../models/MerchantModel";
import { storageService } from "./StorageService";
import { authService } from "./authService";
import { ApiPagedResult, PagedResult, Result } from "../models/Result";
import { CountryModel } from "../models/CountryModel";
import EventEmitter, { Events } from "./eventemitter";
import { SearchRequestBaseModel } from "../models/SearchRequestModel";

class HttpService {
    private instance: AxiosInstance;
    private baseUrl: string = process.env.REACT_APP_CRM_API_BASE_URL!;
    private baseApiUrl: string = `${this.baseUrl}/api/v1`;

    constructor() {
        this.instance = axios.create({
            baseURL: this.baseApiUrl,
            timeout: 120000
        } as AxiosRequestConfig);

        // Grab the token from storage and put it into the http
        const accessToken = storageService.getAccessToken();
        if (accessToken.length > 0) {
            this.setAuthToken(accessToken);
        }

        this.instance.interceptors.response.use(response => {
            return response;
        }, async (error) => {
            // Some CORS Issue. Browser does not notify to local JS about the 401 due to CORS.  
            if (error.toJSON()["message"] != "Network Error") {
                return Promise.reject(error);
            }

            const user = await authService.getUser();

            if (user != null && user.expired === false) {
                return Promise.reject(error);
            }

            try {
                await authService.signinSilent();
                if (error) {
                    return this.instance.request(error.config);
                } else {
                    // remedy in case of failure for Error.
                    // TODO. Fix CORS Issue.
                    window.location.reload();
                }
            } catch (error) {
                EventEmitter.emit(Events.onLogout, "401");
            }
        });
    }

    login = async (): Promise<void> => {
        await authService.signinRedirect();
    };

    setAuthToken = (token: string) => {
        this.instance.defaults.headers.common["Authorization"] = token;
    };

    getCountries = async (): Promise<Result<CountryModel[]>> => {
        const countries = await this.get<CountryModel[]>("/country");
        countries.data.sort((a, b) => (a.name > b.name) ? 1 : -1);

        return countries;
    };

    get = async <T>(path: string, config?: AxiosRequestConfig<string>): Promise<Result<T>> => {
        return this.send(() => this.instance.get<T>(path, config));
    };

    getPage = async <T>(path: string, data: SearchRequestBaseModel, config?: AxiosRequestConfig<string>): Promise<PagedResult<T>> => {

        if(data.pageNumber == undefined || data.pageSize == undefined) {
            return new Promise((resolve, reject) => {
                resolve({ data: null!, success: false, message: "Page Number or Page Size is Not Valid.", totalCount: 0 });
            });
        }

        return this.sendPaged(() => this.instance.post<ApiPagedResult<T>>(path, data, config));
    };

    delete = async <T>(path: string, config?: AxiosRequestConfig<string>): Promise<Result<T>> => {
        return this.send(() => this.instance.delete<T>(path, config));
    };

    post = async <T>(path: string, data: any, config?: AxiosRequestConfig<string>): Promise<Result<T>> => {
        return this.send(() => this.instance.post<T>(path, data, config));
    };

    put = async <T>(path: string, data: any, config?: AxiosRequestConfig<string>): Promise<Result<T>> => {
        return this.send(() => this.instance.put<T>(path, data, config));
    };

    send = async <T>(promise: () => Promise<AxiosResponse<T, any>>): Promise<Result<T>> => {
        try {
            const promiseResult = await promise();

            const result: Result<T> = {
                success: promiseResult.status >= 200 && promiseResult.status <= 299,
                data: promiseResult.data,
                message: this.getErrorMessagesFromRequestBody(promiseResult.data, ""),
            };

            return result;
        } catch (ex) {
            const exception = ex as AxiosError;

            return {
                success: false,
                data: null!,
                message: this.getErrorMessagesFromRequestBody(exception?.response?.data, exception.message),
            } as Result<T>;
        }
    };

    sendPaged = async <T>(promise: () => Promise<AxiosResponse<ApiPagedResult<T>, any>>): Promise<PagedResult<T>> => {
        try {
            const promiseResult = await promise();

            const result: PagedResult<T> = {
                success: promiseResult.status >= 200 && promiseResult.status <= 299,
                data: promiseResult.data.data,
                message: this.getErrorMessagesFromRequestBody(promiseResult.data.data, ""),
                totalCount: promiseResult.data.totalCount
            };

            return result;
        } catch (ex) {
            const exception = ex as AxiosError;

            return {
                success: false,
                data: null!,
                message: this.getErrorMessagesFromRequestBody(exception?.response?.data, exception.message),
                totalCount: 0
            } as PagedResult<T>;
        }
    };

    getErrorMessagesFromRequestBody = (data: any, message?: string) => {
        if(data == null) {
            return message ?? "";
        }

        let finalResult = data;
        if(data.errors) {
            finalResult = data.errors;

            if(finalResult.Error) {
                finalResult = finalResult.Error;
            }
        }

        // Trim Quotes. TODO. Move to extensions
        return JSON.stringify(finalResult).replace(/^"+/, "").replace(/"+$/, "");
    };

   

    getPendingTransactions = (): TransactionReportResponse[] => {
        return [
            {
                id: "7429",
                type:TransType.D,
                senderEmail: "UserName 1",
                senderName: "Giorgi Tabatadze",
                senderSource: "IBAN123",
                senderMerchant: "Merchant 123",
                amount: 100,
                recepientEmail: "ABC Receiver",
                recepientDestination: "IBAN234",
                reason: "Limit Breach",
                timeStamp: new Date().toString(),
                currency: "EUR",
                senderAuthToken: "Auth Token",
                senderAuthData: "Auth Data",
                senderIp: "1.2.3.4",
                duration: "3 min",
                cbsTransactionId: "T1234AB",
                cbsDetails: "[Details here]",
                fee: 20,
                recepientName: "Abc",
                status: PaymentStatus.Pending,
                otherSide: "xyz",
                walletId: "123456789",
                recepienMerchant: "Example Merchant",
                feeCurrency: "EUR",
                accountNumber: "1234567890",
                senderBic: "INTF4001",
                receiverBic: "TEPJ4013",
                senderIban: "BG46TEPJ91550123456789",
                receiverIban: "BG46TEPJ91550233456123",
                reference: "Payment Reference",
                paymentSystem: "Payment System Bisera",
                transactionCategory: "Trx Category",
                cardAlias:'-',
                cardNetwork: "-",
                cardPan: "-",
                paymentProvider: "-",
                purposeDet:"-",
                wireTransferReturnCategory: "-",
                wireTransferReturnStatus: "-",
                wireTransferRelatedTransId: "-",
                wireTransferReason: "-",
            },
            {
                id: "7423",
                type: TransType.D,
                senderEmail: "UserName 1",
                senderName: "Giorgi Tabatadze",
                senderSource: "IBAN123",
                senderMerchant: "Merchant 123",
                amount: 100,
                recepientEmail: "ABC Receiver",
                recepientDestination: "Wrong IBAN",
                reason: "Limit Breach",
                timeStamp: new Date().toString(),
                currency: "BGN",
                senderAuthToken: "Auth Token",
                senderAuthData: "Auth Data",
                senderIp: "1.2.3.4",
                duration: "3 min",
                cbsTransactionId: "T1234AB",
                cbsDetails: "[Details here]",
                fee: 20,
                recepientName: "Radko",
                status: PaymentStatus.Pending,
                otherSide: "",
                walletId: "98765432",
                recepienMerchant: "Merchant abc",
                feeCurrency: "BGN",
                accountNumber: "11223344",
                senderBic: "BUIN8016",
                receiverBic: "BUIN7069",
                senderIban: "BG46TEPJ91550987654321",
                receiverIban: "BG46TEPJ915112233445",
                reference: "Payment Reference",
                paymentSystem: "Payment System Bisera",
                transactionCategory: "Trx Category",
                cardAlias:'-',
                cardNetwork: "-",
                cardPan: "-",
                paymentProvider: "-",
                purposeDet:"-",
                wireTransferReturnCategory: "-",
                wireTransferReturnStatus: "-",
                wireTransferRelatedTransId: "-",
                wireTransferReason: "-",
            },
            {
                id: "7393",
                type: TransType.D,
                senderEmail: "",
                senderName: "",
                senderSource: "",
                senderMerchant: "Merchant 123",
                amount: 100,
                recepientEmail: "ABC Receiver",
                recepientDestination: "IBAN234",
                reason: "The recepient is not recognized",
                timeStamp: new Date().toString(),
                currency: "USD",
                senderAuthToken: "Auth Token",
                senderAuthData: "Auth Data",
                senderIp: "1.2.3.4",
                duration: "3 min",
                cbsTransactionId: "T1234AB",
                cbsDetails: "[Details here]",
                fee: 20,
                recepientName: "Mehmat",
                status: PaymentStatus.Pending,
                otherSide: "",
                walletId: "98765432",
                recepienMerchant: "Merchant abc",
                feeCurrency: "USD",
                accountNumber: "98723413",
                senderBic: "IORT7377",
                receiverBic: "IORT7377",
                senderIban: "BG46TEPJ9155443322123",
                receiverIban: "BG46TEPJ23423454317",
                reference: "Payment Reference",
                paymentSystem: "Payment System Bisera",
                transactionCategory: "Trx Category",
                cardAlias:'-',
                cardNetwork: "-",
                cardPan: "-",
                paymentProvider: "-",
                purposeDet:"-",
                wireTransferReturnCategory: "-",
                wireTransferReturnStatus: "-",
                wireTransferRelatedTransId: "-",
                wireTransferReason: "-",
            },
            {
                id: "7381",
                type:TransType.D,
                senderEmail: "",
                senderName: "",
                senderSource: "",
                senderMerchant: "Merchant 123",
                amount: 100,
                recepientEmail: "ABC Receiver",
                recepientDestination: "IBAN234",
                reason: "Limit Breach",
                timeStamp: new Date().toString(),
                currency: "EUR",
                senderAuthToken: "Auth Token",
                senderAuthData: "Auth Data",
                senderIp: "1.2.3.4",
                duration: "3 min",
                cbsTransactionId: "T1234AB",
                cbsDetails: "[Details here]",
                fee: 20,
                recepientName: "Ali",
                status: PaymentStatus.Pending,
                otherSide: "",
                walletId: "00017001",
                recepienMerchant: "Merchant asd",
                feeCurrency: "EUR",
                accountNumber: "98723413",
                senderBic: "IORT7377",
                receiverBic: "IORT7377",
                senderIban: "BG46TEPJ455298612345",
                receiverIban: "BG46TEPJ9754422348",
                reference: "Payment Reference",
                paymentSystem: "Payment System Bisera",
                transactionCategory: "Trx Category",
                cardAlias:'-',
                cardNetwork: "-",
                cardPan: "-",
                paymentProvider: "-",
                purposeDet:"-",
                wireTransferReturnCategory: "-",
                wireTransferReturnStatus: "-",
                wireTransferRelatedTransId: "-",
                wireTransferReason: "-",
            },
        ];
    };

    getTransactionsByUserId = (id: number): TransactionReportResponse[] => {
        return [
            {
                id: "23",
                type:TransType.D,
                timeStamp: new Date().toString(),
                amount: 1200,
                currency: "USD",
                otherSide: "BG15PATC40025900100312",
                status: PaymentStatus.Pending,
                fee: 123,
                senderEmail: "sender@email.com",
                senderName: "Asad",
                senderSource: "Wallet",
                senderMerchant: "Merchant 1",
                recepientEmail: "mail@email.com",
                recepientName: "Ali",
                recepientDestination: "Card",
                senderAuthToken: "Auth Token",
                senderAuthData: "Auth Data",
                senderIp: "1.2.3.4",
                duration: "3 min",
                cbsTransactionId: "T1234AB",
                cbsDetails: "[Details here]",
                reason: "",
                walletId: "00017001",
                recepienMerchant: "Merchant asd",
                feeCurrency: "EUR",
                accountNumber: "98723413",
                senderBic: "IORT7377",
                receiverBic: "IORT7377",
                senderIban: "BG46TEPJ455298612345",
                receiverIban: "BG46TEPJ9754422348",
                reference: "Payment Reference",
                paymentSystem: "Payment System Bisera",
                transactionCategory: "Trx Category",
                cardAlias:'-',
                cardNetwork: "-",
                cardPan: "-",
                paymentProvider: "-",
                purposeDet:"-",
                wireTransferReturnCategory: "-",
                wireTransferReturnStatus: "-",
                wireTransferRelatedTransId: "-",
                wireTransferReason: "-",
            },
            {
                id: "24",
                type:TransType.D,
                timeStamp: new Date().toString(),
                amount: 340,
                currency: "EUR",
                otherSide: "4748 **** **** 9820",
                status: PaymentStatus.Pending,
                fee: 123,
                senderEmail: "sender@email.com",
                senderName: "Asad",
                senderSource: "Card",
                senderMerchant: "Merchant 2",
                recepientEmail: "mail@email.com",
                recepientName: "Ali",
                recepientDestination: "Wallet",
                senderAuthToken: "Auth Token",
                senderAuthData: "Auth Data",
                senderIp: "1.2.3.4",
                duration: "3 min",
                cbsTransactionId: "T1234AB",
                cbsDetails: "[Details here]",
                reason: "",
                walletId: "98765432",
                recepienMerchant: "Merchant abc",
                feeCurrency: "USD",
                accountNumber: "98723413",
                senderBic: "IORT7377",
                receiverBic: "IORT7377",
                senderIban: "BG46TEPJ9155443322123",
                receiverIban: "BG46TEPJ23423454317",
                reference: "Payment Reference",
                paymentSystem: "Payment System Bisera",
                transactionCategory: "Trx Category",
                cardAlias:'-',
                cardNetwork: "-",
                cardPan: "-",
                paymentProvider: "-",
                purposeDet:"-",
                wireTransferReturnCategory: "-",
                wireTransferReturnStatus: "-",
                wireTransferRelatedTransId: "-",
                wireTransferReason: "-",
            },
            {
                id: "25",
                type:TransType.D,
                timeStamp: new Date().toString(),
                amount: 250,
                currency: "USD",
                otherSide: "BG15PATC40025900100312",
                status: PaymentStatus.Pending,
                fee: 123,
                senderEmail: "sender@email.com",
                senderName: "Asad",
                senderSource: "Wallet",
                senderMerchant: "Merchant 3",
                recepientEmail: "mail@email.com",
                recepientName: "Ali",
                recepientDestination: "Card",
                senderAuthToken: "Auth Token",
                senderAuthData: "Auth Data",
                senderIp: "1.2.3.4",
                duration: "3 min",
                cbsTransactionId: "T1234AB",
                cbsDetails: "[Details here]",
                reason: "",
                walletId: "00017001",
                recepienMerchant: "Merchant asd",
                feeCurrency: "EUR",
                accountNumber: "98723413",
                senderBic: "IORT7377",
                receiverBic: "IORT7377",
                senderIban: "BG46TEPJ455298612345",
                receiverIban: "BG46TEPJ9754422348",
                reference: "Payment Reference",
                paymentSystem: "Payment System Bisera",
                transactionCategory: "Trx Category",
                cardAlias:'-',
                cardNetwork: "-",
                cardPan: "-",
                paymentProvider: "-",
                purposeDet:"-",
                wireTransferReturnCategory: "-",
                wireTransferReturnStatus: "-",
                wireTransferRelatedTransId: "-",
                wireTransferReason: "-",
            },
            {
                id: "26",
                type:TransType.D,
                timeStamp: new Date().toString(),
                amount: 50,
                currency: "USD",
                otherSide: "BG15PATC400BG15PATC400",
                status: PaymentStatus.Pending,
                fee: 123,
                senderEmail: "sender@email.com",
                senderName: "Asad",
                senderSource: "Wallet",
                senderMerchant: "Merchant 4",
                recepientEmail: "mail@email.com",
                recepientName: "Ali",
                recepientDestination: "Card",
                senderAuthToken: "Auth Token",
                senderAuthData: "Auth Data",
                senderIp: "1.2.3.4",
                duration: "3 min",
                cbsTransactionId: "T1234AB",
                cbsDetails: "[Details here]",
                reason: "",
                walletId: "123456789",
                recepienMerchant: "Example Merchant",
                feeCurrency: "EUR",
                accountNumber: "1234567890",
                senderBic: "INTF4001",
                receiverBic: "TEPJ4013",
                senderIban: "BG46TEPJ91550123456789",
                receiverIban: "BG46TEPJ91550233456123",
                reference: "Payment Reference",
                paymentSystem: "Payment System Bisera",
                transactionCategory: "Trx Category",
                cardAlias:'-',
                cardNetwork: "-",
                cardPan: "-",
                paymentProvider: "-",
                purposeDet:"-",
                wireTransferReturnCategory: "-",
                wireTransferReturnStatus: "-",
                wireTransferRelatedTransId: "-",
                wireTransferReason: "-",
            },
            {
                id: "27",
                type:TransType.D,
                timeStamp: new Date().toString(),
                amount: 100,
                currency: "EUR",
                otherSide: "4748 **** **** 9820",
                status: PaymentStatus.Pending,
                fee: 123,
                senderEmail: "sender@email.com",
                senderName: "Asad",
                senderSource: "IBAN",
                senderMerchant: "Merchant 5",
                recepientEmail: "mail@email.com",
                recepientName: "Ali",
                recepientDestination: "Wallet",
                senderAuthToken: "Auth Token",
                senderAuthData: "Auth Data",
                senderIp: "1.2.3.4",
                duration: "3 min",
                cbsTransactionId: "T1234AB",
                cbsDetails: "[Details here]",
                reason: "",
                walletId: "98765432",
                recepienMerchant: "Merchant abc",
                feeCurrency: "USD",
                accountNumber: "98723413",
                senderBic: "IORT7377",
                receiverBic: "IORT7377",
                senderIban: "BG46TEPJ9155443322123",
                receiverIban: "BG46TEPJ23423454317",
                reference: "Payment Reference",
                paymentSystem: "Payment System Bisera",
                transactionCategory: "Trx Category",
                cardAlias:'-',
                cardNetwork: "-",
                cardPan: "-",
                paymentProvider: "-",
                purposeDet:"-",
                wireTransferReturnCategory: "-",
                wireTransferReturnStatus: "-",
                wireTransferRelatedTransId: "-",
                wireTransferReason: "-",
            },
            {
                id: "28",
                type:TransType.D,
                timeStamp: new Date().toString(),
                amount: 100,
                currency: "USD",
                otherSide: "4748 **** **** 9820",
                status: PaymentStatus.Pending,
                fee: 123,
                senderEmail: "sender@email.com",
                senderName: "Asad",
                senderSource: "Card",
                senderMerchant: "Merchant 6",
                recepientEmail: "mail@email.com",
                recepientName: "Ali",
                recepientDestination: "Wallet",
                senderAuthToken: "Auth Token",
                senderAuthData: "Auth Data",
                senderIp: "1.2.3.4",
                duration: "3 min",
                cbsTransactionId: "T1234AB",
                cbsDetails: "[Details here]",
                reason: "",
                walletId: "98765432",
                recepienMerchant: "Merchant abc",
                feeCurrency: "USD",
                accountNumber: "98723413",
                senderBic: "IORT7377",
                receiverBic: "IORT7377",
                senderIban: "BG46TEPJ9155443322123",
                receiverIban: "BG46TEPJ23423454317",
                reference: "Payment Reference",
                paymentSystem: "Payment System Bisera",
                transactionCategory: "Trx Category",
                cardAlias:'-',
                cardNetwork: "-",
                cardPan: "-",
                paymentProvider: "-",
                purposeDet:"-",
                wireTransferReturnCategory: "-",
                wireTransferReturnStatus: "-",
                wireTransferRelatedTransId: "-",
                wireTransferReason: "-",
            },
        ];
    };

    getMerchantTariffsById = (id: string): MerchantTariff[] => {
        return [
            { id: "1", name: "Tariff 1", groupNames: ["G1", "G2"], totalUsers: 5, details: [], code: "T1", merchantName: "Merchant 1" },
            { id: "2", name: "Tariff 2", groupNames: ["G2", "G3"], totalUsers: 5, details: [], code: "T2", merchantName: "Merchant 1" },
            { id: "3", name: "Tariff 3", groupNames: ["G1", "G3"], totalUsers: 5, details: [], code: "T3", merchantName: "Merchant 1" },
        ];
    };
}

const httpService = new HttpService();

export { httpService };
