import React, { Component } from "react";
import { Card, CardBody, Form, Modal, ModalBody, ModalFooter, ModalHeader, Container, CardHeader, Button } from "reactstrap";

import { Toast } from "primereact/toast";
import { merchantDocumentApiService } from "../../services/api/merchants/MerchantDocumentApiService";
import { SelectItem } from "../../models/SelectItem";
import { utils } from "../../utils/utils";
import { AddKycDocument, DocumentFile } from "../../models/KycSubmission";
import { kycSubmissionApiService } from "../../services/api/Kyc/KycSubmissionApiService";
import classNames from "classnames";
import { localeOption } from "primereact/api";
import { Messages, MessagesMessage } from "primereact/messages";
import { ProgressBar } from "primereact/progressbar";
import { Ripple } from "primereact/ripple";
import { IconUtils } from "primereact/utils";
import ExtendedDropdown from "../../components/ExtendedDropdown";
import { DataTable } from "primereact/datatable";
import { Column, ColumnBodyOptions } from "primereact/column";
import pdfFileIcon from "../../images/pdf_file_icon.png";
import imgFileIcon from "../../images/png_file_icon.png";
import otherFileIcon from "../../images/png_file_icon.png";
import { fileService } from "../../services/FileService";
import { userApiService } from "../../services/api/users/UsersApiService";

interface AddManualKycProps {
//    onDialogClose: (success: boolean) => void;
    submissionId: number;
    submitToOwner: (documents: DocumentFile[]) => void;
//    renderIn:string;
}

interface AddManualKycState {
    isLoading: boolean;
    documents: DocumentFile[];
    documentTypes: SelectItem[];
    focused: boolean;
    progress: number;
    validationErrors: Map<string, boolean>;
}

export default class AddManualKyc extends Component<AddManualKycProps, AddManualKycState> {
    toast: Toast | null = null;
    maxFileSize: number = 5242880;

    invalidFileSizeMessageSummary: string = "{0}: Invalid file size, ";
    invalidFileSizeMessageDetail: string = "maximum upload size is {0}.";

    fileInput: HTMLInputElement | null = null;
    messagesUI: Messages | null = null;
    fileUploadContent: HTMLDivElement | null = null;

    constructor(props: AddManualKycProps) {
        super(props);

        this.state = {
            isLoading: false,
            documents: [],
            documentTypes: [],
            focused: false,
            progress: 0,
            validationErrors: new Map<string, boolean>()
        };
    }

    async componentDidMount() {
        this.setState({ isLoading: true });

        const documentsResult = await kycSubmissionApiService.getDocuments(this.props.submissionId);
        if (!documentsResult.success) {
            this.toast?.show({ severity: "error", summary: "Error", detail: documentsResult.message, life: 3000 });
            return;
        }

        const localDocuments = this.state.documents;
        documentsResult.data.forEach(async kycDocument => {
            localDocuments.push(
                {
                    file: null,
                    documentType: kycDocument.documentType,
                    isUploaded: false,
                    objectURL: null,
                    fileName: kycDocument.fileName,
                    fileStorageId: kycDocument.fileStorageId
                });
        });   
        this.setState({ documents: localDocuments });
        this.onDocumentUpdate();

        const documentTypes = await userApiService.getIndividualDocumentsTypes();
        if (!documentTypes.success) {
            this.toast?.show({ severity: "error", summary: "Error", detail: documentTypes.message, life: 3000 });
        }
        this.setState({ documentTypes: documentTypes.data });

        this.setState({ isLoading: false });
    }

    onDocumentUpdate = async () => {
        this.props.submitToOwner(this.state.documents);
    };

    onDownloadClick = async (fileStorageId: string,secondaryLabel: string): Promise<string> => {
        this.setState({ isLoading: true });
        const imagePath = await fileService.GetFile(fileStorageId);
        
        if (!imagePath.success) {
            this.toast?.show({ severity: "error", summary: "Error", detail: imagePath.message, life: 3000 });
            return "";
        }

        const url =  utils.appendBase64Data(imagePath.data,fileStorageId);

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", secondaryLabel);
        document.body.appendChild(link);
        link.click();
        this.setState({ isLoading: false });
        return "ok"; 
    };

    // onSubmitKycSubmissionDocument = async () => {

    //     var allDocuments = this.state.documents;

    //     if(allDocuments.length > 0) {

    //         this.setState({ isLoading: true });

    //         for (const file of allDocuments) {

    //             if (file.isUploaded === true) {
    //                 continue;
    //             }
    //             var objToPost: AddKycDocument = {
    //                 submissionId: this.props.submissionId,
    //                 fileName: file.file?.name,
    //                 fileData: (await utils.convertFileToBase64(file.file!)) as string,
    //                 type: file.documentType,
    //             } as AddKycDocument;

    //             var document = await kycSubmissionApiService.putkycDocument(objToPost);

    //             if (!document.success) {

    //                 this.setState({ isLoading: false });

    //                 this.toast?.show({ severity: "error", summary: "Error", detail: document.message, life: 3000 });
    //                 return;
    //             }

    //             file.isUploaded = true;

    //             this.setState({ documents: allDocuments });
    //         }

    //         var pending = await kycSubmissionApiService.transitionToPendingReview(this.props.submissionId);

    //         if (!pending.success) {

    //             this.setState({ isLoading: false });

    //             this.toast?.show({ severity: "error", summary: "Error", detail: pending.message, life: 3000 });
    //             return;
    //         }


    //         this.setState({ isLoading: false });
    //         this.toast?.show({ severity: "success", summary: "Action Completed", detail: "File Uploaded" });

    //         this.props.onDialogClose(true)

    //     } else {

    //     }
    // };

    hasFiles() {
        return this.state.documents && this.state.documents.length > 0;
    }

    isImage(file: File) {
        return /^image\//.test(file.type);
    }

    onRemoveFile(index: number) {
        //There is no remove command at the server, so ... use it only for not uploaded documents
        this.clearInputElement();
        const currentDocuments = [...this.state.documents];

        currentDocuments.splice(index, 1);
        this.setState({ documents: currentDocuments });
        this.onDocumentUpdate();
    }

    clearInputElement() {
        if (this.fileInput) {
            this.fileInput.value = "";
        }
    }

    formatSize(bytes: number) {
        if (bytes === 0) {
            return "0 B";
        }
        const k = 1024,
            dm = 3,
            sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
            i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }

    onFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files![0];
        if (this.isFileAlreadySelected(file)) {
            const message: MessagesMessage = {
                severity: "error",
                summary: "",
                detail: "This File is already Selected.",
            };
            this.messagesUI?.show(message);
            return;
        }

        if (!this.validateFile(file)) {
            return;
        }

        if (!utils.checkCharacterlength(file.name)) {
            const message: MessagesMessage = {
                severity: "error",
                summary: "",
                detail: "File Name Exceeds 128 Characters.",
            };
            this.messagesUI?.show(message);
            this.clearInputElement();
            return;
        }

        const objectURL = this.isImage(file) ? window.URL.createObjectURL(file) : "";

        const documentFile: DocumentFile = { file: file, documentType: "", isUploaded: false, objectURL: objectURL, fileName: file.name, fileStorageId: ""};

        this.setState({ documents: [...this.state.documents, documentFile] });
        this.onDocumentUpdate();
    };

    isFileAlreadySelected(files: File) {
        for (const sFile of this.state.documents) {
            if ((sFile.file !== null) && (sFile.file.name + sFile.file.type + sFile.file.size === files.name + files.type + files.size)) return true;
        }
        return false;
    }

    validateFile(file: File) {
        if (file.size < this.maxFileSize) {

            if(this.isImage(file) || file.type === "application/pdf" ){
                return true;
            }
            else{

                const message: MessagesMessage = {
                    severity: "error",
                    summary: "",
                    detail: "Formate not supported! Only PDF and Image files are allowed.",
                };

                this.messagesUI?.show(message);
                return false;
            }

        }
        else{
            const message: MessagesMessage = {
                severity: "error",
                summary: this.invalidFileSizeMessageSummary.replace("{0}", file.name),
                detail: this.invalidFileSizeMessageDetail.replace("{0}", this.formatSize(this.maxFileSize)),
            };

            this.messagesUI?.show(message);

            return false;
        }
    }

    choose = () => {
        this.fileInput?.click();
    };

    onKeyDown = (event: any) => {
        // enter
        if (event.which === 13) {
            this.choose();
        }
    };

    onFieldUpdate = (value: any, index: number) => {
        this.setState((prevState) => {
            return (prevState.documents[index].documentType = value);
        });
        this.onDocumentUpdate();
    };

    renderChooseButton() {
        const chooseClassName = classNames(
            "p-button p-fileupload-choose p-component",
            {
                "p-disabled": false,
                "p-focus": this.state.focused,
                "p-button-icon-only": false
            }
        );

        return (
            <span
                className={chooseClassName}
                onClick={this.choose}
                onKeyDown={this.onKeyDown}
                onFocus={() => this.setState({ focused: true })}
                onBlur={() => this.setState({ focused: false })}
                tabIndex={0}
            >
                <input
                    ref={(el) => (this.fileInput = el)}
                    type="file"
                    onChange={this.onFileSelect}
                    multiple={false}
                    accept="image/*,application/pdf"
                />
                {IconUtils.getJSXIcon(
                    "pi pi-fw pi-plus",
                    { className: "p-button-icon p-button-icon-left p-clickable" },
                    { props: this.props }
                )}
                <span className="p-button-label p-clickable">{localeOption("choose", "")}</span>
                <Ripple />
            </span>
        );
    }

    renderProgressBarContent() {
        return <ProgressBar value={this.state.progress} showValue={false} />;
    }

    renderImage(files: DocumentFile) {
        let imageURL = files.objectURL;
        if (imageURL==null || imageURL==="")
        { 
            const fileExtenssion = files.fileName ? files.fileName.substring(files.fileName.length - 3).toLowerCase() : "";
            switch (fileExtenssion) {
            case "pdf":
                imageURL = pdfFileIcon;
                break;
            case "png":
            case "jpg":
            case "bmp":
                imageURL = imgFileIcon;
                break;
            default:
                imageURL = otherFileIcon;
            }
        }

        console.log(files.file?.type);

        return (
            <div>
                <img alt={files.file?.name ?? files.fileName} role="presentation" src={imageURL!} width={50} />
            </div>
        );
    }

    setValidationErrors = (name: string, value: boolean) => {
        const validationErrors = this.state.validationErrors;

        if (validationErrors.get(name) === value) {
            return;
        }

        validationErrors.set(name, value);
        this.setState({ validationErrors: validationErrors });
    };

    DocumentTypes = (documentType: string, index: number, fileName: string) => {
        return (
            <div>
                <ExtendedDropdown
                    name="Document/File Type"
                    style={{ display: "flex" }}
                    value={documentType}
                    options={this.state.documentTypes}
                    filter
                    filterBy="label"
                    onChange={(e) => this.onFieldUpdate(e.value, index)}
                    required={true}
                    placeholder={"Select a Type"}
                    onValidStatusChanged={(value: boolean) => this.setValidationErrors(`image_${fileName}`, value)}
                />
            </div>
        );
    };


    fileName = (name: string) => {
        return (
            <div className="p-fileupload-filename">{name}</div>
        );
    };

    fileSize = (size: number) => {
        return (
            <div>{this.formatSize(size)}</div>
        );
    };


    deleteBody = (index: number, storageId: string) => {
        if (storageId == "")
            return (
                <>
                    <button type="button" className="p-row-editor-cancel p-link" tabIndex={0} onClick={() => this.onRemoveFile(index)}>
                        <span className="p-row-editor-cancel-icon pi pi-fw pi-times"></span>
                    </button>
                </>
            );
    };

    DownloadBody = (storageId: string) => {
        if (storageId != "")
            return (
                <>
                    <a href="#" onClick={() => this.onDownloadClick(storageId,"")}>Download</a>
                </>
            );
    };

    renderFileUploader() {
        let progressBar;

        if (this.hasFiles()) {
            progressBar = this.renderProgressBarContent();
        }

        return (
            <div className="p-fileupload p-fileupload-advanced p-component">
                <div
                    ref={(el) => {
                        this.fileUploadContent = el;
                    }}
                    className="p-fileupload-content"
                >
                    {progressBar}
                    <Messages ref={(el) => (this.messagesUI = el)} />
                    <div className="p-fileupload-files">
                        <DataTable header={this.renderChooseButton()} value={this.state.documents} loading={this.state.isLoading}>
                            <Column header="" body={(rowData: DocumentFile) => this.renderImage(rowData)} />
                            <Column header="File Name" body={(rowData: DocumentFile) => this.fileName(rowData.file?.name ?? rowData.fileName)} />
                            {/* <Column header="File Size" body={(rowData: DocumentFile) => this.fileSize(rowData.file?.size ?? 0)} /> */}
                            <Column
                                header="File or Document Type"
                                body={(rowData: DocumentFile, props: ColumnBodyOptions) => this.DocumentTypes(rowData.documentType, props.rowIndex, rowData.file?.name ?? "")}
                            />
                            <Column
                                header=""
                                body={(rowData: DocumentFile, props) => this.DownloadBody(rowData.fileStorageId)}
                                headerStyle={{ width: "8em", textAlign: "center" }}
                                bodyStyle={{ textAlign: "center", overflow: "visible" }}
                            />                            
                            <Column
                                header=""
                                body={(rowData: DocumentFile, props) => this.deleteBody(props.rowIndex, rowData.fileStorageId)}
                                headerStyle={{ width: "8em", textAlign: "center" }}
                                bodyStyle={{ textAlign: "center", overflow: "visible" }}
                            />                            
                            {/* <Column
                                header=""
                                body={(rowData: DocumentFile, props) => this.deleteBody(props.rowIndex)}
                                headerStyle={{ width: "8em", textAlign: "center" }}
                                bodyStyle={{ textAlign: "center", overflow: "visible" }}
                            /> */}
                        </DataTable>
                    </div>
                </div>
            </div>
        );
    }

    renderModalBody = () => {
        return (
            <Card className="mb-3">
                <CardBody>
                    <Form>
                        {this.renderFileUploader()}
                    </Form>
                </CardBody>
            </Card>
        );
    };

    render() {
        //        if (this.props.renderIn == "kycMatrix")
        //        {
        return (
            <div>
                {this.renderFileUploader()}
            </div>
        );
        //        }
        // else 
        // {
        //     const failedErrors = Array.from(this.state.validationErrors.values())
        //         .filter(f => f == false);

        //     return (
        //         <div>
        //             <Toast ref={(el) => (this.toast = el)} position={"bottom-center"} baseZIndex={99999}></Toast>
        //             <Modal isOpen={true} toggle={() => this.props.onDialogClose(false)} className="modelWidth">
        //                 <ModalHeader>
        //                     <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
        //                         <i className="header-icon lnr-laptop-phone mr-3 text-muted opacity-6"> </i>
        //                         Add Manual KYC Submission
        //                     </div>
        //                 </ModalHeader>
        //                 <ModalBody>{this.renderModalBody()}</ModalBody>
        //                 <ModalFooter>
        //                     <Button disabled={failedErrors.length > 0 || this.state.isLoading || !this.hasFiles()} color="link" onClick={() => this.onSubmitKycSubmissionDocument()}>
        //                         Submit
        //                     </Button>
        //                 </ModalFooter>
        //             </Modal>
        //         </div>
        //     );
        // }
    }
}
