import React, { Component, Fragment } from "react";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Col, Container, Label, Row } from "reactstrap";
import { Journaling } from "../../models/Journaling";
import moment from "moment";
import { Fieldset } from "primereact/fieldset";
import { InputText } from "primereact/inputtext";
import Loader from "../../components/Loader";

interface JournalingDetailsDialogProps {
    isVisible: boolean;
    onHide: () => void;
    journaling: Journaling;
    editable: boolean;
}

interface JournalingDetailsDialogState {
    isDetailVisible: boolean;
    journaling: Journaling;
}

export default class JournalingDetailsDialog extends Component<JournalingDetailsDialogProps, JournalingDetailsDialogState> {
    toast: Toast | null = null;

    constructor(props: JournalingDetailsDialogProps | Readonly<JournalingDetailsDialogProps>) {
        super(props);

        this.state = {
            isDetailVisible: false,
            journaling: this.props.journaling,
        };
    }

    toggleAdvancedMode = () => {
        this.setState({ isDetailVisible: !this.state.isDetailVisible });
    };

    render() {
        if (this.state.isDetailVisible) {
            return (
                <>
                    <Toast ref={(el) => (this.toast = el)} />
                    <Loader />
                </>
            );
        }

        if (this.state.journaling == null) {
            return "";
        }

        if (!this.props.isVisible) {
            return "";
        }

        return (
            <Dialog header="Journaling Details" visible={this.props.isVisible} style={{ width: "70vw" }} onHide={this.props.onHide}>
                <TransitionGroup component="div" appear={true} exit={false} enter={false}>
                    <CSSTransition classNames="TabsAnimation" timeout={1500}>
                        <Fragment>
                            <Toast ref={(el) => (this.toast = el)} position={"bottom-center"} baseZIndex={99999}></Toast>
                            <Container fluid>
                                <Fieldset legend="">
                                    {renderDualRow(
                                        "Journaling Id",
                                        this.state.journaling.id,
                                        false,
                                        (newVal: string) => {},
                                        "Operation Type",
                                        this.state.journaling.objectType,
                                        false,
                                        (newVal: string) => {}
                                    )}
                                    {renderDualRow(
                                        "Made On",
                                        moment.utc(this.state.journaling.madeOn).local().format("DD/MM/YYYY hh:mm:ss A"),
                                        false,
                                        (newVal: string) => {},
                                        "Application",
                                        this.state.journaling.application,
                                        false,
                                        (newVal: string) => {}
                                    )}
                                    {renderDualRow(
                                        "Made By",
                                        this.state.journaling.madeBy,
                                        false,
                                        (newVal: string) => {},
                                        "Made From IP Address",
                                        this.state.journaling.madeFromIPAddress,
                                        false,
                                        (newVal: string) => {}
                                    )}
                                    {renderDualRow("Tenant Id", this.state.journaling.tenantId, false, (newVal: string) => {})}
                                </Fieldset>
                                <h5></h5>
                                <Fieldset legend="Data Info">
                                    {renderDualRow(
                                        "Object Type",
                                        this.state.journaling.objectType,
                                        false,
                                        (newVal: string) => {},
                                        "Object",
                                        this.state.journaling.object,
                                        false,
                                        (newVal: string) => {}
                                    )}

                                    {renderDualRow(
                                        "Old Data",
                                        this.state.journaling.oldData,
                                        false,
                                        (newVal: string) => {},
                                        "New Data",
                                        this.state.journaling.oldData,
                                        false,
                                        (newVal: string) => {}
                                    )}
                                    {renderDualRow("Data", this.state.journaling.data, false, (newVal: string) => {})}
                                </Fieldset>
                            </Container>
                        </Fragment>
                    </CSSTransition>
                </TransitionGroup>
            </Dialog>
        );
    }
}

export function renderDualRow(
    title1: string,
    val1: string | number,
    editable1: boolean,
    onEdit1: (newval: string) => void,
    title2: string | null = null,
    val2: string | number = null as any,
    editable2: boolean = null as any,
    onEdit2: (newval: string) => void = null as any
) {
    return (
        <Row>
            <Col md={6}>
                <Row>
                    <Col md={6}>
                        <Label>
                            <b>{title1} :</b>
                        </Label>
                    </Col>
                    <Col md={6}>
                        {editable1 ? <InputText type="text" value={val1} onChange={(e) => onEdit1(e.target.value)} /> : <Label>{val1}</Label>}
                    </Col>
                </Row>
            </Col>
            {title2 != null && (
                <Col md={6}>
                    <Row>
                        <Col md={6}>
                            <Label>
                                <b>{title2} :</b>
                            </Label>
                        </Col>
                        <Col md={6}>
                            {editable2 ? <InputText type="text" value={val2} onChange={(e) => onEdit2(e.target.value)} /> : <Label>{val2}</Label>}
                        </Col>
                    </Row>
                </Col>
            )}
        </Row>
    );
}
