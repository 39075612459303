import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
import React from "react";
import { isMerchantProfileUpdateAllowed, isUserProfileUpdateAllowed } from "../../authGuard";
import { PaymentMethodStatusType, PaymentMethodType, PaymentMethods } from "../../models/FinancialData";
import { Card, CardBody } from "reactstrap";
import { confirmDialog } from "primereact/confirmdialog";
import { CreateCardPaymentMethodRequest, CreateIbanPaymentMethodRequest, CreateNonIbanPaymentMethodRequest, PaymentMethodStatus } from "../../models/MerchantModel";
import { Dropdown } from "primereact/dropdown";
import { fileService } from "../../services/FileService";
import saveAs from "file-saver";
import { Toast } from "primereact/toast";
import JSZip from "jszip";

interface PaymentMethodCrudComponentProps {
    addPaymentMethod: () => void;
    deletePaymentMethod: (item: PaymentMethods) => void;
    updatePaymentMethod: (item: CreateCardPaymentMethodRequest | CreateIbanPaymentMethodRequest | CreateNonIbanPaymentMethodRequest) => void;
    paymentMethods: PaymentMethods[];
}

interface PaymentMethodCrudComponentState {
    isLoading: boolean;
    fileBase64: string
}

export class PaymentMethodCrudComponent extends React.Component<PaymentMethodCrudComponentProps, PaymentMethodCrudComponentState> {

    toast: Toast | null = null;
    constructor(props: any | Readonly<any>) {
        super(props);

        this.state = {
            isLoading: false,
            fileBase64: ""
        };
    }
    changeCardStatusDialog = (e:any,rowData:any) => {
        confirmDialog({
            message: "Are you sure to reject/approve this payment method?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => {
                this.updatePaymenMethodtStatus(e,rowData);
            },
            reject: this.reject
        });
    };
    reject = () => {
        this.toast?.show({ severity: "warn", summary: "Rejected", detail: "Operation Cancelled", life: 3000 });
    };
    renderHeader = () => {
        return (
            <div className="table-header">
                <span className="p-input-icon-left align-right">
                    <i className="pi" />
                    {(isUserProfileUpdateAllowed() || isMerchantProfileUpdateAllowed()) && (
                        <Button label="Add Payment Method" className="p-button-outlined" icon="pi pi-plus" onClick={this.props.addPaymentMethod} />
                    )}
                </span>
            </div>
        );
    };

    confirmDeletePaymentMethod = async (item: PaymentMethods) => {
        confirmDialog({
            message: "This action will remove the method and is irreversible. Are you sure you want to proceed?",
            header: "Delete Payment Method",
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            accept: () => this.props.deletePaymentMethod(item),
            reject: () => {},
        });
    };

    actionBodyTemplate = (rowData: PaymentMethods) => {
        return (
            <>
                <button type="button" className="p-row-editor-cancel p-link" tabIndex={0} onClick={() => this.confirmDeletePaymentMethod(rowData)}>
                    <span className="p-row-editor-cancel-icon pi pi-fw pi-times"></span>
                </button>
            </>
        );
    };

    renderBadge(success: boolean, title: string) {
        return <div className={classNames("badge", "badge-" + (success ? "success" : "danger"))}>{title}</div>;
    }

    renderStatus(rowData: PaymentMethods) {
        const statustype = PaymentMethodStatusType[rowData.status  as keyof typeof PaymentMethodStatusType];
        if ( rowData.status === PaymentMethodStatus.Approve ){
            return (
                <div className={classNames("badge", "badge-success")}>
                    {statustype}</div>
            );
        } 
        if( rowData.status=== PaymentMethodStatus.Reject ){
            return (
                <div className={classNames("badge", "badge-danger")}>
                    {statustype}</div>
            );
        } 
        if(rowData.status === PaymentMethodStatus.UnderReview ){
            return (
                <Dropdown optionLabel="name" optionValue="id"
                    options={[{ name: "Approve", id: "1" },{ name: "Reject", id: "3" },]}
                    onChange={(e) => this.changeCardStatusDialog(e,rowData)}  placeholder={statustype} className="w-full" />
            );
        }          
    }

    updatePaymenMethodtStatus = async (e: any, rowData: PaymentMethods) => {
        this.setState({ isLoading: true });
        if(rowData.paymentType === PaymentMethodType.Card){
            const cardObj: CreateCardPaymentMethodRequest = {
                cardAlias: rowData.alias,
                cardHolderName: rowData.ownerName,
                cardNumber: rowData.value,
                cardType: rowData.cardType,
                cvvOrCvcCode: rowData.cvvOrCvcCode,
                validityMonth:rowData.validityMonth,
                validityYear: rowData.validityYear,
                Currency: "BGN",
                status: e.target.value,
                paymentType : rowData.paymentType
            }; 
            this.props.updatePaymentMethod(cardObj);
        }

        if(rowData.paymentType === PaymentMethodType.Bank){
            var imagePath = await fileService.GetFile(rowData.bankStatement); // base64
            const IbanOrBankObj: CreateIbanPaymentMethodRequest = {
                bankName: rowData.bankName,
                accountName: rowData.ownerName,
                bankStatementFileName: rowData.bankStatement,
                paymentType: rowData.paymentType,
                ibanNumber: rowData.value,
                swift: rowData.swift,
                status: e.target.value,
                bankStatement: imagePath.data,
                currency: rowData.currency,
            }; 
            this.props.updatePaymentMethod(IbanOrBankObj);
        }

        if(rowData.paymentType === PaymentMethodType.NonIBAN){
            var imagePath = await fileService.GetFile(rowData.bankStatement); // base64
            const nonIBANObj: CreateNonIbanPaymentMethodRequest = {
                bankName: rowData.bankName,
                accountHolderName: rowData.accountHolderName,
                bankAccountName: rowData.ownerName,
                bankAddress: rowData.bankAddress ?? "none",
                bankStatementFileName: rowData.bankStatement,
                paymentType: rowData.paymentType,
                bankAccountNumber: rowData.value,
                swift: rowData.swift,
                status: e.target.value,
                bankStatement: imagePath.data,
                currency: rowData.currency,
            }; 
            this.props.updatePaymentMethod(nonIBANObj);
        }
        this.setState({ isLoading: false });
    };

    onDownloadFromFileStorage = async (fileStorageId: string, secondaryLabel: string, photoZip: any): Promise<void> => {
        const imagePath = await fileService.GetFile(fileStorageId); // base64
        if (!imagePath.success) {
            //this.toast?.show({ severity: "error", summary: "Error", detail: imagePath.message, life: 3000 });
            return;
        }
        photoZip.file(secondaryLabel, imagePath.data, { base64: true });

    };

    downloadDocuments = async (rowData: PaymentMethods) => {
        this.setState({ isLoading: true });
        if(rowData.bankStatement != ""){
            const fileExtenion = rowData.bankStatement.split(".")[1];
            if(fileExtenion){
                const zip = new JSZip();
                const photoZip = zip.folder("documents");
                await this.onDownloadFromFileStorage(rowData.bankStatement, `${"document." + fileExtenion}`, photoZip);
                /// waits for "await DownloadFileFromS3, then executes the save as which saves the zipped folder created in "DownloadFileFromS3"
                const content = await zip.generateAsync({ type: "blob" });
                saveAs(content, "documents");
                
            }
        }
        this.setState({ isLoading: false });
    };

    renderDownload(rowData: PaymentMethods) {
        if(rowData.bankStatement != ""){
            return( 
                <button
                    type="button"
                    className="btn text-white bg-primary"
                    onClick={() => this.downloadDocuments(rowData)}>Download</button>);
        }
    }

    render() {
            
        return (
            <Card>
                <CardBody>
                    <DataTable header={this.renderHeader} loading={this.state.isLoading} value={this.props.paymentMethods}>
                        <Column field="paymentType" header="Type"/>
                        <Column field="value" header="Pan / IBAN"/>
                        <Column field="ownerName" header="Owner Names On"/>
                        <Column field="lastFundsDeposited" header="Last Funds Deposited"/>
                        <Column field="lastFundsWithdrawn" header="Last Funds Withdrawn"/>
                        <Column
                            header="Status"
                            body={(rowData: PaymentMethods) => this.renderStatus(rowData)}
                            bodyStyle={{width: "100%", overflow: "visible" }}
                        />

                        <Column
                            body={(rowData: PaymentMethods) => this.renderDownload(rowData)}
                            header="Uploaded Files"
                        />
                        <Column
                            field="has2FAOr3DS"
                            header="2FA / 3DS"
                            body={(rowData: PaymentMethods) => this.renderBadge(rowData.has2FAOr3DS, rowData.has2FAOr3DS ? "Yes" : "No")}
                        />
                        <Column
                            header=""
                            body={this.actionBodyTemplate}
                            headerStyle={{ width: "8em", textAlign: "center" }}
                            bodyStyle={{ textAlign: "center", overflow: "visible" }}
                        />
                    </DataTable>
                </CardBody>
            </Card>
        );
    }
}
