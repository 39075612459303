import React, { Component } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { MoneyTransferRequestDetails, WithdrawToAnyIBANMoneyTransferRequestDetails } from '../../models/MoneyTransferModal';
import { utils } from '../../utils/utils';
import { Card, CardBody, Col, Label, Row } from 'reactstrap';


interface WithdrawConfirmDialogueProps {
  withdrawToIBANDetails: WithdrawToAnyIBANMoneyTransferRequestDetails;
  isSubmitting: boolean;
  onSubmit: () => void;
  onHide: () => void;

}

class WithdrawConfirmDialogue extends Component<WithdrawConfirmDialogueProps> {
  render() {
    const { withdrawToIBANDetails, isSubmitting, onHide, onSubmit, } = this.props;

    return (
      <Dialog header={"Withdraw Confirm Payment"} visible={!!withdrawToIBANDetails} style={{ width: '60vw' }} onHide={onHide}
        modal
        blockScroll
        draggable={false}
        resizable={false}
      >
        <Card className="m-0">
          <CardBody>
            <div className="p-fluid">
            <Row className='mb-1'>
                <Col md={'4'}>
                  <Label >Source Name:</Label>
                </Col>
                <Col md={'8'} >
                  <div>{withdrawToIBANDetails.sourceCustomerName}</div>
                </Col>
              </Row>

              <Row className='mb-1'>
                <Col md={'4'}>
                  <Label >Source Account:</Label>
                </Col>
                <Col md={'8'} >
                  <div>{withdrawToIBANDetails.sourceCustomerAccount} <p className='text-success d-inline'> ({withdrawToIBANDetails.sourceCustomerCurrency})</p></div>
                </Col>
              </Row>

              <Row className='mb-1'>
                <Col md={'4'}>
                  <Label >Balance:</Label>
                </Col>
                <Col md={'8'} >
                  <div className='text-success d-inline'>{utils.formatBalance(withdrawToIBANDetails.sourceCurrentBalance, withdrawToIBANDetails.sourceCustomerCurrency)}</div>
                </Col>
              </Row>

              <Row className='mb-1'>
                <Col md={'4'}>
                  <Label >Beneficiary Name:</Label>
                </Col>
                <Col md={'8'} >
                  <div>{withdrawToIBANDetails.beneficiaryCustomerName}</div>
                </Col>
              </Row>


              <Row className='mb-1'>
                <Col md={'4'}>
                  <Label >Beneficiary Account:</Label>
                </Col>
                <Col md={'8'} >
                  <div>{withdrawToIBANDetails.beneficiaryCustomerAccount} <p className='text-success d-inline'> ({withdrawToIBANDetails.beneficiaryCustomerCurrency})</p></div>
                </Col>
              </Row>

              <Row className='mb-1'>
                <Col md={'4'}>
                  <Label >Beneficiary Bank:</Label>
                </Col>
                <Col md={'8'} >
                  <div>{withdrawToIBANDetails.beneficiaryCustomerBankName}</div>
                </Col>
              </Row>

              <Row className='mb-1'>
                <Col md={'4'}>
                  <Label >Amount:</Label>
                </Col>
                <Col>
                  <div className='text-success d-inline'>{utils.formatBalance(withdrawToIBANDetails.transactionAmount, withdrawToIBANDetails.sourceCustomerCurrency)}</div>
                </Col>
              </Row>

              <Row className='mb-1'>
                <Col md={'4'}>
                  <Label >Transaction Fee:</Label>
                </Col>
                <Col md={'8'} >
                  <div className='text-success d-inline'>{utils.formatBalance(withdrawToIBANDetails.transactionFee, withdrawToIBANDetails.sourceCustomerCurrency)}</div>
                </Col>
              </Row>

              <Row className='mb-1'>
                <Col md={'4'}>
                  <Label >Payment Notes:</Label>
                </Col>
                <Col md={'8'} >
                  <div>{withdrawToIBANDetails.paymentNotes}</div>
                </Col>
              </Row>

            <div className="d-flex justify-content-end align-items-end">
                <div>
                  <Button type="button" icon="pi pi-check" className="p-button p-button-success" iconPos="right" label="Proceed" onClick={onSubmit} loading={isSubmitting} disabled={isSubmitting} />
                </div>
              </div>

            </div>
          </CardBody>
        </Card>
      </Dialog>
    );
  }
}

export default WithdrawConfirmDialogue;
