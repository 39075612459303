import React from "react";
import { Container, Row, Col, Label, FormGroup } from "reactstrap";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { SelectItem } from "../../models/SelectItem";
import { Button } from "primereact/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CreateIbanPaymentMethodRequest, PaymentMethodStatus } from "../../models/MerchantModel";
import { utils } from "../../utils/utils";
import { currenciesApiService } from "../../services/api/merchants/CurrenciesApiService";
import { faMoneyCheck } from "@fortawesome/free-solid-svg-icons";

interface AddBankProps {
    OnBack: () => void;
    OnSuccess: (item: CreateIbanPaymentMethodRequest) => void;
    selectedMerchantId: number,
    isSubmitting: boolean
}

interface AddBankState {
    accountName: string,
    bankName: string,
    currency: string,
    iban: string,
    swift: string,
    bankStatement: string,
    bankStatementFileName: string,
    currencies: SelectItem[]
}

export class AddBank extends React.Component<AddBankProps, AddBankState> {

    bankStatementInput: HTMLInputElement | null = null;

    constructor(props : AddBankProps) {
        super(props);

        this.state = {
            accountName: "",
            bankName: "",
            currency: "",
            iban: "",
            swift: "",
            bankStatement: "",
            bankStatementFileName: "",
            currencies: []
        };
    }


    componentDidMount(): void {
        this.onLoad();
    }

    onLoad = async () => {
        const currenciesResult = await currenciesApiService.getMerchantActiveCurrencies(this.props.selectedMerchantId);
        if(currenciesResult.success) {
            const currencies = currenciesResult.data.map(a => ({ label: a.charCode, value: a.charCode } as SelectItem));
            currencies.unshift({ label: "NONE", value: "" });
            this.setState({ currencies: currencies });
        }
    };
    
    setBankStatement = async (file: File) => {
        this.setState({bankStatementFileName: file.name || ""});
        const data = (await utils.convertFileToBase64(file)) as string;
        this.setState({bankStatement: data});
    };

    render(): React.ReactNode {
        return (<Container fluid>
            <FontAwesomeIcon size="5x" icon={faMoneyCheck as any} className="text-info" style={{ margin: "auto", display: "block" }} />
            <Row>
                <Col>
                    <FormGroup>
                        <Label>Account Name</Label>
                        <InputText
                            value={this.state.accountName}
                            type="text"
                            placeholder="Account Name"
                            onChange={(event) => { this.setState({ accountName: event.target.value }); }}
                            className={"form-control"}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <FormGroup>
                        <Label>Bank Name</Label>
                        <InputText
                            value={this.state.bankName}
                            type="text"
                            onChange={(event) => { this.setState({ bankName: event.target.value }); }}
                            className={"form-control"}
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label>Currency</Label>
                        <Dropdown style={{ width: "100%" }} value={this.state.currency} options={this.state.currencies} onChange={(e) => { this.setState({ currency: e.value }); }} appendTo={document.body} />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <FormGroup>
                        <Label>IBAN</Label>
                        <InputText
                            value={this.state.iban}
                            type="text"
                            onChange={(event) => { this.setState({ iban: event.target.value }); }}
                            className={"form-control"}
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label>Swift</Label>
                        <InputText
                            value={this.state.swift}
                            type="text"
                            onChange={(event) => { this.setState({ swift: event.target.value }); }}
                            className={"form-control"}
                        />
                    </FormGroup>
                </Col>
            </Row>

            <Row>
                <Col md={6}>
                    <FormGroup>
                        <Label>Bank Statement</Label>
                        <InputText
                            ref={(el) => (this.bankStatementInput = el)}
                            type="file"
                            onChange={(event) => event.target.files && this.setBankStatement(event.target.files[0])}
                            className={"form-control"}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xl={{ size: 3, offset: 6 }} lg={{ size: 4, offset: 4 }} md={{ size: 6 }} sm={{ size: 12 }}>
                    <Button
                        style={{ width: "100%" }}
                        label="Back"
                        type="button"
                        onClick={() => this.props.OnBack()}
                        className="p-button-outlined"
                    ></Button>
                </Col>
                <Col xl={{ size: 3 }} lg={{ size: 4 }} md={{ size: 6 }} sm={{ size: 12 }}>
                    {/* todo. add bank statement */}
                    <Button
                        loading={this.props.isSubmitting}
                        iconPos="right"
                        style={{ width: "100%" }}
                        label="Submit"
                        type="button"
                        onClick={() => {
                            const obj = new CreateIbanPaymentMethodRequest();
                            obj.accountName = this.state.accountName;
                            obj.bankName = this.state.bankName;
                            obj.bankStatement = this.state.bankStatement;
                            obj.bankStatementFileName = this.state.bankStatementFileName;
                            obj.ibanNumber = this.state.iban;
                            obj.swift = this.state.swift;
                            obj.currency = this.state.currency;
                            obj.status = PaymentMethodStatus.Approve;
                            this.props.OnSuccess(obj);
                        }}
                        className="p-button-success"
                    ></Button>
                </Col>

            </Row>
        </Container>);
    }
}