import { MerchantWalletBalanceRequest, Wallets } from "../../../models/FinancialData";
import { CalculateMoneyTransferChargeRequest, MoneyTransferChargeResponse, PointToPointMoneyTransferRequestModel, WithdrawToAnyIBANMoneyTransferRequest } from "../../../models/MoneyTransferModal";
import { Result } from "../../../models/Result";
import { httpService } from "../../HttpService";

class MerchantPaymentApiService {
    path: string = "MerchantPayment";

    getMoneyTransferCharge = async (model: CalculateMoneyTransferChargeRequest): Promise<Result<MoneyTransferChargeResponse>> =>
		await httpService.post<MoneyTransferChargeResponse>(`/${this.path}/get-money-transfer-charge`, model);

    toEndUserMoneyTransfer = async (sourceOperatorId: number, beneficiaryIndividualId: number, model: PointToPointMoneyTransferRequestModel): Promise<Result<string>> =>
      await httpService.post<string>(`/${this.path}/source-operatorId/${sourceOperatorId}/beneficiary-individualId/${beneficiaryIndividualId}/p2p-money-transfer`, model);

    toAnyIbanWithdrawal = async (sourceOperatorId: number, model: WithdrawToAnyIBANMoneyTransferRequest): Promise<Result<string>> =>
      await httpService.post<string>(`/${this.path}/${sourceOperatorId}/withdrawal-to-3rd-party-iban`, model);


  }

const merchantPaymentApiService = new MerchantPaymentApiService();

export { merchantPaymentApiService };