import { SelectItem } from "../../models/SelectItem";
import { PaymentStatus } from "../../models/Transactions";

export default abstract class TransactionStatusHelper {
    public static ClassesMap: Map<string, string> = new Map<string, string>([
        ["Processed", "success"],
        ["Pending", "warning"],
        ["Cancelled", "primary"],
        ["Declined", "danger"],
        ["Revoke Rejected", "danger"],
        ["Revoke Accepted", "success"],
    ]);

    public static Statuses: SelectItem[] = [
        { label: "Processed", value: "Processed" },
        { label: "Pending", value: "Pending" },
        { label: "Cancelled", value: "Cancelled" },
        { label: "Declined", value: "Declined" },
    ];

    public static Types: SelectItem[] = [
        { label: "Incoming", value: "Incoming" },
        { label: "Outgoing", value: "Outgoing" },
        { label: "Internal", value: "Internal" },
    ];

    public static TransactionTypes: SelectItem[] = [
        { label: "Debit", value: "D" },
        { label: "Credit", value: "C" }
        // { label: "Deposit", value: "0" },
        // { label: "Withdraw", value: "1" },
        // { label: "Payment", value: "2" },
        // { label: "Send", value: "3" },
        // { label: "Charge", value: "4" },
        // { label: "Fee", value: "5" },
        // { label: "Other", value: "6" }
    ];

    public static PaymentStatusClassesMap: Map<PaymentStatus, string> = new Map<PaymentStatus, string>([
        [PaymentStatus.Processed, "success"],
        [PaymentStatus.Pending, "warning"],
        [PaymentStatus.Cancelled, "primary"],
        [PaymentStatus.Declined, "danger"],
    ]);
}