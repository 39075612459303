import React, { Component, Fragment } from "react";
import { Container, Card, CardBody } from "reactstrap";
import { OperatorDataModel } from "../../models/User";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import history from "../../history";
import "./users.scss";
import PageTitle from "../../Layout/AppMain/PageTitle";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { operatorsApiService } from "../../services/api/users/OperatorApiService";
import { Toast } from "primereact/toast";
import moment from "moment";
import { rolesApiService } from "../../services/api/users/RolesApiService";
import { SearchRequestBaseModel, SortOrderType } from "../../models/SearchRequestModel";
import ExtendedDataTable from "../../components/ExtendedDataTable";
import { numberFilterMatchModeOptions, stringFilterMatchModeOptions } from "../Paginator";
import { faUserGroup } from "@fortawesome/free-solid-svg-icons";

interface UsersAdministrationState {
    users: OperatorDataModel[];
    isLoading: boolean;
    totalRecords: number;
}

export default class UsersAdministration extends Component<Record<string, never>, UsersAdministrationState> {
    private toast: Toast | null = null;

    userStatusClassesMap: Map<string, string> = new Map<string, string>([
        ["Active", "success"],
        ["Inactive", "warning"],
        ["Deleted", "danger"],
    ]);
    statusTypes: Map<number, string> = new Map<number, string>();
    rolesDictionary: Map<number, string> = new Map<number, string>();
    private dataTable: ExtendedDataTable | null = null;

    constructor(props: Record<string, never> | Readonly<Record<string, never>>) {
        super(props);

        this.state = {
            users: [],
            isLoading: false,
            totalRecords: 0,
        };
    }

    loadUsersData = async (searchRequest: SearchRequestBaseModel) => {
        this.setState({ isLoading: true });

        const statusTypes = await operatorsApiService.getStatusTypes();

        if (!statusTypes.success) {
            this.setState({ isLoading: false });
            this.toast?.show({ severity: "error", summary: "Error", detail: statusTypes.message, life: 3000 });
            return;
        }

        statusTypes.data.forEach((item) => {
            this.statusTypes.set(item.value, item.label);
        });

        const roleResult = await rolesApiService.getAll();

        if (!roleResult.success) {
            this.setState({ isLoading: false });
            this.toast?.show({ severity: "error", summary: "Error", detail: roleResult.message, life: 3000 });
            return;
        }
        roleResult.data.forEach((item) => {
            this.rolesDictionary.set(item.id, item.name);
        });

        const usersResult = await operatorsApiService.getAll(searchRequest);

        if (!usersResult.success) {
            this.setState({ isLoading: false });
            this.toast?.show({ severity: "error", summary: "Error", detail: usersResult.message, life: 3000 });
            return;
        }

        this.setState({ users: usersResult.data, totalRecords: usersResult.totalCount, isLoading: false });
    };

    onActionButtonClick = (user: OperatorDataModel) => {
        history.push(`/profile/${user.id}`);
    };

    actionBodyTemplate = (rowData: OperatorDataModel) => {
        return <Button type="button" icon="pi pi-cog" className="p-button-outlined" onClick={() => this.onActionButtonClick(rowData)}></Button>;
    };

    renderStatusBadge(success: number, title: string) {
        const item = this.statusTypes.get(success);
        const className = this.userStatusClassesMap.get(item ?? "") ?? "danger";
        return <div className={classNames("badge", "badge-" + className)}>{title}</div>;
    }

    renderRolesBadge(titles: string[]) {
        const className = "info";
        return (
            <div>
                {titles.map((title, idx) => (
                    <div key={idx} style={{ margin: "5px" }} className={classNames("badge", "badge-" + className)}>
                        {title}
                    </div>
                ))}
            </div>
        );
    }

    renderHeader = () => {
        return (
            <span className="p-input-icon-left" style={{ display: "flex", justifyContent: "space-between" }}>
                <Button type="button" icon="pi pi-filter-slash" label="Clear" className="p-button-outlined" onClick={() => { this.dataTable!.reset(); }} />
                <Button label="Create User" className="p-button-outlined" icon="pi pi-plus" onClick={this.addUser} />
            </span>
        );
    };

    addUser = () => {
        history.push("/profile/create");
    };

    render() {
        return (
            <Fragment>
                <Toast ref={(el) => (this.toast = el)} />
                <TransitionGroup appear={true} exit={false} enter={false}>
                    <CSSTransition classNames="TabsAnimation" timeout={1500}>
                        <Fragment>
                            <Container fluid>
                                <PageTitle heading="User Administration" icon={faUserGroup} />
                                <Card className="mb-3">
                                    <CardBody>
                                        <ExtendedDataTable
                                            ref={(ref) => (this.dataTable = ref)}
                                            header={this.renderHeader}
                                            loading={this.state.isLoading}
                                            value={this.state.users}
                                            onChange={this.loadUsersData}
                                            totalRecords={this.state.totalRecords}
                                            defaultSortOrder={SortOrderType.Descending}
                                            defaultSortField='lastLogin'
                                        >
                                            <Column
                                                field="firstName"
                                                header="First Name"
                                                filterMatchModeOptions={stringFilterMatchModeOptions}
                                            ></Column>
                                            <Column
                                                field="lastName"
                                                header="Last Name"
                                                filterMatchModeOptions={stringFilterMatchModeOptions}
                                            ></Column>
                                            <Column
                                                header="Roles"
                                                body={(rowData: OperatorDataModel) =>
                                                    this.renderRolesBadge(rowData.userRoles.map((item) => this.rolesDictionary.get(item)!))
                                                }
                                            ></Column>
                                            <Column
                                                field="merchantName"
                                                header="Merchant"
                                                filterMatchModeOptions={stringFilterMatchModeOptions}
                                            ></Column>
                                            <Column field="email" header="Email" filterMatchModeOptions={stringFilterMatchModeOptions}></Column>
                                            <Column
                                                field="lastLogin"
                                                header="Last Login"
                                                body={(rowData: OperatorDataModel) => (
                                                    <p>
                                                        {moment(rowData.lastLogin).isAfter(moment.utc("0001-01-01"))
                                                            ? moment(rowData.lastLogin).format("DD/MM/YYYY hh:mm:ss")
                                                            : ""}
                                                    </p>
                                                )}
                                                filterMatchModeOptions={numberFilterMatchModeOptions}
                                            ></Column>
                                            <Column
                                                field="status"
                                                header="Status"
                                                filterMatchModeOptions={numberFilterMatchModeOptions}
                                                body={(rowData: OperatorDataModel) =>
                                                    this.renderStatusBadge(rowData.status, this.statusTypes.get(rowData.status)!)
                                                }
                                            ></Column>
                                            <Column
                                                body={this.actionBodyTemplate}
                                                headerStyle={{ width: "8em", textAlign: "center" }}
                                                bodyStyle={{ textAlign: "center", overflow: "visible" }}
                                            />
                                        </ExtendedDataTable>
                                    </CardBody>
                                </Card>
                            </Container>
                        </Fragment>
                    </CSSTransition>
                </TransitionGroup>
            </Fragment>
        );
    }
}
