import React, { Component } from "react";
import { httpService } from "../../services/HttpService";
import { MerchantTariff } from "../../models/MerchantModel";
import { DataTable } from "primereact/datatable";
import { Column, ColumnEditorOptions } from "primereact/column";
import { Card } from "reactstrap";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import TariffDetails from "./TariffDetails";
import { Dropdown } from "primereact/dropdown";
import { SelectItem } from "../../models/SelectItem";
import { isMerchantProfileUpdateAllowed } from "../../authGuard";

interface MerchantTariffProps {
    merchantId: string;
}

interface MerchantTariffState {
    tariffs: MerchantTariff[];
    isLoading: boolean;
    isTariffDetailsVisible: boolean;
    selectedTariff: MerchantTariff | null;
}

export default class MerchantTariffsComponent extends Component<MerchantTariffProps, MerchantTariffState> {
    
    merchants: SelectItem[] = [
        { label: "Merchant 1", value: "Merchant 1" },
        { label: "Merchant 2", value: "Merchant 2" },
        { label: "Merchant 3", value: "Merchant 3" },
        { label: "Merchant 4", value: "Merchant 4" },
    ];
    
    constructor(props: any) {
        super(props);

        this.state = {
            tariffs: [],
            isLoading: true,
            selectedTariff: null,
            isTariffDetailsVisible: false,
        };
    }

    textEditor(options : ColumnEditorOptions) {
        return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback && options.editorCallback(e.target.value)} />;
    }

    dropDownEditor(options:ColumnEditorOptions) {
        return <Dropdown
            value={options.value}
            options={this.merchants}
            optionLabel="label"
            optionValue="value"
            onChange={(e) => options.editorCallback && options.editorCallback(e.value)}
            placeholder='Select Merchant'
            filter
            itemTemplate={(option) => {
                return <span>{option.label}</span>;
            }}
        />;
    }

    async componentDidMount() {
        const tariffs = await httpService.getMerchantTariffsById(this.props.merchantId);

        this.setState({ tariffs: tariffs, isLoading: false });
    }

    renderHeader = () => {
        return (
            <div className="table-header">
                <span className="p-input-icon-left align-right">
                    <i className="pi" />
                    {isMerchantProfileUpdateAllowed() && <Button
                        label="Add Tariff"
                        className="p-button-outlined"
                        icon="pi pi-plus"
                        onClick={() => this.addorEditTariff(this.getEmptyTariff())}
                    />}
                </span>
            </div>
        );
    };

    getEmptyTariff = (): MerchantTariff => {
        return { code: "", details: [], groupNames: [], id: "", name: "", totalUsers: 0, merchantName: "" };
    };

    addorEditTariff = (rowData: MerchantTariff) => {
        this.setState({ isTariffDetailsVisible: true, selectedTariff: rowData });
    };

    deleteTariff = (rowData: MerchantTariff) => {
        this.setState({ tariffs: this.state.tariffs.filter((tariff) => tariff.id != rowData.id) });
    };

    onTariffDetailsModalClose = () => {
        this.setState({ isTariffDetailsVisible: false });
    };

    actionBodyTemplate = (rowData: MerchantTariff) => {
        return (
            <>
                <button type="button" className="p-row-editor-init p-link" tabIndex={0} onClick={() => this.addorEditTariff(rowData)}>
                    <span className="p-row-editor-init-icon pi pi-fw pi-pencil"></span>
                </button>
                <button type="button" className="p-row-editor-cancel p-link" tabIndex={0} onClick={() => this.deleteTariff(rowData)}>
                    <span className="p-row-editor-cancel-icon pi pi-fw pi-times"></span>
                </button>
            </>
        );
    };

    onActionButtonClick = (row: any) => { };

    render() {
        return (
            <Card>
                {this.state.isTariffDetailsVisible && this.state.selectedTariff !=null && <TariffDetails
                    onHide={this.onTariffDetailsModalClose}
                    tariff={this.state.selectedTariff}
                />}
                <DataTable header={this.renderHeader} value={this.state.tariffs}>
                    <Column field="id" header="Id"></Column>
                    <Column field="code" header="Code"></Column>
                    {this.props.merchantId == null && <Column field="merchantName" header="Merchant" editor={(options) => this.dropDownEditor(options)}></Column>}
                    <Column field="name" header="Name" editor={(options) => this.textEditor(options)} style={{ width: "20%" }}></Column>
                    <Column field="groupNames" header="Groups" body={(rowData: MerchantTariff) => <p>{rowData.groupNames.join(" ")}</p>}></Column>
                    <Column field="totalUsers" header="Total Users"></Column>
                    <Column
                        header=""
                        body={this.actionBodyTemplate}
                        headerStyle={{ width: "8em", textAlign: "center" }}
                        bodyStyle={{ textAlign: "center", overflow: "visible" }}
                    />
                </DataTable>
            </Card>
        );
    }
}
