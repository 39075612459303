import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { Component } from "react";
import { Card } from "reactstrap";
import { MerchantContact } from "../../models/MerchantModel";
import { SelectItem } from "../../models/SelectItem";
import { merchantContactApiService } from "../../services/api/merchants/MerchantContactsApiService";
import AddMerchantContact from "./AddMerchantContact";
import { Toast } from "primereact/toast";
import _ from "lodash";
import { confirmDialog } from "primereact/confirmdialog";
import { isMerchantProfileUpdateAllowed } from "../../authGuard";

interface MerchantContactProps {
    merchantId: number;
    localMode: boolean
    onLocalContactUpdated?: (contact: MerchantContact[]) => void;
    merchantContacts?: MerchantContact[]
}

interface MerchantContactState {
    contacts: MerchantContact[];
    isLoading: boolean;
    isContactDetailVisible: boolean;
    selectedContact: MerchantContact | null;
}

export default class MerchantContactComponent extends Component<MerchantContactProps, MerchantContactState> {
    toast: Toast | null = null;

    contactTypes: SelectItem[] = [];

    constructor(props: MerchantContactProps | Readonly<MerchantContactProps>) {
        super(props);

        this.state = {
            contacts: this.props.localMode ? this.props.merchantContacts! : [],
            isLoading: true,
            isContactDetailVisible: false,
            selectedContact: null,
        };
    }

    async componentDidMount() {
        const contactTypes = await merchantContactApiService.getContactTypes();

        if (!contactTypes.success) {
            this.toast?.show({ severity: "error", summary: "Error", detail: contactTypes.message, life: 3000 });
            this.setState({ isLoading: false });
            return;
        }

        this.contactTypes = contactTypes.data;

        this.setState({
            isLoading: false
        });

        await this.loadContacts();
    }

    loadContacts = async () => {
        if(this.props.localMode) {
            return;
        }

        this.setState({ isLoading: true });

        const contacts = await merchantContactApiService.getAll(this.props.merchantId);

        this.setState({ isLoading: false });

        if (!contacts.success) {
            this.toast?.show({ severity: "error", summary: "Error", detail: contacts.message, life: 3000 });
            return;
        }

        this.setState({ contacts: contacts.data });
    };

    renderHeader = () => {
        return (
            <div className="table-header">
                <span className="p-input-icon-left align-right">
                    <i className="pi" />
                    {isMerchantProfileUpdateAllowed() && <Button
                        label="Add Contact"
                        className={"p-button-outlined"}
                        icon="pi pi-plus"
                        onClick={() => this.addorEditContact(this.getEmptyContact())}
                    />}
                </span>
            </div>
        );
    };

    getEmptyContact = (): MerchantContact => {
        return { id: 0, name: "", phone: "", email: "", type: "", merchantId: this.props.merchantId };
    };

    addorEditContact = (rowData: MerchantContact) => {
        this.setState({ isContactDetailVisible: true, selectedContact: rowData });
    };

    onDetailsModalClose = async (contact?: MerchantContact) => {
        this.setState({ isContactDetailVisible: false });

        if(this.props.localMode && contact)
        {
            const contacts = this.state.contacts;
            if(contacts.length < 2 )
            {
                if(contacts.find(i => i.type === contact.type))
                {
                    this.toast?.show({ severity: "error", summary: "Error", detail: "Contact Type already exists", life: 3000 });
                } 
                else
                {
                    contacts.push(contact!);
                    this.setState({ contacts: contacts });
                    this.props.onLocalContactUpdated!(contacts);
                }

            }
            else
            {
                this.toast?.show({ severity: "error", summary: "Error", detail: "Contact limit exceeds.", life: 3000 });
            }
        }
        await this.loadContacts();
    };

    typeBodyTemplate = (rowData: MerchantContact): string => {
        const list = _.filter(this.contactTypes, (c) => c.value == rowData.type);

        return list.length > 0 ? list[0].label : "N/A";
    };

    confirmDeleteContact = async (contact: MerchantContact) => {
        if(this.props.localMode) {
            const contacts = this.state.contacts;
            const index = contacts.findIndex(f => f.email == contact.email && f.name == contact.name && f.phone == contact.phone && f.type == contact.type);

            if (index >= 0) {
                contacts.splice(index, 1);
            }

            this.setState({ contacts: contacts });

            this.props.onLocalContactUpdated!(this.state.contacts);
            return;
        }
        
        confirmDialog({
            message: "This action will remove the contact and is irreversible. Are you sure you want to proceed?",
            header: "Delete Contact",
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            accept: () => this.deleteContact(contact),
            reject: () => {},
        });
    };

    deleteContact = async (rowData: MerchantContact) => {
        const deleteResult = await merchantContactApiService.delete(rowData.id);

        if (deleteResult.success) {
            this.setState({ contacts: this.state.contacts.filter((contact) => contact.id !== rowData.id) });
        } else {
            this.toast?.show({ severity: "error", summary: "Error", detail: deleteResult.message, life: 3000 });
        }
    };

    actionBodyTemplate = (rowData: MerchantContact) => {
        return (
            <>
                {this.props.localMode == false && (
                    <button type="button" className="p-row-editor-init p-link" tabIndex={0} onClick={() => this.addorEditContact(rowData)}>
                        <span className="p-row-editor-init-icon pi pi-fw pi-pencil"></span>
                    </button>)}
                <button type="button" className="p-row-editor-cancel p-link" tabIndex={0} onClick={() => this.confirmDeleteContact(rowData)}>
                    <span className="p-row-editor-cancel-icon pi pi-fw pi-times"></span>
                </button>
            </>
        );
    };

    render() {

        return (
            <Card>
                {this.state.isContactDetailVisible && this.state.selectedContact !=null &&(
                    <AddMerchantContact
                        onHide={this.onDetailsModalClose}
                        merchantId={this.props.merchantId}
                        contact={this.state.selectedContact}
                        types={this.contactTypes}
                        localMode={this.props.localMode}
                    />
                )}
                <Toast ref={(el) => (this.toast = el)}  position={"bottom-center"} baseZIndex={99999}/>
                <DataTable header={this.renderHeader} value={this.state.contacts} loading={this.state.isLoading}>
                    <Column field="id" header="Id"></Column>
                    <Column field="name" header="Contact Name" style={{ width: "20%" }}></Column>
                    <Column field="phone" header="Contact phone" style={{ width: "20%" }}></Column>
                    <Column field="email" header="Contact Email" style={{ width: "20%" }}></Column>
                    <Column field="type" header="Type" body={this.typeBodyTemplate} style={{ width: "20%" }}></Column>
                    <Column
                        header=""
                        body={this.actionBodyTemplate}
                        headerStyle={{ width: "8em", textAlign: "center" }}
                        bodyStyle={{ textAlign: "center", overflow: "visible" }}
                    />
                </DataTable>
            </Card>
        );
    }
}
