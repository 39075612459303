import { Result } from "../../../models/Result";
import { httpService } from "../../HttpService";
import { ICrudApiService } from "../../ICrudApiService";
import { CreateOrUpdateMerchantDocument, MerchantDocument } from "../../../models/MerchantModel";
import { SelectItem } from "../../../models/SelectItem";

class MerchantDocumentApiService implements ICrudApiService<MerchantDocument, CreateOrUpdateMerchantDocument> {
    path: string = "merchantDocument";

    getAll = async (merchantId?: number): Promise<Result<MerchantDocument[]>> => await httpService.get<MerchantDocument[]>(`${this.path}/${merchantId}/all`);

    get = async (Id: number): Promise<Result<MerchantDocument>> => await httpService.get<MerchantDocument>(`/${this.path}/${Id}`);

    create = async (document: CreateOrUpdateMerchantDocument): Promise<Result<MerchantDocument>> => await httpService.post<MerchantDocument>(`/${this.path}`, document);

    update = async (document: CreateOrUpdateMerchantDocument): Promise<Result<MerchantDocument>> => await httpService.put<MerchantDocument>(`/${this.path}`, document);

    delete = async (Id: number): Promise<Result<void>> => await httpService.delete<void>(`/${this.path}/${Id}`);

    getMerchantDocumentsTypes = async (): Promise<Result<SelectItem[]>> => await httpService.get<SelectItem[]>(`/${this.path}/documentTypes`);
   
    getDocumentsStatus = async (): Promise<Result<SelectItem[]>> => await httpService.get<SelectItem[]>(`/${this.path}/documentStatus`);
}

const merchantDocumentApiService = new MerchantDocumentApiService();

export { merchantDocumentApiService };
