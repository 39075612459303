import React, { Component } from "react";
import { MerchantGroup } from "../../models/MerchantModel";
import { DataTable, DataTableRowEditCompleteParams } from "primereact/datatable";
import { Column, ColumnEditorOptions } from "primereact/column";
import { Card } from "reactstrap";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { merchantGroupsApiService } from "../../services/api/merchants/MerchantGroupsApiService";
import { Toast } from "primereact/toast";
import Loader from "../../components/Loader";
import { confirmDialog } from "primereact/confirmdialog";
import ExtendedInputText from "../../components/ExtendedInputText";
import { isMerchantProfileUpdateAllowed } from "../../authGuard";

interface MerchantGroupProps {
    merchantId: number;
}

interface MerchantGroupState {
    groups: MerchantGroup[];
    isLoading: boolean;
    hoveredRow: number | null;
}

export default class MerchantGroupsComponent extends Component<MerchantGroupProps, MerchantGroupState> {
    toast: Toast | null = null;
    validationErrors: Map<string, boolean> = new Map<string, boolean>();
    constructor(props: MerchantGroupProps | Readonly<MerchantGroupProps>) {
        super(props);

        this.state = {
            groups: [],
            isLoading: true,
            hoveredRow: null,
        };
    }

    textEditor(options: ColumnEditorOptions) {
        return (
            <ExtendedInputText
                type="text"
                value={options.value}
                onChange={(value) => options.editorCallback && options.editorCallback(value)}
                name={options.field}
                required={true}
                onValidStatusChanged={(value: boolean) => this.validationErrors.set(options.field, value)}
            />
        );
    }

    onRowHover = (rowData: MerchantGroup) => {
        this.setState({ hoveredRow: rowData.id });
    };

    onRowHoverLeave = () => {
        this.setState({ hoveredRow: null });
    };

    async componentDidMount() {
        this.loadData();
    }
    loadData = async () => {
        this.setState({ isLoading: true });

        const groupResult = await merchantGroupsApiService.getAll(this.props.merchantId);
        this.setState({ isLoading: false });
        if (!groupResult.success) {
            this.toast?.show({ severity: "error", summary: "Error", detail: groupResult.message, life: 3000 });
            return;
        }
        console.log(groupResult.data);
        this.setState({ groups: groupResult.data });
        this.setState({ isLoading: false });
    };

    onRowEditComplete = async (e: DataTableRowEditCompleteParams) => {
        this.setState({ isLoading: true });

        const newData = e.newData as MerchantGroup;

        const result = newData.id != 0 ? await merchantGroupsApiService.update(newData) : await merchantGroupsApiService.create(newData);

        this.setState({ isLoading: false });

        if (!result.success) {
            this.toast?.show({ severity: "error", summary: "Error", detail: result.message, life: 3000 });
            return;
        }

        this.loadData();
    };

    addGroup = () => {
        const groups = this.state.groups;
        groups.push({ id: 0, name: "", totalUsers: 0, merchantId: this.props.merchantId, tariffId: 1, isDefault: false });
        this.setState({ groups: groups });
    };

    renderHeader = () => {
        return (
            <div className="table-header">
                <span className="p-input-icon-left align-right">
                    <i className="pi" />
                    {isMerchantProfileUpdateAllowed() && (
                        <Button label="Add Group" className={"p-button-outlined"} icon="pi pi-plus" onClick={this.addGroup} />
                    )}
                </span>
            </div>
        );
    };

    confirmDeleteGroup = async (rowData: MerchantGroup) => {
        if (rowData.id === 0) {
            this.setState({ groups: this.state.groups.filter((group) => group.id !== rowData.id) });
            return;
        }

        confirmDialog({
            message: "This action will remove the group and is irreversible. Are you sure you want to proceed?",
            header: "Delete Group",
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            accept: () => this.deleteGroup(rowData),
            reject: () => {},
        });
    };

    changeMerchantStatus = async (rowData: MerchantGroup) => {
        this.setState({ isLoading: true });
        if (rowData.id === 0) {
            this.setState({ groups: this.state.groups.filter((group) => group.id !== rowData.id) });
            return;
        }
        const result = await merchantGroupsApiService.changeDefaultMerchant(rowData.id);

        if (!result.success) {
            this.setState({ isLoading: false });
            this.toast?.show({ severity: "error", summary: "Error", detail: result.message, life: 3000 });
        }
        this.loadData();
    };

    deleteGroup = async (rowData: MerchantGroup) => {
        const deleteResult = await merchantGroupsApiService.delete(rowData.id);

        if (deleteResult.success) {
            this.setState({ groups: this.state.groups.filter((group) => group.id !== rowData.id) });
        } else {
            this.toast?.show({ severity: "error", summary: "Error", detail: deleteResult.message, life: 3000 });
        }
    };

    actionBodyTemplate = (rowData: MerchantGroup) => {
        const isHovered = this.state.hoveredRow === rowData.id;

        return (
            <div className="d-flex align-items-center">
                <button type="button" className="p-row-editor-cancel p-link m-2" tabIndex={0} onClick={() => this.confirmDeleteGroup(rowData)}>
                    <span className="p-row-editor-cancel-icon pi pi-fw pi-times"></span>
                </button>

                <Button
                    label="Set Default Merchant"
                    type="button"
                    className="p-button p-button-warning p-button-sm m-2"
                    onClick={() => this.changeMerchantStatus(rowData)}
                />
            </div>
        );
    };

    render() {
        return (
            <Card>
                <Toast ref={(el) => (this.toast = el)} />
                <DataTable
                    header={this.renderHeader}
                    value={this.state.groups}
                    editMode="row"
                    dataKey="id"
                    loading={this.state.isLoading}
                    onRowEditComplete={this.onRowEditComplete}
                >
                    <Column field="id" header="Id"></Column>
                    <Column field="name" header="Name" editor={(options) => this.textEditor(options)} style={{ width: "20%" }}></Column>
                    <Column field="totalUsers" header="Total Users"></Column>
                    <Column field="isDefault" header="Is Default Merchant"></Column>
                    <Column rowEditor headerStyle={{ width: "10%", minWidth: "8rem" }} bodyStyle={{ textAlign: "center" }}></Column>
                    <Column
                        header=""
                        body={this.actionBodyTemplate}
                        headerStyle={{ width: "8em", textAlign: "center" }}
                        bodyStyle={{ textAlign: "center", overflow: "visible" }}
                    ></Column>
                </DataTable>
            </Card>
        );
    }
}
