import { Result } from "../../../models/Result";
import { httpService } from "../../HttpService";
import { ICrudApiService } from "../../ICrudApiService";
import { MerchantGroup } from "../../../models/MerchantModel";

class MerchantGroupsApiService implements ICrudApiService<MerchantGroup> {
    path: string = "MerchantGroup";

    getAll = async (merchantId?: number): Promise<Result<MerchantGroup[]>> => await httpService.get<MerchantGroup[]>(`${this.path}/${merchantId}/all`);

    get = async (Id: number): Promise<Result<MerchantGroup>> => await httpService.get<MerchantGroup>(`/${this.path}/${Id}`);

    create = async (group: MerchantGroup): Promise<Result<MerchantGroup>> => await httpService.post<MerchantGroup>(`/${this.path}`, group);

    update = async (group: MerchantGroup): Promise<Result<MerchantGroup>> => await httpService.put<MerchantGroup>(`/${this.path}`, group);

    changeDefaultMerchant = async (merchantId?: number): Promise<Result<void>> => await httpService.put<void>(`/${this.path}/${merchantId}/default`,{});


    delete = async (Id: number): Promise<Result<void>> => await httpService.delete<void>(`/${this.path}/${Id}`);
}

const merchantGroupsApiService = new MerchantGroupsApiService();

export { merchantGroupsApiService };
