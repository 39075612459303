import { PagedResult, Result } from "../models/Result";
import { httpService } from "./HttpService";
import { SftpTransaction } from "../models/Transactions";
import { SearchRequestBaseModel } from "../models/SearchRequestModel";

class SFTPService {
   
    baseApiUrl: string = process.env.REACT_APP_SFTP_API_BASE_URL ?? "";

    ListFiles = async (searchRequest: SearchRequestBaseModel) : Promise<PagedResult<SftpTransaction[]>> => {
        return await httpService.getPage<SftpTransaction[]>("/sftp/search", searchRequest);
    };

    GetFileContent = async (fileName : string) : Promise<Result<string>> => {
        return await httpService.get<string>(`/api/v1/SFTP/${encodeURIComponent(fileName)}`, { baseURL: this.baseApiUrl });
    };
}

const sftpService = new SFTPService();

export { sftpService };