import React, { Component } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { MoneyTransferRequestDetails } from '../../models/MoneyTransferModal';
import { utils } from '../../utils/utils';
import { Card, CardBody, Col, Label, Row } from 'reactstrap';


interface TransferFundsConfirmDialogueProps {
  transactionDetails: MoneyTransferRequestDetails;
  sourceSelectedCurrency: string;
  beneficiarySelectedCurrency: string;
  isSubmitting: boolean;
  onSubmit: () => void;
  onHide: () => void;
  p2pTransfer: boolean;
  p2AnyIbanTransfer: boolean;
}

class TransferFundsConfirmDialogue extends Component<TransferFundsConfirmDialogueProps> {
  render() {
    const { transactionDetails, isSubmitting, onHide, onSubmit, p2pTransfer, p2AnyIbanTransfer } = this.props;


    return (
      <Dialog header={p2pTransfer ? "Confirm Wallet Trasnfer" : "Confirm External IBAN Transfer "} visible={!!transactionDetails} style={{ width: '60vw' }} onHide={onHide}
        modal
        blockScroll
        draggable={false}
        resizable={false}
      >
        <Card className="m-0">
          <CardBody>
            <div className="p-fluid">
              {p2pTransfer && (
                <Row>
                  <Col md={'4'}>
                    <Label >Source Email:</Label>
                  </Col>
                  <Col md={'8'} >
                    <div> {transactionDetails.sourceCustomerEmail}</div>
                  </Col>
                </Row>
              )}

              <Row>
                <Col md={'4'}>
                  <Label >Source Name:</Label>
                </Col>
                <Col md={'8'} >
                  <div> {transactionDetails.sourceCustomerName}</div>
                </Col>
              </Row>

              <Row className='mb-1'>
                <Col md={'4'}>
                  <Label >Source Account:</Label>
                </Col>
                <Col md={'8'} >
                  <div>{transactionDetails.sourceCustomerAccount} <p className='text-success d-inline'> ({transactionDetails.sourceCustomerCurrency})</p></div>
                </Col>
              </Row>

              <Row className='mb-1'>
                <Col md={'4'}>
                  <Label >Balance:</Label>
                </Col>
                <Col md={'8'} >
                  <div className='text-success d-inline'>{utils.formatBalance(transactionDetails.sourceCurrentBalance, transactionDetails.sourceCustomerCurrency)}</div>
                </Col>
              </Row>

              {p2pTransfer && (
                <Row className='mb-1'>
                  <Col md={'4'}>
                    <Label >Beneficiary Email:</Label>
                  </Col>
                  <Col md={'8'} >
                    <div>{transactionDetails.beneficiaryCustomerEmail}</div>
                  </Col>
                </Row>
              )}

                <Row className='mb-1'>
                  <Col md={'4'}>
                    <Label >Beneficiary Name:</Label>
                  </Col>
                  <Col md={'8'} >
                    <div>{transactionDetails.beneficiaryName}</div>
                  </Col>
                </Row>
              <Row className='mb-1'>
                <Col md={'4'}>

                  {p2AnyIbanTransfer ? (
                    <Label >Beneficiary IBAN:</Label>
                  ) : p2pTransfer ? (
                    <Label >Beneficiary Account:</Label>
                  ) : (
                    ""
                  )}
                </Col>
                <Col>
                  <div>
                    {transactionDetails.beneficiaryCustomerAccount}
                    {p2AnyIbanTransfer ? "" : p2pTransfer ? <p className='text-success d-inline'> ({transactionDetails.beneficiaryCustomerCurrency})</p> : ""}
                  </div>
                </Col>
              </Row>

              <Row className='mb-1'>
                <Col md={'4'}>
                  <Label >Amount:</Label>
                </Col>
                <Col>
                  <div className='text-success d-inline'>{utils.formatBalance(transactionDetails.transactionAmount, transactionDetails.sourceCustomerCurrency)}</div>
                </Col>
              </Row>

              <Row className='mb-1'>
                <Col md={'4'}>
                  <Label >Transaction Fee:</Label>
                </Col>
                <Col md={'8'} >
                  <div className='text-success d-inline'>{utils.formatBalance(transactionDetails.transactionFee, transactionDetails.sourceCustomerCurrency)}</div>
                </Col>
              </Row>

              <Row className='mb-1'>
                <Col md={'4'}>
                  <Label >Payment Notes:</Label>
                </Col>
                <Col md={'8'} >
                  <div>{transactionDetails.paymentNotes}</div>
                </Col>
              </Row>

              <div className="d-flex justify-content-end align-items-end">
                <div>
                  <Button type="button" icon="pi pi-check" className="p-button p-button-success" iconPos="right" label="Proceed" onClick={onSubmit} loading={isSubmitting} disabled={isSubmitting} />
                </div>
              </div>

            </div>
          </CardBody>
        </Card>



      </Dialog>
    );
  }
}

export default TransferFundsConfirmDialogue;
