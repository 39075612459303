export interface TransactionReportResponse {
    id: string,
    type: string,
    timeStamp: string,
    amount: number,
    currency: string,
    otherSide: string,
    status: PaymentStatus,
    fee: number,
    senderEmail: string,
    senderName: string,
    senderSource: string,
    senderMerchant: string,
    recepientEmail: string,
    recepientName: string,
    recepientDestination: string,
    senderAuthToken: string,
    senderAuthData: string,
    senderIp: string,
    duration: string,
    cbsTransactionId: string,
    cbsDetails: string,
    reason: string,
    walletId: string,
    recepienMerchant: string,
    feeCurrency: string;
    accountNumber: string;
    senderBic: string;
    receiverBic: string;
    senderIban: string;
    receiverIban: string;
    reference: string;
    paymentSystem: string;
    transactionCategory: string;
    cardNetwork: string;
    cardPan: string;
    cardAlias:string;
    paymentProvider:string;
    purposeDet: string;
    wireTransferReturnCategory: string;
    wireTransferReturnStatus: string;
    wireTransferRelatedTransId: string;
    wireTransferReason: string;
}

export interface AllTransactionReportResponse {
    transactions: TransactionReportDetails[];
    transactionCount: number;
}

export interface TransactionReportDetails {
	amount: number;
	merchant: string;
	timeStamp: string;
	senderEmail: string;
	transactionId: string;
	transactionFee: number;
	feeCurrency: string;
	senderName: string;
	receiverName: string;
	accountNumber: string;
	senderBic: string;
	receiverBic: string;
	senderIban: string;
	receiverIban: string;
	transactionType: string;
	paymentNotes: string;
	currency: string;
	paymentSystem: string;
	transactionCategory: string;
	cardNetwork: string;
	cardPan: string;
	cardAlias: string;
	paymentProvider: string;
	purposeDet: string;
	scaStatus: string;
    receiverEmail: string;
    wireTransferReturnCategory: string;
    wireTransferReturnStatus: string;
    wireTransferRelatedTransId: string;
    wireTransferReason: string;
    // extra field
    senderAuthToken: string,
    senderAuthData: string,
    senderIp: string,
    duration: string,
    cbsTransactionId: string,
    cbsDetails: string,
}


export interface TransactionReport {
   sender: SenderReceiver,
   receiver: SenderReceiver
}


export interface SenderReceiver {
    amount: number;
    merchant: string;
    timestamp: string;
    userEmail: string;
    transactionId: string;
    transactionFee: number;
    feeCurrency: string;
    sideA: string;
    sideB: string;
    accountNumber: string;
    senderBic: string;
    receiverBic: string;
    senderIban: string;
    receiverIban: string;
    type: string;
    reference: string;
    currency: string;
    paymentSystem: string;
    transactionCategory: string;
    cardNetwork: string;
    cardPan: string;
    cardAlias:string;
    paymentProvider:string;
    purposeDet: string;
}

export interface TransactionRequestModel {
    transactionId: string
}

export interface AllTransactionRequestModel {
    transactionID: string;
    customerID: string;
    trxDirection: string;
    accountID: string;
    amountFrom: string;
    amountTo: string;
    iban: string;
    paymentSystem: string;
    dataBatch: number;
    timestampFrom: string;
    timestampTo: string;
    externalRefNo: string;
    currency: string;
    individualId: number;
    merchant: string;
    dataBatchCount: number;
  }

export interface Transaction {
    walletId: string,
    source: string,
    transactionType: string,
    transactionTimestamp: Date,
    cbsDate: Date,
    amount: number,
    amountFcy: number,
    currency: string,
    status: PaymentStatus,
    referenceNumber: string,
    branchDescription: string,
    financialYear: string,
    fcModule: string,
    fcModuleDescription: string,
    transactionCode: string,
    valueDate: string
}

export interface SftpTransaction {
    to : string
    from : string
    user : string
    action : string
    fileName : string
    actionType : string
    fileTimeStamp : Date | null
    transferStatus : string
    actionTimeStamp : Date | null
}

export enum TransactionType {
    Deposit,
    Withdraw,
    Payment,
    Send,
    Charge,
    Fee,
    Other
}

export const TransType = {
    "C" : "Credit",
    "D" : "Debit"
};
  
export enum PaymentStatus {
    Processed,
    Pending,
    Cancelled,
    Declined,
}

export const TransactionStatus = {
    "Rejected" : "Revoke Rejected",
    "Accepted" : "Revoke Accepted",
}