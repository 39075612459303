import React from "react";
import { Container, Row, Col, Card } from "reactstrap";
import { HoveredWidget } from "../../components/HoveredWidget";
import { faCreditCard, faMoneyCheck } from "@fortawesome/free-solid-svg-icons";

export enum DepositPaymentMethodType {
    Bank,
    Card,
    NonIBAN,
    EPay
}

interface DepositSelectPaymentMethodProps {
    OnSelect: (type: DepositPaymentMethodType) => void;
    showEPay: boolean;
}

export class DepositSelectPaymentMethod extends React.Component<DepositSelectPaymentMethodProps, any> {
    
    selectPaymentType = (type: DepositPaymentMethodType) => {
        this.props.OnSelect(type);
    };

    render(): React.ReactNode {
        return (<Container fluid className="short-widget-numbers">
            <Row>
                <Col>
                    <Card className="main-card mb-3">
                        <div className="grid-menu grid-menu-2col">
                            <Row className="no-gutters">
                                <Col>
                                    <HoveredWidget textClass="info" small={true} icon={faCreditCard} description="" title="Via Debit/Credit Card" onClick={() => this.selectPaymentType(DepositPaymentMethodType.Card)} />
                                </Col>
                                <Col>
                                    <HoveredWidget textClass="info" small={true} icon={faMoneyCheck} description="" title="Via Bank Account" onClick={() => this.selectPaymentType(DepositPaymentMethodType.Bank)} />
                                </Col>
                                <Col>
                                    <HoveredWidget textClass="info" small={true} icon={faCreditCard} description="" title="Via Non-IBAN" onClick={() => this.selectPaymentType(DepositPaymentMethodType.NonIBAN)} />
                                </Col>
                                {this.props.showEPay && <Col>
                                    <HoveredWidget textClass="info" small={true} icon={faCreditCard} description="" title="Via EPay" onClick={() => this.selectPaymentType(DepositPaymentMethodType.EPay)} />
                                </Col>}
                            </Row>
                        </div>
                    </Card>
                </Col>
            </Row>
        </Container>);
    }
}