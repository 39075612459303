import moment from "moment";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Fragment, Component, ReactNode } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Card, CardBody, CardHeader, Container } from "reactstrap";
import Loader from "../../components/Loader";
import { SftpTransaction } from "../../models/Transactions";
import { sftpService } from "../../services/SFTPService";
import { dateFilterTemplate, numberFilterMatchModeOptions, stringFilterMatchModeOptions } from "../Paginator";
import { ActionBodyTemplate } from "./TransactionFilterTemplates";
import "./style.css";
import ExtendedDataTable from "../../components/ExtendedDataTable";
import { SearchRequestBaseModel, SortOrderType } from "../../models/SearchRequestModel";
import { Toast } from "primereact/toast";
import { utils } from "../../utils/utils";

interface SFTPTransactionsState {
    transactions: SftpTransaction[]
    isLoading: boolean;
    totalRecords: number;
    transactionDetailVisible: boolean
    selectedTransaction: string | null;
}

class SFTPTransactions extends Component<any, SFTPTransactionsState> {
    private toast: Toast | null = null;
    private dataTable: ExtendedDataTable | null = null;

    constructor(props: any) {
        super(props);

        this.state = {
            transactions: [],
            isLoading: false,
            totalRecords: 0,
            transactionDetailVisible: false,
            selectedTransaction: null
        };
    }

    onActionButtonClick = async (rowData: SftpTransaction) => {
        this.setState({ isLoading: true });

        const contentResult = await sftpService.GetFileContent(rowData.fileName);

        this.setState({ isLoading: false });

        if (!contentResult.success) {
            this.toast?.show({ severity: "error", summary: "Error", detail: contentResult.message, life: 3000 });
            return;
        }

        this.setState({ transactionDetailVisible: true, selectedTransaction: contentResult.data });
    };

    renderIcon = (rowData: SftpTransaction) => {

        if(rowData.action == null) {
            return "";
        }

        return (
            <i
                className={
                    rowData.action.toLowerCase() == "in" 
                        ? "pi pi-arrow-circle-right"
                        : "pi pi-arrow-circle-left"
                }
                style={{ fontSize: "1.5em" }}
            ></i>
        );
    };

    hideTransactionDetails = () => {
        this.setState({ transactionDetailVisible: false, selectedTransaction: null });
    };

    renderTransactionDetail = () => {
        if (!this.state.transactionDetailVisible) {
            return "";
        }

        if (this.state.selectedTransaction == null) {
            return (
                <Dialog header="Transaction Details" visible={true} style={{ width: "70vw" }} onHide={() => this.hideTransactionDetails()}>
                    <Loader />
                </Dialog>
            );
        }

        return (
            <Dialog header="Transaction Details" visible={true} style={{ width: "70vw" }} onHide={() => this.hideTransactionDetails()}>
                <TransitionGroup component="div" appear={true} exit={false} enter={false}>
                    <CSSTransition classNames="TabsAnimation" timeout={1500}>
                        <Fragment>
                            <Container fluid>
                                <pre className="prettyprint">{this.state.selectedTransaction}</pre>
                            </Container>
                        </Fragment>
                    </CSSTransition>
                </TransitionGroup>
            </Dialog>
        );
    };

    loadTransactionsData = async (searchRequest: SearchRequestBaseModel) => {
        this.setState({ isLoading: true });
        searchRequest.filters = utils.manageDataTableFilters(searchRequest.filters);
        const transactionsResult = await sftpService.ListFiles(searchRequest);

        this.setState({ isLoading: false });

        if (!transactionsResult.success) {
            this.toast?.show({ severity: "error", summary: "Error", detail: transactionsResult.message, life: 3000 });
        }

        this.setState({ transactions: transactionsResult.data, totalRecords: transactionsResult.totalCount });
    };

    renderDate = (date : Date | null) => {
        if(date == null) {
            return <p></p>;
        }

        return <p>{moment(date).format("DD/MM/YYYY hh:mm:ss")}</p>;
    };

    render(): ReactNode {
        return (
            <>
                <Card className="mb-3">
                    {this.renderTransactionDetail()}
                    <Toast ref={(el) => (this.toast = el)} position={"bottom-center"} baseZIndex={99999}></Toast>
                    <CardHeader className="card-header-tab">
                        <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                            <i className="header-icon lnr-laptop-phone mr-3 text-muted opacity-6"> </i>
                            SFTP Files Exchanged
                        </div>
                    </CardHeader>
                    <CardBody>
                        <ExtendedDataTable
                            ref={(ref) => this.dataTable = ref}
                            value={this.state.transactions}
                            onChange={this.loadTransactionsData}
                            loading={this.state.isLoading}
                            totalRecords={this.state.totalRecords}
                            defaultSortOrder={SortOrderType.Descending}
                            defaultSortField='fileTimeStamp'
                        >
                            <Column field="to" header="To" filterMatchModeOptions={stringFilterMatchModeOptions} />    
                            <Column field="from" header="From" filterMatchModeOptions={stringFilterMatchModeOptions} />    
                            <Column field="fileName" header="File Name" filterMatchModeOptions={stringFilterMatchModeOptions} />    
                            <Column field="user" header="User" filterMatchModeOptions={stringFilterMatchModeOptions} />    
                            <Column header="Icon" body={(rowData: SftpTransaction) => this.renderIcon(rowData)} />
                            <Column field="action" header="Action" filterMatchModeOptions={stringFilterMatchModeOptions} />    
                            <Column field="actionType" header="Action Type" filterMatchModeOptions={stringFilterMatchModeOptions} />    
                            <Column 
                                field="fileTimeStamp"
                                header="File TimeStamp"
                                body={(rowData: SftpTransaction) => this.renderDate(rowData.actionTimeStamp)}
                                dataType="date"
                                filterElement={dateFilterTemplate}    
                                filterMatchModeOptions={numberFilterMatchModeOptions}
                            />
                            <Column field="transferStatus" header="Transfer Status" filterMatchModeOptions={stringFilterMatchModeOptions} />
                            <Column
                                field="actionTimeStamp"
                                header="TimeStamp"
                                body={(rowData: SftpTransaction) => this.renderDate(rowData.actionTimeStamp)}
                                dataType="date"
                                filterElement={dateFilterTemplate}    
                                filterMatchModeOptions={numberFilterMatchModeOptions}
                            />    
                            <Column
                                header="View Content"
                                body={(rowData: SftpTransaction) => <ActionBodyTemplate data={rowData} onClickAction={this.onActionButtonClick} />}
                            />
                        </ExtendedDataTable>
                    </CardBody>
                </Card>
            </>
        );
    }
}

export default SFTPTransactions;
