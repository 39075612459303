export class SearchRequestBaseModel {
    filters: IFilterModel[] = [];
    sortings: ISortModel[] = [];
    pageNumber: number = 1;
    pageSize: number = 10;
}

export interface IFilterModel {
    columnName: string;
    operator: string;
    value: string;
}

export interface ISortModel {
    sortOrder: number;
    sortBy: string;
}

export enum SortOrderType {
    Ascending = 1,
    Descending = -1
}