import { IndividualWalletBalanceRequest, IndividualWalletCloseRequest, Wallets } from "../../../models/FinancialData";
import { Result } from "../../../models/Result";
import { httpService } from "../../HttpService";

class UserWalletApiService {
    path: string = "IndividualWallet";

    getWallets = async(userId: number): Promise<Result<Wallets[]>> => await httpService.get<Wallets[]>(`/${this.path}/${userId}/wallets`);

    getWalletBalance = async (request: IndividualWalletBalanceRequest): Promise<Result<Wallets>> => await httpService.post<Wallets>(`/${this.path}/wallet-balance`, request);
        
    closeWallet = async (request: IndividualWalletCloseRequest): Promise<Result<void>> => await httpService.post<void>(`/${this.path}/close-wallet`, request);
}

const userWalletApiService = new UserWalletApiService();

export {userWalletApiService};