import React, { Component, Fragment } from "react";
import { Container, Dropdown } from "reactstrap";
import { User } from "../../models/User";
import { DataTable, DataTablePFSEvent } from "primereact/datatable";
import { Column } from "primereact/column";
import { classNames } from "primereact/utils";
import moment from "moment";
import { CSSTransitionComponent } from "../../components/CSSTransition";
import Loader from "../../components/Loader";
import { Toast } from "primereact/toast";
import { merchantApiService } from "../../services/api/merchants/MerchantApiService";
import { userApiService } from "../../services/api/users/UsersApiService";

interface MerchantUsersState {
    users: User[];
    first: number;
    rows: number;
    isLoading: boolean;
}

interface MerchantUsersProps {
    merchantId: number;
}

export default class MerchantUsers extends Component<MerchantUsersProps, MerchantUsersState> {
    toast: Toast | null = null;
    constructor(props: MerchantUsersProps | Readonly<MerchantUsersProps>) {
        super(props);

        this.state = {
            users: [],
            first: 0,
            rows: 10,
            isLoading: true,
        };
    }

    async componentDidMount() {

        this.setState({ isLoading: true });

        const usersResult = await merchantApiService.getUsers(this.props.merchantId);

        this.setState({ isLoading: false });

        if (!usersResult.success) {
            this.toast?.show({ severity: "error", summary: "Error", detail: usersResult.message, life: 3000 });
            return;
        }

        this.setState({ users: usersResult.data });

        const users = this.state.users ?? [];
        users.filter(a => a.cbsOnboarded).forEach((user) => {
            userApiService.getUserBalance(user.id).then(item => {
                const stateUsers = this.state.users;
                const index = stateUsers.findIndex(a => a.id == user.id);
                stateUsers[index].walletBalance = item.data?.walletBalance ?? 0;
                stateUsers[index].walletId = item.data?.walletId;
                stateUsers[index].currency = item.data?.currency;

                this.setState({ users: stateUsers });
            }).catch((_) => {});
        });
    }

    onPageChange = (event : DataTablePFSEvent) => {
        this.setState({
            first: event.first,
            rows: event.rows,
        });
    };

    getPaginatorTemplate = () => {
        return {
            layout: "RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink",
            RowsPerPageDropdown: (options: { value: any; onChange: React.FormEventHandler<HTMLElement>; }) => {
                const dropdownOptions = [
                    { label: 10, value: 10 },
                    { label: 20, value: 20 },
                    { label: 50, value: 50 },
                ];

                return (
                    <>
                        <span className="p-mx-1" style={{ color: "var(--text-color)", userSelect: "none" }}>
                            Items per page:{" "}
                        </span>
                        <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} appendTo={document.body} />
                    </>
                );
            },
            CurrentPageReport: (options: { first: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal; last: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal; totalRecords: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal; }) => {
                return (
                    <span style={{ color: "var(--text-color)", userSelect: "none", width: "120px", textAlign: "center" }}>
                        {options.first} - {options.last} of {options.totalRecords}
                    </span>
                );
            },
        };
    };

    renderStatusBadge(success: number, title: string) {
        return <div className={classNames("badge", "badge-" + (success === 200 ? "success" : "danger"))}>{title}</div>;
    }

    render() {
        if (this.state.isLoading) {
            return <>
                <Toast ref={(el) => this.toast = el} />
                <Loader />
            </>;
        }

        const template = {
            layout: "RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink",
            RowsPerPageDropdown: (options: { value: any; onChange: React.FormEventHandler<HTMLElement>; }) => {
                const dropdownOptions = [
                    { label: 10, value: 10 },
                    { label: 20, value: 20 },
                    { label: 50, value: 50 },
                ];

                return (
                    <>
                        <span className="p-mx-1" style={{ color: "var(--text-color)", userSelect: "none" }}>
                            Items per page:{" "}
                        </span>
                        <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} appendTo={document.body} />
                    </>
                );
            },
            CurrentPageReport: (options: { first: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal; last: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal; totalRecords: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal; }) => {
                return (
                    <span style={{ color: "var(--text-color)", userSelect: "none", width: "120px", textAlign: "center" }}>
                        {options.first} - {options.last} of {options.totalRecords}
                    </span>
                );
            },
        } as any;

        return (
            <Fragment>
                <Toast ref={(el) => this.toast = el} />
                <CSSTransitionComponent>
                    <Fragment>
                        <Container fluid>
                            <DataTable
                                value={this.state.users}
                                stripedRows={true}
                                paginator
                                paginatorTemplate={template}
                                first={this.state.first}
                                rows={this.state.rows}
                                onPage={this.onPageChange}
                                paginatorClassName="p-jc-end"
                            >
                                <Column field="id" header="User Id" sortable></Column>
                                <Column field="firstName" header="First Name" sortable></Column>
                                <Column field="lastName" header="Last Name" sortable></Column>
                                <Column field="email" header="Email" sortable></Column>
                                <Column field="walletId" header="Wallet Id" sortable></Column>
                                <Column field="walletBalance" header="Balance" sortable></Column>
                                <Column field="merchantGroup" header="Group" sortable></Column>
                                <Column
                                    field="lastLogin"
                                    header="Last Login"
                                    body={(rowData: User) => (
                                        <p>
                                            {moment(rowData.lastLogin).isAfter(moment.utc("0001-01-01"))
                                                ? moment(rowData.lastLogin).format("DD/MM/YYYY hh:mm:ss")
                                                : ""}
                                        </p>
                                    )}
                                    sortable
                                ></Column>
                                <Column
                                    field="active"
                                    header="Status"
                                    sortable
                                    body={(rowData: User) => this.renderStatusBadge(rowData.status, rowData.status === 200 ? "Active" : "Inactive")}
                                ></Column>
                            </DataTable>
                        </Container>
                    </Fragment>
                </CSSTransitionComponent>
            </Fragment>
        );
    }
}
