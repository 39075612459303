import React, { Component } from "react";

import { Toast } from "primereact/toast";
import { Comments } from "../../models/Comments";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { Card, CardBody, CardHeader } from "reactstrap";
import Loader from "../../components/Loader";

interface ViewCommentsState {
    isLoading: boolean;
    comments: Comments[];
}

export default class UserCommentsComponent extends Component<{}, ViewCommentsState> {
    toast: Toast | null = null;

    constructor(props : any | Readonly<any>) {
        super(props);

        this.state = {
            isLoading: false,
            comments: [],
        };
    }

    actionBodyTemplate(rowData : any) {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" />
            </React.Fragment>
        );
    }

    render() {
        if (this.state.isLoading) {
            return (
                <>
                    <Toast ref={(el) => (this.toast = el)} />
                    <Loader />
                </>
            );
        }

        return (
            <Card>
                <CardHeader className="card-header-tab">
                    <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                        <i className="header-icon lnr-laptop-phone mr-3 text-muted opacity-6"> </i>
                        Comments
                    </div>
                </CardHeader>
                <CardBody>
                    <Toast ref={(el) => this.toast = el} />
                    <DataTable value={this.state.comments} emptyMessage="No Comments available" stripedRows={true} showGridlines>
                        <Column field="text" header="Comment Text"></Column>
                        <Column body={this.actionBodyTemplate}></Column>
                    </DataTable>
                </CardBody>
            </Card>
        );
    }
}
