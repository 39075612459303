import { PaymentMethodType } from "../../../models/FinancialData";
import { CreateIbanPaymentMethodRequest, MerchantPaymentMethod, CreateCardPaymentMethodRequest, CreateNonIbanPaymentMethodRequest } from "../../../models/MerchantModel";
import { Result } from "../../../models/Result";
import { httpService } from "../../HttpService";

class MerchantPaymentMethodApiService {
    path: string = "merchantPaymentMethod";

    getAll = async (merchantId: number): Promise<Result<MerchantPaymentMethod[]>> => await httpService.get<MerchantPaymentMethod[]>(`${this.path}/${merchantId}`);

    create = async (merchantId: number, item: CreateIbanPaymentMethodRequest | CreateCardPaymentMethodRequest | CreateNonIbanPaymentMethodRequest): Promise<Result<void>> => await httpService.post<void>(`/${this.path}/${merchantId}/${this.getPaymentTypePath(item)}`, item);

    update = async (merchantId: number, item: CreateIbanPaymentMethodRequest | CreateCardPaymentMethodRequest | CreateNonIbanPaymentMethodRequest): Promise<Result<void>> => await httpService.put<void>(`/${this.path}/${merchantId}/${this.getPaymentTypePath(item)}`, item);

    delete = async (merchantId: number, cardOrIbanNumber: string ): Promise<Result<void>> => await httpService.delete<void>(`/${this.path}/${merchantId}/${cardOrIbanNumber}`);

    getPaymentTypePath = (item: CreateIbanPaymentMethodRequest | CreateCardPaymentMethodRequest | CreateNonIbanPaymentMethodRequest) => item.paymentType === PaymentMethodType.Bank ? "iban" : item.paymentType === PaymentMethodType.Card ? "card" : "non-iban";
}

const merchantPaymentMethodApiService = new MerchantPaymentMethodApiService();

export { merchantPaymentMethodApiService };
