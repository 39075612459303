export interface MoneyTransferRequestDetails {
	sourceCustomerEmail?: string;
    sourceCustomerName: string;
	sourceCustomerAccount: string;
	sourceCustomerCurrency: string;
	beneficiaryCustomerAccount: string;
	beneficiaryCustomerEmail?: string;
	beneficiaryCustomerCurrency?: string;
	transactionAmount: string;
	paymentNotes: string;
	transactionFee?: number;
	beneficiaryName: string;
	sourceCurrentBalance: number;
	p2AnyIbanTransferId?: number;
}

export interface WithdrawToAnyIBANMoneyTransferRequestDetails {
	sourceCustomerAccount: string;
    sourceCustomerName: string;
	sourceCustomerCurrency: string;
	beneficiaryCustomerAccount: string;
	beneficiaryCustomerBankName: string;
	beneficiaryCustomerCurrency: string;
	beneficiaryCustomerName: string;
	transactionAmount: number;
	paymentNotes: string;
	transactionFee: number;
	sourceCurrentBalance: number;
}

export interface WithdrawToAnyIBANMoneyTransferRequest {
	sourceCustomerAccount: string;
	beneficiaryCustomerAccount: string;
	beneficiaryCustomerName: string;
	amount: number;
	notes?: string;
	currency: string;
}

export interface BankTransferInitiateRequestModel {
	sourceEntityType: TennEntityType;
	sourceEntityId: number;
	sourceCustomerAccount: string;
	beneficiaryCustomerBIC: string;
	beneficiaryCustomerIBAN: string;
	beneficiaryCustomerName: string;
	amount: number;
	paymentNotes?: string | null;
	currency: string;
}

export enum TennEntityType {
	Individual = 1,
	Merchant = 2
}

export enum BankTransferResponseCode {
	OK = 0,
	RiskReject = 1,
	SourceOfFundsRequired = 2,
	UnderReiew = 3
}

export interface PointToPointMoneyTransferRequestModel {
	sourceCustomerAccount: string;
	beneficiaryCustomerAccount: string;
	transactionAmount: number;
	paymentNotes?: string;
}


export interface CalculateMoneyTransferChargeRequest {
	customerAccount: string;
	amount: number;
	id: number;
	PaymentType: string;
}

export const PaymentType = {
	P2P: "P2P",
	P2IbanWithdrawal: "P2IbanWithdrawal",
	P2IBAN: "P2IBAN",
	DepositFromMasterCard: "DepositFromMasterCard",
	DepositFromVisaCard: "DepositFromVisaCard",
	DepositFromOtherCard: "DepositFromOtherCard",
	WithdrawalMasterCard: "WithdrawalMasterCard",
	withdrawalVisaCard: "WithdrawalVisaCard",
	WithdrawalOtherCard: "WithdrawalOtherCard"
};


export interface PointToPointMoneyTransferRequestModel {
	sourceCustomerAccount: string;
	beneficiaryCustomerAccount: string;
	transactionAmount: number;
	paymentNotes?: string;
}


export interface ValidateIbanRequest {
	iban: string;
}

export interface ValidateIbanResponse {
	iban: string;
	bic: string;
	bank: string;
	branch?: string;
	country?: string;
	city?: string;
	state?: string;
	zip?: string;
	address?: string;
	account?: string;
	timeStamp: Date;
}

export interface CalculateMoneyTransferChargeRequest {
	customerAccount: string;
	amount: number;
	id: number;
	PaymentType: string;
}

export interface MoneyTransferChargeResponse {
	accountNo: string;
	accountBalance: number;
	accountCurrency: string;
	transactionAmount: number;
	transactionCurrency: string;
	transactionFee: number;
	transactionFeeCurrency: string;
}

export enum PaymentMethodStatus {
	Approve = "1",
	UnderReview = "2",
	Reject = "3"
}

export const PaymentMethodType = {
	Card: "card",
	Bank: "bank",
    NonIBAN: "non-iban"
}