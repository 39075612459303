import React, { Fragment, PureComponent } from "react";

import AppHeader from "./AppHeader";
import AppSidebar from "./AppSidebar";

class MainLayout extends PureComponent {
    render(): React.ReactNode {
        return <Fragment>
            <AppHeader />
            <div className="app-main">
                <AppSidebar />
                <div className="app-main__outer">
                    <div className="app-main__inner">
                        {this.props.children}
                    </div>
                </div>
            </div>
        </Fragment>;
    }
}

export default MainLayout;
