import React, { Component } from "react";
import { OperatorDataModel } from "../../models/User";
import { Toast } from "primereact/toast";
import "./userprofile.scss";
import Loader from "../../components/Loader";
import { utils } from "../../utils/utils";
import _ from "lodash";
import { operatorsApiService } from "../../services/api/users/OperatorApiService";
import { RoleModel } from "../../models/Role";
import ProfileShared from "./ProfileShared";
import { rolesApiService } from "../../services/api/users/RolesApiService";

interface ProfileState {
    userId: number | null;
    roles: RoleModel[];
    user: OperatorDataModel | null;
    isLoading: boolean;
}

export default class UpdateOperator extends Component<{}, ProfileState> {
    toast: Toast | null = null;

    constructor(props: {}) {
        super(props);

        const userId = utils.getIdentifierFromPath();
        this.state = {
            userId: isNaN(userId) ? null : userId,
            roles: [],
            user: null,
            isLoading: true,
        };
    }

    async componentDidMount() {
        if (this.state.userId == null) {
            return;
        }

        this.setState({ isLoading: true });

        const userProfile = await operatorsApiService.getById(this.state.userId);

        if (!userProfile.success) {
            this.setState({ isLoading: false });
            this.toast?.show({ severity: "error", summary: "Error", detail: userProfile.message, life: 3000 });
            return;
        }

        const roleResult = await rolesApiService.getAllByMerchant(parseInt(userProfile!.data.merchantId!));

        if (!roleResult.success) {
            this.setState({ isLoading: false });
            this.toast?.show({ severity: "error", summary: "Error", detail: roleResult.message, life: 3000 });
            return;
        }

        this.setState({ isLoading: false, user: userProfile.data, roles: roleResult.data });
    }

    onSubmitButtonClick = async () => {
        this.setState({ isLoading: true });

        const newData = _.cloneDeep(this.state.user);

        const result = await operatorsApiService.update(newData!);

        if (!result.success) {
            this.setState({ isLoading: false });
            this.toast?.show({ severity: "error", summary: "Error", detail: result.message, life: 3000 });
            return;
        }

        const userProfile = await operatorsApiService.getById(this.state.userId!);

        if (!userProfile.success) {
            this.setState({ isLoading: false });
            this.toast?.show({ severity: "error", summary: "Error", detail: userProfile.message, life: 3000 });
            return;
        }
        this.toast?.show({ severity: "success", summary: "Success", detail: "Operation completed successfully.", life: 3000 });
        this.setState({ isLoading: false, user: userProfile.data });
    };

    render() {
        if (this.state.isLoading || this.state.user === null) {
            return (
                <>
                    <Toast ref={(el) => (this.toast = el)} />
                    <Loader />
                </>
            );
        }

        return (
            <>
                <Toast ref={(el) => (this.toast = el)} position={"bottom-center"} baseZIndex={99999} />
                <ProfileShared
                    allowResetPhoneAndMobile={false}
                    isEditMode={true}
                    showMerchant={false}
                    showPassword={false}
                    showRoles={true}
                    merchants={[]}
                    roles={this.state.roles}
                    user={this.state.user}
                    onUpdate={(user) => this.setState({ user: user })}
                    onSubmit={this.onSubmitButtonClick}
                />
            </>
        );
    }
}
