import Dashboard from "./Pages/DashboardComponent";
import UserProfile from "./Pages/Users/UserProfile";
import Users from "./Pages/Users/Users";
import MerchantDashboard from "./Pages/MerchantDashboard";
import RequestLogsComponent from "./Pages/RequestLogsComponent";
import KycSubmissionComponent from "./Pages/Kyc/KycSubmissions";
import CreateMerchant from "./Pages/Merchants/CreateMerchant";
import Merchants from "./Pages/Merchants/Merchants";
import Tariffs from "./Pages/Tariffs";
import MerchantDetails from "./Pages/Merchants/MerchantDetails";
import UsersAdministration from "./Pages/Users/UsersAdministration";
import UpdateOperator from "./Pages/Users/UpdateOperator";
import CreateOperatorComponent from "./Pages/Users/CreateOperator";
import Currencies from "./Pages/Currencies";
import { isCRMAllAllowed, isKYCApproveAllowed, isKYCAssessAllowed, isMerchantAllAllowed } from "./authGuard";
import JournalingComponent from "./Pages/JournalingComponent";
import ReviewBankTransfersOutgoing from "./Pages/Compliance/ReviewBankTransfersOutgoing";
import { storageService } from "./services/StorageService";

export interface Route {
    label: string,
    path: string,
    component: any,
    componentProps: object,
    permissionName: string,
    visibleInNav: boolean,
}
const checkIsMerchantAllAllowed = isMerchantAllAllowed();

export const routes: Route[] = [
    {
        label: "Dashboard",
        path: "/dashboard",
        component: Dashboard,
        componentProps: {},
        permissionName: "crm:dashboard:view",
        visibleInNav: true,
    },
    {
        label: "Merchant Dashboard",
        path: "/merchantdashboard",
        component: MerchantDashboard,
        componentProps: {},
        permissionName: "crm:merchantdashboard:view",
        visibleInNav: true,
    },
    {
        label: "Customers",
        path: "/users",
        component: Users,
        componentProps: { merchantId: 1 },
        permissionName: "crm:users:view",
        visibleInNav: true,
    },
    {
        label: "",
        path: "/user-profile/:id",
        component: UserProfile,
        componentProps: {},
        permissionName: "crm:users:viewdetails",
        visibleInNav: false,
    },
    {
        label: "Tariffs and Currencies",
        path: "/tariffs",
        component: Tariffs,
        componentProps: {},
        permissionName: "crm:merchants:view",
        visibleInNav: true,
    },
    {
        label: checkIsMerchantAllAllowed ? "Merchants" : "Merchant Profile",
        path: checkIsMerchantAllAllowed ? "/merchants" : `/merchant/${storageService.getSelectedMerchantId()}`,
        component: checkIsMerchantAllAllowed ? Merchants : MerchantDetails,
        componentProps: {},
        permissionName: "crm:merchants:view",
        visibleInNav: true,
    },
    // {
    //     label: "Currencies",
    //     path: "/currencies",
    //     component: Currencies,
    //     componentProps: {},
    //     permissionName: "crm:merchants:all",
    //     visibleInNav: true,
    // },
    {
        label: "",
        path: "/merchant/create",
        component: CreateMerchant,
        componentProps: {},
        permissionName: "crm:merchants:create",
        visibleInNav: false,
    },
    {
        label: "",
        path: "/merchant/:id",
        component: MerchantDetails,
        componentProps: {},
        permissionName: "crm:merchants:viewdetails",
        visibleInNav: false,
    },
    {
        label: "Kyc",
        path: "/kyc",
        component: KycSubmissionComponent,
        componentProps: {},
        permissionName: "crm:kyc-submission:view",
        visibleInNav: ((isKYCApproveAllowed() || isKYCAssessAllowed())) ? true : false,
    },
    {
        label: "Review Bank Transfers",
        path: "/Compliance",
        component: ReviewBankTransfersOutgoing,
        componentProps: {},
        permissionName: "crm:kyc-submission:view",
        visibleInNav: ((isKYCApproveAllowed() || isKYCAssessAllowed())) ? true : false,
    },    
    {
        label: "Journaling",
        path: "/journaling",
        component: JournalingComponent,
        componentProps: {},
        permissionName: "crm:journaling:view",
        visibleInNav: true,
    },
    {
        label: "Access Logs",
        path: "/accesslogs",
        component: RequestLogsComponent,
        componentProps: {},
        permissionName: "crm:journaling:view",
        visibleInNav: true,
    },
    {
        label: "User Administration",
        path: "/useradministration",
        component: UsersAdministration,
        componentProps: {},
        permissionName: "crm:operators-and-roles:view-user",
        visibleInNav: isCRMAllAllowed() ? true : false,
    },
    {
        label: "User Administration",
        path: "/profile/:id",
        component: UpdateOperator,
        componentProps: {},
        permissionName: "crm:operators-and-roles:update-operator",
        visibleInNav: false,
    },
    {
        label: "User Administration",
        path: "/profile/create",
        component: CreateOperatorComponent,
        componentProps: { create: true },
        permissionName: "crm:operators-and-roles:view-user",
        visibleInNav: false,
    }
];

export const OperationNav = [
    {
        label: "All Transaction",
        to: "/all-transactions",
    },
    // {
    //     label: "Outgoing SEPA TRX",
    //     to: "/outgoing-sepa-transactions",
    // },
    // {
    //     label: "Outgoing Swift TRX",
    //     to: "/outgoing-swift-transactions",
    // }, 
    // {
    //     label: "Outgoing BG TRX (Bisera)",
    //     to: "/outgoing-bg-transactions"
    // },
    // {
    //     label: "Internal TRX (P2P)",
    //     to: "/internal-p2p-transactions"
    // },
    // {
    //     label: "Incoming IBAN Transfers",
    //     to: "/incoming-iban-transfers"
    // },
    // {
    //     label: "Incoming Card Deposits",
    //     to: "/incoming-card-deposits"
    // },
    {
        label: "Pending TRX",
        to: "/pending-transactions"
    },
    {
        label: "SFTP Files Exchanged",
        to: "sftp-files-exchanged"
    }
];