import React, { Component, Fragment } from "react";

import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Col, Container, Label, Row } from "reactstrap";
import { PaymentStatus, TransactionReportDetails, TransactionRequestModel, TransactionStatus } from "../../models/Transactions";
import moment from "moment";
import { Fieldset } from "primereact/fieldset";
import { InputText } from "primereact/inputtext";
import Loader from "../../components/Loader";
import { transactionsReportApiService } from "../../services/api/operations/TransactionsReportApiService";
import ReturnTransactionDetailsDialog from "./ReturnTransactionDetails";
import ReactLoader from "react-loaders";

interface TransactionDetailsDialogProps {
    isVisible: boolean;
    onHide: () => void;
    transaction: TransactionReportDetails | any;
    editable: boolean;
}

interface TransactionDetailsDialogState {
    isDetailVisible: boolean;
    transaction: TransactionReportDetails | any;
    viewTransactionDetails: boolean
    selectedTransaction: TransactionReportDetails | null;
}

export default class TransactionDetailsDialog extends Component<TransactionDetailsDialogProps, TransactionDetailsDialogState> {
    toast: Toast | null = null;
    constructor(props: TransactionDetailsDialogProps | Readonly<TransactionDetailsDialogProps>) {
        super(props);

        this.state = {
            isDetailVisible: false,
            transaction: this.props.transaction,
            viewTransactionDetails: false,
            selectedTransaction: null
        };
    }

    toggleAdvancedMode = () => {
        this.setState({ isDetailVisible: !this.state.isDetailVisible });
    };

    getTrasaction = async (id: string) => {
        this.setState({ isDetailVisible: true });
        const request: TransactionRequestModel = {
            transactionId: id
        }
        const transactionResult = await transactionsReportApiService.getTransactionById(request);
        if (!transactionResult.success) {
            this.setState({ isDetailVisible: false });
            this.toast?.show({ severity: "error", summary: "Error", detail: transactionResult.message, life: 3000 });
            return;
        }

        this.setState({ isDetailVisible: false, selectedTransaction: transactionResult.data });
    };

    render() {
        // if (this.state.isDetailVisible) {
        //     return (
        //         <>
        //             <Toast ref={(el) => (this.toast = el)} />
        //             <Loader />
        //         </>
        //     );
        // }

        if (this.state.transaction == null) {
            return "";
        }

        if (!this.props.isVisible) {
            return "";
        }

        return (
            <Dialog header="Transaction Details" visible={this.props.isVisible} style={{ width: "70vw" }} onHide={this.props.onHide}>
                <TransitionGroup component="div" appear={true} exit={false} enter={false}>
                    <CSSTransition classNames="TabsAnimation" timeout={1500}>
                        <Fragment>
                            <Toast ref={(el) => (this.toast = el)} position={"bottom-center"} baseZIndex={99999}></Toast>

                            {this.state.isDetailVisible &&
                                <Dialog
                                    closable={false} visible={this.state.isDetailVisible} onHide={() => { }}>
                                    <ReactLoader type="ball-grid-pulse" active />
                                </Dialog>
                            }

                            {this.state.selectedTransaction != null && (
                                <ReturnTransactionDetailsDialog
                                    onHide={() => this.setState({ isDetailVisible: false, selectedTransaction: null })}
                                    transaction={this.state.selectedTransaction}
                                />
                            )}

                            <Container fluid>
                                <Fieldset legend="">
                                    {renderDualRow(
                                        "Transaction Id",
                                        this.state.transaction.transactionId,
                                        false,
                                        (newVal: string) => { },
                                        "Type",
                                        // TransactionType[this.state.transaction.type],
                                        this.state.transaction.transactionType,
                                        false,
                                        () => { }
                                    )}
                                    {renderDualRow(
                                        "Timestamp",
                                        moment(this.state.transaction.timeStamp).format("DD/MM/YYYY hh:mm:ss"),
                                        false,
                                        () => { },
                                        "Amount",
                                        this.state.transaction.amount,
                                        this.props.editable,
                                        (newVal: any) => {
                                            const transaction = this.state.transaction;
                                            transaction.amount = newVal;
                                            this.setState({ transaction: transaction });
                                        }
                                    )}
                                    {renderDualRow(
                                        "Transaction Fee",
                                        this.state.transaction.transactionFee,
                                        this.props.editable,
                                        (newVal: any) => {
                                            const transaction = this.state.transaction;

                                            transaction.transactionFee = newVal;
                                            this.setState({ transaction: transaction });
                                        },
                                        "Currency",
                                        this.state.transaction.currency,
                                        false,
                                        (newVal: string) => { },

                                    )}


                                    {renderDualRow("Status",
                                        this.state.transaction.wireTransferReturnStatus ? this.state.transaction.wireTransferReturnStatus : PaymentStatus[0],
                                        false, (newVal: string) => { },

                                        "Transaction Category",
                                        this.state.transaction.transactionCategory,
                                        this.props.editable,
                                        (newVal: any) => {
                                            const transaction = this.state.transaction;
                                            transaction.transactionCategory = newVal;
                                            this.setState({ transaction: transaction });
                                        }
                                    )}

                                    {this.state.transaction.wireTransferRelatedTransId ?
                                        renderDualRow("Return Transaction Id", this.state.transaction.wireTransferRelatedTransId,
                                            false, () => { }, null, "", false, () => { },
                                            true, () => { this.getTrasaction(this.state.transaction.wireTransferRelatedTransId) })
                                        :
                                        this.state.transaction.wireTransferReason ?
                                            renderDualRow("Reason", this.state.transaction.wireTransferReason,
                                                false, () => { }, null, "", false, () => { })
                                        : ""
                                    }

                                </Fieldset>

                                <h5></h5>

                                <Fieldset legend="Sender Info">
                                    {renderDualRow(
                                        // "User",
                                        // this.state.transaction.senderEmail,
                                        // this.props.editable,
                                        // (newVal: string) => {
                                        //     const transaction = this.state.transaction;
                                        //     transaction.senderEmail = newVal;
                                        //     this.setState({ transaction: transaction });
                                        // },
                                        "Name",
                                        this.state.transaction.senderName,
                                        this.props.editable,
                                        (newVal: string) => {
                                            const transaction = this.state.transaction;
                                            transaction.senderName = newVal;
                                            this.setState({ transaction: transaction });
                                        }
                                    )}
                                    {renderDualRow(
                                        "Source",
                                        this.state.transaction.senderIban,
                                        this.props.editable,
                                        (newVal: string) => {
                                            const transaction = this.state.transaction;
                                            transaction.senderIban = newVal;
                                            this.setState({ transaction: transaction });
                                        },
                                        "Merchant",
                                        this.state.transaction.merchant,
                                        false,
                                        (newVal: string) => { }
                                    )}
                                </Fieldset>

                                <h5></h5>

                                <Fieldset legend="Recepient Info">
                                    {renderDualRow(
                                        // "User",
                                        // this.state.transaction.receiverEmail,
                                        // this.props.editable,
                                        // (newVal: string) => {
                                        //     const transaction = this.state.transaction;
                                        //     transaction.receiverEmail = newVal;
                                        //     this.setState({ transaction: transaction });
                                        // },
                                        "Name",
                                        this.state.transaction.receiverName,
                                        this.props.editable,
                                        (newVal: string) => {
                                            const transaction = this.state.transaction;
                                            transaction.receiverName = newVal;
                                            this.setState({ transaction: transaction });
                                        }
                                    )}
                                    {renderDualRow("Destination", this.state.transaction.receiverIban, this.props.editable, (newVal: string) => {
                                        const transaction = this.state.transaction;
                                        transaction.receiverIban = newVal;
                                        this.setState({ transaction: transaction });
                                    })}
                                </Fieldset>
                                <h5></h5>

                                <Fieldset legend="Advanced Info" toggleable={true} collapsed={true}>
                                    {renderDualRow(
                                        "Sender Authorization Token",
                                        this.state.transaction.senderAuthData,
                                        false,
                                        (newVal: string) => { },
                                        "Sender Authorization Data",
                                        this.state.transaction.senderAuthData,
                                        false,
                                        (newVal: string) => { }
                                    )}
                                    {renderDualRow(
                                        "Sender Ip",
                                        this.state.transaction.senderIp,
                                        false,
                                        (newVal: string) => { },
                                        "Transaction Duration",
                                        this.state.transaction.duration,
                                        false,
                                        (newVal: string) => { }
                                    )}
                                    {renderDualRow(
                                        "CBS Transaction Id",
                                        this.state.transaction.cbsTransactionId,
                                        false,
                                        (newVal: string) => { },
                                        "CBS Details",
                                        this.state.transaction.cbsDetails,
                                        false,
                                        (newVal: string) => { }
                                    )}
                                </Fieldset>
                            </Container>
                            <br></br>
                            <Fieldset legend="Transaction Details">
                                <pre>
                                    <code>
                                        {this.props.transaction ? JSON.stringify(this.props.transaction, null, 2) : ""}
                                    </code>
                                </pre>
                            </Fieldset>

                        </Fragment>
                    </CSSTransition>
                </TransitionGroup>
            </Dialog>
        );
    }
}

export function renderDualRow(
    title1: string,
    val1: string | number,
    editable1: boolean,
    onEdit1: (newval: string) => void,
    title2: string | null = null,
    val2: string | number = null as any,
    editable2: boolean = null as any,
    onEdit2: (newval: string) => void = null as any,
    link?: boolean,
    linkFun?: () => void,
) {
    return (
        <Row>
            <Col md={6}>
                <Row>
                    <Col md={6}>
                        <Label>
                            <b>{title1} :</b>
                        </Label>
                    </Col>
                    <Col md={6}>
                        {editable1 ? (
                            <InputText type="text" value={val1} onChange={(e) => onEdit1(e.target.value)} />
                        ) :

                            link ?
                                (<a
                                    href="#"
                                    style={{ color: 'blue', textDecoration: 'underline' }}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        linkFun!();
                                    }}
                                >
                                    {val1}
                                </a>
                                )
                                :
                                (
                                    <Label>{val1}</Label>
                                )}
                    </Col>
                </Row>
            </Col>
            {title2 != null && (
                <Col md={6}>
                    <Row>
                        <Col md={6}>
                            <Label>
                                <b>{title2} :</b>
                            </Label>
                        </Col>
                        <Col md={6}>
                            {editable2 ? (
                                <InputText type="text" value={val2} onChange={(e) => onEdit2(e.target.value)} />
                            ) : (
                                <Label>{val2}</Label>
                            )}
                        </Col>
                    </Row>
                </Col>
            )}
        </Row>
    );
}