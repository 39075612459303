import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import React, { Fragment } from "react";
import { CreateCardPaymentMethodRequest, CreateIbanPaymentMethodRequest, CreateNonIbanPaymentMethodRequest } from "../../models/MerchantModel";
import { AddBank } from "./AddBank";
import { AddCard } from "./AddCard";
import { AddNonIBAN } from "./AddNonIBAN";
import { PaymentMethodType, SelectPaymentMethod } from "./SelectPaymentMethod";
import { storageService } from "../../services/StorageService";

interface AddPaymentMethodDialogProps{
    isSubmitting: boolean
    onHide: () => void;
    onSuccess:(item: CreateCardPaymentMethodRequest | CreateIbanPaymentMethodRequest | CreateNonIbanPaymentMethodRequest) => void;
}

interface AddPaymentMethodDialogState {
    paymentMethod: PaymentMethodType | null
    currentStage: CurrentStage
}

enum CurrentStage {
    PaymentMethod,
    EnterDetails
}

export class AddPaymentMethodDialog extends React.Component<AddPaymentMethodDialogProps, AddPaymentMethodDialogState>{

    constructor(props: AddPaymentMethodDialogProps | Readonly<AddPaymentMethodDialogProps>){
        super(props);
        this.state = {
            paymentMethod: null,
            currentStage: CurrentStage.PaymentMethod
        };
    }

    async componentDidMount() {
    }

    selectPaymentMethod = (type: PaymentMethodType | null) => {
        this.setState({ paymentMethod: type, currentStage: CurrentStage.EnterDetails });
    };

    onBack = () => {
        this.setState({ paymentMethod: null, currentStage: CurrentStage.PaymentMethod });
    };

    renderBody = () => {
        if (this.state.currentStage === CurrentStage.PaymentMethod) {
            return <SelectPaymentMethod OnSelect={(type) => { this.selectPaymentMethod(type); }} />;
        }

        if (this.state.currentStage === CurrentStage.EnterDetails && this.state.paymentMethod === PaymentMethodType.Bank) {
            return <AddBank isSubmitting={this.props.isSubmitting} selectedMerchantId={storageService.getLoggedInUserMerchantId()} OnBack={this.onBack} OnSuccess={this.props.onSuccess} />;
        }

        if (this.state.currentStage === CurrentStage.EnterDetails && this.state.paymentMethod === PaymentMethodType.Card) {
            return <AddCard OnBack={this.onBack} OnSuccess={this.props.onSuccess} />;
        }

        if (this.state.currentStage === CurrentStage.EnterDetails && this.state.paymentMethod === PaymentMethodType.NonIBAN) {
            return <AddNonIBAN OnBack={this.onBack} OnSuccess={this.props.onSuccess} />;
        }

        return "";
    };

    render() {
        return (
            <Dialog header="Add Payment Method" visible={true} style={{ width: "60vw" }} onHide={this.props.onHide}>
                <Fragment>
                    {this.renderBody()}
                </Fragment>
            </Dialog>
        );
    }
}